// @flow

import type { FrotherSurveyType } from 'services/Survey/types';
import type { ImmutableMap } from 'types';

import { fromJS } from 'immutable';

function getFrotherCustomBlendTotal(survey: FrotherSurveyType): number {
    if (!survey) {
        return 0;
    }

    // Building block percentages are always 0 or another number
    const buildingBlockPercentageOne = survey.get('frotherBuildingBlockIdOnePercentage');
    const buildingBlockPercentageTwo = survey.get('frotherBuildingBlockIdTwoPercentage');
    const buildingBlockPercentageThree = survey.get('frotherBuildingBlockIdThreePercentage');

    return buildingBlockPercentageOne + buildingBlockPercentageTwo + buildingBlockPercentageThree;
}

export function isSolvayFrotherSelected(survey: FrotherSurveyType) {
    return (
        survey.get('knowTheFrother') &&
        survey.get('isFrotherSolvay') &&
        survey.get('frotherProductId')
    );
}

export function knowFrotherCompositionAtTopPercent(survey: FrotherSurveyType) {
    return (
        survey.get('knowTheFrother') &&
        survey.get('knowFrotherComposition') &&
        getFrotherCustomBlendTotal(survey) === 100
    );
}

/**
 * Get the disable rule result if a field should be disabled for the Frother Survey Primary Questionare
 * @param {*} survey
 * @param {*} key
 */
export function getIndividualPrimaryQuestionDisableRule(survey: FrotherSurveyType, key: string) {
    switch (key) {
        case 'usingAModifier':
            return survey.get('contentNonSulfideGangueSlimesSignificantlyImpacting') !== 1;
        case 'preFloatForTalc':
            return survey.get('greaterThanOneTalcInFeed') !== 1;
        default:
            return false;
    }
}

/**
 * Get the disable rule result if a field should be disabled for the Frother Survey Custom Blend question
 * @param {*} survey
 * @param {*} fieldName
 */
export function getIndividualCustomBlendDisableRule(survey: FrotherSurveyType, fieldName: string) {
    switch (fieldName) {
        case 'isFrotherSolvay':
            return survey.get('knowTheFrother') !== 1;
        case 'frotherProductId':
            return survey.get('knowTheFrother') !== 1 || survey.get('isFrotherSolvay') !== 1;
        case 'knowFrotherComposition':
            return survey.get('knowTheFrother') !== 1 || survey.get('isFrotherSolvay') !== 0;
        case 'frotherBuildingBlockIdOne':
            return (
                survey.get('knowTheFrother') !== 1 ||
                survey.get('isFrotherSolvay') !== 0 ||
                survey.get('knowFrotherComposition') !== 1
            );
        case 'frotherBuildingBlockIdTwo':
            return (
                survey.get('knowTheFrother') !== 1 ||
                survey.get('isFrotherSolvay') !== 0 ||
                survey.get('knowFrotherComposition') !== 1 ||
                !survey.get('frotherBuildingBlockIdOne') ||
                !survey.get('frotherBuildingBlockIdOnePercentage')
            );
        case 'frotherBuildingBlockIdThree':
            return (
                survey.get('knowTheFrother') !== 1 ||
                survey.get('isFrotherSolvay') !== 0 ||
                survey.get('knowFrotherComposition') !== 1 ||
                !survey.get('frotherBuildingBlockIdOne') ||
                !survey.get('frotherBuildingBlockIdOnePercentage') ||
                !survey.get('frotherBuildingBlockIdTwo') ||
                !survey.get('frotherBuildingBlockIdTwoPercentage')
            );
        default:
            return false;
    }
}

/**
 * Function to change the survey answers from a frother.
 * Depending on some answer, other ones can be nullified
 *
 *
 * @param {*} survey
 */
export function changeFrotherSurveyAnswers(survey: FrotherSurveyType) {
    const newSurvey: FrotherSurveyType = survey.toJS();
    const knowTheFrother = newSurvey.knowTheFrother;
    const isFrotherSolvay = newSurvey.isFrotherSolvay;
    const knowFrotherComposition = newSurvey.knowFrotherComposition;

    // Conditional questions can be null if parent question is false.
    if (!newSurvey.contentNonSulfideGangueSlimesSignificantlyImpacting) {
        newSurvey.usingAModifier = null;
    }
    if (!newSurvey.greaterThanOneTalcInFeed) {
        newSurvey.preFloatForTalc = null;
    }

    if (knowTheFrother) {
        // If the frother is not from solvay, frother product id must be set to null
        if (!isFrotherSolvay) {
            newSurvey.frotherProductId = null;
        }

        // If the composition is not known, frother building block must be set to null
        if (!knowFrotherComposition || isFrotherSolvay) {
            newSurvey.frotherBuildingBlockIdOne = null;
            newSurvey.frotherBuildingBlockIdOnePercentage = null;
            newSurvey.frotherBuildingBlockIdTwo = null;
            newSurvey.frotherBuildingBlockIdTwoPercentage = null;
            newSurvey.frotherBuildingBlockIdThree = null;
            newSurvey.frotherBuildingBlockIdThreePercentage = null;
        }
    }
    // If the frother is unknown, every other questions must be setted to null
    else {
        newSurvey.isFrotherSolvay = 0;
        newSurvey.frotherProductId = null;
        newSurvey.knowFrotherComposition = 0;
        newSurvey.frotherBuildingBlockIdOne = null;
        newSurvey.frotherBuildingBlockIdOnePercentage = null;
        newSurvey.frotherBuildingBlockIdTwo = null;
        newSurvey.frotherBuildingBlockIdTwoPercentage = null;
        newSurvey.frotherBuildingBlockIdThree = null;
        newSurvey.frotherBuildingBlockIdThreePercentage = null;
    }

    return fromJS(newSurvey);
}
