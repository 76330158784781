// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { SubtleAnchor, Table } from 'OsedeaReactUI';

// Constants
import { PRODUCT_AVAILABILITY_LINK } from 'utils/constants';

// Styles
import {
    Container,
    // CountryRestrictions,
    TableTitle,
    ProductOfferContainer,
} from './styles';

// Types
import type { ImmutableMap, IntlType } from 'types';
import type { ProductAvailabilityType, ShipmentType } from 'services/Product/types';

type Props = {
    intl: IntlType,
    product: ImmutableMap<string, string>,
};

/**
 * ProductTableDetails
 *
 * Toggleable product details which can be expanded and collapsed. Renders product data such as
 * commercial and material availability.
 */

class ProductTableDetails extends React.PureComponent<Props> {
    /**
     * Returns an array of objects where each object represents a table header in the commercials
     * table.
     */
    getCommercialTableHeaders = () => [
        {
            label: this.props.intl.formatMessage({
                id: 'components.ProductTable.CommercialTable.header.shipTo',
            }),
            id: 'companyName',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ProductTable.CommercialTable.header.salesRep',
            }),
            id: 'salesRep',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ProductTable.CommercialTable.header.endUse',
            }),
            id: 'endUse',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ProductTable.CommercialTable.header.application',
            }),
            id: 'application',
        },
    ];

    /**
     * Returns an array of objects. Each object contains commercial properties which will be
     * rendered to the corresponding table data.
     */
    getMappedCommercialTableRows = () =>
        this.props.product.get('sales').map(
            (shipment: ShipmentType) =>
                shipment && {
                    id: shipment.get('id'),
                    companyName: shipment.get('companyName')
                        ? shipment.get('companyName')
                        : this.props.intl.formatMessage({
                              id: 'common.notAssigned',
                          }),
                    salesRep: shipment.get('salesRep')
                        ? shipment.get('salesRep')
                        : this.props.intl.formatMessage({
                              id: 'common.notAssigned',
                          }),
                    endUse: shipment.get('endUse')
                        ? shipment.get('endUse')
                        : this.props.intl.formatMessage({
                              id: 'common.notAssigned',
                          }),
                    application: shipment.get('application')
                        ? shipment.get('application')
                        : this.props.intl.formatMessage({
                              id: 'common.notAssigned',
                          }),
                }
        );

    /**
     * Returns an array of objects where each object represents a table header in the availability
     * table.
     */
    getAvailabilityTableHeaders = () => [
        {
            label: this.props.intl.formatMessage({
                id: 'components.ProductTable.AvailabilityTable.header.materialCode',
            }),
            id: 'materialCode',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ProductTable.AvailabilityTable.header.materialDescription',
            }),
            id: 'materialDescription',
        },
        // {
        //     label: this.props.intl.formatMessage({
        //         id: 'components.ProductTable.AvailabilityTable.header.leadTime',
        //     }),
        //     id: 'leadtime',
        // },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ProductTable.AvailabilityTable.header.location',
            }),
            id: 'location',
        },
    ];

    /**
     * Returns an array of objects. Each object contains availability properties which will be
     * rendered to the corresponding table data.
     */
    getMappedMaterialAvailabilityTableRows = () =>
        this.props.product &&
        this.props.product.get('availabilities').map(
            (item: ProductAvailabilityType) =>
                item && {
                    id: item.get('id'),
                    materialCode: item.get('materialCode'),
                    materialDescription: item.get('materialDescription'),
                    leadTime: item.get('leadTime'),
                    location: item.get('location'),
                }
        );

    render() {
        const { intl } = this.props;
        const commercialRows = this.getMappedCommercialTableRows();
        const availabilityRows = this.getMappedMaterialAvailabilityTableRows();

        // TODO: Replace with real count.
        // const COUNTRY_RESTRICTIONS_COUNT = 8;
        return (
            <Container>
                {/* TODO: un-comment this when Country Restrictions Data available */}
                {/* <CountryRestrictions>
                    <FormattedMessage id={'components.ProductTable.countryRestrictions'} />
                    <FormattedMessage
                        id={'components.ProductTable.countryRestrictions.count'}
                        values={{ count: COUNTRY_RESTRICTIONS_COUNT }}
                    >
                        {(text: string) => <ModalTrigger>{text}</ModalTrigger>}
                    </FormattedMessage>
                </CountryRestrictions> */}
                <div>
                    <TableTitle>
                        {intl.formatMessage({
                            id: 'components.ProductTable.CommercialTable.title',
                        })}
                    </TableTitle>
                    {commercialRows.size ? (
                        <Table
                            header={this.getCommercialTableHeaders()}
                            rows={commercialRows}
                            subtleStyling
                        />
                    ) : (
                        intl.formatMessage({
                            id: 'components.ProductTable.CommercialTable.noResults',
                        })
                    )}
                </div>
                <div>
                    <TableTitle>
                        {intl.formatMessage({
                            id: 'components.ProductTable.AvailabilityTable.title',
                        })}
                    </TableTitle>
                    {availabilityRows.size ? (
                        <React.Fragment>
                            <Table
                                header={this.getAvailabilityTableHeaders()}
                                rows={availabilityRows}
                                subtleStyling
                            />
                        </React.Fragment>
                    ) : (
                        intl.formatMessage({
                            id: 'components.ProductTable.CommercialTable.noResults',
                        })
                    )}
                </div>
                <ProductOfferContainer>
                    {/* Clickable "View Product Offer Book" link */}
                    <SubtleAnchor
                        href={PRODUCT_AVAILABILITY_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                        externalLinkIcon
                    >
                        <u>
                            {intl.formatMessage({
                                id:
                                    'components.ProductTable.AvailabilityTable.viewProductOffer.clickable',
                            })}
                        </u>
                    </SubtleAnchor>
                    {/* Product offer link details */}
                    <div>
                        {intl.formatMessage({
                            id: 'components.ProductTable.AvailabilityTable.viewProductOffer',
                        })}
                    </div>
                </ProductOfferContainer>
            </Container>
        );
    }
}

export default injectIntl(ProductTableDetails);
