// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, Header, Body, Footer, AreYouSureText, DangerText } from './styles';

// Constants
import { MODAL_WIDTH } from 'utils/constants';

// Components
import { Title } from 'styles/common';
import { PrimaryButton, WarningButton, SecondaryButton, Modal } from 'OsedeaReactUI';

// Types
import type { IntlType, ErrorType } from 'types';

type Props = {
    intl: IntlType,
    title: string,
    areYouSureStart?: string,
    areYouSureDanger?: string,
    areYouSureEnd?: string,
    confirmButtonText: string,
    danger?: boolean,

    errors: ErrorType,
    loading: boolean,
    onConfirm: () => void,
    onCancel: () => void,
};

class ConfirmationModal extends React.PureComponent<Props> {
    static defaultProps = {
        areYouSureDanger: null,
        areYouSureEnd: null,
        areYouSureStart: null,
        danger: true,
    };

    render() {
        return (
            <Modal
                modalWidth={MODAL_WIDTH.LARGE}
                onHandleClose={this.props.onCancel}
                style={{
                    wrapper: {
                        zIndex: '999999',
                    },
                }}
                disableClose
            >
                <Wrapper>
                    <Header>
                        <Title>{this.props.title}</Title>
                    </Header>
                    <Body>
                        <AreYouSureText>
                            {this.props.areYouSureStart}
                            {this.props.areYouSureDanger && (
                                <DangerText>{this.props.areYouSureDanger}</DangerText>
                            )}
                            {this.props.areYouSureEnd && this.props.areYouSureEnd}
                        </AreYouSureText>
                    </Body>
                    <Footer>
                        <SecondaryButton
                            text={this.props.intl.formatMessage({
                                id: 'components.ConfirmationModal.cancelButton',
                            })}
                            onClick={this.props.onCancel}
                        />
                        {this.props.danger ? (
                            <WarningButton
                                text={this.props.confirmButtonText}
                                onClick={this.props.onConfirm}
                                loading={this.props.loading}
                            />
                        ) : (
                            <PrimaryButton
                                text={this.props.confirmButtonText}
                                onClick={this.props.onConfirm}
                                loading={this.props.loading}
                            />
                        )}
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default injectIntl(ConfirmationModal);
