// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const Factors = styled.div`
    width: 100%;
`;

export const FactorTitle = styled.h2`
    font-size: 17px;
    font-weight: 400;
    margin-right: 5px;
`;

export const FactorsTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const FactorsSliderWrapper = styled.div`
    width: 100%;
    margin-bottom: 10px;
`;

export const FactorWrapper = styled.div`
    display: flex;
    align-items: flex-end;

    height: 66px;

    margin-bottom: 8px;
    padding: 14px 16px;

    background-color: ${LegacyTheme.white};
    border: 1px solid ${LegacyTheme.defaultBorder};
    border-radius: 4px;
`;

export const FactorSlider = styled.div`
    width: 75%;

    ${(props) =>
        props.fullRange &&
        `
        margin-bottom: inherit;
    `};
`;

export const FactorSliderTitle = styled.div`
    width: 25%;
    margin-right: 5px;

    font-size: 14px;
`;

export const TotalText = styled.div`
    margin-right: 5px;
    margin-bottom: 1px;
    color: ${LegacyTheme.defaultSubtleColor};
    font-family: inherit;
    font-size: 11px;
    letter-spacing: 0.07px;
`;

export const TotalNumber = styled.div`
    color: ${({ error }) => (error ? LegacyTheme.defaultWarningColor : 'inherit')};
`;
