// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the survey state domain
 */
export const selectSurveyState = () => (state) => state.get('survey');

/**
 * Select survey
 */
export const selectSurvey = () =>
    createSelector(selectSurveyState(), (substate) => substate.get('surveyBody'));

/**
 * Select single survey is fetching state
 */
export const selectSurveyIsFetching = () =>
    createSelector(selectSurveyState(), (substate: any) => substate.get('surveyIsFetching'));

/**
 * Select single survey is submitting state
 */
export const selectSurveyIsSubmitting = () =>
    createSelector(selectSurveyState(), (substate: any) => substate.get('surveyIsSubmitting'));
