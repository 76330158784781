// @flow

import React from 'react';
import { PDFViewer, Document, Page, Text, View, Image, PDFDownloadLink } from '@react-pdf/renderer';
import { PrimaryButton, SecondaryButton } from 'OsedeaReactUI';
import moment from 'moment';

// Constants
import { DISCOVERY_AGREEMENT_DATE_FORMAT } from 'utils/constants';
import { API_URL, API_VER } from 'env';

// Styles
import { pdfStyles, PDFControlsContainer, ButtonsContainer } from './styles';

// Assets
import solvayLogo from 'assets/solvay-logo-original.png';

// Types
import type { ImmutableList, ImmutableMap, IntlType } from 'types';

type Props = {
    content: ImmutableMap<{
        background: string,
        customerName: string,
        customerTitle: string,
        customerMineSite: string,
        objective: string,
        measurementOfSuccess: Array<{}>,
        projectId: number,
        selectedProducts: Array<{}>,
        testPlan: string,
        scopeOfWork: string,
        title: string,
        mineralProcessing: string,
        phone: string,
        email: string,
        savedImages: Array<{}>,
    }>,
    products: ImmutableList<{}>,
    projectId: number,
    intl: IntlType,
    size: string,
    onCancel: () => void,
    onRenderHandler: () => void,
    companyAddresses: ImmutableMap<{
        companyAddress1: {},
        companyAddress2: {},
    }>,
};

class AgreementPDF extends React.Component<Props> {
    // If props update, force PDF viewer to not refresh.
    shouldComponentUpdate() {
        return false;
    }

    handleOnRender = () => this.props.onRenderHandler();

    renderPDF = () => {
        const { content, products, intl, size, companyAddresses, projectId } = this.props;

        // PDF/document metadata
        const title = `discovery_agreement_project_#${projectId}`;
        const author = content.get('representativeName');
        const subject = 'discovery agreement';

        return (
            <Document
                title={title}
                author={author}
                subject={subject}
                onRender={this.handleOnRender}
            >
                {/* Page 1 of 2 */}
                <Page size={size} style={pdfStyles.page}>
                    {/* Header section */}
                    <View style={pdfStyles.pageHeader}>
                        {/* Brand logo */}
                        <View style={pdfStyles.pageHeaderCol}>
                            <Image src={solvayLogo} style={pdfStyles.pageHeaderLogo} />
                        </View>
                        {/* Today's date */}
                        <View style={pdfStyles.pageHeaderCol}>
                            <Text style={pdfStyles.pageHeaderDate}>
                                {moment().format(DISCOVERY_AGREEMENT_DATE_FORMAT)}
                            </Text>
                        </View>
                    </View>

                    {/* Customer Information section */}
                    <View style={pdfStyles.section}>
                        <Text style={pdfStyles.header}>{content.get('customerName')}</Text>
                        <Text>{content.get('customerTitle')}</Text>
                        <Text>{content.get('customerMineSite')}</Text>
                    </View>

                    {/* Company Address section */}
                    <View style={pdfStyles.section}>
                        {/* Company address 1 */}
                        <Text style={pdfStyles.header}>
                            {intl.formatMessage({
                                id: 'components.AgreementPDF.companyAddress1',
                            })}
                        </Text>
                        <Text>{companyAddresses.getIn(['companyAddress1', 'name'])}</Text>
                        <Text>{companyAddresses.getIn(['companyAddress1', 'civicAddress'])}</Text>
                        <Text>
                            {companyAddresses.getIn([
                                'companyAddress1',
                                'provinceCountryPostalCode',
                            ])}
                        </Text>

                        {/* Optional: Company address 2 */}
                        {companyAddresses.get('companyAddress2') && (
                            <React.Fragment>
                                <Text style={pdfStyles.header}>
                                    {'\n'}
                                    {intl.formatMessage({
                                        id: 'components.AgreementPDF.companyAddress2',
                                    })}
                                </Text>
                                <Text>{companyAddresses.getIn(['companyAddress2', 'name'])}</Text>
                                <Text>
                                    {companyAddresses.getIn(['companyAddress2', 'civicAddress'])}
                                </Text>
                                <Text>
                                    {companyAddresses.getIn([
                                        'companyAddress2',
                                        'provinceCountryPostalCode',
                                    ])}
                                </Text>
                            </React.Fragment>
                        )}
                    </View>

                    {/* Background section */}
                    <View style={pdfStyles.section}>
                        <Text style={pdfStyles.header}>
                            {intl.formatMessage({
                                id: 'components.AgreementPDF.background',
                            })}
                        </Text>
                        <Text break>{content.get('background')}</Text>
                    </View>

                    {/* Objectives section */}
                    <View style={pdfStyles.section}>
                        <Text style={pdfStyles.header}>
                            {intl.formatMessage({
                                id: 'components.AgreementPDF.objective',
                            })}
                        </Text>
                        <Text break>{content.get('objective')}</Text>
                    </View>

                    {/* Measurement of success section */}
                    <View style={pdfStyles.section}>
                        <Text style={pdfStyles.header}>
                            {intl.formatMessage({
                                id: 'components.AgreementPDF.measurementsOfSuccess',
                            })}
                        </Text>
                        {content.get('measurement') &&
                            content.get('measurement').map((measurement: ImmutableMap) => (
                                <Text key={measurement.get('id')} style={pdfStyles.textBlock} break>
                                    • {measurement.get('value')}
                                </Text>
                            ))}
                    </View>

                    {/* Page number */}
                    <Text style={pdfStyles.pageNumber} render={this.renderPageNumber} fixed />
                </Page>

                {/* Page 2 of 2 */}
                <Page size={size} style={pdfStyles.page}>
                    {/* Selected products section */}
                    <View style={pdfStyles.section} break>
                        <Text style={pdfStyles.header}>
                            {intl.formatMessage({
                                id: 'components.AgreementPDF.selectedProducts',
                            })}
                        </Text>
                        {products && products.size ? (
                            // If products has entities, render each.
                            products.map((product: ImmutableMap) => {
                                const productName =
                                    product.get('name') && product.get('name').trim();
                                const productRationale =
                                    product.getIn(['pivot', 'rationale']) &&
                                    product.getIn(['pivot', 'rationale']).trim();

                                return (
                                    <Text key={product.get('id')} style={pdfStyles.textBlock} break>
                                        • {productName} - {productRationale}
                                    </Text>
                                );
                            })
                        ) : (
                            // If products is empty, render no products message.
                            <Text>
                                {intl.formatMessage({
                                    id: 'components.AgreementPDF.noSelectedProducts',
                                })}
                            </Text>
                        )}
                    </View>
                    {/* Test plan section */}
                    <View>
                        <View style={pdfStyles.section}>
                            <Text style={pdfStyles.header}>
                                {intl.formatMessage({
                                    id: 'components.AgreementPDF.testPlan',
                                })}
                            </Text>
                            <Text break>{content.get('testPlan')}</Text>
                        </View>
                    </View>

                    {/* Scope of work section */}
                    <View style={pdfStyles.section}>
                        <Text style={pdfStyles.header}>
                            {intl.formatMessage({
                                id: 'components.AgreementPDF.scopeOfWork',
                            })}
                        </Text>
                        <Text break>{content.get('scopeOfWork')}</Text>
                    </View>

                    {/* Footer note */}
                    <View style={pdfStyles.section}>
                        <Text style={pdfStyles.paragraph}>
                            {intl.formatMessage({
                                id: 'components.AgreementPDF.questions',
                            })}
                        </Text>
                        <Text style={pdfStyles.paragraph}>
                            {intl.formatMessage({ id: 'components.AgreementPDF.thankYou' })}
                        </Text>
                        <Text style={pdfStyles.paragraph}>
                            {intl.formatMessage({
                                id: 'components.AgreementPDF.sincerely',
                            })}
                        </Text>
                    </View>

                    {/* Contact Information */}
                    <View style={pdfStyles.section}>
                        <Text style={pdfStyles.header}>{content.get('representativeName')}</Text>
                        <Text>{content.get('representativeTitle')}</Text>
                        <Text>{content.get('mineralProcessing')}</Text>
                        <Text>{content.get('representativePhone')}</Text>
                        <Text>{content.get('representativeEmail')}</Text>
                    </View>

                    {/* Page number */}
                    <Text style={pdfStyles.pageNumber} render={this.renderPageNumber} fixed />
                </Page>

                {/* Loop over savedFiles and fire a request to fetch image */}
                {content.get('savedFiles') &&
                    content.get('savedFiles').map((file: ImmutableMap) => (
                        <Page key={file.get('id')} size={size} style={pdfStyles.page}>
                            <Image
                                style={pdfStyles.image}
                                source={{
                                    uri: `${API_URL}${API_VER}projects/${projectId}/agreement/images/${file.get(
                                        'filename'
                                    )}`,
                                    method: 'GET',
                                    headers: {
                                        Authorization: `Bearer ${localStorage.getItem(
                                            'api_token'
                                        ) || ''}`,
                                    },
                                }}
                            />
                        </Page>
                    ))}

                {/* Loop over files that are just added, but not yet saved */}
                {content.get('files') &&
                    content.get('files').map((file: ImmutableMap) => (
                        <Page key={file.name} size={size} style={pdfStyles.page}>
                            <Image style={pdfStyles.image} src={window.URL.createObjectURL(file)} />
                        </Page>
                    ))}
            </Document>
        );
    };

    renderPageNumber = ({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`;

    render() {
        const { intl, onCancel, projectId } = this.props;
        const PDF = this.renderPDF();
        const fileName = `discovery_agreement_project_#${projectId}`;

        return (
            <React.Fragment>
                {/* PDF content */}
                <PDFViewer width={'100%'} height={'100%'} style={{ border: '0 none' }}>
                    {PDF}
                </PDFViewer>

                <PDFControlsContainer>
                    <ButtonsContainer>
                        {/* Cancel button */}
                        <SecondaryButton
                            onClick={onCancel}
                            text={intl.formatMessage({
                                id: 'components.AgreementPDF.controls.cancel',
                            })}
                        />
                        {/* Export to PDF button */}
                        <PDFDownloadLink document={PDF} fileName={fileName}>
                            <PrimaryButton
                                onClick={null}
                                text={intl.formatMessage({
                                    id: 'components.AgreementPDF.controls.export',
                                })}
                            />
                        </PDFDownloadLink>
                    </ButtonsContainer>
                </PDFControlsContainer>
            </React.Fragment>
        );
    }
}

export default AgreementPDF;
