// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Containers
import MessageBlockTimeoutProvider from 'containers/MessageBlockTimeoutProvider';

// Components
import { MessageBlock, Close, Checkmark, LegacyTheme } from 'OsedeaReactUI';

// Styles
import { MessageBlockContainer } from 'styles/common';

// Types
import type { IntlType, ImmutableMap } from 'types';

type Props = {
    autoHideDuration: number,
    onHide: () => void,
    errors: ImmutableMap,
    intl: IntlType,
    discoveryAgreement: ?ImmutableMap,
};

class AgreementFeedbackBlock extends React.PureComponent<Props> {
    state = {
        updated: false,
    };

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.discoveryAgreement.get('updatedAt') !==
            prevProps.discoveryAgreement.get('updatedAt')
        ) {
            this.handleOnAgreementUpdate();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeout);
    }

    /**
     * Updates this.state.updated to true, then sets it back to false based on
     * this.props.autoHideDuration
     */
    handleOnAgreementUpdate = () => {
        this.setState({ updated: true }, () => {
            this.timeout = setTimeout(() => {
                this.setState({ updated: false });
            }, this.props.autoHideDuration);
        });
    };

    render() {
        const { intl, onHide, errors, autoHideDuration } = this.props;

        // We will set these variables based on a matching if statement case.
        let icon;
        let color;
        let text;

        const discoveryAgreementDidNotSave =
            errors.get('status') && errors.get('notAdded') === 'discoveryAgreement';

        // Error occurred in trying to save discovery agreement
        if (discoveryAgreementDidNotSave) {
            icon = <Close />;
            color = LegacyTheme.defaultWarningColor;
            text = intl.formatMessage({ id: 'components.AgreementFeedbackBlock.error' });
        } else if (this.state.updated && !discoveryAgreementDidNotSave) {
            // Discovery agreement successfully submitted
            icon = <Checkmark />;
            color = LegacyTheme.defaultSuccessColor;
            text = intl.formatMessage({ id: 'components.AgreementFeedbackBlock.success' });
        }

        // If icon, color and text variables not set, return null from function.
        if (!icon || !color || !text) {
            return null;
        }

        return (
            <MessageBlockTimeoutProvider
                autoHideDuration={autoHideDuration}
                onHide={discoveryAgreementDidNotSave ? onHide : null}
            >
                <MessageBlockContainer>
                    <MessageBlock icon={icon} color={color} text={text} />
                </MessageBlockContainer>
            </MessageBlockTimeoutProvider>
        );
    }
}

export default injectIntl(AgreementFeedbackBlock);
