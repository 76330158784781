// @flow

import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

// Constants
import {
    PROJECT_DATA_FORMAT,
    PROJECT_DEFAULT_SORTBY,
    PROJECT_DEFAULT_SORTORDER,
    PROJECT_TYPE_OPTIONS,
} from 'utils/constants';

// Components
import {
    ButtonHover,
    ButtonWrapper,
    Caret,
    DotMenu,
    InfoIcon,
    InputField,
    LoadingDots,
    Pagination,
    InputSelect,
    PrimaryButton,
    Table,
    SecondaryButton,
    ToolTip,
} from 'OsedeaReactUI';

// Styles
import {
    ActionButton,
    ActionButtonContainer,
    ProductTableTitle,
    ProductName,
    ProjectTableContainer,
    ProjectTableProductControls,
    StaticProductCount,
    ProjectTypeInputContainer,
} from './styles';
import { Note, ProductToolTip, SimpleList } from 'styles/common';

// Helper
import { getSelectOptionForSelectList } from 'utils/helpers';

// Types
import type { IntlType, InputEvent, SelectOptionType } from 'types';
import type {
    BuildingBlockType,
    ProjectType,
    ProjectSearchCriteriaType,
} from 'services/Project/types';
import type { ResultType } from 'services/Result/types';
import type { UserType } from 'management/services/Authentication/types';

type Props = {
    currentProjectId?: number,
    disabledInputs?: boolean,
    handleFetchProject: (number) => void,
    handleRedirect: (number, string) => void,
    history: {
        push: () => void,
    },
    intl: IntlType,
    loading: boolean,
    onCancelProject: () => void,
    onDeleteProject: (number) => void,
    onEditProject: (number) => void,
    onProjectsRefetch: () => void,
    onSaveProject: () => void,
    projectIsFetching?: boolean,
    projects: Array<ProjectType>,
    projectsPageCount: number,
    searchCriteria: ProjectSearchCriteriaType,
    user: UserType,
};

type State = {
    currentProject?: ProjectType,
    expandedRowId: number,
    pagination: {
        page: number,
    },
};

// Prevent closure of model on content interaction
const stopPropagation = (event: InputEvent) => {
    event.stopPropagation();
};

class ProjectTable extends React.PureComponent<Props, State> {
    static defaultProps = {
        currentProjectId: null,
        disabledInputs: false,
        projectIsFetching: false,
    };

    state = {
        currentProject: this.props.projects[0],
        expandedRowId: null,
        pagination: {
            page: this.props.searchCriteria.page,
        },
    };

    componentDidUpdate(prevProps: Props) {
        const { currentProjectId, projects } = this.props;
        // Check if redux has updated current project id from props, if so replace local state
        // Wrapped in conditional to avoid infinite loop
        // https://reactjs.org/docs/react-component.html#componentdidupdate
        if (prevProps.currentProjectId !== currentProjectId && projects) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                currentProject: currentProjectId
                    ? projects.find(
                          (project: ProjectType) => Number(project.id) === Number(currentProjectId)
                      )
                    : projects[0],
            });
        }

        // Check if pagination's current page has externall changed (project creation)
        // If so, save to state
        // Wrapped in conditional to avoid infinite loop
        // https://reactjs.org/docs/react-component.html#componentdidupdate
        if (prevProps.searchCriteria.page !== this.props.searchCriteria.page) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                pagination: {
                    page: this.props.searchCriteria.page,
                },
            });
        }
    }

    TABLE_CONFIG = [
        {
            label: this.props.intl.formatMessage({ id: 'components.ProjectTable.thead.id' }),
            id: 'id',
            sortable: true,
        },
        {
            label: this.props.intl.formatMessage({ id: 'components.ProjectTable.thead.owner' }),
            id: 'owner',
            sortable: true,
        },
        {
            label: this.props.intl.formatMessage({ id: 'components.ProjectTable.thead.plant' }),
            id: 'plant',
            sortable: true,
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ProjectTable.thead.objectives',
            }),
            id: 'objectives',
            sortable: true,
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ProjectTable.thead.projectType',
            }),
            id: 'projectType',
            sortable: true,
        },
        {
            label: this.props.intl.formatMessage({ id: 'components.ProjectTable.thead.date' }),
            id: 'createdAt',
            sortable: true,
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ProjectTable.thead.products',
            }),
            id: 'products',
        },
    ];

    handleCurrentProjectChange = (name: string) => (event: InputEvent) => {
        const value = event.target.value;
        this.setState((prevState: State) => ({
            currentProject: {
                ...prevState.currentProject,
                [name]: value,
            },
        }));
    };

    handleSelectInputChange = (name: string) => (event: SelectOptionType) => {
        const value = event.value;
        this.setState((prevState: State) => ({
            currentProject: {
                ...prevState.currentProject,
                [name]: value,
            },
        }));
    };

    handlePaginationPageSelection = (page: number, total: number) => () =>
        page > 0 && page <= total
            ? this.setState(
                  {
                      pagination: {
                          page,
                      },
                  },
                  () => this.props.onProjectsRefetch(this.state.pagination)
              )
            : null;

    /* On product expand, expandedRowId is not null fetch single project with products */
    handleProductExpand = (projectId: number) => () => {
        this.setState(
            (prevState: State) => ({
                expandedRowId:
                    prevState.expandedRowId === Number(projectId) ? null : Number(projectId),
            }),
            () => this.state.expandedRowId && this.props.handleFetchProject(projectId)
        );
    };

    provideEditableProject = (project: ProjectType) =>
        this.state.currentProject && {
            ...project,
            id: project && project.id ? `#${project.id}` : '',
            owner: (
                <InputField
                    onChange={this.handleCurrentProjectChange('owner')}
                    disabled={this.props.disabledInputs}
                    placeholder={this.props.intl.formatMessage({
                        id: 'components.ProjectTable.ownerName',
                    })}
                    value={this.state.currentProject ? this.state.currentProject.owner : ''}
                />
            ),
            plant: (
                <InputField
                    onChange={this.handleCurrentProjectChange('plant')}
                    disabled={this.props.disabledInputs}
                    placeholder={this.props.intl.formatMessage({
                        id: 'components.ProjectTable.plantName',
                    })}
                    value={this.state.currentProject ? this.state.currentProject.plant : ''}
                />
            ),
            objectives: (
                <InputField
                    onChange={this.handleCurrentProjectChange('objectives')}
                    disabled={this.props.disabledInputs}
                    placeholder={this.props.intl.formatMessage({
                        id: 'components.ProjectTable.objectives',
                    })}
                    value={this.state.currentProject ? this.state.currentProject.objectives : ''}
                />
            ),
            projectType: !project.id ? (
                <ProjectTypeInputContainer>
                    <InputSelect
                        onSelect={this.handleSelectInputChange('projectType')}
                        disabled={this.props.disabledInputs}
                        placeholder={this.props.intl.formatMessage({
                            id: 'components.ProjectTable.projectType',
                        })}
                        controlShouldRenderValue
                        options={PROJECT_TYPE_OPTIONS}
                        selectedOption={
                            this.state.currentProject
                                ? getSelectOptionForSelectList(
                                      this.state.currentProject.projectType,
                                      PROJECT_TYPE_OPTIONS
                                  )
                                : ''
                        }
                    />
                </ProjectTypeInputContainer>
            ) : (
                <span>{this.state.currentProject.projectType}</span>
            ),
            createdAt: moment().format(PROJECT_DATA_FORMAT),
            products: (
                <ButtonWrapper>
                    <PrimaryButton
                        disabled={
                            this.props.disabledInputs ||
                            !this.state.currentProject.owner ||
                            !this.state.currentProject.plant ||
                            !this.state.currentProject.objectives ||
                            !this.state.currentProject.projectType
                        }
                        onClick={this.props.onSaveProject(this.state.currentProject)}
                        text={this.props.intl.formatMessage({
                            id: 'components.ProjectTable.saveProject',
                        })}
                    />
                    <SecondaryButton
                        disabled={this.props.disabledInputs}
                        onClick={this.props.onCancelProject(this.props.currentProjectId)}
                        text={this.props.intl.formatMessage({
                            id: 'components.ProjectTable.cancelProject',
                        })}
                    />
                </ButtonWrapper>
            ),
        };

    provideInteractiveProjectExpandedContent = (project: ProjectType, hasResults: boolean) => {
        let products = [
            {
                id: 'empty',
                name: '—',
                labResults: '—',
                plantTrialResults: '—',
            },
        ];

        if (hasResults) {
            products = this.provideInteractiveProjectProducts(project);
        }

        return (
            <React.Fragment key={`exp-${project.id}`}>
                <ProductTableTitle>
                    {this.props.intl.formatMessage({
                        id: 'components.ProjectTable.projectProducts',
                    })}
                </ProductTableTitle>
                {this.props.projectIsFetching && !hasResults ? (
                    <LoadingDots />
                ) : (
                    <Table
                        header={[
                            {
                                label: 'Product',
                                id: 'name',
                            },
                            {
                                label: 'Lab Results',
                                id: 'labResults',
                            },
                            {
                                label: 'Plant Trial Results',
                                id: 'plantTrialResults',
                            },
                        ]}
                        loading={this.props.projectIsFetching}
                        rows={products}
                        subtleStyling
                    />
                )}
            </React.Fragment>
        );
    };

    provideInteractiveProjectProducts = (project: ProjectType) => {
        const results = project.results
            .filter((result: ResultType) => result.product)
            .map((result: ResultType) => {
                const buildingBlockCompositionList = result.product.buildingBlocks.map(
                    (buildingBlock: BuildingBlockType) => {
                        // Manipulating building block percentage. For example, from "45.00" we want to
                        // display "45%".
                        const percentage = `${buildingBlock.pivot.percentage.split('.')[0]}%`;
                        return (
                            <li key={buildingBlock.id}>
                                {percentage} {buildingBlock.name}
                            </li>
                        );
                    }
                );

                const name = (
                    <ProductName>
                        <span>{result.product.name}</span>
                        <ToolTip
                            content={
                                <ProductToolTip>
                                    <Note>
                                        {this.props.intl.formatMessage({
                                            id:
                                                'components.AgreementObjectivesStatement.selectedProducts.toolTipNote',
                                        })}
                                    </Note>
                                    <SimpleList>{buildingBlockCompositionList}</SimpleList>
                                </ProductToolTip>
                            }
                            position="bottom"
                            trigger={<InfoIcon width="14px" height="14px" />}
                            triggerType="click"
                        />
                    </ProductName>
                );

                return (
                    result && {
                        id: result.product.id,
                        name,
                        labResults: result.labResultDesc ? result.labResultDesc : '-',
                        plantTrialResults: result.plantTrialResultDesc
                            ? result.plantTrialResultDesc
                            : '-',
                    }
                );
            });

        return results;
    };

    provideInteractiveProject = (project: ProjectType) => {
        if (!project) {
            return null;
        }

        let expandedContent;
        const expanded = Number(project.id) === this.state.expandedRowId;
        if (expanded) {
            const hasResults = project && project.results && project.results.length > 0;
            expandedContent = this.provideInteractiveProjectExpandedContent(project, hasResults);
        }

        const projectCount = project.productCount || 0;
        const projectCountLabel = this.props.intl.formatMessage(
            { id: 'components.ProjectTable.productCount' },
            { count: projectCount }
        );
        let projectCountMarkup = <StaticProductCount>{projectCountLabel}</StaticProductCount>;
        if (projectCount) {
            projectCountMarkup = (
                <ButtonHover active={expanded} onClick={this.handleProductExpand(project.id)}>
                    {this.props.intl.formatMessage(
                        { id: 'components.ProjectTable.productCount' },
                        { count: projectCount }
                    )}
                    <Caret margin="0 0 0 5px" up={expanded} down={!expanded} black />
                </ButtonHover>
            );
        }

        return {
            ...project,
            onClick: () => {
                // Fetch single project & navigate to survey
                this.props.handleRedirect(project.id, '/survey');
            },
            id: `#${project.id}`,
            products: (
                <ProjectTableProductControls onClick={stopPropagation}>
                    {projectCountMarkup}
                    {this.props.user &&
                        this.props.user.get('email') === project.email && (
                            <ToolTip
                                content={
                                    <ActionButtonContainer>
                                        <ActionButton
                                            onClick={this.props.onEditProject(project.id)}
                                        >
                                            {this.props.intl.formatMessage({
                                                id: 'components.ProjectTable.editProject',
                                            })}
                                        </ActionButton>
                                        <ActionButton
                                            onClick={this.props.onDeleteProject(project.id)}
                                            warning
                                        >
                                            {this.props.intl.formatMessage({
                                                id: 'components.ProjectTable.deleteProject',
                                            })}
                                        </ActionButton>
                                    </ActionButtonContainer>
                                }
                                position="bottom"
                                trigger={<DotMenu />}
                                triggerType="click"
                                closeOnInternalClick
                                interactive
                            />
                        )}
                </ProjectTableProductControls>
            ),
            createdAt: project.createdAt && moment(project.createdAt).format(PROJECT_DATA_FORMAT),
            expandedContent,
        };
    };

    provideRenderableProjects = () => {
        if (!this.props.projects) {
            return null;
        }

        return this.props.projects.map((project: ProjectType) => {
            if (Number(project.id) === Number(this.props.currentProjectId) || !project.id) {
                return this.provideEditableProject(project);
            } else {
                return this.provideInteractiveProject(project);
            }
        });
    };

    render() {
        return (
            <ProjectTableContainer>
                <Table
                    currentSorting={{
                        sortBy:
                            (this.props.searchCriteria && this.props.searchCriteria.sortBy) ||
                            PROJECT_DEFAULT_SORTBY,
                        sortOrder:
                            (this.props.searchCriteria && this.props.searchCriteria.sortOrder) ||
                            PROJECT_DEFAULT_SORTORDER,
                    }}
                    expandedRowId={this.state.expandedRowId}
                    header={this.TABLE_CONFIG}
                    loading={this.props.loading}
                    overlay={this.props.loading ? <div /> : null} // Add overlay to prevent user interaction
                    onSortBy={this.props.onProjectsRefetch}
                    rows={this.provideRenderableProjects()}
                />
                <Pagination
                    currentPage={this.state.pagination.page}
                    onPageSelection={this.handlePaginationPageSelection}
                    pagesTotal={this.props.projectsPageCount}
                    summaryPrefix={this.props.intl.formatMessage({
                        id: 'defaults.Pagination.summaryPrefix',
                    })}
                    summaryJoinner={this.props.intl.formatMessage({
                        id: 'defaults.Pagination.summaryJoiner',
                    })}
                />
            </ProjectTableContainer>
        );
    }
}

export default withRouter(injectIntl(ProjectTable));
