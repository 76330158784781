// @flow

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import {
    SecondaryButton,
    Table,
    CheckBox,
    PrimaryButton,
    ToolTip,
    Caret,
    Modal,
} from 'OsedeaReactUI';
import ProductTableDetails from 'components/ProductTableDetails';
import ProductCustomBlendModalContent from 'components/ProductCustomBlendModalContent';
import ProductFeedbackBlock from 'components/ProductFeedbackBlock';

// Styles
import {
    ColumnContainer,
    ColumnBody,
    ColumnSection,
    ContentContainer,
    HeaderContainer,
    Title,
} from 'styles/common';

import {
    BuildingBlocksMessage,
    StyledUnorderedList,
    StyledListItem,
    ToolTipContainer,
    ToolTipTrigger,
    ToolTipContentContainer,
    ToolTipContentItem,
    ExpandableDetailsButton,
    DetailsToggleText,
} from './styles';

// Constants
import { PROJECT_TYPE } from 'utils/constants';

// Flow types
import type { IntlType, ImmutableList, ImmutableMap } from 'types';
import type { ConstraintType } from 'services/BuildingBlock/types';
import type { ProductType, QueryProductType, ProductComponentType } from 'services/Product/types';
import type { BuildingBlockType, CustomBlendType } from 'services/Project/types';

type Props = {
    intl: IntlType,
    noSelectedBuildingBlocks: boolean,
    products: ImmutableList<QueryProductType>,
    isLoading: boolean,
    onProductSelect: (id: number) => () => void,
    recommendedBuildingBlocks: ImmutableList<BuildingBlockType>,
    allBuildingBlocks: ImmutableList<BuildingBlockType>,
    fetchCustomBlendConstraints: (Array<number>) => void,
    constraints: ImmutableList<ConstraintType>,
    constraintsAreFetching: boolean,
    onAddCustomBlend: (projectId: number, CustomBlendType) => void,
    onSubmitProductsToDiscovery: () => void,
    projectId: number,
    customBlendIsSubmitting: boolean,
    customBlendName: ?string,
    customBlendProductExists: ?boolean,
    projectErrors: {
        message?: string,
        status?: number,
    },
    resetCustomBlendState: () => void,
    selectedProductCount: number,
    productsAddedCount: ?number,
    projectProducts: ImmutableList<ProductType>,
    selectedBuildingBlockIds?: ImmutableList<number>,
    isOwner: ?boolean,
    projectType: string,
};

type State = {
    productDetailIds: Array<number>,
    productsMap: ImmutableMap<number, QueryProductType>,
};

/**
 * ProductTable
 *
 * Renders a table of products based on the users' selected building blocks. Users can view
 * information about the product such as composition, country restrictions and availability.
 */
class ProductTable extends React.PureComponent<Props, State> {
    static defaultProps = {
        selectedBuildingBlockIds: fromJS([]),
        projectProducts: fromJS([]),
    };

    state = {
        productDetailIds: fromJS([]),
        productsMap: fromJS({}),
    };

    componentDidMount() {
        this.createProductsMap();
    }

    componentDidUpdate(prevProps: Props) {
        // Recreate product map:
        // if products contains data and previous products was empty, or
        // if products does not equal the previous products (ie. product list is updated when
        // a user adds new building blocks from the sidebar)
        if (
            (this.props.products.size && prevProps.products.isEmpty()) ||
            this.props.products !== prevProps.products
        ) {
            this.createProductsMap();
        }
    }

    componentWillUnmount() {
        this.props.resetCustomBlendState();
    }

    /**
     * Helper function which takes the list of products and converts it into a map of products,
     * where the product ID is used as the key. For easy look up of products.
     */
    createProductsMap = () => {
        const productsMap = this.props.products.reduce(
            (accumulator: {}, currentValue: QueryProductType) => ({
                ...accumulator,
                [currentValue.get('id')]: currentValue,
            }),
            {}
        );

        this.setState({ productsMap: fromJS(productsMap) });
    };

    /**
     * Returns an array of objects used to render the table headers.
     * Get the corresponding header depending on the project type
     * TODO: Add logic to add a 6th table header when a country registration is selected.
     */
    getTableHeaders = () => {
        const { projectType } = this.props;

        if (projectType === PROJECT_TYPE.COLLECTOR) {
            return this.getCollectorsTableHeader();
        } else if (projectType === PROJECT_TYPE.FROTHER) {
            return this.getFrothersTableHeader();
        }
    };

    /**
     * Return the header for a Collector project
     */
    getCollectorsTableHeader = () => {
        return [
            {
                label: '',
                id: 'productCheckbox',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.ProductTable.header.productName',
                }),
                id: 'productName',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.ProductTable.header.productFamily',
                }),
                id: 'productFamily',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.ProductTable.header.buildingBlockComponents',
                }),
                id: 'buildingBlockComponents',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.ProductTable.header.shipTo',
                }),
                id: 'shipments',
            },
            {
                label: '',
                id: 'productDetailsToggle',
            },
        ];
    };

    /**
     * Return the header for a Frother project
     */
    getFrothersTableHeader = () => {
        return [
            {
                label: '',
                id: 'productCheckbox',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.ProductTable.header.productName',
                }),
                id: 'productName',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.ProductTable.header.productComposition',
                }),
                id: 'productComposition',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.ProductTable.header.buildingBlockComponents',
                }),
                id: 'buildingBlockComponents',
            },
        ];
    };

    /**
     * Returns an array of objects. Each object represents a table row whose keys map to content
     * (strings, JSX, etc) that will be rendered under the corresponding table header.
     */
    getMappedTableRows = () => {
        const { projectType } = this.props;
        return this.props.products.map((product: QueryProductType, index: number) => {
            // If the product is found within the users project list of products disable.
            const isDisabled = this.props.isOwner
                ? this.props.projectProducts.find(
                      (projectProduct: ProductType) =>
                          projectProduct.get('id') === product.get('id')
                  )
                : true;

            const productCheckbox = this.renderTableDataCheckbox(
                product.get('id'),
                product.get('name'),
                isDisabled
            );

            const buildingBlockComponents = this.renderTableDataBuildingBlockComponents(
                product.get('buildingBlocks')
            );

            const tableRow = {
                id: `product-row-${index}-${product.get('name')}}`,
                productCheckbox,
                productName: product.get('name'),
                disabled: isDisabled,
                buildingBlockComponents,
            };

            if (projectType === PROJECT_TYPE.COLLECTOR) {
                const productFamily = product.get('family');

                const shipments = this.renderTableDataShipToText(product.get('shippedToCount'));
                const productDetailsToggle = this.renderTableDataDetailsToggle(product.get('id'));
                const expandedContent = this.renderProductRowExpandableDetails(product.get('id'));

                tableRow.shipments = shipments;
                tableRow.productFamily = productFamily;
                tableRow.productDetailsToggle = productDetailsToggle;
                tableRow.expandedContent = expandedContent;
            }

            if (projectType === PROJECT_TYPE.FROTHER) {
                const productComposition = this.renderProductCompositionDetails(product);
                tableRow.productComposition = productComposition;
            }
            return tableRow;
        });
    };

    /**
     * Handles the selection or deselection of a product to view its details.
     */
    handleToggleProductDetails = (id: number) => () => {
        const { productDetailIds } = this.state;
        const isProductDetailsAlreadyOpen = productDetailIds.includes(id);

        this.setState((prevState: State) => {
            let updatedProductIds;

            if (isProductDetailsAlreadyOpen) {
                const idIndex = productDetailIds.indexOf(id);
                updatedProductIds = prevState.productDetailIds.delete(idIndex);
            } else {
                updatedProductIds = prevState.productDetailIds.push(id);
            }

            return {
                productDetailIds: updatedProductIds,
            };
        });
    };

    /**
     * Helper function which returns the JSX for the  checkbox input used for the Product Name
     * header column.
     */
    renderTableDataCheckbox = (id: number, name: string, disabled: boolean) => {
        if (disabled) {
            return null;
        } else {
            return <CheckBox name={name} onClickHandler={this.props.onProductSelect(id)} />;
        }
    };

    /**
     * Helper function which returns the JSX for the Building Block Components column:
     * 1. Lists only selected building blocks (selected via the product sidebar) with corresponding bullet color
     * 2. A tooltip which when clicked on shows the remaining hidden components
     */
    renderTableDataBuildingBlockComponents = (components: ImmutableList<ProductComponentType>) => {
        const { recommendedBuildingBlocks, selectedBuildingBlockIds } = this.props;

        // Filter the products' building blocks which have been selected
        const shownComponents = components.filter((buildingBlock) =>
            selectedBuildingBlockIds.includes(buildingBlock.get('id'))
        );

        // Hide the remainder of building blocks in the tooltip which have not been selected
        const hiddenComponents = components.filter(
            (buildingBlock) => !selectedBuildingBlockIds.includes(buildingBlock.get('id'))
        );

        // Returns an array of JSX list elements of the name of the selected building block
        // and a color indicator which corresponds to the building block in the product side bar.
        const renderVisibleComponents = () =>
            // Map through the list of components to be shown.
            shownComponents.map((component: ProductComponentType) => {
                const selectedRecommendedBuildingBlock = recommendedBuildingBlocks.find(
                    (buildingBlock: BuildingBlockType) =>
                        buildingBlock.get('id') === component.get('id')
                );

                // If the building block is not selected and the building block id does not exist
                // in the list of selected building block ids, return null to not display
                if (
                    !selectedRecommendedBuildingBlock &&
                    !selectedBuildingBlockIds.includes(component.get('id'))
                ) {
                    return null;
                }

                // String manipulation to get the building block name and its composition (%) in the product
                const buildingBlockPercentage = component.getIn(['pivot', 'percentage']);
                const buildingBlockName = component.get('name');
                const buildingBlockNameWithCompositionPercent = `${buildingBlockPercentage}% ${buildingBlockName}`;

                return (
                    <StyledListItem
                        key={component.get('id')}
                        bulletColor={
                            selectedRecommendedBuildingBlock &&
                            selectedRecommendedBuildingBlock.getIn(['colors', 'accent'])
                        }
                    >
                        {buildingBlockNameWithCompositionPercent}
                    </StyledListItem>
                );
            });

        return (
            <React.Fragment>
                {/* Listed building block components */}
                <StyledUnorderedList>{renderVisibleComponents()}</StyledUnorderedList>

                {/* Hidden/tool tip building block components */}
                {Boolean(hiddenComponents.size) &&
                    this.renderHiddenComponentsToolTip(hiddenComponents)}
            </React.Fragment>
        );
    };

    /**
     * Helper function which returns a string with the correct shipment text depending on context.
     */
    renderTableDataShipToText = (shippedToCount: number) => {
        const pluralizeText = shippedToCount === 0 || shippedToCount > 1;

        const companiesText = pluralizeText
            ? this.props.intl.formatMessage({ id: 'components.ProductTable.companies' })
            : this.props.intl.formatMessage({ id: 'components.ProductTable.company' });

        return `${shippedToCount} ${companiesText}`;
    };

    /**
     * Helper function which returns a the JSX for the clickable content which toggles the
     * products expandable details.
     */
    renderTableDataDetailsToggle = (productId: number) => {
        const isProductDetailsAlreadyOpen = this.state.productDetailIds.includes(productId);

        const text = isProductDetailsAlreadyOpen
            ? this.props.intl.formatMessage({ id: 'components.ProductTable.hide' })
            : this.props.intl.formatMessage({ id: 'components.ProductTable.details' });

        const caret = isProductDetailsAlreadyOpen ? <Caret up black /> : <Caret down black />;

        return (
            <ExpandableDetailsButton onClick={this.handleToggleProductDetails(productId)}>
                <DetailsToggleText>{text}</DetailsToggleText>
                {caret}
            </ExpandableDetailsButton>
        );
    };

    /**
     * Helper function which returns the product family name
     */
    renderProductCompositionDetails = (product: QueryProductType): JSX.Element => {
        const blocksCompositions = product.get('buildingBlocks').map((buildingBlock: any) => {
            const frotherComposition = buildingBlock.get('frotherComposition');
            return (
                <StyledListItem key={buildingBlock.get('id')}>
                    {frotherComposition.get('commonName')} -{' '}
                    {frotherComposition.get('chemicalComposition')}
                </StyledListItem>
            );
        });
        return <StyledUnorderedList>{blocksCompositions}</StyledUnorderedList>;
    };

    /**
     * Helper function which returns the JSX for the tooltip used to hide components.
     */
    renderHiddenComponentsToolTip = (components: ImmutableList<ProductComponentType>) => {
        const triggerText =
            components.size > 1
                ? this.props.intl.formatMessage({ id: 'components.ProductTable.others' })
                : this.props.intl.formatMessage({ id: 'components.ProductTable.other' });

        // Clickable trigger for tool tip
        const trigger = <ToolTipTrigger>{`+${components.size} ${triggerText}`}</ToolTipTrigger>;

        // List of hidden components in tool tip
        const content = (
            <ToolTipContentContainer>
                {components.map((component: ProductComponentType) => (
                    <ToolTipContentItem key={component.get('id')}>
                        {`${component.get('pivot').get('percentage')}%  ${component.get('name')}`}
                    </ToolTipContentItem>
                ))}
            </ToolTipContentContainer>
        );

        return (
            <ToolTipContainer>
                <ToolTip
                    position="bottom"
                    triggerType="click"
                    trigger={trigger}
                    content={content}
                    closeOnInternalClick
                />
            </ToolTipContainer>
        );
    };

    /**
     * Helper function which returns the JSX for the expandable details of a product
     */
    renderProductRowExpandableDetails = (productId: number) => {
        const isExpanded = this.state.productDetailIds.includes(productId);
        const product = this.state.productsMap.get(String(productId));
        return isExpanded && product && <ProductTableDetails product={product} />;
    };

    renderAddToDiscoveryAgreementButton = () => {
        return (
            <PrimaryButton
                text={this.props.intl.formatMessage({
                    id: 'components.ProductTable.addToDiscoveryAgreement',
                })}
                onClick={this.props.onSubmitProductsToDiscovery}
                disabled={
                    !this.props.selectedProductCount ||
                    this.props.customBlendIsSubmitting ||
                    !this.props.isOwner
                }
            />
        );
    };

    renderAddCustomBlendModal = () => {
        const {
            recommendedBuildingBlocks,
            allBuildingBlocks,
            fetchCustomBlendConstraints,
            constraints,
            constraintsAreFetching,
            onAddCustomBlend,
            projectId,
            intl,
            customBlendProductExists,
        } = this.props;
        return (
            <Modal
                modalHeight="526px"
                modalWidth="1044px"
                padding="none"
                trigger={
                    <SecondaryButton
                        text={intl.formatMessage({
                            id: 'components.ProductTable.createCustomBlend',
                        })}
                        inline
                    />
                }
                hideCloseIcon
            >
                {/* Modal content */}
                <ProductCustomBlendModalContent
                    recommendedBuildingBlocks={recommendedBuildingBlocks}
                    allBuildingBlocks={allBuildingBlocks}
                    fetchCustomBlendConstraints={fetchCustomBlendConstraints}
                    constraints={constraints}
                    constraintsAreFetching={constraintsAreFetching}
                    onAddCustomBlend={onAddCustomBlend}
                    projectId={projectId}
                    customBlendProductExists={customBlendProductExists}
                />
            </Modal>
        );
    };

    renderProductTableTitle = () => {
        const { projectType, intl } = this.props;
        let tableTitle = '';
        switch (projectType) {
            case PROJECT_TYPE.COLLECTOR:
                tableTitle = 'components.ProductTable.collectorTitle';
                break;
            case PROJECT_TYPE.FROTHER:
                tableTitle = 'components.ProductTable.frotherTitle';
                break;
        }

        return (
            <Title>
                {intl.formatMessage({
                    id: tableTitle,
                })}
            </Title>
        );
    };

    renderNoSelectedBuildingBlocksMessage = () => {
        return (
            <React.Fragment>
                <BuildingBlocksMessage>
                    {this.props.intl.formatMessage({
                        id: 'components.ProductTable.noSelectedBuildingBlocks',
                    })}
                </BuildingBlocksMessage>
            </React.Fragment>
        );
    };

    renderTable = () => {
        const { noSelectedBuildingBlocks, isLoading, projectType } = this.props;
        return noSelectedBuildingBlocks && projectType === PROJECT_TYPE.COLLECTOR ? (
            this.renderNoSelectedBuildingBlocksMessage()
        ) : (
            <Table
                header={this.getTableHeaders()}
                loading={isLoading}
                overlay={isLoading && <div />}
                rows={this.getMappedTableRows()}
                tdVerticalAlign="baseline"
            />
        );
    };

    render() {
        const {
            customBlendIsSubmitting,
            resetCustomBlendState,
            customBlendName,
            projectErrors,
            projectType,
            productsAddedCount,
        } = this.props;

        const content = this.renderTable();

        return (
            <ColumnContainer flex>
                <ColumnBody>
                    <ContentContainer padding={'24px'}>
                        <HeaderContainer justifyContent={'flex-start'} margin={'0 0 24px'}>
                            {this.renderProductTableTitle()}
                            {/* Create a custom blend modal */}
                            {this.props.isOwner &&
                                projectType === PROJECT_TYPE.COLLECTOR &&
                                this.renderAddCustomBlendModal()}
                        </HeaderContainer>
                        {content}
                    </ContentContainer>
                </ColumnBody>
                <ColumnSection
                    minHeight={'64px'}
                    padding={'0 24px'}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                >
                    {projectType === PROJECT_TYPE.COLLECTOR &&
                        this.renderAddToDiscoveryAgreementButton()}
                    <ProductFeedbackBlock
                        autoHideDuration={2500}
                        customBlendName={customBlendName}
                        isLoading={customBlendIsSubmitting}
                        onHide={resetCustomBlendState}
                        errors={projectErrors}
                        productsAddedCount={productsAddedCount}
                    />
                </ColumnSection>
            </ColumnContainer>
        );
    }
}

export default injectIntl(ProductTable);
