// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import firebase from 'firebase/app';
import * as Sentry from '@sentry/browser';

import { SENTRY_DSN } from 'env.js';

import App from 'containers/App';
import LanguageProvider from 'containers/LanguageProvider';
import configureStore from './store';
import { translationMessages } from './i18n';

// Error handling with Sentry.io
if (SENTRY_DSN && __PROD__) {
    Sentry.init({
        dsn: SENTRY_DSN,
    });
}

// Create redux store with history
const initialState = {};
const history = createHistory();

export const store = configureStore(initialState, history);

history.listen(() => {
    const currentUser = firebase.auth().currentUser;
    const firebaseUid = currentUser && currentUser.uid;
    const reduxFirebaseUid = store.getState().getIn(['auth', 'user', 'firebaseUid']);

    if (firebaseUid === reduxFirebaseUid) {
        currentUser
            .getIdToken(true)
            .then((idToken: string) => {
                localStorage.setItem('api_token', idToken);
            })
            .catch((error: {}) => {
                console.error(error);
            });
    }
});

const MOUNT_NODE = document.getElementById('app');

// TODO: Check if need to do special hot reloading for i18n files (like in react-boilerplate)
const renderApp = (messages: {}) => {
    ReactDOM.render(
        <Provider store={store}>
            <LanguageProvider messages={messages}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </LanguageProvider>
        </Provider>,
        MOUNT_NODE
    );
};

renderApp(translationMessages);

if (module.hot) {
    module.hot.accept('containers/App', () => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        renderApp(translationMessages);
    });
}
