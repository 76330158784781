// @flow

export const MANAGEMENT_CONFIG = {
    titleLogo: 'Select-A-Guide',
    userManagement: {
        showReagents: false,
        showPMUsers: false,
    },
};

export const ROLES = {
    ADMIN: 'Admin',
    SAM: 'SAM',
    PM: 'PM',
};

export const ROUTES = {
    HOME: '/',
    SURVEY: '/project/:projectId/survey',
    BUILDING_BLOCKS: '/project/:projectId/building-blocks',
    PRODUCTS: '/project/:projectId/products',
    DISCOVERY_AGREEMENT: '/project/:projectId/discovery-agreement',
    RESULTS: '/project/:projectId/results',
    LOGIN: '/login',
    USERS: '/settings/users',
    DISCLAIMERS: '/settings/disclaimers',
};

export const REQUEST_SUPPORT_LINK = 'https://solvay-dwp.onbmc.com/';
export const HELP_EMAIL = 'juan.mantilla@solvay.com';
export const HELP_SUBJECT = 'Select A Guide - Support Needed';
export const REPORT_SUBJECT = 'Select A Guide - Report Recommended Building Blocks';
export const REPORT_BODY = 'Please provide text and screenshots to best describe your concern.';

export const PRODUCT_AVAILABILITY_LINK =
    'https://sites.google.com/a/solvay.com/product-availability-ts/?pli=1';
export const SPS_LINK = 'http://ehs-search.solvay.com/';
export const TPS_LINK = 'https://drive.google.com/drive/folders/0B6CrUJSYoxUZZ1pCZmhwZW9YNGs';
export const KNOWLEDGE_BASE_LINK =
    'https://wiki.solvay.com/pages/viewpage.action?spaceKey=TSTS&title=FM-100+Knowledge+Directory#space-menu-link-content';

export const PROJECT_DATA_FORMAT = 'MM/DD/YY';
export const DISCOVERY_AGREEMENT_DATE_FORMAT = 'LL';

export const PROJECT_DEFAULT_PAGE = 1;
export const PROJECT_DEFAULT_SORTBY = 'id';
export const PROJECT_DEFAULT_SORTORDER = 'desc';

export const VIEW_BLOCK = 'block';
export const VIEW_SURVEY = 'survey';
export const SURVEY_TYPE_PRIMARY = 'surveyPrimary';
export const SURVEY_TYPE_SECONDARY = 'surveySecondary';
export const SURVEY_TYPE_TERTIARY = 'surveyTertiary';

export const BLENDING_STATUS_SHOULDNOT = 'shouldNot';
export const BLENDING_STATUS_NEEDSOTHER = 'needsOther';
export const BLENDING_STATUS_CANNOT = 'cannot';
export const BLENDING_STATUS_CAN = 'can';

export const ERROR_MESSAGE_MARGIN_TOP = '12px';

export const TEXTAREA_LIMIT = 2000;

// Primary Navigation routes for Collector
export const NAVIGATION_ITEMS_COLLECTORS = [
    {
        label: 'Survey',
        to: 'survey',
    },
    {
        label: 'Building Blocks',
        to: 'building-blocks',
    },
    {
        label: 'Products',
        to: 'products',
    },
    {
        label: 'Discovery Agreement',
        to: 'discovery-agreement',
    },
    {
        label: 'Results',
        to: 'results',
    },
];

// Primary Navigation routes for Frothers
// Frothers share the same pages as the Collectors, however some labels are differents.
export const NAVIGATION_ITEMS_FROTHERS = [
    {
        label: 'Survey',
        to: 'survey',
    },
    {
        label: 'Blends',
        to: 'building-blocks',
    },
    {
        label: 'Product Catalog',
        to: 'products',
    },
];

// Routes in which the primary navigation should be displayed
export const NAVIGATION_ALLOWED_ROUTES = [
    'survey',
    'building-blocks',
    'products',
    'discovery-agreement',
    'results',
];

export const COLLECTOR_SURVEY_QUESTIONNAIRE = [
    {
        text: 'Is it massive sulfide ore?',
        type: 'boolean',
        key: 'massiveSulfideOre',
        value: null,
        page: 'survey',
    },
    {
        text: 'What is the target operating pH range?',
        type: 'options',
        options: [
            {
                label: '7 - 9',
                value: '7-9',
            },
            {
                label: '9 - 10.5',
                value: '9-10.5',
            },
            {
                label: '> 10.5',
                value: '>10.5',
            },
        ],
        key: 'operatingPhRange',
        value: null,
        page: 'survey',
    },
    {
        text: 'What is the pyrite percentage in the ore? Based on feed assay',
        type: 'input',
        min: 0,
        max: 25,
        key: 'pyritePercentage',
        value: null,
        page: 'survey',
    },
    {
        text: 'What is the % of chalcopyrite? Based on total copper in the feed',
        type: 'input',
        min: 0,
        max: 100,
        key: 'percentageOfChalcopyrite',
        value: null,
        page: 'survey',
    },
    {
        text: 'What is the % of chalcocite? Based on total copper in the feed',
        type: 'input',
        min: 0,
        max: 100,
        key: 'percentageOfChalcocite',
        value: null,
        page: 'survey',
    },
    {
        text: 'What is the % of covellite? Based on total copper in the feed',
        type: 'input',
        min: 0,
        max: 100,
        key: 'percentageOfCovellite',
        value: null,
        page: 'survey',
    },
    {
        text: 'What is the % of bornite? Based on total copper in the feed',
        type: 'input',
        min: 0,
        max: 100,
        key: 'percentageOfBornite',
        value: null,
        page: 'survey',
    },
    {
        text: 'Is the native copper content > 10%? Based on total copper in the feed',
        type: 'boolean',
        key: 'nativeCopperContentGreaterThanTen',
        value: null,
        page: 'survey',
    },
    {
        text: 'What is the percent of slag? Based on feed tonnage',
        type: 'input',
        min: 0,
        max: 100,
        key: 'percentOfSlagContent',
        value: null,
        page: 'survey',
    },
    {
        text: 'Are there any tarnished sulfides?',
        type: 'boolean',
        key: 'tarnishedSulfides',
        value: null,
        page: 'survey',
    },
    {
        text: 'Is the content of oxide copper (ASCu) minerals > 5% of total Cu in the feed?',
        type: 'boolean',
        key: 'oxideCopperMinerals',
        value: null,
        page: 'survey',
    },
    {
        text: 'Is sulfidization practiced or sulfidizing agent used?',
        type: 'boolean',
        key: 'sulfidizationCpsPraciced',
        value: null,
        page: 'survey',
    },
    {
        text: 'Is chrysocolla present?',
        type: 'boolean',
        key: 'chrysocollaPresent',
        value: null,
        page: 'survey',
    },
    {
        text:
            'Is the content of non-sulfide gangue slimes (clays, limonite, chlorite, sericite, goethite) > 5%?',
        type: 'boolean',
        key: 'nonSulfideGanguePresent',
        value: null,
        page: 'survey',
    },
    {
        text: 'Is Mo recovery important?',
        type: 'boolean',
        key: 'moCurrentlyRecovered',
        value: null,
        page: 'survey',
    },
    {
        text: 'Is recovery of Au/Ag values important?',
        type: 'boolean',
        key: 'auAndOrAgValuesRecovered',
        value: null,
        page: 'survey',
    },
    {
        text:
            'Is there any chalcocite/covellite/digenite/bornite rimming on (or veins in) chalcopyrite?',
        type: 'boolean',
        key: 'chalCoveDigeBornRimmingOnChalcopyrite',
        value: null,
        page: 'survey',
    },
    {
        text: 'Are any of the following penalty elements present and problematic? As, Sb, Bi',
        type: 'boolean',
        key: 'asSbBiPenaltyElementsPresent',
        value: null,
        page: 'survey',
    },
    {
        text: 'What is the grind size range? (In microns)',
        type: 'options',
        options: [
            {
                label: '< 75',
                value: '<75',
            },
            {
                label: '75 - 150',
                value: '75-150',
            },
            {
                label: '> 150',
                value: '>150',
            },
        ],
        key: 'grindSizeRange',
        value: null,
        page: 'survey',
    },
    {
        text: 'Is recovery of pyrite-copper sulfides composites (sulfide middlings) important?',
        type: 'boolean',
        key: 'pyriteCopperSulfidesCompositesImportant',
        value: null,
        page: 'survey',
    },
    {
        text: 'Is froth-positive collector tolerated or desirable?',
        type: 'boolean',
        key: 'frothPositiveCollectorDesirable',
        value: null,
        page: 'survey',
    },
];

export const COLLECTOR_SURVEY_WEIGHTING_FACTORS = [
    {
        text: 'Cu Grade',
        key: 'primaryCuGrade',
        group: 'primary',
        value: 65,
        min: 15,
        max: 75,
    },
    {
        text: 'Cu Recovery',
        key: 'primaryCuRecovery',
        group: 'primary',
        value: 35,
        min: 25,
        max: 85,
    },
    {
        text: 'Au/Ag Recovery',
        key: 'primaryAuAgRecovery',
        group: 'primary',
        value: 0,
        min: 0,
        max: 50,
    },
    {
        text: 'Moly Recovery',
        key: 'primaryMolyRecovery',
        group: 'primary',
        value: 0,
        min: 0,
        max: 40,
    },
    {
        text: 'Penalty Elements',
        key: 'primaryPenaltyElements',
        group: 'primary',
        value: 0,
        min: 0,
        max: 20,
    },
    {
        text: 'Cu Grade',
        key: 'secondaryCuGrade',
        group: 'secondary',
        value: 0,
        min: 0,
        max: 80,
    },
    {
        text: 'Cu Recovery',
        key: 'secondaryCuRecovery',
        group: 'secondary',
        value: 100,
        min: 0,
        max: 100,
    },
    {
        text: 'Au/Ag Recovery',
        key: 'secondaryAuAgRecovery',
        group: 'secondary',
        value: 0,
        min: 0,
        max: 80,
    },
    {
        text: 'Moly Recovery',
        key: 'secondaryMolyRecovery',
        group: 'secondary',
        value: 0,
        min: 0,
        max: 80,
    },
    {
        text: 'Penalty Elements',
        key: 'secondaryPenaltyElements',
        group: 'secondary',
        value: 0,
        min: 0,
        max: 20,
    },
];

export const FROTHER_SURVEY_PRIMARY_QUESTIONNAIRE = [
    {
        text: 'What is the target operating pH range at the Rougher?',
        type: 'options',
        key: 'phRangeRougher',
        options: [
            {
                label: '< 9.5',
                value: '<9.5',
            },
            {
                label: '9.5 - 10.5',
                value: '9.5-10.5',
            },
            {
                label: '> 10.5',
                value: '>10.5',
            },
        ],
        value: null,
        page: 'survey',
    },
    {
        text: 'What is the target operating pH range at the Cleaner?',
        type: 'options',
        key: 'phRangeCleaner',
        options: [
            {
                label: '< 9.5',
                value: '<9.5',
            },
            {
                label: '9.5 - 10.5',
                value: '9.5-10.5',
            },
            {
                label: '> 10.5',
                value: '>10.5',
            },
        ],
        value: null,
        page: 'survey',
    },
    {
        text: 'Are you using more than 50 g/t NaHS?',
        type: 'boolean',
        key: 'usingMoreFiftyGtNaHs',
        value: 0,
        page: 'survey',
    },
    {
        text:
            'Is the content of non-sulfide gangue slimes significativily impacting the process? ( Gangue slimes = clays, limonite, chlorite, sericite, goethite, amphiboles, serpentines,argyllaceous ...)',
        type: 'boolean',
        key: 'contentNonSulfideGangueSlimesSignificantlyImpacting',
        value: 0,
        page: 'survey',
    },
    {
        text: 'If yes, are you using a modifier?',
        type: 'boolean',
        key: 'usingAModifier',
        required: false,
        value: 0,
        page: 'survey',
    },
    {
        text: 'What is the grind size range? (In microns)',
        type: 'options',
        key: 'grindSizeRangeMicrons',
        options: [
            {
                label: '< 50',
                value: '<50',
            },
            {
                label: '50 - 125',
                value: '50-125',
            },
            {
                label: '125 - 175',
                value: '125-175',
            },
            {
                label: '175 - 225',
                value: '175-225',
            },
            {
                label: '> 225',
                value: '>225',
            },
        ],
        value: null,
        page: 'survey',
    },
    {
        text: 'What is the particle size after regrind? (In microns)',
        type: 'options',
        key: 'particuleSizeAfterRegrindMicron',
        options: [
            {
                label: '< 30',
                value: '<30',
            },
            {
                label: '30 - 50',
                value: '30-50',
            },
            {
                label: '> 50',
                value: '>50',
            },
            {
                label: 'Not Applicable',
                value: 'notApplicable',
            },
        ],
        value: null,
        page: 'survey',
    },
    {
        text: 'Is the current collector suite froth …',
        type: 'options',
        key: 'currentCollectorSuiteFroth',
        options: [
            {
                label: 'Positive',
                value: 'positive',
            },
            {
                label: 'Negative',
                value: 'negative',
            },
            {
                label: 'Neutral',
                value: 'neutral',
            },
        ],
        value: null,
        page: 'survey',
    },
    {
        text: 'Is there >1% talc in the feed?',
        type: 'boolean',
        key: 'greaterThanOneTalcInFeed',
        value: 0,
        page: 'survey',
    },
    {
        text: 'If yes, is there a pre-float for talc?',
        type: 'boolean',
        key: 'preFloatForTalc',
        required: false,
        value: 0,
        page: 'survey',
    },
    {
        text: 'Do you have gravity concentrator in the flowsheet?',
        type: 'boolean',
        key: 'gravityConcentratorInFlowsheet',
        value: 0,
        page: 'survey',
    },
    {
        text: 'Does the frother in recycle water negatively impacting the circuit?',
        type: 'boolean',
        key: 'frotherInRecycleWaterAffectGravityCircuit',
        value: 0,
        page: 'survey',
    },
    {
        text: 'Do you have woodgrove SFR/Eriez stackcells / Eriez Hydrofloat in the plant?',
        type: 'boolean',
        key: 'woodgroveSfrStackcellsHydrofloatInPlant',
        value: 0,
        page: 'survey',
    },
    {
        text: 'What type of flotation cell are used in rougher?',
        type: 'options',
        key: 'floatationCellsInRougher',
        options: [
            {
                label: 'Self aspirated',
                value: 'selfAspirated',
            },
            {
                label: 'Forced air',
                value: 'forcedAir',
            },
            {
                label: 'Combination of the two',
                value: 'combinationOfTheTwo',
            },
            {
                label: 'Others',
                value: 'others',
            },
        ],
        value: null,
        page: 'survey',
    },
    {
        text: 'Do you have flashfloat?',
        type: 'boolean',
        key: 'haveFlashfloat',
        value: 0,
        page: 'survey',
    },
    {
        text: 'Do you have Jameson cells?',
        type: 'boolean',
        key: 'haveJamesonCells',
        value: 0,
        page: 'survey',
    },
    {
        text: 'How is frothing in your cleaners/columns, is it overfrothing or underfrothing?',
        type: 'options',
        key: 'overfrothingOrUnderfrothingCleanersColumns',
        options: [
            {
                label: 'Overfrothing',
                value: 'overfrothing',
            },
            {
                label: 'Underfrothing',
                value: 'underfrothing',
            },
            {
                label: 'Neither',
                value: 'neither',
            },
        ],
        value: null,
        page: 'survey',
    },
    {
        text:
            'Considering the plant current losses fraction, what would be target fraction for improvement? (In microns)',
        type: 'options',
        key: 'targetFracationForImprovement',
        options: [
            {
                label: '< 30',
                value: '<30',
            },
            {
                label: '30 - 150',
                value: '30-150',
            },
            {
                label: '150 - 250',
                value: '150-250',
            },
            {
                label: '> 250',
                value: '>250',
            },
        ],
        value: null,
        page: 'survey',
    },
    {
        text: 'Does froth break down sufficiently quickly in launders?',
        type: 'boolean',
        key: 'frothBreakDownSufficientlyQuicklyInLaunders',
        value: 0,
        page: 'survey',
    },
    {
        text:
            'Does the frother in use cause issues with froth breakdown in the concentrate thickener?',
        type: 'boolean',
        key: 'issuesWithFrothBreakdownInConcentrateThickener',
        value: 0,
        page: 'survey',
    },
    {
        text: 'What is the current rougher mass pull? (%)?',
        type: 'options',
        key: 'currentRougherMassPull',
        options: [
            {
                label: '< 5%',
                value: '<5%',
            },
            {
                label: '5% - 8%',
                value: '5-8%',
            },
            {
                label: '> 8%',
                value: '>8%',
            },
        ],
        value: null,
        page: 'survey',
    },
    {
        text: 'What is the maximum rougher mass pull based on cleaner capacity? (%)',
        type: 'options',
        key: 'maximumRougherMassPull',
        options: [
            {
                label: '< 5%',
                value: '<5%',
            },
            {
                label: '5% - 8%',
                value: '5-8%',
            },
            {
                label: '> 8%',
                value: '>8%',
            },
        ],
        value: null,
        page: 'survey',
    },
    {
        text: 'What is the range of scavenger mass pull? (%)',
        type: 'options',
        key: 'rangeScavengerMassPull',
        options: [
            {
                label: '< 3%',
                value: '<3%',
            },
            {
                label: '3% - 5%',
                value: '3-5%',
            },
            {
                label: '5% - 8%',
                value: '5-8%',
            },
            {
                label: 'Not applicable',
                value: 'notApplicable',
            },
        ],
        value: null,
        page: 'survey',
    },
    {
        text: 'What is the target scavenger mass pull?',
        type: 'options',
        key: 'targetScavengerMassPull',
        options: [
            {
                label: '< 3%',
                value: '<3%',
            },
            {
                label: '3% - 5%',
                value: '3-5%',
            },
            {
                label: '5% - 8%',
                value: '5-8%',
            },
            {
                label: 'Not applicable',
                value: 'notApplicable',
            },
        ],
        value: null,
        page: 'survey',
    },
];

// Questions for filtering the Frother Building Blocks
export const FROTHER_BUILDING_BLOCK_FILTERS_QUESTIONS = [
    {
        text: 'Do you need a non flammable frother?',
        type: 'boolean',
        value: null,
        key: 'isFrotherNonFlammable',
        page: 'buildingBlock',
        isFilter: true,
    },
    {
        text: 'REACH approved formulation?',
        type: 'boolean',
        key: 'reachApproved',
        value: null,
        page: 'buildingBlock',
        isFilter: true,
    },
    {
        text: 'APAC approved formulation?',
        type: 'boolean',
        key: 'apacApproved',
        value: null,
        page: 'buildingBlock',
        isFilter: true,
    },
    {
        text: 'Use a existing product?',
        type: 'boolean',
        key: 'existingProduct',
        value: null,
        page: 'buildingBlock',
        isFilter: true,
    },
    {
        text: 'Sorting by Price ($/KG)',
        type: 'options',
        key: 'sortByPrice',
        options: [
            {
                label: 'Ascending',
                value: 'asc',
            },
            {
                label: 'Descending',
                value: 'desc',
            },
        ],
        value: null,
        page: 'buildingBlock',
        isFilter: true,
    },
];

export const COLLECTOR_BUILDING_BLOCK_COMPOSITION = [
    {
        name: 'Cu Grade',
        key: 'averageCuGrade',
        intlId: 'constants.buildingBlockComposition.cuGrade',
    },
    {
        name: 'Cu Recovery',
        key: 'averageCuRecovery',
        intlId: 'constants.buildingBlockComposition.cuRecovery',
    },
    {
        name: 'Penalty Elements',
        key: 'averagePenaltyElements',
        intlId: 'constants.buildingBlockComposition.penaltyElements',
    },
    {
        name: 'Moly Recovery',
        key: 'averageMoly',
        intlId: 'constants.buildingBlockComposition.molyRecovery',
    },
    {
        name: 'Au/Ag Recovery',
        key: 'averageAuAg',
        intlId: 'constants.buildingBlockComposition.auAgRecovery',
    },
];

export const FROTHER_BUILDING_BLOCK_COMPOSITION = [
    {
        name: 'Mobility',
        key: 'mobility',
        intlId: 'constants.buildingBlockComposition.mobility',
    },
    {
        name: 'Dosage Efficiency',
        key: 'dosageEfficiency',
        intlId: 'constants.buildingBlockComposition.dosageEfficiency',
    },
    {
        name: 'Persistence',
        key: 'persistence',
        intlId: 'constants.buildingBlockComposition.persistence',
    },
    {
        name: 'Selectivity',
        key: 'selectivity',
        intlId: 'constants.buildingBlockComposition.selectivity',
    },
    {
        name: 'Bubble Size',
        key: 'bubbleSize',
        intlId: 'constants.buildingBlockComposition.bubbleSize',
    },
    {
        name: 'Stability',
        key: 'stability',
        intlId: 'constants.buildingBlockComposition.stability',
    },
];

// Building block types
export const PRIMARY_BUILDING_BLOCK_TYPE = 'P';
export const PRIMARY_AND_SECONDARY_BUILDING_BLOCK_TYPE = 'PS';
export const SECONDARY_BUILDING_BLOCK_TYPE = 'S';

export const ROUGHER_BUILDING_BLOCK_TYPE = 'R';
export const SCAVENGER_BUILDING_BLOCK_TYPE = 'S';
export const ROUGHER_AND_SCAVENGER_BUILDING_BLOCK_TYPE = 'RS';

export const FROTHER_BUILDING_BLOCK_TYPE = 'F';

// Modal
export const MODAL_WIDTH = {
    SMALL: '358px',
    BIG: '440px',
    LARGE: '558px',
    XLARGE: '1064px',
};

// Project type
export const PROJECT_TYPE = {
    COLLECTOR: 'Collector',
    FROTHER: 'Frother',
};

export const PROJECT_TYPE_OPTIONS = [
    {
        value: PROJECT_TYPE.COLLECTOR,
        label: 'Collector',
    },
    {
        value: PROJECT_TYPE.FROTHER,
        label: 'Frother',
    },
];

/**
 * High value used to apply to the "none" option value in building block composition.  Since 'null' value prevent the None option to show,
 * we are using a "dumb fake high" value, so no frother Building block id can reach.
 */

export const BUILDING_BLOCK_COMPOSITION_NONE_OPTION = 9999;
