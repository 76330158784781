// @flow

import request from 'services/request';

/**
 * Fetches the list of all building blocks
 */
export const requestBuildingBlocks = (projectType: string) =>
    request('building-blocks', { method: 'GET' }, { projectType });

/**
 * Fetches constraints between multiple building blocks
 */
export const checkBuildingBlockConstraints = (ids: Array) =>
    request('building-blocks/constraints', {
        method: 'POST',
        data: { ids },
    });

/**
 * Fetches the constraints based on the provided array of building blocks and their customized
 * percent/composition
 */
export const checkCustomBlendConstraints = (ids: Array) =>
    request(
        'building-blocks/blends-validation',
        {
            method: 'POST',
            data: {
                buildingBlocks: ids,
            },
        },
        /* eslint-disable-next-line no-undefined */
        undefined,
        /* eslint-disable-next-line no-undefined */
        undefined,
        false // turn off humps, backend expects 'buildingBlocks' and not 'building_blocks'
    );
