// @flow

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import {
    Close,
    Common,
    CollapsibleBlock,
    InfoIcon,
    InputFileSet,
    InputMultiple,
    SecondaryButton,
    Table,
    TextField,
    LegacyTheme,
    ToolTip,
} from 'OsedeaReactUI';
import ErrorMessage from 'components/ErrorMessage';

// Constants
import { ERROR_MESSAGE_MARGIN_TOP } from 'utils/constants';

// Styles
import { BlankButton, FormLabel, Note, ProductToolTip, SimpleList } from 'styles/common';
import { ProductName } from './styles';

// Types
import type { ImmutableList, ImmutableMap, IntlType } from 'types';

type Props = {
    currentAgreement: ImmutableMap<string, string | number>,
    handleOnChange: (string) => void,
    intl: IntlType,
    projectProducts: ImmutableList<{}>,
    selectedProductsMap: ImmutableMap<string, { rationale: string }>,
    selectedProductsRemovedIds: ImmutableList<number>,
    onChangeProductRationale: (id: number) => (rationale: string) => void,
    onRemoveImage: (file: File) => void,
    onRemoveSelectedProduct: (id: number) => () => void,
    errors: ImmutableMap<string, boolean>,
    disabled: boolean,
};

type State = {
    measurement: Array<string>,
};

export class AgreementObjectivesStatement extends React.PureComponent<Props, State> {
    state = {
        measurement: this.props.currentAgreement.get('measurement') || fromJS([]),
    };

    componentDidUpdate() {
        // Check if measurement key exists on currentAgreement
        if (this.props.currentAgreement.has('measurement')) {
            this.handleUpdateMeasurement();
        }
    }

    /**
     * Then compare the sizes between incoming props and this.state.
     * If not equal, update state.measurement.
     */
    handleUpdateMeasurement = () => {
        const { currentAgreement } = this.props;

        if (currentAgreement.get('measurement').size !== this.state.measurement.size) {
            this.setState({ measurement: currentAgreement.get('measurement') });
        }
    };

    /**
     * On InputField change, fire props.handleOnChange(name)(value) to save to currentAgreement
     */
    createOnChangeHandler = (name: string) => (event: EventType) => {
        this.props.handleOnChange(name)(event.target.value);
    };

    /**
     * On InputMultiple change, set passed values to state & fire props.handleOnChange(name)(value: array)
     */
    createInputMultipleOnChangeHandler = (name: string) => (
        values: ImmutableList<Array<string>>
    ) => {
        this.setState(
            {
                measurement: values,
            },
            () => this.props.handleOnChange(name)(this.state.measurement)
        );
    };

    /**
     * On InputMultiple change, delete passed index & fire props.handleOnChange(name)(value: array)
     */
    createInputMultipleOnRemoveHandler = (name: string) => (i: number) => {
        this.setState(
            (prevState: State) => ({
                measurement: prevState.measurement.delete(i),
            }),
            () => this.props.handleOnChange(name)(this.state.measurement)
        );
    };

    /**
     * Renders a table row with table data which corresponds to the table header.
     */
    renderTableRowsForSelectedProducts = () =>
        this.props.projectProducts &&
        this.props.projectProducts
            .filter(
                // Filter out products which have been removed via the 'close' icon.
                (product: {}) => !this.props.selectedProductsRemovedIds.includes(product.get('id'))
            )
            .map((product: {}, index: number) => {
                const selector = this.renderSelectorForTableRow(product);
                const productName = this.renderProductNameForTableRow(product);
                const rationale = this.renderRationaleForTableRow(product);

                return {
                    id: index,
                    selector,
                    productName,
                    rationale,
                };
            });

    /**
     * Returns a JSX component for the 'close' icon used to remove the product from the agreement.
     */
    renderSelectorForTableRow = (product: {}) => (
        <BlankButton onClick={this.props.onRemoveSelectedProduct(product.get('id'))}>
            <Close
                strokeWidth={0}
                width="16px"
                height="16px"
                fill={LegacyTheme.defaultIconColor}
                clickable
            />
        </BlankButton>
    );

    /**
     * Returns a JSX component consisting of the product name and a tooltip component which opens
     * when clicked to display the product's building block composition.
     */
    renderProductNameForTableRow = (product: {}) => {
        const buildingBlockCompositionList = product
            .get('buildingBlocks')
            .map((buildingBlock: {}) => {
                // Manipulating building block percentage. For example, from "45.00" we want to
                // display "45%".
                const percentage = `${buildingBlock.getIn(['pivot', 'percentage']).split('.')[0]}%`;

                return (
                    <li key={buildingBlock.get('id')}>
                        {percentage} {buildingBlock.get('name')}
                    </li>
                );
            });

        return (
            <ProductName>
                {product.get('name')}
                <ToolTip
                    content={
                        <ProductToolTip>
                            <Note>
                                {this.props.intl.formatMessage({
                                    id:
                                        'components.AgreementObjectivesStatement.selectedProducts.toolTipNote',
                                })}
                            </Note>
                            <SimpleList>{buildingBlockCompositionList}</SimpleList>
                        </ProductToolTip>
                    }
                    position="bottom"
                    trigger={<InfoIcon />}
                    triggerType="click"
                />
            </ProductName>
        );
    };

    /**
     * Returns a text field input for the product rationale.
     */
    renderRationaleForTableRow = (product: {}) => {
        const productId = product.get('id');
        const { onChangeProductRationale, intl, selectedProductsMap, errors } = this.props;

        const textFieldValue =
            selectedProductsMap.has(`${productId}`) &&
            selectedProductsMap.getIn([`${productId}`, 'rationale']);

        const isError =
            !selectedProductsMap.getIn([`${productId}`, 'rationale']) &&
            errors.getIn(['productsRationales', `${productId}`]);

        return (
            <React.Fragment>
                <TextField
                    onChange={onChangeProductRationale(productId)}
                    placeholder={intl.formatMessage({
                        id:
                            'components.AgreementObjectivesStatement.selectedProducts.rationalePlaceholder',
                    })}
                    value={textFieldValue || ''}
                    minHeight={'110px'}
                    disabled={this.props.disabled}
                />
                {isError && (
                    <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                        {intl.formatMessage({
                            id: 'components.ErrorMessage.required',
                        })}
                    </ErrorMessage>
                )}
            </React.Fragment>
        );
    };

    render() {
        const selectedProductRows = this.renderTableRowsForSelectedProducts();

        return (
            <CollapsibleBlock
                title={this.props.intl.formatMessage({
                    id: 'components.AgreementObjectivesStatement.blockTitle',
                })}
            >
                <Common.Row>
                    <Common.Column>
                        <FormLabel>
                            {this.props.intl.formatMessage({
                                id: 'components.AgreementObjectivesStatement.background',
                            })}
                        </FormLabel>
                        <TextField
                            onChange={this.createOnChangeHandler('background')}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.AgreementObjectivesStatement.backgroundPlaceholder',
                            })}
                            value={this.props.currentAgreement.get('background') || ''}
                            disabled={this.props.disabled}
                        />
                        {this.props.errors.get('background') && (
                            <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                {this.props.intl.formatMessage({
                                    id: 'components.ErrorMessage.required',
                                })}
                            </ErrorMessage>
                        )}
                    </Common.Column>
                </Common.Row>
                <Common.Row>
                    <Common.Column>
                        <FormLabel>
                            {this.props.intl.formatMessage({
                                id: 'components.AgreementObjectivesStatement.objectives',
                            })}
                        </FormLabel>
                        <TextField
                            onChange={this.createOnChangeHandler('objective')}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.AgreementObjectivesStatement.objectivesPlaceholder',
                            })}
                            value={this.props.currentAgreement.get('objective') || ''}
                            disabled={this.props.disabled}
                        />
                        {this.props.errors.get('objective') && (
                            <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                {this.props.intl.formatMessage({
                                    id: 'components.ErrorMessage.required',
                                })}
                            </ErrorMessage>
                        )}
                    </Common.Column>
                </Common.Row>
                <Common.Row
                    style={{
                        maxWidth: 'calc(33% - 8px)',
                        marginLeft: 0,
                    }}
                >
                    <Common.Column>
                        <FormLabel>
                            {this.props.intl.formatMessage({
                                id: 'components.AgreementObjectivesStatement.measurement',
                            })}
                        </FormLabel>
                        <InputMultiple
                            buttonText={this.props.intl.formatMessage({
                                id:
                                    'components.AgreementObjectivesStatement.measurement.addCriteria',
                            })}
                            initialValues={this.props.currentAgreement.get('measurement')}
                            onChange={this.createInputMultipleOnChangeHandler('measurement')}
                            onRemoveInput={this.createInputMultipleOnRemoveHandler('measurement')}
                            placeholder={this.props.intl.formatMessage({
                                id:
                                    'components.AgreementObjectivesStatement.measurementOfSuccessPlaceholder',
                            })}
                            disableAll={this.props.disabled}
                        />
                        {this.props.errors.get('measurement') && (
                            <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                {this.props.intl.formatMessage({
                                    id: 'components.ErrorMessage.required',
                                })}
                            </ErrorMessage>
                        )}
                    </Common.Column>
                </Common.Row>
                <Common.Row>
                    <Common.Column>
                        <FormLabel>
                            {this.props.intl.formatMessage({
                                id: 'components.AgreementObjectivesStatement.selectedProducts',
                            })}
                        </FormLabel>
                        {selectedProductRows && selectedProductRows.size ? (
                            <Table
                                header={[
                                    {
                                        label: '',
                                        id: 'selector',
                                    },
                                    {
                                        label: this.props.intl.formatMessage({
                                            id:
                                                'components.AgreementObjectivesStatement.selectedProducts.productName',
                                        }),
                                        id: 'productName',
                                    },
                                    {
                                        label: this.props.intl.formatMessage({
                                            id:
                                                'components.AgreementObjectivesStatement.selectedProducts.rationale',
                                        }),
                                        id: 'rationale',
                                    },
                                ]}
                                rows={this.renderTableRowsForSelectedProducts()}
                                tdVerticalAlign={'top'}
                            />
                        ) : (
                            <p>
                                {this.props.intl.formatMessage({
                                    id: 'components.AgreementObjectivesStatement.noProducts',
                                })}
                            </p>
                        )}
                    </Common.Column>
                </Common.Row>
                <Common.Row>
                    <Common.Column>
                        <FormLabel>
                            {this.props.intl.formatMessage({
                                id: 'components.AgreementObjectivesStatement.testPlan',
                            })}
                        </FormLabel>
                        <TextField
                            onChange={this.createOnChangeHandler('testPlan')}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.AgreementObjectivesStatement.testPlanPlaceholder',
                            })}
                            value={this.props.currentAgreement.get('testPlan') || ''}
                            disabled={this.props.disabled}
                        />
                        {this.props.errors.get('testPlan') && (
                            <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                {this.props.intl.formatMessage({
                                    id: 'components.ErrorMessage.required',
                                })}
                            </ErrorMessage>
                        )}
                    </Common.Column>
                </Common.Row>
                <Common.Row>
                    <Common.Column>
                        <FormLabel>
                            {this.props.intl.formatMessage({
                                id: 'components.AgreementObjectivesStatement.scopeOfWork',
                            })}
                        </FormLabel>
                        <TextField
                            onChange={this.createOnChangeHandler('scopeOfWork')}
                            placeholder={this.props.intl.formatMessage({
                                id:
                                    'components.AgreementObjectivesStatement.scopeOfWorkPlaceholder',
                            })}
                            value={this.props.currentAgreement.get('scopeOfWork') || ''}
                            disabled={this.props.disabled}
                        />
                        {this.props.errors.get('scopeOfWork') && (
                            <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                {this.props.intl.formatMessage({
                                    id: 'components.ErrorMessage.required',
                                })}
                            </ErrorMessage>
                        )}
                    </Common.Column>
                </Common.Row>
                {/* Attach media / Add image button */}
                <Common.Row>
                    <Common.Column>
                        <FormLabel>
                            {this.props.intl.formatMessage({
                                id: 'components.AgreementObjectivesStatement.attachMedia',
                            })}
                        </FormLabel>
                        {this.props.currentAgreement &&
                            this.props.currentAgreement.get('savedFiles') && (
                                <InputFileSet
                                    name={'savedFiles'}
                                    onRemove={this.props.onRemoveImage('savedFiles')}
                                    savedFiles={
                                        this.props.currentAgreement &&
                                        this.props.currentAgreement.get('savedFiles')
                                    }
                                    enableThumbnail
                                />
                            )}
                        <InputFileSet
                            buttonComponent={
                                <SecondaryButton
                                    text={this.props.intl.formatMessage({
                                        id: 'components.AgreementObjectivesStatement.addImage',
                                    })}
                                    disabled={this.props.disabled}
                                />
                            }
                            name={'unsavedFiles'}
                            onChange={this.props.handleOnChange('files')}
                            onRemove={this.props.onRemoveImage('files', false)}
                            accept="image/jpg, image/jpeg, image/png"
                            fileSizeLimitErrorMessage={
                                <ErrorMessage>
                                    {this.props.intl.formatMessage(
                                        {
                                            id: 'components.ErrorMessage.fileSizeLimit',
                                        },
                                        { limit: '5mb' }
                                    )}
                                </ErrorMessage>
                            }
                            enableThumbnail
                        />
                    </Common.Column>
                </Common.Row>
            </CollapsibleBlock>
        );
    }
}

export default injectIntl(AgreementObjectivesStatement);
