// @flow

import request from 'services/request';
import { decamelize } from 'humps';

import type { UserSettings } from 'types';
import type { ImmutableUserType } from './types';

/**
 * POST Request to authenticate user
 *
 * @param {string} email
 * @param {string} password
 */
export function authenticate(user: ImmutableUserType): Promise<UserType> {
    return request('user', {
        method: 'POST',
        data: user,
    });
}

/**
 * GET Request which returns authenticated user
 */
export function getAuthenticatedUser(): Promise<UserType> {
    return request('whoami', {
        method: 'GET',
    });
}

/**
 * Logs a user out by sending a request to invalidate the token
 */
export function logout(): Promise<boolean> {
    return request('logout', {
        method: 'GET',
    });
}

/**
 * User accepts disclaimer
 *
 * @param {string} key
 */
export function acceptDisclaimer(key: string): Promise<UserType> {
    return request('user/accept-disclaimer', {
        method: 'POST',
        data: {
            key: decamelize(key),
        },
    });
}

/**
 * Update user settings
 *
 * @param {number} id
 * @param {UserSettings} userSettings
 */
export const updateSettings = (id: number, userSettings: UserSettings): Promise<ImmutableUser> =>
    request(`user/${id}`, {
        data: userSettings,
        method: 'PUT',
    });
