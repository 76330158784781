// @flow

import React from 'react';

// Components
import { PrimaryButton, TertiaryButton } from 'OsedeaReactUI';

// Styles
import { Content, Footer, Header, Modal, Note } from './styles';

// Types
import type { ReactNode } from 'types';

type Props = {
    actionButtonText: string,
    content: string,
    handleAgreement: () => void,
    title: string,
    uiIsLoading: boolean,
    warningNote: string,
};

type State = {
    displayWarning?: boolean,
    enableButton?: boolean,
    expectedYValue?: number,
};

const disclaimerViewMaxHeight = 424;

export default class ModalContent extends React.Component<Props, State> {
    inner: ReactNode;

    outer: ReactNode;

    state = {
        displayWarning: false,
        enableButton: false,
        expectedYValue: 0,
    };

    /**
     * Add outer element scroll event
     * Calculate expected y value for inner element to end trackScrolling()
     *
     * If inner element height is greater than defined "max height";
     * calculate expectedYValue, add event listener & disable button
     * else
     * enable button as the user is unable to scroll to enable it
     */
    componentDidMount() {
        let expectedYValue;
        let enableButton = true;

        if (this.inner.getBoundingClientRect().height > disclaimerViewMaxHeight) {
            const outerStyles = window.getComputedStyle(this.outer);
            const outerPadding =
                Number(outerStyles.getPropertyValue('padding-top').replace(/px$/, '')) +
                Number(outerStyles.getPropertyValue('padding-bottom').replace(/px$/, ''));

            expectedYValue =
                this.inner.getBoundingClientRect().height -
                this.outer.getBoundingClientRect().height -
                this.inner.getBoundingClientRect().top +
                outerPadding;

            enableButton = false;
            this.handleOuterElEventSetter();
        }

        this.setState({
            expectedYValue,
            enableButton,
        });
    }

    /**
     * Remove outer element scroll event;
     */
    componentWillUnmount() {
        this.handleOuterElEventSetter(false);
    }

    /**
     * Track scrolling; on scroll check when at bottom of inner div and set enableButton to true, reset displayWarning
     */
    trackScrolling = () => {
        if (
            Math.round(Math.abs(Number(this.state.expectedYValue))) ===
            Math.round(Math.abs(this.inner.getBoundingClientRect().top))
        ) {
            this.handleOuterElEventSetter();
            this.setState({
                displayWarning: false,
                enableButton: true,
            });
        }
    };

    /**
     * Add/Remove scroll event listener to outer element
     */
    handleOuterElEventSetter = (addEvent: boolean = true) => {
        const outerEl = this.outer;
        if (addEvent) {
            outerEl.addEventListener('scroll', this.trackScrolling);
        } else {
            outerEl.removeEventListener('scroll', this.trackScrolling);
        }
    };

    createMarkup = () => ({ __html: this.props.content });

    /**
     * Set displayWarning to true
     */
    handleDisplayWarning = () =>
        this.setState({
            displayWarning: true,
        });

    render() {
        let button;
        if (this.props.handleAgreement) {
            if (this.state.enableButton) {
                button = (
                    <PrimaryButton
                        loading={this.props.uiIsLoading}
                        onClick={!this.props.uiIsLoading ? this.props.handleAgreement : null}
                        text={this.props.actionButtonText}
                    />
                );
            } else {
                button = (
                    <TertiaryButton
                        onClick={this.handleDisplayWarning}
                        style={{
                            cursor: 'not-allowed',
                        }}
                        text={this.props.actionButtonText}
                    />
                );
            }
        }

        return (
            <Modal>
                <Header>{this.props.title}</Header>
                <Content maxHeight={disclaimerViewMaxHeight}>
                    {/* eslint-disable-next-line */}
                    <div ref={(elem) => (this.outer = elem)}>
                        {/* eslint-disable-next-line */}
                        <div ref={(elem) => (this.inner = elem)}>
                            {/* eslint-disable-next-line react/no-danger */}
                            <div dangerouslySetInnerHTML={this.createMarkup()} />
                        </div>
                    </div>
                </Content>
                <Footer>
                    <div>
                        {this.props.warningNote && (
                            <Note warning={this.state.displayWarning}>
                                {this.state.displayWarning && '* '}
                                {this.props.warningNote}
                            </Note>
                        )}
                        {button}
                    </div>
                </Footer>
            </Modal>
        );
    }
}
