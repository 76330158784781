// @flow

import axios from 'axios';
import { push } from 'react-router-redux';
import { API_URL, API_VER } from 'env';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { logoutUser } from 'management/services/Authentication/thunks';
import { store } from '../index';

/**
 * Requests a URL, returning a promise.
 *
 * Axios by default will reject a promise if it is not between status codes 200-300
 * (This can be modified by implementing the validateStatus method)
 *
 * @param {string} url       The URL we want to request
 * @param {object} [options] The options we want to pass to "fetch"
 * @param {apiVer} [optional] Optional api version (eg. /api/v2/)
 * @param {humps} [optional] Set to false to not camelize/decamelize keys
 *
 * @return {object}           An object containing either "data" or "err"
 */

export default function request(
    url: string,
    options?: { method?: ?string, data?: ?Object } = {
        method: 'GET',
        data: {},
    },
    params?: Object = {},
    apiVer?: string = API_VER,
    humps?: boolean = true,
    isFormData?: boolean = false
) {
    return axios({
        url: `${API_URL}${apiVer}${url}`,
        method: options.method ? options.method : 'GET',
        data: humps && !isFormData ? decamelizeKeys(options.data) : options.data,
        headers: {
            Accept: 'application/json',
            'Content-Type':
                options.headers && options.headers.contentType
                    ? options.headers.contentType
                    : 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: `Bearer ${localStorage.getItem('api_token') || ''}`,
        },
        params,
    })
        .then((response: Object) =>
            Promise.resolve(humps ? camelizeKeys(response.data) : response.data)
        )
        .catch((error: Object) => {
            if (error.response && error.response.status === 403) {
                store.dispatch(logoutUser());
            }
            if (error.response && error.response.status === 404) {
                store.dispatch(push('/404'));
            }

            if (error instanceof Error) {
                // If there is a network error, logout user and force redirect to login
                if (error.toString().includes('Network Error')) {
                    store.dispatch(logoutUser(true));
                }

                error.response = {
                    message: error.message,
                    status: 500,
                };
            } else {
                // eslint-disable-next-line no-param-reassign
                error.response = {
                    ...error.response,
                    status: error.response.status,
                };
            }

            return Promise.reject(humps ? camelizeKeys(error.response) : error.response);
        });
}
