// @flow

import React from 'react';
import { Switch, Redirect, Route, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Analytics from './analytics';

// Components
import { Loader } from 'OsedeaReactUI';

// Constants
import { ROUTES } from 'utils/constants';

// Views
import Disclaimers from 'management/views/Disclaimers';
import Login from 'management/views/Login';
import Users from 'management/views/Users';
import Agreement from 'views/Agreement';
import Blocks from 'views/Blocks';
import NotFound from 'views/NotFound';
import Products from 'views/Products';
import Projects from 'views/Projects';
import Results from 'views/Results';
import Survey from 'views/Survey';

// Styles
import { Banner, LoaderWrapper } from 'styles/common';

// Types
import type { UserType } from 'management/services/Authentication/types';

// Utils
import { userIsAdmin } from 'utils/helpers';

type Props = {
    location: {
        pathname: string,
    },
    isOwner?: boolean,
    projectIsFetching: boolean,
    user?: UserType,
    userIsFetching: boolean,
    userIsReady?: boolean,
};

export class Routes extends React.PureComponent<Props> {
    static defaultProps = {
        isOwner: true,
        user: null,
        userIsReady: false,
    };

    render() {
        const { user } = this.props;

        // If user is fetching/authenticating, display loader to avoid pop in rendered UI
        if (this.props.userIsFetching) {
            return (
                <LoaderWrapper>
                    <Loader loading />
                </LoaderWrapper>
            );
        }

        // User exists, has roles & has accepted required policies
        // Ready for application
        const denyUser =
            !user ||
            !this.props.userIsReady ||
            (user && user.isEmpty()) ||
            // Otherwise if we don't have roles (=not given access to the system)
            (user && user.has('roles') && user.get('roles').size === 0);

        const loginRedirect =
            denyUser &&
            // And we are not on the login page
            this.props.location.pathname.replace(/\/$/, '') !== ROUTES.LOGIN;

        const displayBanner =
            !this.props.projectIsFetching &&
            !this.props.isOwner &&
            this.props.location.pathname.split('/')[1] === 'project';

        // If the user doesn't have roles and is not on login
        // redirect to projects
        const projectsRedirect =
            (!denyUser && this.props.location.pathname.replace(/\/$/, '') === ROUTES.LOGIN) ||
            this.props.location.pathname.replace(/\/$/, '') === ROUTES.HOME;

        const isAdmin = user && userIsAdmin(user);

        return (
            <React.Fragment>
                {loginRedirect ? <Redirect to="/login" /> : null}
                {projectsRedirect ? <Redirect to="/" /> : null}
                <Switch>
                    <Route path={ROUTES.HOME} component={Projects} exact />
                    <Route path={ROUTES.SURVEY} component={Survey} exact />
                    <Route path={ROUTES.BUILDING_BLOCKS} component={Blocks} exact />
                    <Route path={ROUTES.PRODUCTS} component={Products} exact />
                    <Route path={ROUTES.DISCOVERY_AGREEMENT} component={Agreement} exact />
                    <Route path={ROUTES.RESULTS} component={Results} exact />
                    <Route path={ROUTES.LOGIN} component={Login} exact />
                    {isAdmin && <Route path={ROUTES.USERS} component={Users} exact />}
                    {isAdmin && <Route path={ROUTES.DISCLAIMERS} component={Disclaimers} exact />}
                    <Route component={NotFound} />
                </Switch>
                {displayBanner && (
                    <Banner>
                        <FormattedMessage id="components.ProjectRoute.notOwner" />
                    </Banner>
                )}
                <Analytics />
            </React.Fragment>
        );
    }
}

export default withRouter(Routes);
