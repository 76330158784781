// @flow

import { createSelector } from 'reselect';

import { ProductType } from './types';
import { PROJECT_TYPE } from 'utils/constants';

/**
 * Direct selector to the train state domain
 */
export const selectProductState = () => (state: {}) => state.get('products');

/**
 * Select list of products
 */
export const selectAllProducts = () =>
    createSelector(selectProductState(), (substate: any) => substate.get('list'));

/**
 * Select list of products of Frother type
 */
export const selectFrotherProducts = () =>
    createSelector(selectAllProducts(), (substate: any) =>
        substate.filter((product: ProductType) => {
            return product.get('projectType') === PROJECT_TYPE.FROTHER;
        })
    );

/**
 * Select products list is fetching state
 */
export const selectProductsListIsFetching = () =>
    createSelector(selectProductState(), (substate: any) => substate.get('listIsFetching'));

/**
 * Select list of products whose composition consist of user selected building blocks
 */
export const selectProductsContainingBuildingBlocks = () =>
    createSelector(selectProductState(), (substate: any) => substate.get('productTableList'));

/**
 * Select fetching state of products whose composition consist of user selected building blocks
 */
export const selectProductTableListIsFetching = () =>
    createSelector(selectProductState(), (substate: any) =>
        substate.get('productTableListIsFetching')
    );
