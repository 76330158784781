// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Containers
import MessageBlockTimeoutProvider from 'containers/MessageBlockTimeoutProvider';

// Components
import { Checkmark, LegacyTheme, MessageBlock } from 'OsedeaReactUI';

// Types
import type { IntlType } from 'types';

type Props = {
    autoHideDuration?: number,
    isLoading?: boolean,
    onHide?: Function,
    intl: IntlType,
};

class ResultFeedbackBlock extends React.PureComponent<Props> {
    render() {
        const { intl, isLoading, onHide, autoHideDuration } = this.props;

        if (!isLoading) {
            return null;
        }

        const icon = <Checkmark />;
        const color = LegacyTheme.defaultSuccessColor;
        const text = intl.formatMessage({ id: 'components.ResultTable.updateSuccess' });

        // If icon, color and text variables not set, return early from function.
        if (!icon || !color || !text) {
            return null;
        }

        return (
            <MessageBlockTimeoutProvider autoHideDuration={autoHideDuration} onHide={onHide}>
                <MessageBlock icon={icon} color={color} text={text} />
            </MessageBlockTimeoutProvider>
        );
    }
}

export default injectIntl(ResultFeedbackBlock);
