// @flow

import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';

// Types
import type { ImmutableMap, GenericActionType } from 'types';

/**
 * Initial routing state
 */
const initialState = fromJS({
    location: null,
});

/**
 * Merge route into the global application state
 */
function routeReducer(state: ImmutableMap<string, any> = initialState, action: GenericActionType) {
    switch (action.type) {
        case LOCATION_CHANGE:
            return state.merge({
                location: action.payload,
            });
        default:
            return state;
    }
}

export default routeReducer;
