// @flow

import styled from 'styled-components';
import { transparentize } from 'polished';
import { LegacyTheme } from 'OsedeaReactUI';

export const LayoutContainer = styled.div`
    position: relative;

    display: flex;
    flex: 1;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 1;
`;

export const BackgroundImage = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 50%;
    left: 0;

    background-image: url(${({ backgroundImage }: Object) => backgroundImage});
    background-position: center;
    background-size: cover;

    z-index: 0;
`;

export const MainBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    max-width: 300px;
    min-width: 200px;
    min-height: 200px;

    padding: 15px 20px;
    margin: 0 auto;

    background-color: ${LegacyTheme.loginLayout};
    box-shadow: 0 2px 10px 3px ${transparentize(0.75, LegacyTheme.loginLayoutShadow)};
    border-radius: 4px;
`;

export const Title = styled.h1`
    max-width: 260px;
    margin: 24px auto !important;

    font-size: 24px;
    font-weight: normal;

    color: ${LegacyTheme.defaultColor};

    text-align: center;
`;

export const ContainerCentered = styled.div`
    position: relative;

    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;

    background-color: ${LegacyTheme.defaultBackground};
`;

export const Modal = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0 32px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 24px;

    font-size: 20px;
    line-height: normal;
    text-align: center;
`;

export const Content = styled.div`
    display: flex;
    min-height: 150px;
    max-height: ${({ maxHeight }) => `${maxHeight}px` || '424px'};

    color: ${LegacyTheme.defaultColor};
    font-size: 14px;
    line-height: 1.24;

    border: 1px solid ${LegacyTheme.defaultBorder};

    > div {
        width: 100%;

        padding: 24px;
        overflow-y: scroll;
    }

    p {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;

    text-align: right;
`;

export const Note = styled.p`
    margin-top: 0;

    color: ${({ warning }: Object) => (warning ? LegacyTheme.defaultWarningColor : LegacyTheme.defaultColor)};

    font-size: 14px;
`;
