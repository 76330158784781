// @flow

import styled from 'styled-components';

export const ActionsSection = styled.div`
    position: relative;
    display: flex;
    padding: 24px 0 60px;
`;

export const ActionButtonWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    button:not(:first-child) {
        margin-left: 16px;
    }
`;

export const ActionButtonSet = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const ExternalLinkWrapper = styled.div`
    flex: 1;

    a {
        margin-left: 8px;
    }
`;

export const LinkTitle = styled.span`
    font-size: 12px;
`;

export const Section = styled.section`
    margin-bottom: 14px;

    :last-child {
        margin-bottom: 0;
    }
`;

export const SingleColumn = styled.div`
    max-width: calc(33% - ${({ halfGutter }) => halfGutter || '8px'});
`;
