// @flow

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import { Table, InputSelect, Caret, Loader } from 'OsedeaReactUI';
import ResultTableDetails from 'components/ResultTableDetails';

// Styles
import {
    StyledUnorderedList,
    StyledListItem,
    ExpandableDetailsButton,
    DetailsToggleText,
    ProductInactive,
    PlantTrialResultOptionsWrapper,
} from './styles';

// Flow types
import type { GenericOptionType, IntlType, ImmutableList } from 'types';
import type { ResultType } from 'services/Result/types';
import type { BuildingBlockType } from 'services/Project/types';

type Props = {
    intl: IntlType,
    results: ImmutableList<ResultType>,
    labResultOptions: ?ImmutableList<Object>,
    plantTrialResultOptions: ?ImmutableList<Object>,
    isLoading: boolean,
    isOwner: boolean,
    onHandleUpdateResult: () => void,
};

type State = {
    commentDetailIds: Array<number>,
    results: ImmutableList<ResultType>,
};

/**
 * ResultTable
 *
 * Renders a table of results.
 */
class ResultTable extends React.PureComponent<Props, State> {
    state = {
        commentDetailIds: fromJS([]),
        results: this.props.results,
    };

    /**
     * Returns an array of objects used to render the table headers.
     */
    getTableHeaders = () => [
        {
            label: this.props.intl.formatMessage({
                id: 'components.ResultTable.header.productName',
            }),
            id: 'productName',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ResultTable.header.productFamily',
            }),
            id: 'productFamily',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ResultTable.header.components',
            }),
            id: 'components',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ResultTable.header.labResults',
            }),
            id: 'labResults',
        },
        {
            label: this.props.intl.formatMessage({
                id: 'components.ResultTable.header.plantTrialResults',
            }),
            id: 'plantTrialResults',
        },
        {
            label: '',
            id: 'commentDetailsToggle',
        },
    ];

    /**
     * Returns an array of objects. Each object represents a table row whose keys map to content
     * (strings, JSX, etc) that will be rendered under the corresponding table header.
     */
    getMappedTableRows = () =>
        this.state.results.map((result: ResultType, index: number) => {
            const productName = this.renderTableNameComponents(
                result.get('product').get('name'),
                result.get('product').get('active')
            );
            const productFamily = result.get('product').get('family');
            const components = this.renderTableDataBuildingBlockComponents(
                result.get('product').get('buildingBlocks')
            );
            const labResults = this.renderTableResultDropdown(
                index,
                'labResult',
                result.get('labResult')
            );

            const plantTrialResults = this.renderTableResultDropdown(
                index,
                'plantTrialResult',
                result.get('plantTrialResult')
            );

            const commentDetailsToggle = this.renderTableDataCommentsToggle(result.get('id'));
            const expandedContent = this.renderCommentRowExpandableDetails(index, result);

            return {
                id: `product-row-${index}-${result.get('id')}}`,
                productName,
                productFamily,
                components,
                labResults,
                plantTrialResults,
                commentDetailsToggle,
                expandedContent,
                disabled: false,
            };
        });

    // handle result selected from dropdown selector
    handleOnSelect = (index: number, name: string) => (option: GenericOptionType) => {
        this.setState(
            (prevState: State) => {
                return {
                    results: prevState.results.setIn([index, name], option.value),
                };
            },
            () => {
                this.props.onHandleUpdateResult(this.state.results.get(index));
            }
        );
    };

    // handle text change from textarea
    handleOnTextChange = (index: number, name: string, value: string) => {
        this.setState((prevState: State) => {
            return {
                results: prevState.results.setIn([index, name], value),
            };
        });
    };

    handleOnTextBlur = (index: number) => {
        this.props.onHandleUpdateResult(this.state.results.get(index));
    };

    /**
     * Handles the selection or deselection of a result to view its comments.
     */
    handleToggleProductDetails = (id: number) => () => {
        const { commentDetailIds } = this.state;
        const isCommentDetailsAlreadyOpen = commentDetailIds.includes(id);

        this.setState((prevState: State) => {
            let updatedCommentIds;

            if (isCommentDetailsAlreadyOpen) {
                const idIndex = commentDetailIds.indexOf(id);
                updatedCommentIds = prevState.commentDetailIds.delete(idIndex);
            } else {
                updatedCommentIds = prevState.commentDetailIds.push(id);
            }

            return {
                commentDetailIds: updatedCommentIds,
            };
        });
    };

    /**
     * Helper function which returns a the JSX for the product name content.
     */
    renderTableNameComponents = (productName: string, active: boolean) => (
        <div>
            {productName}
            {!active && (
                <ProductInactive>
                    {this.props.intl.formatMessage({
                        id: 'components.ResultTable.productNotAvailable',
                    })}
                </ProductInactive>
            )}
        </div>
    );

    /**
     * Helper function which returns a the JSX for the clickable content which toggles the
     * comments expandable details.
     */
    renderTableDataCommentsToggle = (resultId: number) => {
        const isCommentDetailsAlreadyOpen = this.state.commentDetailIds.includes(resultId);

        const result = this.state.results.find((r) => r.get('id') === resultId);

        let countComment =
            result.get('labResultComment') === null || result.get('labResultComment') === ''
                ? 0
                : 1;
        countComment +=
            result.get('plantTrialResultComment') === null ||
            result.get('plantTrialResultComment') === ''
                ? 0
                : 1;

        const text = isCommentDetailsAlreadyOpen
            ? this.props.intl.formatMessage({ id: 'components.ResultTable.hide' })
            : this.props.intl.formatMessage(
                  { id: 'components.ResultTable.comment' },
                  { count: countComment }
              );

        const caret = isCommentDetailsAlreadyOpen ? <Caret up black /> : <Caret down black />;

        return (
            <ExpandableDetailsButton onClick={this.handleToggleProductDetails(resultId)}>
                <DetailsToggleText>{text}</DetailsToggleText>
                {caret}
            </ExpandableDetailsButton>
        );
    };

    renderTableResultDropdown = (index, key, value) => {
        const options =
            key === 'labResult' ? this.props.labResultOptions : this.props.plantTrialResultOptions;
        return (
            <PlantTrialResultOptionsWrapper>
                <InputSelect
                    onSelect={this.handleOnSelect(index, key)}
                    options={options}
                    placeholder={'-'}
                    isDisabled={!this.props.isOwner}
                    selectedOption={options.find(
                        (option: GenericOptionType) => option.value === value && option
                    )}
                    controlShouldRenderValue
                />
            </PlantTrialResultOptionsWrapper>
        );
    };

    /**
     * Helper function which returns the JSX for the Building Block Components column:
     * 1. List of show components (max of 3)
     * 2. A tooltip which when clicked on shows the remaining hidden components
     */
    renderTableDataBuildingBlockComponents = (components: ImmutableList<BuildingBlockType>) => (
        <React.Fragment>
            {/* Listed building block components */}
            <StyledUnorderedList>
                {components.map((component: object, index: number) => (
                    <StyledListItem key={component.get('id')}>
                        {`${component
                            .get('pivot')
                            .get('percentage')
                            .substring(
                                0,
                                component.get('pivot').get('percentage').length - 3
                            )}%  ${component.get('name')}`}
                    </StyledListItem>
                ))}
            </StyledUnorderedList>
        </React.Fragment>
    );

    /**
     * Helper function which returns the JSX for the expandable comment of a result
     */
    renderCommentRowExpandableDetails = (index: number, result: ResultType) => {
        const isExpanded = this.state.commentDetailIds.includes(result.get('id'));

        return (
            isExpanded && (
                <ResultTableDetails
                    result={result}
                    index={index}
                    onHandleTextChange={this.handleOnTextChange}
                    onTextBlur={this.handleOnTextBlur}
                    isOwner={this.props.isOwner}
                />
            )
        );
    };

    render() {
        const { isLoading } = this.props;

        if (
            !this.state.results ||
            !this.props.labResultOptions ||
            !this.props.plantTrialResultOptions
        ) {
            return null;
        }

        return (
            <React.Fragment>
                <React.Fragment>
                    <div style={{ position: 'relative' }}>
                        <Table
                            header={this.getTableHeaders()}
                            loading={isLoading}
                            overlay={isLoading ? <div /> : null}
                            rows={this.getMappedTableRows()}
                            tdVerticalAlign="baseline"
                        />
                    </div>
                </React.Fragment>
            </React.Fragment>
        );
    }
}

export default injectIntl(ResultTable);
