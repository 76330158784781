// @flow

import type { ErrorsType, GenericActionType } from 'types';
import type { DisclaimerType } from './types';

export const DISCLAIMERS_LIST_IS_FETCHING =
    'app/management/services/Disclaimer/DISCLAIMERS_ARE_FETCH';
export const DISCLAIMERS_LIST_FETCH_SUCCESS =
    'app/management/services/Disclaimer/DISCLAIMERS_LIST_FETCH_SUCCESS';
export const DISCLAIMERS_LIST_FETCH_FAIL =
    'app/management/services/Disclaimer/DISCLAIMERS_LIST_FETCH_FAIL';
export const DISCLAIMER_UPDATE_SUCCESS =
    'app/management/services/Disclaimer/DISCLAIMER_UPDATE_SUCCESS';
export const DISCLAIMER_UPDATE_FAIL = 'app/management/services/Disclaimer/DISCLAIMER_UPDATE_FAIL';

export const setDisclaimersListIsFetching = (disclaimersListIsFetching: boolean = true) => ({
    type: DISCLAIMERS_LIST_IS_FETCHING,
    payload: { disclaimersListIsFetching },
});

export const receivedDisclaimersListSuccess = (
    disclaimersList: Array<DisclaimerType>
): GenericActionType => ({
    type: DISCLAIMERS_LIST_FETCH_SUCCESS,
    payload: { disclaimersList },
});

export const receivedDisclaimersListFail = (errors: ErrorsType): GenericActionType => ({
    type: DISCLAIMERS_LIST_FETCH_FAIL,
    payload: { errors },
});

export const receivedDisclaimerUpdateSuccess = (disclaimer: DisclaimerType): GenericActionType => ({
    type: DISCLAIMER_UPDATE_SUCCESS,
    payload: { disclaimer },
});

export const receivedDisclaimerUpdateFail = (errors: ErrorsType): GenericActionType => ({
    type: DISCLAIMER_UPDATE_FAIL,
    payload: { errors },
});
