// @flow

import { replace } from 'react-router-redux';

// Types
import type { ReduxDispatch, ResponseErrorType } from 'types';
import type { ProjectType, CustomBlendType } from './types';
import type { FrotherBlendsFilter } from 'services/BuildingBlock/types';

// Resources
import {
    create,
    del,
    index,
    reportProject,
    show,
    update,
    createCustomProductBlend,
    addProductsToDiscovery,
    getDiscoveryAgreement,
    saveDiscoveryAgreementToProject,
    getCompanyAddressesList,
} from './resources';
import { report } from 'management/services/Errors/resources';

// Actions
import {
    receivedCreateFailure,
    receivedCreateSuccess,
    receivedFetchFailure,
    receivedFetchSuccess,
    receivedFetchListSuccess,
    receivedFetchListFailure,
    receivedReportFailure,
    receivedReportSuccess,
    receivedUpdateFailure,
    receivedUpdateSuccess,
    setListIsFetchingStatus,
    setSingleProjectIsFetchingStatus,
    setClearSingleProject,
    receivedCreateCustomBlendSuccess,
    receivedCreateCustomBlendFailure,
    setCustomBlendIsSubmitting,
    resetCustomBlendStateSuccess,
    receivedProductsAddedToDiscoverySuccess,
    receivedProductsAddedToDiscoveryFailure,
    receivedDiscoveryAgreementSuccess,
    receivedDiscoveryAgreementFailure,
    receivedDiscoveryAgreementSaveSuccess,
    receivedDiscoveryAgreementSaveFailure,
    setDiscoveryAgreementIsFetching,
    setDiscoveryAgreementIsSubmitting,
    resetDiscoveryAgreementSuccess,
    resetProjectErrorsSuccess,
    receivedCompanyAddressesSuccess,
    receivedCompanyAddressesFailure,
    receivedDeleteFailure,
    receivedDeleteSuccess,
} from './actions';

/**
 * Thunks
 *
 * Basic function: Validate response and dispatch action to save data/error to redux store
 */

/**
 * Create project
 *
 * @param {ProjectType} projectObject // Project's object to save to db
 * @param {boolean} updateList // If true, set new project into project list
 */
export const createProject = (projectObject: ProjectType, updateList?: boolean = false) => (
    dispatch: ReduxDispatch
) =>
    create(projectObject)
        .then((response: {}) => {
            if (response.error) {
                dispatch(receivedCreateFailure(response.error));
            } else if (response) {
                dispatch(receivedCreateSuccess(response, updateList));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCreateFailure(error));
        });

/**
 * Fetch project by id
 *
 * @param {number} id
 * @param {Array} relations // Array of relationships to load in return object
 * @param {string} path // Redirect path
 * @param {boolean} updateList // If true, set new project into project list
 */
export const fetchProject = (
    id: number,
    relations?: Array<string> = [],
    path?: string,
    updateList?: boolean = true
) => (dispatch: ReduxDispatch) => {
    dispatch(setSingleProjectIsFetchingStatus());
    return show(id, relations)
        .then((response: {}) => {
            if (response.error) {
                dispatch(receivedFetchFailure(response.error));
            } else if (response) {
                // If path is passed, replace history
                if (path) {
                    dispatch(replace(path));
                }
                dispatch(receivedFetchSuccess(response, updateList));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchFailure(error));
        });
};

export const fetchProjectWithBlendFilter = (
    id: number,
    relations: string[],
    filters: FrotherBlendsFilter
) => (dispatch: ReduxDispatch) => {
    dispatch(setSingleProjectIsFetchingStatus());
    return show(id, relations, filters)
        .then((response: {}) => {
            if (response.error) {
                dispatch(receivedFetchFailure(response.error));
            } else if (response) {
                dispatch(receivedFetchSuccess(response, true));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchFailure(error));
        });
};

/**
 * Fetch all projects
 */
export const fetchAllProjects = (
    searchCriteria: ProjectSearchCriteriaType,
    page: number,
    perPage: number
) => (dispatch: ReduxDispatch) => {
    dispatch(setListIsFetchingStatus());
    index(searchCriteria, page, perPage)
        .then((response: {}) => {
            if (response.error) {
                dispatch(receivedFetchListFailure(response.error));
            } else if (response) {
                dispatch(receivedFetchListSuccess(response));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchListFailure(error));
        });
};

/**
 * Update project by id
 */
export const updateProject = (project: ProjectType) => (dispatch: ReduxDispatch) =>
    update(project.id, project)
        .then((response: {}) => {
            if (response.error) {
                dispatch(receivedUpdateFailure(response.error));
            } else if (response) {
                dispatch(receivedUpdateSuccess(response));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateFailure(error));
        });

/**
 * Report project's recommendations by project id
 */
export const reportProjectRecommendations = (projectId: number) => (dispatch: ReduxDispatch) =>
    reportProject(projectId)
        .then((response: {}) => {
            if (response.error) {
                dispatch(receivedReportFailure(response.error));
            } else if (response) {
                dispatch(receivedReportSuccess(response));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedReportFailure(error));
        });

/**
 * Clears the currently stored project in Redux
 */
export const clearSingleProject = () => (dispatch: ReduxDispatch) => {
    dispatch(setClearSingleProject());
};

/**
 * Creates a custom blend for a project by id
 */
export const createCustomBlend = (projectId: number, customBlend: CustomBlendType) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setCustomBlendIsSubmitting());

    createCustomProductBlend(projectId, customBlend)
        .then((response: {}) => dispatch(receivedCreateCustomBlendSuccess(response)))
        .catch((error: ResponseErrorType) => dispatch(receivedCreateCustomBlendFailure(error)));
};

/**
 * In preparation for the next custom blend submission, clears custom blend name, is submitting
 * state and project errors.
 */

export const resetCustomBlendState = () => (dispatch: ReduxDispatch) =>
    dispatch(resetCustomBlendStateSuccess());

export const submitProductsToDiscovery = (projectId: number, productIds: Array<number>) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setCustomBlendIsSubmitting());

    addProductsToDiscovery(projectId, productIds)
        .then((response: {}) =>
            dispatch(receivedProductsAddedToDiscoverySuccess(response, productIds))
        )
        .catch((error: ResponseErrorType) =>
            dispatch(receivedProductsAddedToDiscoveryFailure(error))
        );
};

/**
 * Fetches the discovery agreement attached to provided project ID argument.
 */
export const fetchDiscoveryAgreement = (projectId: number) => (dispatch: ReduxDispatch) => {
    dispatch(setDiscoveryAgreementIsFetching());

    getDiscoveryAgreement(projectId)
        .then((response: {}) => dispatch(receivedDiscoveryAgreementSuccess(response)))
        .catch((error: ResponseErrorType) => dispatch(receivedDiscoveryAgreementFailure(error)));
};

/**
 * Submits the form data collected in the Discovery Agreement to be saved on the project.
 */
export const saveDiscoveryAgreement = (projectId: number, formData: {}) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setDiscoveryAgreementIsSubmitting());

    saveDiscoveryAgreementToProject(projectId, formData)
        .then((response: {}) => dispatch(receivedDiscoveryAgreementSaveSuccess(response)))
        .catch((error: ResponseErrorType) =>
            dispatch(receivedDiscoveryAgreementSaveFailure(error))
        );
};

/**
 * Clears project errors
 */
export const resetProjectErrors = () => (dispatch: ReduxDispatch) =>
    dispatch(resetProjectErrorsSuccess());

/**
 * Fetches the list of company addresses used for company address select input options in Discovery.
 */
export const fetchCompanyAddresses = () => (dispatch: ReduxDispatch) =>
    getCompanyAddressesList()
        .then((response: []) => dispatch(receivedCompanyAddressesSuccess(response)))
        .catch((error: ResponseErrorType) => dispatch(receivedCompanyAddressesFailure(error)));

/**
 * Delete project by id
 */
export const deleteProject = (projectId: number) => (dispatch: ReduxDispatch) => {
    dispatch(setListIsFetchingStatus());
    del(projectId)
        .then((response: {}) => {
            if (response.error) {
                dispatch(receivedDeleteFailure(response.error));
            } else if (response) {
                dispatch(receivedDeleteSuccess(response));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedDeleteFailure(error));
        });
};

/**
 * Resets the current store discovery agreement to initial state
 */
export const resetDiscoveryAgreement = () => (dispatch: ReduxDispatch) =>
    dispatch(resetDiscoveryAgreementSuccess());
