// @flow

import styled from 'styled-components';

export const Container = styled.div`
    display: block;
    width: 100%;
    max-width: 380px;

    text-align: center;
`;
