// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';
import { decamelize } from 'humps';

// Components
import {
    Common,
    Loader,
    PrimaryButton,
    SidebarLayout,
    SubtleAnchor,
    SecondaryButton,
} from 'OsedeaReactUI';
import AgreementCompanyAddresses from 'components/AgreementCompanyAddresses';
import AgreementCustomer from 'components/AgreementCustomer';
import AgreementObjectivesStatement from 'components/AgreementObjectivesStatement';
import AgreementPDF from 'components/AgreementPDF';
import AgreementPersonalContactInfo from 'components/AgreementPersonalContactInfo';
import AgreementFeedbackBlock from 'components/AgreementFeedbackBlock';
import ErrorMessage from 'components/ErrorMessage';
import SurveyIncompleteContainer from 'components/SurveyIncompleteContainer';

// Constants
import { ERROR_MESSAGE_MARGIN_TOP, SPS_LINK, TPS_LINK } from 'utils/constants';

// Selectors
import {
    selectSingleProject,
    selectSingleProjectIsFetching,
    selectDiscoveryAgreement,
    selectDiscoveryAgreementIsSubmitting,
    selectDiscoveryAgreementIsFetching,
    selectProjectErrors,
    selectCompanyAddresses,
} from 'services/Project/selectors';

// Styles
import {
    ActionsSection,
    ActionButtonWrapper,
    ActionButtonSet,
    ExternalLinkWrapper,
    LinkTitle,
    Section,
} from './styles';
import { LoaderWrapper, Title } from 'styles/common';

// Thunks
import {
    fetchCompanyAddresses,
    fetchDiscoveryAgreement,
    fetchProject,
    saveDiscoveryAgreement,
    resetDiscoveryAgreement,
    resetProjectErrors,
} from 'services/Project/thunks';

// Types
import type { GenericOptionType, ImmutableList, ImmutableMap, InputEvent, IntlType } from 'types';
import type { ProjectType } from 'services/Project/types';

type Props = {
    companyAddresses: ImmutableList<GenericOptionType>,
    discoveryAgreement?: ?ImmutableMap<string, Object>,
    discoveryAgreementIsFetching: boolean,
    discoveryAgreementIsSubmitting: boolean,
    errors: ?ImmutableMap<string, Object>,
    fetchCompanyAddresses: () => void,
    fetchDiscoveryAgreement: (projectId: number) => void,
    fetchProject: (number, Array<string>) => void,
    intl: IntlType,
    match?: {
        params?: {
            projectId: string,
        },
    },
    project?: ?ProjectType,
    projectIsFetching: boolean,
    resetProjectErrors: () => void,
    resetDiscoveryAgreement: () => void,
    saveDiscoveryAgreement: (projectId: number, formData: {}) => void,
};

type State = {
    agreementAlreadyFetched: boolean,
    fieldsAlreadyPrepopulated: boolean,
    attemptedSave: boolean,
    currentAgreement: ImmutableMap<string, string>,
    errors: ImmutableMap<string, boolean>,
    objectiveStatementProductsMap: ImmutableMap<string, { rationale: string }>,
    objectiveStatementRemovedProductIds: ImmutableList<number>,
    pdfHasRendered: boolean,
};

export class Agreement extends React.Component<Props, State> {
    static defaultProps = {
        discoveryAgreement: fromJS({}),
        match: {
            params: {
                projectId: null,
            },
        },
        project: null,
    };

    state = {
        agreementAlreadyFetched: false,
        attemptedSave: false,
        currentAgreement: fromJS({}),
        errors: fromJS({}),
        fieldsAlreadyPrepopulated: false,
        objectiveStatementProductsMap: fromJS({}),
        objectiveStatementRemovedProductIds: fromJS([]),
        showPdfViewer: false,
        pdfHasRendered: false,
    };

    /**
     * On component mount:
     * 1. Fetch company addresses for company address select options,
     * 2. Create a products map for easy editing of product selection and rationale.
     */
    componentDidMount() {
        const projectId = Number(this.props.match.params.projectId);
        if (projectId) {
            this.props.fetchProject(projectId, ['products']);
        }

        if (this.props.companyAddresses.isEmpty()) {
            this.props.fetchCompanyAddresses();
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { project, discoveryAgreement } = this.props;

        if (project && project !== prevProps.project) {
            // Case 1: After project is fetched and thereSave is an existing agreement, then fetch it.
            if (project.get('isAgreementFilled') && !this.state.agreementAlreadyFetched) {
                this.handleFetchAgreement();
            } else if (!project.get('isOwner') && !project.get('isAgreementFilled')) {
                this.props.resetDiscoveryAgreement();
            }

            if (project.get('products') && project.get('products').size) {
                this.mapProjectProductsToSelectedProducts();
            }
        }

        // Case 2: If a filled agreement is fetched then populate fields with the data
        // First load where agreement is fetched and discovery agreement is no longer null.
        const isFirstAgreementFetch =
            this.state.agreementAlreadyFetched &&
            prevProps.discoveryAgreement.size === 0 &&
            discoveryAgreement.size;

        // Agreement is already fetched, but prevProps.discoveryAgreement was truthy. This might be
        // the case when the user already visits the discovery page, leaves it, then returns to it.
        const agreementFetchedAndFieldsNotPopulated =
            !this.state.agreementAlreadyFetched &&
            discoveryAgreement &&
            !this.state.fieldsAlreadyPrepopulated;

        const agreementUpdated =
            (discoveryAgreement && discoveryAgreement.get('updatedAt')) !==
            (prevProps.discoveryAgreement && prevProps.discoveryAgreement.get('updatedAt'));

        if (isFirstAgreementFetch || agreementFetchedAndFieldsNotPopulated) {
            this.handlePopulateFields();
        } else if (agreementUpdated) {
            /* eslint-disable react/no-did-update-set-state */
            this.setState({ currentAgreement: discoveryAgreement });
        }
    }

    /**
     * Removes the file from the array of savedFiles
     */
    handleRemoveImage = (name: string, immutableMap: boolean = true) => (file: ImmutableMap) => {
        this.setState((prevState: State) => {
            const savedFiles = prevState.currentAgreement && prevState.currentAgreement.get(name);

            // If immutableMap (file entity returned from backend) check on id,
            // Else assume File Object (unsaved file), check on name & size
            const fileIdxToRemove =
                savedFiles &&
                savedFiles.findIndex((f: ImmutableMap | {}) => {
                    if (immutableMap) {
                        return f.get('id') === file.get('id');
                    } else {
                        return f.name === file.name && f.size === file.size;
                    }
                });

            if (fileIdxToRemove < 0) {
                return null;
            }

            return {
                currentAgreement: prevState.currentAgreement.deleteIn([name, fileIdxToRemove]),
            };
        });
    };

    /**
     * Populate fields with the existing discovery agreement data values
     */
    handlePopulateFields = () => {
        this.setState((prevState: State) => ({
            currentAgreement: this.props.discoveryAgreement,
            fieldsAlreadyPrepopulated: !prevState.fieldsAlreadyPrepopulated,
        }));
    };

    /**
     * Fetches the agreement and sets this.state.agreementAlreadyFetched to true to prevent
     * unnecessary requests on re-renders.
     */
    handleFetchAgreement = () =>
        this.setState(
            (prevState: State) => ({
                agreementAlreadyFetched: !prevState.agreementAlreadyFetched,
            }),

            this.props.fetchDiscoveryAgreement(this.props.project.get('id'))
        );

    /**
     * Updates the current agreement form data values.
     */
    onCurrentAgreementUpdate = (name: string) => (
        value: string | number | ImmutableMap<string, Object> | ImmutableList<any>
    ) => {
        this.setState(
            (prevState: State) => ({
                currentAgreement: prevState.currentAgreement.set(name, value),
            }),
            // setState callback.
            // If user already attempted to save, but got errors, run live validations.
            () => {
                if (this.state.attemptedSave) {
                    this.validateFormFields();
                }
            }
        );
    };

    /**
     * Handles the forwarding of the Discovery Agreement data to the thunk.
     * Before submitting we do two things:
     *  1. invoke serializeProductsMap to transform our products map into a list
     *  2. invoke validateFormFields, to validate that all required fields are set and not left empty.
     */
    handleSave = async () => {
        await this.serializeProductsMap();

        const formIsIncomplete = this.validateFormFields();
        if (formIsIncomplete) {
            this.setState({ attemptedSave: true });
            return false;
        }

        const formData = this.serializeCurrentAgreementToFormData();
        this.props.saveDiscoveryAgreement(this.props.project.get('id'), formData);
        return true;
    };

    /**
     * Handles the removal of a product. This handler takes the product ID as an argument then
     * pushes the ID to the list of removed product ids and removes the property from the selected
     * products map.
     */
    handleRemoveSelectedProduct = (id: number) => () => {
        this.setState((prevState: State) => ({
            objectiveStatementRemovedProductIds: prevState.objectiveStatementRemovedProductIds.push(
                id
            ),
            objectiveStatementProductsMap: prevState.objectiveStatementProductsMap.delete(`${id}`),
        }));
    };

    /**
     * Updates the rationale of a selected product inside the selected products map.
     */
    handleOnChangeProductRationale = (id: number) => (event: InputEvent) => {
        event.persist();

        this.setState(
            (prevState: State) => ({
                objectiveStatementProductsMap: prevState.objectiveStatementProductsMap.setIn(
                    [`${id}`, 'rationale'],
                    event.target.value
                ),
            }),
            this.serializeProductsMap
        );
    };

    /**
     * Creates a selected products map to make it easier to find products, edit their rationale or
     * remove them from the list. An alternative to working with the products in an array of objects.
     */
    mapProjectProductsToSelectedProducts = () => {
        const objectiveStatementProductsMap = this.props.project.get('products').reduce(
            // As we loop through each product...
            (map: {}, product: {}) =>
                // we will set on the accumulator a property where the key is set to the product ID
                // (casted to a string) which has a rationale subkey initialized to an empty string.
                map.set(
                    `${product.get('id')}`,
                    fromJS({
                        rationale: product.getIn(['pivot', 'rationale'])
                            ? product.getIn(['pivot', 'rationale'])
                            : '',
                    })
                ),
            fromJS({}) // This is the accumulator initialized to an Immutable object.
        );

        this.setState({ objectiveStatementProductsMap });
    };

    /**
     * Serializes the currentAgreement state into a new instance of a form data.
     *
     * For array structures in currentAgreement (ex. products, images and measurement fields) we
     * will JSON stringify the array.
     *
     * For files/images, we will loop through the files and append each file onto the form data
     * under the 'files[]' key.
     */
    serializeCurrentAgreementToFormData = () => {
        // Initialize form data instance
        const formData = new FormData();

        // Loop through each entry in currentAgreement
        for (const field of this.state.currentAgreement) {
            // Looping through object properties of an ImmutableMap returns entries like: [KEY, VALUE]
            // So let's store the keys and values as variables below:
            const fieldName = field[0];
            const fieldData = field[1];

            if (fieldName === 'savedFiles') {
                // Array of objects
                fieldData.forEach((val: ImmutableMap, i: number) => {
                    const obj = val.toJS();
                    Object.keys(obj).forEach((key: string) => {
                        if (obj[key]) {
                            formData.append(decamelize(`${fieldName}[${i}][${key}]`), obj[key]);
                        }
                    });
                });
            } else if (fieldName === 'products' || fieldName === 'measurement') {
                const stringifiedArrayData = JSON.stringify(fieldData);
                formData.append(decamelize(fieldName), stringifiedArrayData);
            } else if (fieldName === 'files') {
                const filesArray = fieldData;
                filesArray.forEach((file: File) => formData.append('files[]', file));
            } else if (fieldData === 'null' || fieldData === null) {
                // We do not append null values into the form data.
            } else {
                formData.append(decamelize(fieldName), fieldData);
            }
        }

        // Un-comment below code to console.log content of formData
        // for (const pair of formData.entries()) {
        //     console.log('createFormData', pair);
        // }

        return formData;
    };

    /**
     * Transforms the products map into an array of objects.
     * We want to transform the products map into a list of objects.
     *
     * Example:
     *  Converting from: { "22": { rationale: 'good' } }
     *  Converting to:   [ { id: 22, rationale: 'good'} ]
     */
    serializeProductsMap = () => {
        const serializedProducts = this.state.objectiveStatementProductsMap
            // Returns an array of arrays. Each subarray contains two elements, [KEY, PROPERTIES]
            // of the ImmutableMap it is called upon.
            .entrySeq()
            // We then want to flatten this 2D array into an array of objects where each object
            .reduce(
                (list: [], [key, value]: [string, ImmutableMap]) =>
                    list.push({
                        id: Number(key),
                        rationale: value.get('rationale'),
                    }),
                fromJS([])
            );

        this.onCurrentAgreementUpdate('products')(serializedProducts);
    };

    /**
     * Validates the completion of the required form fields. Missing fields are set as errors in
     * local state.
     */
    validateFormFields = () => {
        const REQUIRED_FIELDS = [
            'addressOneId',
            'customerName',
            'customerTitle',
            'customerMineSite',
            'representativeName',
            'representativeTitle',
            'mineralProcessing',
            'representativePhone',
            'representativeEmail',
            'background',
            'objective',
            'measurement',
            'products',
            'testPlan',
            'scopeOfWork',
        ];

        // To create an errors object, we will iterate over the array of REQUIRED_FIELDS above and
        // pass to Array.reduce our custom function that will generate our missing fields error object.
        const errors = REQUIRED_FIELDS.reduce(this.createRequiredFieldsErrorObject, fromJS({}));

        this.setState({ errors });

        // Return boolean indicating if the form is incomplete.
        return errors.size > 0;
    };

    /**
     * Generates an errors object based on missing fields that are required.
     *
     * Note: there are special cases for products and measurements of success because their data
     * is stored in an array of objects with different properties.
     *
     * Example output:
     * const errors = {
     *    addressOneId: true,
     *    productsRationales: { "22": true },
     * }
     */
    createRequiredFieldsErrorObject = (errors: ImmutableMap, field: string) => {
        const { currentAgreement } = this.state;

        if (field === 'products') {
            // For product rationales, let's loop through the currentAgreement products..
            for (const product of currentAgreement.get('products')) {
                // then find products with missing rationales..
                if (!product.rationale) {
                    // then set on the errors object on the productsRationales property an object
                    // with a key of the product ID set to true.
                    errors = errors.setIn(['productsRationales', `${product.id}`], true);
                }
            }

            return errors;
        } else if (field === 'measurement') {
            // Cases where an error should be set for measurement:
            // 1: measurement not yet set on currentAgreement
            // 2: measurement is an empty list
            // 3: measurement contains criteria, but cannot find at least one with a value.

            if (
                !currentAgreement.get('measurement') || // Case 1
                currentAgreement.get('measurement').isEmpty() // Case 2
            ) {
                return errors.set('measurement', true);
            }

            const foundCriteria = currentAgreement
                .get('measurement')
                .find((criteria: {}) => criteria.get('value'));

            // Case 3
            if (!foundCriteria) {
                return errors.set('measurement', true);
            }

            return errors;
        } else if (!currentAgreement.get(field)) {
            // If field is missing, on the errors object set a property with key of the field
            // name with value true.
            return errors.set(field, true);
        } else {
            // If field is set by the user, then just return the errors object are continue
            // to the next field iteration.
            return errors;
        }
    };

    handleOnRender = () => {
        this.setState({
            pdfHasRendered: true,
        });
    };

    /**
     * Attempts to save agreement after validations.
     * Then, toggles the showPdfViewer state between true and false.
     */
    handleTogglePdfViewer = async () => {
        const saved = await this.handleSave();

        if (!saved) {
            return;
        }

        this.setState((prevState: State) => ({
            showPdfViewer: !prevState.showPdfViewer,
            pdfHasRendered: false,
        }));
    };

    /**
     * Finds and returns the company names of company address IDs attached to the discovery agreement.
     */
    getCompanyAddressNames = () => {
        const { companyAddresses } = this.props;
        const { currentAgreement } = this.state;

        const getCompanyByKey = (key: string) => (address: ImmutableMap) =>
            /* eslint-disable eqeqeq */
            // Using loose equality here to compare values and not type. Ie. comparing "5" == 5.
            address.get('id') == currentAgreement.get(key);

        const formatCompany = (company: ImmutableMap) =>
            fromJS({
                name: company.get('name'),
                civicAddress: `${company.get('address')}`,
                provinceCountryPostalCode: `${company.get('city')}, ${company.get(
                    'province'
                )}, ${company.get('country')} ${company.get('postal')}`,
            });

        const companyAddress1 = companyAddresses.find(getCompanyByKey('addressOneId'));
        const formattedCompanyAddress1 = formatCompany(companyAddress1);

        // Optional company address 2
        let companyAddress2;
        let formattedCompanyAddress2;

        if (currentAgreement.get('addressTwoId')) {
            companyAddress2 = companyAddresses.find(getCompanyByKey('addressTwoId'));
            formattedCompanyAddress2 = formatCompany(companyAddress2);
        }

        return fromJS({
            companyAddress1: formattedCompanyAddress1,
            companyAddress2: formattedCompanyAddress2 || null,
        });
    };

    /**
     * Helper render function to render the Agreement PDF previewer.
     */
    renderPdfViewer = () => (
        <React.Fragment>
            {(this.props.discoveryAgreementIsSubmitting || !this.state.pdfHasRendered) && (
                <LoaderWrapper
                    style={{
                        position: 'absolute',
                        backgroundColor: 'white',
                        height: 'calc(100% - 48px)',
                    }}
                >
                    <div>
                        <Title>
                            {this.props.discoveryAgreementIsSubmitting
                                ? this.props.intl.formatMessage({
                                      id: 'views.Agreement.savingContent',
                                  })
                                : this.props.intl.formatMessage({
                                      id: 'views.Agreement.generatingPDF',
                                  })}
                        </Title>
                        <Loader loading />
                    </div>
                </LoaderWrapper>
            )}
            {!this.props.discoveryAgreementIsSubmitting && (
                <AgreementPDF
                    size="LETTER"
                    projectId={this.props.project.get('id')}
                    content={this.state.currentAgreement}
                    products={this.props.project.get('products')}
                    onCancel={this.handleTogglePdfViewer}
                    onRenderHandler={this.handleOnRender}
                    companyAddresses={this.getCompanyAddressNames()}
                    intl={this.props.intl}
                />
            )}
        </React.Fragment>
    );

    renderMain = () => {
        const disableButtons =
            !this.state.errors.isEmpty() ||
            this.props.discoveryAgreementIsSubmitting ||
            !this.props.project.get('isOwner');

        return (
            <React.Fragment>
                <Title>
                    {this.props.intl.formatMessage({
                        id: 'views.Agreement.pageTitle',
                    })}
                </Title>
                <br />
                <Section>
                    <AgreementCompanyAddresses
                        companyAddresses={this.props.companyAddresses}
                        currentAgreement={this.state.currentAgreement}
                        handleOnChange={this.onCurrentAgreementUpdate}
                        errors={this.state.errors}
                        disabled={!this.props.project.get('isOwner')}
                    />
                </Section>
                <Section>
                    <AgreementCustomer
                        currentAgreement={this.state.currentAgreement}
                        handleOnChange={this.onCurrentAgreementUpdate}
                        errors={this.state.errors}
                        disabled={!this.props.project.get('isOwner')}
                    />
                </Section>
                <Section>
                    <AgreementPersonalContactInfo
                        currentAgreement={this.state.currentAgreement}
                        handleOnChange={this.onCurrentAgreementUpdate}
                        errors={this.state.errors}
                        disabled={!this.props.project.get('isOwner')}
                    />
                </Section>
                <Section>
                    <AgreementObjectivesStatement
                        currentAgreement={this.state.currentAgreement}
                        handleOnChange={this.onCurrentAgreementUpdate}
                        projectProducts={this.props.project.get('products')}
                        selectedProductsMap={this.state.objectiveStatementProductsMap}
                        selectedProductsRemovedIds={this.state.objectiveStatementRemovedProductIds}
                        onChangeProductRationale={this.handleOnChangeProductRationale}
                        onRemoveSelectedProduct={this.handleRemoveSelectedProduct}
                        onRemoveImage={this.handleRemoveImage}
                        errors={this.state.errors}
                        disabled={!this.props.project.get('isOwner')}
                    />
                </Section>
                <div>
                    <ActionsSection>
                        {/* Agreement save feedback */}
                        <AgreementFeedbackBlock
                            autoHideDuration={2500}
                            discoveryAgreement={this.props.discoveryAgreement}
                            onHide={this.props.resetProjectErrors}
                            errors={this.props.errors}
                        />
                        <ExternalLinkWrapper>
                            <LinkTitle>
                                {this.props.intl.formatMessage({
                                    id: 'views.Agreement.externalLink.title',
                                })}
                            </LinkTitle>
                            <SubtleAnchor
                                href={SPS_LINK}
                                target="_blank"
                                rel="noopener noreferrer"
                                externalLinkIcon
                            >
                                {this.props.intl.formatMessage({
                                    id: 'views.Agreement.externalLink.sps',
                                })}
                            </SubtleAnchor>
                            <SubtleAnchor
                                href={TPS_LINK}
                                target="_blank"
                                rel="noopener noreferrer"
                                externalLinkIcon
                            >
                                {this.props.intl.formatMessage({
                                    id: 'views.Agreement.externalLink.tps',
                                })}
                            </SubtleAnchor>
                        </ExternalLinkWrapper>
                        <ActionButtonWrapper>
                            <ActionButtonSet>
                                <SecondaryButton
                                    onClick={this.handleTogglePdfViewer}
                                    text={this.props.intl.formatMessage({
                                        id: 'views.Agreement.exportAgreement',
                                    })}
                                    disabled={disableButtons}
                                />
                                <PrimaryButton
                                    onClick={this.handleSave}
                                    text={this.props.intl.formatMessage({
                                        id: 'views.Agreement.save',
                                    })}
                                    disabled={disableButtons}
                                />
                            </ActionButtonSet>
                            {!this.state.errors.isEmpty() && (
                                <ErrorMessage
                                    marginTop={ERROR_MESSAGE_MARGIN_TOP}
                                    textAlign={'right'}
                                >
                                    {this.props.intl.formatMessage({
                                        id: 'views.Agreement.missingFields',
                                    })}
                                </ErrorMessage>
                            )}
                        </ActionButtonWrapper>
                    </ActionsSection>
                </div>
            </React.Fragment>
        );
    };

    render() {
        let content = (
            <LoaderWrapper>
                <Loader loading />
            </LoaderWrapper>
        );

        if (this.props.project && !this.props.projectIsFetching) {
            const isOwner = this.props.project.get('isOwner');

            if (!isOwner && !this.props.project.get('isAgreementFilled')) {
                content = (
                    <Common.Column justifyContent="center" alignItems="center">
                        <p>
                            {this.props.intl.formatMessage({
                                id: 'views.Agreement.noDiscoveryAgreementGenerated',
                            })}
                        </p>
                    </Common.Column>
                );
            } else if (!this.props.project.get('surveyFilled') && !isOwner) {
                content = (
                    <Common.Column justifyContent="center" alignItems="center">
                        <SurveyIncompleteContainer />
                    </Common.Column>
                );
            } else if (this.props.project && !this.props.discoveryAgreementIsFetching) {
                const mainContent = this.state.showPdfViewer
                    ? this.renderPdfViewer
                    : this.renderMain;
                content = (
                    <SidebarLayout
                        renderMain={mainContent}
                        mainMaxWidth={this.state.showPdfViewer ? '100%' : '1048px'}
                    />
                );
            }
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.Agreement.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.Agreement.helmetDescription',
                        })}
                    />
                </Helmet>
                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    project: selectSingleProject(),
    projectIsFetching: selectSingleProjectIsFetching(),
    discoveryAgreement: selectDiscoveryAgreement(),
    discoveryAgreementIsSubmitting: selectDiscoveryAgreementIsSubmitting(),
    discoveryAgreementIsFetching: selectDiscoveryAgreementIsFetching(),
    errors: selectProjectErrors(),
    companyAddresses: selectCompanyAddresses(),
});

const mapDispatchToProps = {
    fetchCompanyAddresses,
    fetchProject,
    fetchDiscoveryAgreement,
    saveDiscoveryAgreement,
    resetProjectErrors,
    resetDiscoveryAgreement,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(injectIntl(Agreement)));
