// @flow

import { report } from 'management/services/Errors/resources';
import {
    receivedDisclaimersListSuccess,
    receivedDisclaimersListFail,
    receivedDisclaimerUpdateSuccess,
    receivedDisclaimerUpdateFail,
    setDisclaimersListIsFetching,
} from './actions';

import { getDisclaimers, postDisclaimer } from './resources';

import type { ReduxDispatch, ResponseErrorType } from 'types';
import type { DisclaimerType } from './types';

export const fetchDisclaimers = () => (dispatch: ReduxDispatch) => {
    dispatch(setDisclaimersListIsFetching());
    getDisclaimers()
        .then((response: {}) => {
            // TODO: Refactor response...
            if (response) {
                dispatch(receivedDisclaimersListSuccess(response));
            } else {
                throw new Error('response is empty');
            }
        })
        .catch((response: ResponseErrorType) => {
            report(response);
            dispatch(receivedDisclaimersListFail(response.message || response.data.errors));
        });
};

export const updateDisclaimer = (id: number, disclaimer: DisclaimerType) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setDisclaimersListIsFetching());
    postDisclaimer(id, disclaimer)
        .then((response: {}) => {
            if (response) {
                dispatch(receivedDisclaimerUpdateSuccess(response));
            } else {
                throw new Error('response is empty');
            }
        })
        .catch((response: ResponseErrorType) => {
            report(response);
            dispatch(receivedDisclaimerUpdateFail(response.message || response.data.errors));
        });
};
