// @flow

import React from 'react';

// Types
type Props = {
    children: React.Node,
    autoHideDuration?: number, // seconds
    onHide?: () => void,
};

/**
 * A wrapper for the MessageBlock component.
 *
 * Hides the MessageBlock after the provided amount of time via the autoHideDuration prop. If no
 * autoHideDuration prop is provided, default to 5 seconds.
 */
class MessageBlockTimeoutProvider extends React.PureComponent<Props> {
    static defaultProps = {
        autoHideDuration: 5000,
        onHide: null,
    };

    state = {
        renderMessageBlock: true,
    };

    /**
     * On mount, invoke this.handleHideMessageBlock after set amount of seconds (autoHideDuration)
     */
    componentDidMount() {
        this.autoHideTimeout = setTimeout(this.handleHideMessageBlock, this.props.autoHideDuration);
    }

    /**
     * On will unmount, clear the timeout to prevent potential memory leaks.
     */
    componentWillUnmount() {
        clearTimeout(this.autoHideTimeout);
    }

    /**
     * Toggles the renderMessageBlock conditional to false. If an optional onHide callback function
     * is provided call it.
     */
    handleHideMessageBlock = () =>
        this.setState({ renderMessageBlock: false }, () => {
            if (this.props.onHide) {
                this.props.onHide();
            }
        });

    render() {
        if (this.state.renderMessageBlock) {
            return this.props.children;
        } else {
            return null;
        }
    }
}

export default MessageBlockTimeoutProvider;
