// @flow

import type { ReduxDispatch, ResponseErrorType } from 'types';
import type { ResultType } from './types';

import { show, update, getLabResultOptions, getPlantTrialResultOptions } from './resources';
import {
    receivedFetchFailure,
    receivedFetchSuccess,
    receivedFetchLabResultOptionFailure,
    receivedFetchLabResultOptionSuccess,
    receivedFetchPlantTrialResultOptionFailure,
    receivedFetchPlantTrialResultOptionSuccess,
    receivedUpdateSuccess,
    receivedUpdateFailure,
    setResultIsFetchingStatus,
    setResultIsSubmittingStatus,
} from './actions';
import { report } from 'management/services/Errors/resources';

/**
 * Thunks
 *
 * Basic function: Validate response and dispatch action to save data/error to redux store
 */

/**
 * Fetch survey by Project ID
 *
 * @param {number} projectId
 */
export const fetchResult = (projectId: number) => (dispatch: ReduxDispatch) => {
    dispatch(setResultIsFetchingStatus(true));
    show(projectId)
        .then((response: {}) => {
            if (response.error) {
                dispatch(receivedFetchFailure(response.error));
            } else if (response) {
                dispatch(receivedFetchSuccess(response));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchFailure(error));
        });
};

/**
 * Update survey by projectId
 */
export const updateResult = (projectId: number, resultId: number, result: ResultType) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setResultIsSubmittingStatus(true));
    update(projectId, resultId, result)
        .then((response: {}) => {
            if (response.error) {
                dispatch(receivedUpdateFailure(response.error));
            } else if (response) {
                dispatch(receivedUpdateSuccess(response));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateFailure(error));
        });
};

/**
 * Fetch lab result option list
 *
 */
export const fetchLabResultOptions = () => (dispatch: ReduxDispatch) => {
    getLabResultOptions()
        .then((response: {}) => {
            if (response.error) {
                dispatch(receivedFetchLabResultOptionFailure(response.error));
            } else if (response) {
                dispatch(receivedFetchLabResultOptionSuccess(response));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchLabResultOptionFailure(error));
        });
};

/**
 * Fetch plant trial result option list
 *
 */
export const fetchPlantTrialResultOptions = () => (dispatch: ReduxDispatch) => {
    getPlantTrialResultOptions()
        .then((response: {}) => {
            if (response.error) {
                dispatch(receivedFetchPlantTrialResultOptionFailure(response.error));
            } else if (response) {
                dispatch(receivedFetchPlantTrialResultOptionSuccess(response));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchPlantTrialResultOptionFailure(error));
        });
};
