// @flow

/*
 *
 * Authentication reducer
 *
 */

import { fromJS } from 'immutable';

// Actions
import {
    DISCLAIMERS_LIST_IS_FETCHING,
    DISCLAIMERS_LIST_FETCH_SUCCESS,
    DISCLAIMERS_LIST_FETCH_FAIL,
    DISCLAIMER_UPDATE_SUCCESS,
    DISCLAIMER_UPDATE_FAIL,
} from './actions';

// Types
import type { GenericActionType, ImmutableList } from 'types';
import type { DisclaimerType } from './types';

type State = {
    disclaimersList: ImmutableList<DisclaimerType>,
    disclaimersListIsFetching: boolean,
    errors?: {},
};

const initialState = fromJS({
    disclaimersList: [],
    disclaimersListIsFetching: false,
    errors: {},
});

function disclaimerServiceReducer(state: State = initialState, action: GenericActionType) {
    switch (action.type) {
        case DISCLAIMERS_LIST_IS_FETCHING:
            return state.set('disclaimersListIsFetching', action.payload.disclaimersListIsFetching);

        case DISCLAIMERS_LIST_FETCH_SUCCESS:
            return state
                .set('disclaimersList', fromJS(action.payload.disclaimersList))
                .set('disclaimersListIsFetching', false);

        case DISCLAIMERS_LIST_FETCH_FAIL:
            return state
                .set('disclaimersListIsFetching', false)
                .set('errors', fromJS(action.payload.error));

        case DISCLAIMER_UPDATE_SUCCESS: {
            const updatedDisclaimer = action.payload.disclaimer;
            const idx = state
                .getIn(['disclaimersList'])
                .findIndex(
                    (disclaimer: DisclaimerType) => disclaimer.get('id') === updatedDisclaimer.id
                );
            return state
                .setIn(['disclaimersList', idx], fromJS(updatedDisclaimer))
                .set('disclaimersListIsFetching', false);
        }
        case DISCLAIMER_UPDATE_FAIL:
            return state
                .set('disclaimersListIsFetching', false)
                .set('errors', fromJS(action.payload.error));

        default:
            return state;
    }
}

export default disclaimerServiceReducer;
