// eslint-disable flowtype/no-weak-types
// @flow

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import {
    ButtonWrapper,
    Common,
    DotMenu,
    InfoIcon,
    InputField,
    InputSearch,
    InputSelect,
    Modal,
    NotificationBell,
    Pagination,
    PrimaryButton,
    SecondaryButton,
    Table,
    TagList,
    Toggle,
    ToolTip
} from 'OsedeaReactUI';
import ConfirmationModal from 'management/components/ConfirmationModal';
import AddItemToListModal from 'management/components/AddItemToListModal';

// Constants
import { MANAGEMENT_CONFIG } from 'utils/constants';

// Styles
import {
    ActivityStatus,
    ActivityToolTipBottom,
    ActivityToolTipTop,
    ActivityToolTipWrapper,
    ActivityTrigger,
    BlankButton,
    Count,
    LayoutContainer,
    RoleSelector,
    SearchLabel,
    StatusDot,
    StatusList,
    Title,
    UserTableContainer,
    ToolTipContent,
} from './styles';

// Types
import type {
    ErrorsType,
    ImmutableMap,
    ImmutableList,
    InputEvent,
    IntlType,
    ReactNode,
    ReactSelectObject,
} from 'types';
import type {
    ImmutableRoleType,
    ImmutableUserType,
    UserType,
    ImmutableUserUsersType,
} from 'management/services/Authentication/types';
import type { UserSearchCriteria } from 'management/services/User/types';

// Utils
import { provideFullRoleName } from 'management/utils';

// TODO: Import from MinChem
type ImmutableReagentType = ImmutableMap<
    string,
    {
        id: number,
        oximeId: number,
        ownerId: number,
        name: string,
        oximeRatio: number,
        specificGravity: number,
        strength: number,
        createdAt: string,
        updatedAt: string,
    }
>;

type ImmutableUserReagentsType = ImmutableMap<string, {
    createdAt: string,
    id: number,
    reagent: ImmutableReagentType,
    reagentId: number,
    updated_at: string,
    userId: number
}>

type Props = {
    activeRole?: string,
    allUsers: ImmutableList<ImmutableUserType>,
    authUser: ImmutableUserType,
    createUser: ?(?UserType) => void,
    defaultPage: number,
    defaultSearchCriteria: UserSearchCriteria,
    deleteUser: ?(string, UserSearchCriteria, number) => void,
    errors: ErrorsType,
    hideActiveStatus: boolean,
    intl: IntlType,
    notificationPreferencesModal?: (ImmutableUserType) => ReactNode,
    onNotificationTriggerClick?: (ImmutableUserType) => () => void,
    onUsersReFetch?: (UserSearchCriteria, ?number) => void,
    pageCount?: number,
    queriedUsers: ImmutableList<ImmutableUserType>,
    reagents?: ImmutableList<ImmutableReagentType>,
    reagentsAreFetching?: boolean,
    roles?: ImmutableList<ImmutableRoleType>,
    searchable: boolean,
    title?: string,
    updateUser: (UserType) => void,
    userIsDeleting: boolean,
    userIsUpdating: boolean,
    usersAreQuerying: boolean,
    usersTotal?: number,
    withSearch: boolean,
};

type State = {
    activeRow?: ImmutableUserType,
    disabledInputs: boolean,
    enableAddPMUser: boolean,
    enableAddReagent: boolean,
    enableAddRow: boolean,
    pagination: { page: number },
    rowToDelete?: ImmutableUserType,
    searchCriteria: UserSearchCriteria,
};

class UserManagement extends React.Component<Props, State> {
    static defaultProps = {
        activeRole: null,
        notificationPreferencesModal: null,
        onNotificationTriggerClick: null,
        onUsersReFetch: null,
        pageCount: null,
        reagents: null,
        reagentsAreFetching: false,
        roles: null,
        title: null,
        usersTotal: 0,
    };

    state = {
        activeRow: null,
        disabledInputs: false,
        enableAddRow: true,
        pagination: {
            page: this.props.defaultPage,
        },
        searchCriteria: this.props.defaultSearchCriteria,
        rowToDelete: null,
        enableAddReagent: false,
        enableAddPMUser: false,
    };

    /**
     * On componentDidUpdate check if props.users has changed, if so reset UI via state
     * If props.errors have changed, re-enable inputs to allow user to continue
     */
    componentDidUpdate(prevProps: Props) {
        const { errors, queriedUsers } = this.props;
        // Wrapped in conditional to avoid infinite loop
        // https://reactjs.org/docs/react-component.html#componentdidupdate
        if (!prevProps.queriedUsers.equals(queriedUsers)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                activeRow: null,
                disabledInputs: false,
                enableAddRow: true,
                rowToDelete: null,
            });
        } else if (prevProps.errors !== errors) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                disabledInputs: false,
            });
        }
    }

    validateADMINView = () =>
        Boolean(
            this.props.reagents &&
                this.props.activeRole &&
                this.props.activeRole.toUpperCase() === 'ADMIN'
        );

    validatePMView = () =>
        Boolean(
            this.props.reagents &&
                this.props.activeRole &&
                this.props.activeRole.toUpperCase() === 'PM'
        );

    validateSAMView = () =>
        Boolean(
            this.props.allUsers &&
                this.props.activeRole &&
                this.props.activeRole.toUpperCase() === 'SAM'
        );

    /**
     * On search clear, reset searchCriteria.search & pagination.page
     * Fire props.onUsersReFetch() with updated searchCriteria and page
     */
    onSearchClear = () => {
        this.setState(
            (prevState: State) => ({
                searchCriteria: {
                    ...prevState.searchCriteria,
                    search: '',
                },
                pagination: {
                    page: this.props.defaultPage,
                },
            }),
            () =>
                this.props.onUsersReFetch &&
                this.props.onUsersReFetch(this.state.searchCriteria, this.state.pagination.page)
        );
    };

    /**
     * On Click, set oximeIdToDelete in state for this.renderConditionalDeleteModal()
     */
    handleSetRowToDelete = (user?: ImmutableUserType) => () => {
        this.setState({
            rowToDelete: user || null,
        });
    };

    handleInactiveUsersOnly = () => {
        this.setState(
            (prevState: State) => ({
                searchCriteria: {
                    ...prevState.searchCriteria,
                    inactiveUsersOnly: !prevState.searchCriteria.inactiveUsersOnly,
                },
                pagination: {
                    page: this.props.defaultPage,
                },
            }),
            () =>
                this.props.onUsersReFetch &&
                this.props.onUsersReFetch(this.state.searchCriteria, this.state.pagination.page)
        );
    };

    /**
     * Set's event's value to provided name to state.activeRow
     */
    handleActiveRowChange = (name: string) => (event: InputEvent) => {
        const value = event.target.value;
        this.setState((prevState: State) => ({
            activeRow: prevState.activeRow && prevState.activeRow.set(name, value),
            disabledInputs: false,
        }));
    };

    /**
     * On keypress, check for key === 'Enter' if so fire this.onSearchSubmit()
     */
    handleKeyPress = (event: InputEvent) => event && event.key === 'Enter' && this.onSearchSubmit();

    /**
     * On pagination change, update state with provided page
     * Fire props.onUsersReFetch() with updated searchCriteria and page
     */
    handlePaginationPageSelection = (page: number, total: number) => () => {
        if (page >= total && page < 0) {
            return null;
        }

        return this.setState(
            {
                pagination: {
                    page,
                },
            },
            () =>
                this.props.onUsersReFetch &&
                this.props.onUsersReFetch(this.state.searchCriteria, this.state.pagination.page)
        );
    };

    /**
     * On search change set state.search with event's value
     */
    handleSearchOnChange = (event: InputEvent) => {
        const value = event.target.value;
        this.setState((prevState: State) => ({
            searchCriteria: {
                ...prevState.searchCriteria,
                search: value,
            },
        }));
    };

    /**
     * On table sorting, set provided sortBy & sortOrder to state.searchCriteria
     * Reset state.pagination.page to default
     * Fire props.onUsersReFetch() with updated searchCriteria and page
     */
    handleSortBy = (criteria: UserSearchCriteria) => {
        this.setState(
            (prevState: State) => ({
                searchCriteria: {
                    ...prevState.searchCriteria,
                    ...criteria,
                },
                pagination: {
                    page: this.props.defaultPage,
                },
            }),
            () =>
                this.props.onUsersReFetch &&
                this.props.onUsersReFetch(this.state.searchCriteria, this.state.pagination.page)
        );
    };

    /**
     * On user role selection set provided role to state.activeRow
     */
    handleUserSetRole = (selectedRole: ReactSelectObject) => {
        this.setState((prevState: State) => ({
            activeRow: prevState.activeRow && prevState.activeRow.set('role', selectedRole.value),
            disabledInputs: false,
        }));
    };

    /**
     * On user creation, insert "empty" user to list of users & set as current user
     * Set enableAddRow to false to disable [Add User] button
     */
    handleUserCreate = () =>
        this.setState({
            activeRow: fromJS({
                id: null,
                email: null,
                role: this.props.activeRole,
                reagents: [],
                users: [],
            }),
            enableAddRow: false, // Disable [Add User] button
        });

    /**
     * On user edit, set provided user as current user
     */
    handleUserEditable = (user: ImmutableUserType) => () => {
        if (user) {
            this.setState({
                activeRow: user,
            });
        }
    };

    /**
     * On user save, disable inputs for further editing
     * If state.activeRow has an id (assume update) and fire props.updateUser()
     * Else (assume create) reset search & pagination in state
     * On setState callback, fire props.createUser() & props.onUsersReFetch() with updated state on callback
     */
    handleUserSave = () => {
        this.setState(
            {
                disabledInputs: true, // Disable inputs to avoid unexpected duplicate requests
            },
            () => {
                const { activeRow } = this.state;
                if (activeRow && activeRow.get('id')) {
                    this.props.updateUser(activeRow.toJS());
                } else {
                    this.setState(
                        (prevState: State) => ({
                            searchCriteria: {
                                ...prevState.searchCriteria,
                                ...this.props.defaultSearchCriteria,
                            },
                            pagination: {
                                page: this.props.defaultPage,
                            },
                        }),
                        () =>
                            this.props.createUser &&
                            this.props.createUser(
                                this.state.activeRow && this.state.activeRow.toJS()
                            )
                    );
                }
            }
        );
    };

    /**
     * On user cancel (within user edit) update sate
     * If id is not provided (assume unsaved user) and delete first user from list
     * Else (assume saved user), nullify state.activeRow
     * Set enableAddRow to true to re-allow [Add User]
     */
    handleUserCancel = () =>
        this.setState({
            enableAddRow: true,
            activeRow: null,
        });

    handleAddReagentModel = () => () =>
        this.setState((prevState: State) => ({
            enableAddReagent: !prevState.enableAddReagent,
        }));

    handleAddPMUserModel = () => () =>
        this.setState((prevState: State) => ({
            enableAddPMUser: !prevState.enableAddPMUser,
        }));

    /**
     * On user delete fire props.deleteUser()
     */
    handleUserDelete = (userEmail: string) => () => {
        if (userEmail && this.props.deleteUser) {
            this.props.deleteUser(userEmail, this.state.searchCriteria, this.state.pagination.page);
        }
    };

    /**
     * Handles addition of reagent to active row's reagents list
     * Prevents duplicate reagents
     */
    handleAddReagentToActiveRow = (selectedObject: ReactSelectObject) =>
        this.setState((prevState: State) => {
            // If active row does not have a list of reagents, create one to push into
            const hasReagents = prevState.activeRow.has('reagents');
            const newActiveRow = !hasReagents
                ? prevState.activeRow.set('reagents', fromJS([]))
                : prevState.activeRow;
            const reagentIndex =
                hasReagents &&
                prevState.activeRow
                    .get('reagents')
                    .findIndex(
                        (reagent: ImmutableReagentType) => reagent.get('reagentId') === selectedObject.value
                    );
            // Check for reagent's index in list of reagents, return early to avoid duplicate
            if (reagentIndex !== -1) {
                return null;
            }

            const newActiveRowWithReagent = newActiveRow.update(
                'reagents',
                (reagents: ImmutableList<ImmutableReagentType>) =>
                    reagents.push(
                        fromJS({
                            reagentId: selectedObject.value,
                            reagent: {
                                id: selectedObject.value,
                                name: selectedObject.label,
                            }
                        })
                    )
            );
            return {
                enableAddReagent: false,
                activeRow: newActiveRowWithReagent,
            };
        });

    /**
     * Handles addition of pm user to active row's users list
     * Prevents duplicate users
     */
    handleAddPMUserToActiveRow = (selectedObject: ReactSelectObject) =>
        this.setState((prevState: State) => {
            // If active row does not have a list of users, create one to push into
            const hasUsers = prevState.activeRow.has('users');
            const newActiveRow = !hasUsers
                ? prevState.activeRow.set('users', fromJS([]))
                : prevState.activeRow;
            const userIndex =
                hasUsers &&
                prevState.activeRow
                    .get('users')
                    .findIndex(
                        (users: ImmutableUserUsersType) =>
                            Number(users.get('pmId')) === Number(selectedObject.value)
                    );
            // Check for user's index in list of users, return early to avoid duplicate
            if (userIndex !== -1) {
                return null;
            }

            const newActiveRowWithPMUser = newActiveRow.update(
                'users',
                (users: ImmutableList<ImmutableUserUsersType>) =>
                    users.push(
                        fromJS({
                            pmId: selectedObject.value,
                            pm: {
                                id: selectedObject.value,
                                email: selectedObject.label,
                            },
                        })
                    )
            );
            return {
                enableAddPMUser: false,
                activeRow: newActiveRowWithPMUser,
            };
        });

    /**
     * Handles removal of reagent from active row's reagents list
     */
    handleRemoveReagentFromActiveRow = (id: number) => () =>
        this.setState((prevState: State) => {
            const idx = prevState.activeRow
                .get('reagents')
                .findIndex((reagent: ImmutableReagentType) => reagent.get('reagentId') === id);
            if (idx === -1) {
                return null;
            }
            return {
                activeRow: prevState.activeRow.deleteIn(['reagents', idx]),
            };
        });

    /**
     * Handles removal of pm user from active row's users list
     */
    handleRemovePMUserFromActiveRow = (id: number) => () =>
        this.setState((prevState: State) => {
            const idx = prevState.activeRow
                .get('users')
                .findIndex((user: ImmutableUserType) => user.get('pmId') === id);
            if (idx === -1) {
                return null;
            }
            return {
                activeRow: prevState.activeRow.deleteIn(['users', idx]),
            };
        });

    /**
     * On submit of search, reset pagination
     * Fire props.onUsersReFetch() with current searchCriteria and updated page
     */
    onSearchSubmit = () => {
        this.setState(
            {
                pagination: {
                    page: this.props.defaultPage,
                },
            },
            () =>
                this.props.onUsersReFetch &&
                this.props.onUsersReFetch(this.state.searchCriteria, this.state.pagination.page)
        );
    };

    provideTableHeader = () => {
        const TABLE_HEADER = [];

        TABLE_HEADER.push({
            label: this.props.intl.formatMessage({
                id: 'management.components.UserManagement.tableHeaderId',
            }),
            id: 'id',
            sortable: true,
        });

        TABLE_HEADER.push({
            label: this.props.intl.formatMessage({
                id: 'management.components.UserManagement.tableHeaderEmail',
            }),
            id: 'email',
            sortable: true,
        });

        /**
         * PM specific reagents column
         */
        if (this.validatePMView()) {
            TABLE_HEADER.push({
                label: this.props.intl.formatMessage({
                    id: 'management.components.UserManagement.tableHeaderReagents',
                }),
                id: 'reagents',
            });
        }

        /**
         * SAM specific reagents column
         */
        if (this.validateSAMView()) {
            TABLE_HEADER.push({
                label: this.props.intl.formatMessage({
                    id: 'management.components.UserManagement.tableHeaderPMUsers',
                }),
                id: 'pmUsers',
            });
        }

        if (this.props.roles) {
            TABLE_HEADER.push({
                label: this.props.intl.formatMessage({
                    id: 'management.components.UserManagement.tableHeaderRole',
                }),
                id: 'role',
                // sortable: true // requires backend logic
            });
        }

        if (!this.props.hideActiveStatus) {
            TABLE_HEADER.push({
                label: this.renderActivityTableHeader(),
                id: 'activity',
                sortable: true,
            });
        }

        TABLE_HEADER.push({
            label: '',
            id: 'controls',
        });

        return TABLE_HEADER;
    };

    /**
     * Provides users for table
     * Maps over props.users to determine if editable or interactive
     * If the iterated user's id matches the id of the state.activeRow, render this.provideEditableRow()
     * Else return this.provideInteractiveRow()
     */
    provideTableRows = () => {
        const rows = this.props.queriedUsers.map((user: ImmutableUserType) => {
            if (Number(user && user.get('id')) === Number(this.state.activeRow && this.state.activeRow.get('id')) || !user.has('id')) {
                return this.provideEditableRow();
            } else {
                return this.provideInteractiveRow(user);
            }
        });

         // If state.activeRow is an emptyRow, insert empty editable row at start of row
         let newRows;
         if (this.state.activeRow && !this.state.activeRow.get('id')) {
             newRows = rows.insert(0, this.provideEditableRow());
         }

         return (newRows || rows).toJS();
    }


    /**
     * Returns an "editable" user row with input/select for the state.activeRow
     */
    provideEditableRow = () => {
        const { activeRow } = this.state;

        if (!activeRow) {
            return null;
        }

        const selectedRole =
            activeRow.get('role') || activeRow.getIn(['roles', 0, 'label'])
                ? {
                      value: activeRow.get('role') || activeRow.getIn(['roles', 0, 'slug']),
                      label: provideFullRoleName(
                          activeRow.get('role') || activeRow.getIn(['roles', 0, 'slug']),
                          this.props.intl
                      ),
                  }
                : null;

        const userIsSavable = Boolean(
            ((this.props.roles && selectedRole) || !this.props.roles) && activeRow.get('email')
        );

        const user = fromJS({});
        const returnUser = user
            .set('id', activeRow.get('id') ? `#${activeRow.get('id')}` : '')
            .set(
                'email',
                activeRow.get('id') ? (
                    activeRow.get('email')
                ) : (
                    <InputField
                        onChange={this.handleActiveRowChange('email')}
                        placeholder={this.props.intl.formatMessage({
                            id: 'management.components.UserManagement.emailPlaceholder',
                        })}
                        value={activeRow.get('email') || ''}
                    />
                )
            )
            .set(
                'role',
                this.props.roles ? (
                    <RoleSelector>
                        <InputSelect
                            placeholder={this.props.intl.formatMessage({
                                id: 'management.components.UserManagement.rolePlaceholder',
                            })}
                            onSelect={this.handleUserSetRole}
                            selectedOption={selectedRole}
                            options={
                                this.props.roles &&
                                this.props.roles.map((role: ImmutableRoleType) => ({
                                    value: role.get('label'),
                                    label: provideFullRoleName(role.get('slug'), this.props.intl),
                                }))
                            }
                            disabled={this.state.disabledInputs}
                            controlShouldRenderValue
                        />
                    </RoleSelector>
                ) : (
                    this.props.activeRole
                )
            )
            .set('activity', this.provideUsersStatus(activeRow))
            .set(
                'controls',
                <ButtonWrapper right>
                    <PrimaryButton
                        disabled={this.state.disabledInputs || !userIsSavable}
                        onClick={this.handleUserSave}
                        loading={this.props.userIsUpdating}
                        text={this.props.intl.formatMessage({
                            id: 'management.components.UserManagement.userSave',
                        })}
                    />
                    <SecondaryButton
                        disabled={this.state.disabledInputs}
                        onClick={this.handleUserCancel}
                        text={this.props.intl.formatMessage({
                            id: 'management.components.UserManagement.userCancel',
                        })}
                    />
                </ButtonWrapper>
            );

            if (MANAGEMENT_CONFIG.userManagement.showReagents) {
                returnUser.set(
                    'reagents',
                    this.validatePMView() && (
                        <TagList
                            onAddItem={
                                this.provideSelectableReagentsArray().length &&
                                this.handleAddReagentModel
                            }
                            onRemoveItem={this.handleRemoveReagentFromActiveRow}
                            list={this.provideReagentsToTagList(activeRow.get('reagents'))}
                        />
                    )
                );
            }

            if (MANAGEMENT_CONFIG.userManagement.showPMUsers) {
                returnUser.set(
                    'pmUsers',
                    this.validateSAMView && (
                        <TagList
                            onAddItem={
                                this.provideSelectablePMUsersArray().length && this.handleAddPMUserModel
                            }
                            onRemoveItem={this.handleRemovePMUserFromActiveRow}
                            list={this.providePMUsersToTagList(activeRow.get('users'))}
                        />
                    )
                );
            }

            return returnUser;
    };

    provideReagentsToTagList = (reagents: ImmutableList<ImmutableUserReagentsType>) =>
        reagents && reagents.map((r: ImmutableUserReagentsType) => ({
            label: r.getIn(['reagent', 'name']),
            value: r.getIn(['reagent', 'id']),
        })).toJS() || [];

    providePMUsersToTagList = (users: ImmutableList<ImmutableUserType>) =>
        users && users.map((u: ImmutableUserType) => ({
            label: u.getIn(['pm', 'email']),
            value: u.getIn(['pm', 'id']),
        })).toJS() || [];

    provideUsersStatus = (user: ImmutableUserType) => !this.props.hideActiveStatus ?
        <ActivityStatus>
            <StatusDot active={user.get('activeUser')} />
            {user.get('lastActiveAt') ||
                this.props.intl.formatMessage({
                    id: 'management.components.UserManagement.userNeverLoggedIn',
                })}
        </ActivityStatus>
        : null;

    /**
     * Returns an "interactive" user row for the provided user
     */
    provideInteractiveRow = (user: ImmutableUserType) => {
        if (!user) {
            return null;
        }

        const controlItems = [];
        // Do not render the edit button if it is the current authenticated user
        // Or if user is currently looking at Admins, this is because Admin's currently have no editable inputs
        if (user.get('id') !== this.props.authUser.get('id') && !this.validateADMINView()) {
            const editUserButtonText = this.props.intl.formatMessage({
                id: 'management.components.UserManagement.userEdit',
            });

            controlItems.push(
                <li key="edit">
                    <BlankButton onClick={this.handleUserEditable(user)} title={editUserButtonText}>
                        {editUserButtonText}
                    </BlankButton>
                </li>
            );
            controlItems.push(this.renderUserDeleteButton(user));
        }
        // Optionally render notification preferences modal only if provided through props
        if (this.props.notificationPreferencesModal) {
            controlItems.push(
                <li key="notifications">{this.renderNotificationPreferencesButton(user)}</li>
            );
        }

        const returnUser = user
            .set('id', user.get('id') ? `#${user.get('id')}` : '')
            .set(
                'role',
                provideFullRoleName(
                    user.get('role') || user.getIn(['roles', 0, 'slug']),
                    this.props.intl
                )
            )
            .set('activity', this.provideUsersStatus(user))
            .set(
                'controls',
                controlItems.length ? (
                    <div style={{ textAlign: 'right' }}>
                        <ToolTip
                            content={<ToolTipContent>{controlItems}</ToolTipContent>}
                            position="bottom"
                            trigger={<DotMenu />}
                            triggerType="click"
                            interactive
                        />
                    </div>
                ) : null
            );

        if (MANAGEMENT_CONFIG.userManagement.showReagents) {
            returnUser.set(
                'reagents',
                this.validatePMView ? (
                    <TagList list={this.provideReagentsToTagList(user.get('reagents'))} />
                ) : null
            );
        }

        if (MANAGEMENT_CONFIG.userManagement.showPMUsers) {
            returnUser.set(
                'pmUsers',
                this.validateSAMView ? (
                    <TagList list={this.providePMUsersToTagList(user.get('users'))} />
                ) : null
            );
        }

        return returnUser;
    };

    /**
     * Map provided users to JS Array of ReactSelectObjects
     */
    provideSelectablePMUsersArray = (): Array<ReactSelectObject> =>
        (this.props.allUsers &&
            this.props.allUsers
                .filter((user: ImmutableUserType) => {
                    const userIdx =
                        this.state.activeRow.has('users') &&
                        this.state.activeRow
                            .get('users')
                            .findIndex((users: ImmutableUserUsersType) => {
                                return Number(users.get('pmId')) === Number(user.get('id'));
                            });
                    return user.get('role') === 'PM' && userIdx === -1;
                })
                .map(
                    (user: ImmutableUserType): ReactSelectObject => ({
                        label: user.get('email'),
                        value: user.get('id'),
                    })
                )
                .toJS()) ||
        [];

    /**
     * Map provided reagents to JS Array of ReactSelectObjects
     */
    provideSelectableReagentsArray = (): Array<ReactSelectObject> =>
        (this.props.reagents &&
            this.props.reagents
                .filter((reagent: ImmutableReagentType) => {
                    const reagentIdx =
                        this.state.activeRow.has('reagents') &&
                        this.state.activeRow
                            .get('reagents')
                            .findIndex((r: ImmutableUserReagentsType) => {
                                return Number(r.get('reagentId')) === Number(reagent.get('id'));
                            });
                    return reagentIdx === -1;
                })
                .map(
                    (reagent: ImmutableReagentType): ReactSelectObject => ({
                        label: reagent.get('name'),
                        value: reagent.get('id'),
                    })
                )
                .toJS()) ||
        [];

    /**
     * TODO: Replace for text as we now use the tooltip for the user controls
     */
    renderNotificationPreferencesButton = (user: ImmutableUserType) => (
        <Modal
            modalWidth="392px"
            padding="30px 46px 20px 46px"
            trigger={
                <BlankButton
                    onClick={
                        this.props.onNotificationTriggerClick &&
                        this.props.onNotificationTriggerClick(user)
                    }
                >
                    <NotificationBell />
                </BlankButton>
            }
            inlineTrigger
        >
            {/* Using render props pattern to give control of the modal
                content in parent component instead of coupling it to `this`.*/}
            {this.props.notificationPreferencesModal &&
                this.props.notificationPreferencesModal(user)}
        </Modal>
    );

    renderAddReagentModal = () => {
        if (!this.state.enableAddReagent) {
            return null;
        }

        return (
            <AddItemToListModal
                onConfirm={this.handleAddReagentToActiveRow}
                onCancel={this.handleAddReagentModel()}
                strings={{
                    title: this.props.intl.formatMessage({
                        id: 'management.components.UserManagement.AddReagentModal.title',
                    }),
                    fieldTitle: this.props.intl.formatMessage({
                        id: 'management.components.UserManagement.AddReagentModal.fieldTitle',
                    }),
                    cancelButton: this.props.intl.formatMessage({
                        id: 'management.components.UserManagement.AddReagentModal.cancelButton',
                    }),
                    saveButton: this.props.intl.formatMessage({
                        id: 'management.components.UserManagement.AddReagentModal.saveButton',
                    }),
                }}
                loading={this.props.reagentsAreFetching}
                list={this.provideSelectableReagentsArray()}
            />
        );
    };

    renderAddPMUserModal = () => {
        if (!this.state.enableAddPMUser) {
            return null;
        }

        return (
            <AddItemToListModal
                onConfirm={this.handleAddPMUserToActiveRow}
                onCancel={this.handleAddPMUserModel()}
                list={this.provideSelectablePMUsersArray()}
                strings={{
                    title: this.props.intl.formatMessage({
                        id: 'management.components.UserManagement.AddPMUserModal.title',
                    }),
                    fieldTitle: this.props.intl.formatMessage({
                        id: 'management.components.UserManagement.AddPMUserModal.fieldTitle',
                    }),
                    cancelButton: this.props.intl.formatMessage({
                        id: 'management.components.UserManagement.AddPMUserModal.cancelButton',
                    }),
                    saveButton: this.props.intl.formatMessage({
                        id: 'management.components.UserManagement.AddPMUserModal.saveButton',
                    }),
                }}
                loading={false}
            />
        );
    };

    renderUserDeleteConfirmationModal = () => {
        const { rowToDelete } = this.state;
        if (!rowToDelete) {
            return null;
        }

        return (
            <ConfirmationModal
                title={this.props.intl.formatMessage(
                    {
                        id: 'management.components.UserManagement.DeleteModal.title',
                    },
                    { email: rowToDelete.get('email') }
                )}
                confirmButtonText={this.props.intl.formatMessage({
                    id: 'management.components.UserManagement.DeleteModal.confirmButton',
                })}
                errors={{}}
                loading={this.props.userIsDeleting}
                onConfirm={rowToDelete ? this.handleUserDelete(rowToDelete.get('email')) : null}
                onCancel={this.handleSetRowToDelete()}
                danger
            />
        );
    };

    /**
     * If user is provided & is not props.activeRow return delete user confirmation modal
     */
    renderUserDeleteButton = (user: ImmutableUserType) => {
        if (
            !this.props.deleteUser ||
            !user ||
            (user.get('id') === this.props.authUser && this.props.authUser.get('id'))
        ) {
            return null;
        }

        const buttonText = this.props.intl.formatMessage({
            id: 'management.components.UserManagement.deleteUser',
        });

        return (
            <li key="delete">
                <BlankButton onClick={this.handleSetRowToDelete(user)} title={buttonText}>
                    {buttonText}
                </BlankButton>
            </li>
        );
    };

    renderSearch = () =>
        this.props.searchable && (
            <Common.Column alignItems="flex-end">
                <Common.Row gutter={16} alignItems="center">
                    <Common.Column alignItems="flex-end" flex={1}>
                        <SearchLabel>
                            {this.props.intl.formatMessage({
                                id: 'management.components.UserManagement.searchTitle',
                            })}
                        </SearchLabel>
                    </Common.Column>
                    <Common.Column flex={3}>
                        <InputSearch
                            handleOnClear={this.onSearchClear}
                            handleOnClick={this.onSearchSubmit}
                            onChange={this.handleSearchOnChange}
                            onKeyDown={this.handleKeyPress}
                            placeholder={this.props.intl.formatMessage({
                                id: 'management.components.UserManagement.searchPlaceholder',
                            })}
                            value={this.state.searchCriteria.search}
                            withSearch={this.props.withSearch}
                        />
                    </Common.Column>
                </Common.Row>
            </Common.Column>
        );

    renderActivityTableHeader = () => (
        <React.Fragment>
            {this.props.intl.formatMessage({
                id: 'management.components.UserManagement.tableHeaderActivity',
            })}
            <ToolTip
                content={
                    <ActivityToolTipWrapper>
                        <ActivityToolTipTop>
                            <StatusList>
                                <li>
                                    <StatusDot active />{' '}
                                    {this.props.intl.formatMessage({
                                        id: 'management.components.UserManagement.userActiveLabel',
                                    })}
                                </li>
                                <li>
                                    <StatusDot />{' '}
                                    {this.props.intl.formatMessage({
                                        id:
                                            'management.components.UserManagement.userInactiveLabel',
                                    })}
                                </li>
                            </StatusList>
                            <p>
                                {this.props.intl.formatMessage({
                                    id: 'management.components.UserManagement.userActivityNote',
                                })}
                            </p>
                        </ActivityToolTipTop>
                        <ActivityToolTipBottom>
                            <Toggle
                                checked={this.state.searchCriteria.inactiveUsersOnly}
                                onClickHandler={this.handleInactiveUsersOnly}
                                label={this.props.intl.formatMessage({
                                    id: 'management.components.UserManagement.userActivityToggle',
                                })}
                            />
                        </ActivityToolTipBottom>
                    </ActivityToolTipWrapper>
                }
                trigger={
                    <ActivityTrigger active={this.state.searchCriteria.inactiveUsersOnly}>
                        <InfoIcon />
                    </ActivityTrigger>
                }
                triggerType="click"
                position="bottom"
                closeOnInternalClick
                interactive
            />
        </React.Fragment>
    );

    render() {
        const TABLE_HEADER = this.provideTableHeader();
        const TABLE_ROWS = this.provideTableRows();

        const renderedSearch = this.renderSearch();

        return (
            <LayoutContainer>
                {this.renderUserDeleteConfirmationModal()}
                {this.renderAddReagentModal()}
                {this.renderAddPMUserModal()}
                <Common.Row alignItems="center" flex="initial">
                    <Common.Column alignItems="flex-start">
                        <Common.Row gutter={24} alignItems="center">
                            <Common.Column flex={renderedSearch ? 0 : 1}>
                                <Title>
                                    {this.props.title}
                                    {this.props.usersTotal && !this.props.usersAreQuerying ? (
                                        <Count>({this.props.usersTotal})</Count>
                                    ) : null}
                                </Title>
                            </Common.Column>
                            {this.props.createUser && (
                                <Common.Column
                                    flex={1}
                                    alignItems={renderedSearch ? 'flex-start' : 'flex-end'}
                                >
                                    <PrimaryButton
                                        disabled={
                                            !this.state.enableAddRow || this.props.usersAreQuerying
                                        }
                                        onClick={this.handleUserCreate}
                                        text={this.props.intl.formatMessage({
                                            id: 'management.components.UserManagement.addUser',
                                        })}
                                    />
                                </Common.Column>
                            )}
                        </Common.Row>
                    </Common.Column>
                    {renderedSearch}
                </Common.Row>
                <UserTableContainer>
                    <Table
                        currentSorting={{
                            sortBy: this.state.searchCriteria && this.state.searchCriteria.sortBy,
                            sortOrder:
                                this.state.searchCriteria && this.state.searchCriteria.sortOrder,
                        }}
                        header={TABLE_HEADER}
                        onSortBy={this.handleSortBy}
                        rows={TABLE_ROWS}
                        overlay={this.props.usersAreQuerying && <div />}
                        loading={this.props.usersAreQuerying}
                        tdMinHeight="26px"
                        tdVerticalAlign="top"
                        footerMessage={!this.props.usersAreQuerying && !TABLE_ROWS.length && this.props.intl.formatMessage({
                            id: 'management.components.UserManagement.noUsers',
                        })}
                    />
                    <Pagination
                        currentPage={this.state.pagination.page}
                        isLoading={this.props.usersAreQuerying}
                        onPageSelection={this.handlePaginationPageSelection}
                        pagesTotal={this.props.pageCount}
                        summaryPrefix={this.props.intl.formatMessage({
                            id: 'management.components.UserManagement.paginationPrefix',
                        })}
                        summaryJoinner={this.props.intl.formatMessage({
                            id: 'management.components.UserManagement.paginationJoinner',
                        })}
                    />
                </UserTableContainer>
            </LayoutContainer>
        );
    }
}

export default injectIntl(UserManagement);
