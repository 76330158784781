// @flow

import { createSelector } from 'reselect';

// Types
import type { FrotherBlendsFilter } from 'services/BuildingBlock/types';

// Constants
import { PROJECT_TYPE } from 'utils/constants';

/**
 * Direct selector to the train state domain
 */
export const selectProjectState = () => (state: {}) => state.get('projects');

/**
 * Select list of projects
 */
export const selectAllProjects = () =>
    createSelector(selectProjectState(), (substate: any) => {
        if (substate.get('list')) {
            return substate
                .get('list')
                .filter((item: ProjectType) => item)
                .toList();
        }
        return null;
    });

/**
 * Select list is fetching state
 */
export const selectListIsFetching = () =>
    createSelector(selectProjectState(), (substate: any) => substate.get('listIsFetching'));

/**
 * Select single project
 */
export const selectSingleProject = () =>
    createSelector(selectProjectState(), (substate: any) => substate.get('singleProject'));

/**
 * Select single is fetching state
 */
export const selectSingleProjectIsFetching = () =>
    createSelector(selectProjectState(), (substate: any) =>
        substate.get('singleProjectIsFetching')
    );

/**
 * Select project's total count
 */
export const selectTotalCount = () =>
    createSelector(selectProjectState(), (substate: any) => substate.get('totalCount'));

/**
 * Select project's total page count
 */
export const selectTotalPageCount = () =>
    createSelector(selectProjectState(), (substate: any) => substate.get('lastPage'));

/**
 * Select project's search status
 */
export const selectWithSearch = () =>
    createSelector(selectProjectState(), (substate: any) => substate.get('withSearch'));

/**
 * Select the latest created custom blend name
 */
export const selectLatestCreatedCustomBlendName = () =>
    createSelector(selectProjectState(), (substate: any) =>
        substate.get('latestCreatedCustomBlendName')
    );

/**
 * Select the submission/loading state of the submitted custom blend
 */
export const selectCustomBlendIsSubmitting = () =>
    createSelector(selectProjectState(), (substate: any) =>
        substate.get('customBlendIsSubmitting')
    );

/**
 * Select the project errors
 */
export const selectProjectErrors = () =>
    createSelector(selectProjectState(), (substate: any) => substate.get('errors'));

/**
 * Select the count of newly added products to a project
 */
export const selectProductsAddedCount = () =>
    createSelector(selectProjectState(), (substate: any) => substate.get('productCountAdded'));

/**
 * Select discovery agreement
 */
export const selectDiscoveryAgreement = () =>
    createSelector(selectProjectState(), (substate: any) => substate.get('discoveryAgreement'));

/**
 * Select discovery agreement fetching state
 */
export const selectDiscoveryAgreementIsFetching = () =>
    createSelector(selectProjectState(), (substate: any) =>
        substate.get('discoveryAgreementIsFetching')
    );

/**
 * Select the submission/loading state of the submitted discovery agreement
 */
export const selectDiscoveryAgreementIsSubmitting = () =>
    createSelector(selectProjectState(), (substate: any) =>
        substate.get('discoveryAgreementIsSubmitting')
    );

/**
 * Select the list of company addresses
 */
export const selectCompanyAddresses = () =>
    createSelector(selectProjectState(), (substate: any) => substate.get('companyAddresses'));
