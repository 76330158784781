// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { RadioButtonSet, InputNumber, InputSelect, Close, CloseContainer } from 'OsedeaReactUI';
import ErrorMessage from 'components/ErrorMessage';

// Styles
import {
    QuestionWrapper,
    QuestionTitle,
    QuestionNote,
    InputWrapper,
    FilterClearIcon,
} from './styles';

// Helper
import { getSelectOptionForSelectList } from 'utils/helpers';

// Types
import type { InputEvent, SelectOptionType, ImmutableList } from 'types';
import type {
    SurveyQuestionnaireType,
    SurveyQuestionnaireMultipleInputType,
} from 'services/Survey/types';

type Props = {
    text: string,
    type: string,
    max?: number,
    min?: number,
    note?: string,
    isFilter?: boolean,
    value: ?(number | string | boolean),
    options?: Array<SurveyQuestionnaireType>,
    selectOptions?: ImmutableList<SelectOptionType>,
    questionKey: string,
    disabled?: boolean,
    onFilterClearIconClick?: (filterKey: string) => void,
    onValueChangeHandler: (string | number, string) => void,
    error?: string,
    renderString?: boolean,
};

type State = {
    booleanOptions: Array<{
        label: string,
        value: number,
    }>,
    value: ?(string | number),
};

/* eslint-disable-next-line flowtype/require-parameter-type */
const defaultValue = (value) => {
    let v;

    if (typeof value === 'number' || typeof value === 'string') {
        // If value is number(0) or a string, keep.
        v = value;
    } else if (value === null || (!value && value !== 0)) {
        // If value is null or falsey but not number(0), default to ''
        v = '';
    }

    return v;
};

class SurveyQuestionnaire extends React.PureComponent<Props, State> {
    static defaultProps = {
        disabled: true,
        error: null,
        max: null,
        min: null,
        note: '',
        options: null,
        renderString: false,
    };

    state = {
        booleanOptions: [
            {
                label: 'Yes',
                value: 1,
            },
            {
                label: 'No',
                value: 0,
            },
        ],
        value: defaultValue(this.props.value),
    };

    componentDidUpdate(prevProps: Props) {
        // Intentional loose equality check to take advantage of coercion.
        // Case when reverting to original value. Example: 50 != "50".
        /* eslint-disable eqeqeq */
        if (this.props.value != prevProps.value) {
            /* eslint-disable react/no-did-update-set-state */
            this.setState({ value: defaultValue(this.props.value) });
        }
    }

    handleInputNumberChange = (event: InputEvent) => this.handleOnValueChange(event.target.value);

    handleOnValueChange = (value: string) => {
        this.setState({ value });
        this.props.onValueChangeHandler(value, this.props.questionKey);
    };

    handleOnSelectChange = (value: SelectOptionType) => {
        this.setState({ value: value.value });
        this.props.onValueChangeHandler(value.value, this.props.questionKey);
    };

    handleOnFilterClearIconClick = () => {
        this.props.onFilterClearIconClick(this.props.questionKey);
    };

    renderControls = () => {
        switch (this.props.type) {
            case 'boolean':
                return (
                    <InputWrapper>
                        <RadioButtonSet
                            onClick={this.handleOnValueChange}
                            options={this.state.booleanOptions}
                            value={this.state.value}
                            disabled={this.props.disabled}
                            renderString={this.props.renderString}
                        />
                    </InputWrapper>
                );
            case 'input':
                return (
                    <InputWrapper>
                        <InputNumber
                            value={this.state.value}
                            onChange={this.handleInputNumberChange}
                            disabled={this.props.disabled}
                            placeholder={'—'}
                            max={this.props.max}
                            min={this.props.min}
                            renderString={this.props.renderString}
                            unit={'%'}
                        />
                    </InputWrapper>
                );
            case 'options':
                return (
                    <InputWrapper>
                        <RadioButtonSet
                            onClick={this.handleOnValueChange}
                            options={this.props.options}
                            value={this.state.value}
                            disabled={this.props.disabled}
                            renderString={this.props.renderString}
                        />
                    </InputWrapper>
                );
            case 'select':
                return (
                    <InputWrapper>
                        <InputSelect
                            onSelect={this.handleOnSelectChange}
                            isDisabled={this.props.disabled}
                            controlShouldRenderValue
                            options={this.props.selectOptions}
                            selectedOption={getSelectOptionForSelectList(
                                this.state.value,
                                this.props.selectOptions
                            )}
                        />
                    </InputWrapper>
                );
            // When custom is used as a type, render the children instead
            case 'custom':
                return this.props.children.map((child: JSX.Element, index: number) => (
                    // Disabling next lint rule.  It's hard to use another prop than the array index for key prop
                    // while mapping children.
                    /* eslint-disable-next-line react/no-array-index-key */
                    <InputWrapper key={`customSurveyQuestionnaire${index}`}>{child}</InputWrapper>
                ));
            default:
                return null;
        }
    };

    /**
     * Render a "clear" filter icon when the question is a filter
     */
    renderClearFilterIcon = () => {
        return <FilterClearIcon onClick={this.handleOnFilterClearIconClick}>X</FilterClearIcon>;
    };

    render() {
        const { text, note, error, isFilter } = this.props;

        return (
            <QuestionWrapper>
                <QuestionTitle>{text}</QuestionTitle>
                {note && <QuestionNote>{note}</QuestionNote>}
                {this.renderControls()}
                {isFilter && this.renderClearFilterIcon()}
                {error && <ErrorMessage marginTop="20px">{error}</ErrorMessage>}
            </QuestionWrapper>
        );
    }
}

export default injectIntl(SurveyQuestionnaire);
