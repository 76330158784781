// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { Common, CollapsibleBlock, InputField } from 'OsedeaReactUI';
import ErrorMessage from 'components/ErrorMessage';

// Constants
import { ERROR_MESSAGE_MARGIN_TOP } from 'utils/constants';

// Styles
import { FormLabel } from 'styles/common';
import { SingleColumn } from 'views/Agreement/styles';

// Types
import type { IntlType } from 'types';

type Props = {
    currentAgreement: ImmutableMap<string, string | number>,
    handleOnChange: (string) => void,
    intl: IntlType,
    errors: ImmutableMap<string, boolean>,
    disabled: boolean,
};

export class AgreementPersonalContactInfo extends React.PureComponent<Props> {
    /**
     * On InputField change, fire props.handleOnChange(name)(value) to save to currentAgreement
     */
    handleOnChange = (name: string) => (event: EventType) => {
        this.props.handleOnChange(name)(event.target.value);
    };

    render() {
        return (
            <CollapsibleBlock
                title={this.props.intl.formatMessage({
                    id: 'components.AgreementPersonalContactInfo.blockTitle',
                })}
            >
                <SingleColumn>
                    <Common.Row>
                        <Common.Column>
                            <FormLabel>
                                {this.props.intl.formatMessage({
                                    id:
                                        'components.AgreementPersonalContactInfo.representativeName',
                                })}
                            </FormLabel>
                            <InputField
                                onChange={this.handleOnChange('representativeName')}
                                placeholder={this.props.intl.formatMessage({
                                    id:
                                        'components.AgreementPersonalContactInfo.representativeNamePlaceholder',
                                })}
                                value={this.props.currentAgreement.get('representativeName')}
                                disabled={this.props.disabled}
                            />
                            {this.props.errors.get('representativeName') && (
                                <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                    {this.props.intl.formatMessage({
                                        id: 'components.ErrorMessage.required',
                                    })}
                                </ErrorMessage>
                            )}
                        </Common.Column>
                    </Common.Row>
                    <Common.Row>
                        <Common.Column>
                            <FormLabel>
                                {this.props.intl.formatMessage({
                                    id: 'components.AgreementPersonalContactInfo.title',
                                })}
                            </FormLabel>
                            <InputField
                                onChange={this.handleOnChange('representativeTitle')}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'components.AgreementPersonalContactInfo.titlePlaceholder',
                                })}
                                value={this.props.currentAgreement.get('representativeTitle')}
                                disabled={this.props.disabled}
                            />
                            {this.props.errors.get('representativeTitle') && (
                                <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                    {this.props.intl.formatMessage({
                                        id: 'components.ErrorMessage.required',
                                    })}
                                </ErrorMessage>
                            )}
                        </Common.Column>
                    </Common.Row>
                    <Common.Row>
                        <Common.Column>
                            <FormLabel>
                                {this.props.intl.formatMessage({
                                    id: 'components.AgreementPersonalContactInfo.mineralProcessing',
                                })}
                            </FormLabel>
                            <InputField
                                onChange={this.handleOnChange('mineralProcessing')}
                                placeholder={this.props.intl.formatMessage({
                                    id:
                                        'components.AgreementPersonalContactInfo.mineralProcessingPlaceholder',
                                })}
                                value={this.props.currentAgreement.get('mineralProcessing')}
                                disabled={this.props.disabled}
                            />
                            {this.props.errors.get('mineralProcessing') && (
                                <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                    {this.props.intl.formatMessage({
                                        id: 'components.ErrorMessage.required',
                                    })}
                                </ErrorMessage>
                            )}
                        </Common.Column>
                    </Common.Row>
                    <Common.Row>
                        <Common.Column>
                            <FormLabel>
                                {this.props.intl.formatMessage({
                                    id: 'components.AgreementPersonalContactInfo.phone',
                                })}
                            </FormLabel>
                            <InputField
                                onChange={this.handleOnChange('representativePhone')}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'components.AgreementPersonalContactInfo.phonePlaceholder',
                                })}
                                value={this.props.currentAgreement.get('representativePhone')}
                                disabled={this.props.disabled}
                            />
                            {this.props.errors.get('representativePhone') && (
                                <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                    {this.props.intl.formatMessage({
                                        id: 'components.ErrorMessage.required',
                                    })}
                                </ErrorMessage>
                            )}
                        </Common.Column>
                    </Common.Row>
                    <Common.Row>
                        <Common.Column>
                            <FormLabel>
                                {this.props.intl.formatMessage({
                                    id: 'components.AgreementPersonalContactInfo.email',
                                })}
                            </FormLabel>
                            <InputField
                                onChange={this.handleOnChange('representativeEmail')}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'components.AgreementPersonalContactInfo.emailPlaceholder',
                                })}
                                value={this.props.currentAgreement.get('representativeEmail')}
                                disabled={this.props.disabled}
                            />
                            {this.props.errors.get('representativeEmail') && (
                                <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                    {this.props.intl.formatMessage({
                                        id: 'components.ErrorMessage.required',
                                    })}
                                </ErrorMessage>
                            )}
                        </Common.Column>
                    </Common.Row>
                </SingleColumn>
            </CollapsibleBlock>
        );
    }
}

export default injectIntl(AgreementPersonalContactInfo);
