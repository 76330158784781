// @flow

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

export const CREATE_COLLECTOR_SUCCESS = 'app/services/Survey/CREATE_COLLECTOR_SUCCESS';
export const CREATE_COLLECTOR_FAILURE = 'app/services/Survey/CREATE_COLLECTOR_FAILURE';
export const CREATE_FROTHER_SUCCESS = 'app/services/Survey/CREATE_FROTHER_SUCCESS';
export const CREATE_FROTHER_FAILURE = 'app/services/Survey/CREATE_FROTHER_FAILURE';
export const FETCH_SUCCESS = 'app/services/Survey/FETCH_SUCCESS';
export const FETCH_FAILURE = 'app/services/Survey/FETCH_FAILURE';
export const REVERT_SUCCESS = 'app/services/Survey/REVERT_SUCCESS';
export const REVERT_FAILURE = 'app/services/Survey/REVERT_FAILURE';
export const SURVEY_IS_FETCHING = 'app/services/Survey/SURVEY_IS_FETCHING';
export const SURVEY_IS_SUBMITTING = 'app/services/Survey/SURVEY_IS_SUBMITTING';
export const UPDATE_SUCCESS = 'app/services/Survey/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'app/services/Survey/UPDATE_FAILURE';
export const INIT_DEFAULT_SURVEY_SUCCESS = 'app/services/Survey/INIT_DEFAULT_SURVEY_SUCCESS';
export const CLEAR_SURVEY_BODY_SUCCESS = 'app/services/Survey/CLEAR_SURVEY_BODY_SUCCESS';

export const receivedCreateCollectorSuccess = (data: {}) => ({
    type: CREATE_COLLECTOR_SUCCESS,
    payload: { data },
});

export const receivedCreateCollectorFailure = (errors: {}) => ({
    type: CREATE_COLLECTOR_FAILURE,
    payload: { errors },
});

export const receivedCreateFrotherSuccess = (data: {}) => ({
    type: CREATE_FROTHER_SUCCESS,
    payload: { data },
});

export const receivedCreateFrotherFailure = (errors: {}) => ({
    type: CREATE_FROTHER_FAILURE,
    payload: { errors },
});

export const receivedFetchSuccess = (data: {}) => ({
    type: FETCH_SUCCESS,
    payload: { data },
});

export const receivedFetchFailure = (errors: {}) => ({
    type: FETCH_FAILURE,
    payload: { errors },
});

export const receivedUpdateSuccess = (data: {}) => ({
    type: UPDATE_SUCCESS,
    payload: { data },
});

export const receivedUpdateFailure = (errors: {}) => ({
    type: UPDATE_FAILURE,
    payload: { errors },
});

export const receivedRevertSuccess = (data: {}) => ({
    type: REVERT_SUCCESS,
    payload: { data },
});

export const receivedRevertFailure = (errors: {}) => ({
    type: REVERT_FAILURE,
    payload: { errors },
});

export const setSurveyIsFetchingStatus = (surveyIsFetching: boolean = true) => ({
    type: SURVEY_IS_FETCHING,
    payload: { surveyIsFetching },
});

export const setSurveyIsSubmittingStatus = (surveyIsSubmitting: boolean = true) => ({
    type: SURVEY_IS_SUBMITTING,
    payload: { surveyIsSubmitting },
});

export const setDefaultSurvey = (projectId: number, projectType: string) => ({
    type: INIT_DEFAULT_SURVEY_SUCCESS,
    payload: { projectId, projectType },
});

export const setClearedSurveyBody = () => ({
    type: CLEAR_SURVEY_BODY_SUCCESS,
});
