// @flow

import request from 'services/request';

/*
* Fetch the list of products
*/
export const index = () => request('products', { method: 'GET' });

/**
 * Fetch the list of products whose composition consists of the building block ids.
 * TODO: Replace the set timeout with a real request to an endpoint.
 */
export const requestProductsContainingBuildingBlockIds = (
    ids: Array<number>,
    relations: string[] = []
) =>
    request(
        'products/get-by-building-blocks',
        {
            method: 'POST',
            data: {
                ids,
            },
        },
        { relations }
    );
