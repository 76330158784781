// @flow

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

export const FETCH_SUCCESS = 'app/services/Result/FETCH_SUCCESS';
export const FETCH_FAILURE = 'app/services/Result/FETCH_FAILURE';
export const RESULT_IS_FETCHING = 'app/services/Result/RESULT_IS_FETCHING';
export const RESULT_IS_SUBMITTING = 'app/services/Result/RESULT_IS_SUBMITTING';
export const FETCH_LAB_RESULT_OPTION_SUCCESS =
    'app/services/Result/FETCH_LAB_RESULT_OPTION_SUCCESS';
export const FETCH_LAB_RESULT_OPTION_FAILURE =
    'app/services/Result/FETCH_LAB_RESULT_OPTION_FAILURE';
export const FETCH_PLANT_TRIAL_RESULT_OPTION_SUCCESS =
    'app/services/Result/FETCH_PLANT_TRIAL_RESULT_OPTION_SUCCESS';
export const FETCH_PLANT_TRIAL_RESULT_OPTION_FAILURE =
    'app/services/Result/FETCH_PLANT_TRIAL_RESULT_OPTION_FAILURE';
export const UPDATE_SUCCESS = 'app/services/Result/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'app/services/Result/UPDATE_FAILURE';

export const receivedFetchSuccess = (data: {}) => ({
    type: FETCH_SUCCESS,
    payload: { data },
});

export const receivedFetchFailure = (errors: {}) => ({
    type: FETCH_FAILURE,
    payload: { errors },
});

export const receivedUpdateSuccess = (data: {}) => ({
    type: UPDATE_SUCCESS,
    payload: { data },
});

export const receivedUpdateFailure = (errors: {}) => ({
    type: UPDATE_FAILURE,
    payload: { errors },
});

export const setResultIsFetchingStatus = (resultIsFetching: boolean = false) => ({
    type: RESULT_IS_FETCHING,
    payload: { resultIsFetching },
});

export const setResultIsSubmittingStatus = (resultIsSubmitting: boolean = false) => ({
    type: RESULT_IS_SUBMITTING,
    payload: { resultIsSubmitting },
});

export const receivedFetchLabResultOptionSuccess = (data: {}) => ({
    type: FETCH_LAB_RESULT_OPTION_SUCCESS,
    payload: { data },
});

export const receivedFetchLabResultOptionFailure = (errors: {}) => ({
    type: FETCH_LAB_RESULT_OPTION_FAILURE,
    payload: { errors },
});

export const receivedFetchPlantTrialResultOptionSuccess = (data: {}) => ({
    type: FETCH_PLANT_TRIAL_RESULT_OPTION_SUCCESS,
    payload: { data },
});

export const receivedFetchPlantTrialResultOptionFailure = (errors: {}) => ({
    type: FETCH_PLANT_TRIAL_RESULT_OPTION_FAILURE,
    payload: { errors },
});
