// @flow

// Building block colors.
export const blockColors = {
    yellowFca: 'rgba(252,196,0,0.3)',
    yellowFC: '#FCC400',

    violetB1a: 'rgba(177,151,195,0.3)',
    violetB1: '#B197C3',

    blue84a: 'rgba(132,208,240,0.3)',
    blue84: '#84D0F0',

    redE6a: 'rgba(230,68,97,0.3)',
    redE6: '#E64461',

    green97a: 'rgba(151,191,13,0.3)',
    green97: '#97BF0D',

    orangeFFa: 'rgba(255,132,0,0.3)',
    orangeFF: '#FF8400',

    grey7Ba: 'rgba(123,124,126,0.3)',
    grey7B: '#7B7C7E',
};
