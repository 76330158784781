// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the disclaimer state domain
 *
 * TODO: Replace Object type for proper Redux Store Type
 */
export const selectDisclaimerState = () => (state: Object) => state.get('disclaimers');

/**
 * Select disclaimers.disclaimersListIsFetching
 */
export const selectDisclaimersAreFetching = () =>
    createSelector(
        selectDisclaimerState(),
        (substate: any) => substate.get('disclaimersListIsFetching')
    );

/**
 * Select disclaimers.disclaimersList
 */
export const selectDisclaimers = () =>
    createSelector(
        selectDisclaimerState(),
        (substate: any) => substate.get('disclaimersList')
    );

/**
 * Select disclaimers.errors
 */
export const selectErrors = () =>
    createSelector(
        selectDisclaimerState(),
        (substate: any) => substate.get('errors')
    );
