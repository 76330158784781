// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Constants
import {
    KNOWLEDGE_BASE_LINK,
    PROJECT_DEFAULT_PAGE,
    PROJECT_DEFAULT_SORTBY,
    PROJECT_DEFAULT_SORTORDER,
} from 'utils/constants';

// Components
import { Common, Loader, SidebarLayout, SubtleAnchor } from 'OsedeaReactUI';
import ProjectSidebar from 'components/ProjectSidebar';
import ProjectTable from 'components/ProjectTable';

// Services
import { selectUser } from 'management/services/Authentication/selectors';
import {
    selectAllProjects,
    selectListIsFetching,
    selectSingleProjectIsFetching,
    selectTotalCount,
    selectTotalPageCount,
    selectWithSearch,
} from 'services/Project/selectors';
import {
    createProject,
    deleteProject,
    fetchProject,
    fetchAllProjects,
    updateProject,
} from 'services/Project/thunks';
import { fetchAllProducts } from 'services/Product/thunks';
import { selectAllProducts, selectProductsListIsFetching } from 'services/Product/selectors';

// Styles
import { LoaderWrapper, Title } from 'styles/common';
import { ProjectCount } from './styles';

// Types
import type { IntlType, ImmutableList } from 'types';
import type { ProjectType, ProjectSearchCriteriaType } from 'services/Project/types';
import type { ProductType } from 'services/Product/types';
import type { UserType } from 'management/services/Authentication/types';

type State = {
    currentProjectId: number,
    disabledInputs: boolean,
    enableAddProject: boolean,
    projects: ImmutableList<ProjectType>,
    searchCriteria: ProjectSearchCriteriaType,
    withSearch?: boolean,
};

type Props = {
    createProject: (ProjectType, boolean) => void,
    deleteProject: (number) => void,
    fetchAllProjects: (ProjectSearchCriteriaType) => void,
    fetchProject: (number, ?Array<string>) => void,
    history: {
        push: (string) => void,
    },
    intl: IntlType,
    listIsFetching: boolean,
    projectIsFetching?: boolean,
    projects?: ImmutableList<ProjectType>,
    projectsCount: number,
    projectsPageCount: number,
    updateProject: (ProjectType) => void,
    user?: UserType,
    withSearch: boolean,
    fetchAllProducts: () => void,
    products: ImmutableList<ProductType>,
    productsListIsFetching: boolean,
};

const defaultSearchCriteria = {
    page: PROJECT_DEFAULT_PAGE,
    sortBy: PROJECT_DEFAULT_SORTBY,
    sortOrder: PROJECT_DEFAULT_SORTORDER,
};

export class Projects extends React.PureComponent<Props, State> {
    static defaultProps = {
        projectIsFetching: false,
        projects: fromJS([]),
        user: null,
    };

    state = {
        currentProjectId: null,
        disabledInputs: false,
        enableAddProject: false,
        projects: this.props.projects,
        withSearch: false,
        searchCriteria: defaultSearchCriteria,
    };

    componentDidMount() {
        // Fetch all projects (originally done only if no projects where found however this way we avoid mismatch with reset filters)
        this.props.fetchAllProjects(this.state.searchCriteria);

        if (!this.props.products || (this.props.products && this.props.products.size === 0)) {
            this.props.fetchAllProducts();
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { projects } = this.props;
        // Check if redux has updated projects from props, if so replace local state
        // Save when wrapped in conditional to avoid infinite loop
        // https://reactjs.org/docs/react-component.html#componentdidupdate
        if (prevProps.projects !== projects) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                currentProjectId: null,
                disabledInputs: false,
                enableAddProject: true,
                projects,
                withSearch: this.props.withSearch,
            });
        }
    }

    handleCreateProject = () => {
        const { projects } = this.state;
        // Build up new project with user details
        const emptyProject = fromJS({
            id: null,
            owner: this.props.user && this.props.user.get('displayName'),
            email: this.props.user && this.props.user.get('email'),
            plant: '',
            objectives: '',
        });
        // Insert empty project to top of list
        const newProjects = projects.insert(0, emptyProject);
        this.setState({
            currentProjectId: 0,
            enableAddProject: false,
            projects: newProjects,
        });
    };

    /**
     * Handles deleting of project
     */
    handleDeleteProject = (projectId: number) => () => this.props.deleteProject(projectId);

    handleEditProjectDetails = (projectId: number) => () => {
        // If project id, set to current project id state to pass to table component
        if (projectId) {
            this.setState({
                currentProjectId: projectId,
            });
        }
    };

    /**
     * Handles saving of project, if provided project contains an id, update, else create
     * If create project, reset searchCriteria and fire props.createProject(project, true), second param to updateList
     */
    handleSaveProject = (project: ProjectType) => () => {
        // Disable inputs during save, if project contains id, update else create
        this.setState(
            {
                disabledInputs: true,
            },
            () => {
                if (project.id) {
                    this.props.updateProject(project);
                } else {
                    // Reset search & pagination, then create project
                    this.setState(
                        (prevState: State) => ({
                            searchCriteria: {
                                ...prevState.searchCriteria,
                                ...defaultSearchCriteria,
                            },
                        }),
                        () => this.props.createProject(project, true)
                    );
                }
            }
        );
    };

    handleSearchCriteria = (criteria: ProjectSearchCriteriaType) => {
        this.setState(
            (prevState: State) => ({
                searchCriteria: {
                    ...prevState.searchCriteria,
                    ...criteria,
                    page: PROJECT_DEFAULT_PAGE, // Reset pagination
                },
            }),
            () => this.props.fetchAllProjects(this.state.searchCriteria)
        );
    };

    handleResetWithSearch = () => {
        this.setState({
            withSearch: false,
        });
    };

    handleProjectsRefetch = (criteria: {}) => {
        this.setState(
            (prevState: State) => ({
                searchCriteria: {
                    ...prevState.searchCriteria,
                    ...criteria,
                },
            }),
            () => this.props.fetchAllProjects(this.state.searchCriteria)
        );
    };

    handleCancelProject = (projectId: number) => () => {
        let newProjects;
        // If no projectId (assume newly created), delete first item from list
        if (!projectId) {
            const { projects } = this.state;
            newProjects = projects.delete('0');
        }
        // Re-enable add project now that user is no longer editing project item,
        // Nullify current project id,
        // Replace projects for new projects if empty/new one was deleted
        this.setState((prevState: State) => ({
            enableAddProject: true,
            currentProjectId: null,
            projects: newProjects || prevState.projects,
        }));
    };

    /**
     * Fetch project with product results
     */
    onFetchProject = (projectId: number) => this.props.fetchProject(projectId, ['results']);

    onRedirect = (projectId: number, path: string) => {
        this.props.history.push(`/project/${projectId}${path}`);
    };

    renderMain = () => {
        const noProjects = !this.state.projects || this.state.projects.isEmpty();

        if (!this.props.user || (this.props.listIsFetching && noProjects)) {
            return (
                <LoaderWrapper>
                    <Loader loading />
                </LoaderWrapper>
            );
        }

        let content = (
            <LoaderWrapper>
                <Loader loading />
            </LoaderWrapper>
        );

        if (!this.props.listIsFetching && noProjects) {
            content = (
                <div>{this.props.intl.formatMessage({ id: 'views.Projects.noProjects' })}</div>
            );
        } else if (!noProjects) {
            content = (
                <ProjectTable
                    currentProjectId={this.state.currentProjectId}
                    disabledInputs={this.state.disabledInputs}
                    handleFetchProject={this.onFetchProject}
                    handleRedirect={this.onRedirect}
                    loading={this.props.listIsFetching}
                    overlay={this.props.listIsFetching ? <Loader loading /> : null}
                    onCancelProject={this.handleCancelProject}
                    onDeleteProject={this.handleDeleteProject}
                    onEditProject={this.handleEditProjectDetails}
                    onSaveProject={this.handleSaveProject}
                    onProjectsRefetch={this.handleProjectsRefetch}
                    projectIsFetching={this.props.projectIsFetching}
                    projects={this.state.projects.toJS()}
                    projectsPageCount={this.props.projectsPageCount}
                    searchCriteria={this.state.searchCriteria}
                    user={this.props.user}
                />
            );
        }
        return (
            <React.Fragment>
                <Common.Row alignItems="center" flex="initial" style={{ minHeight: '30px' }}>
                    <Common.Column>
                        <Title>
                            {this.props.intl.formatMessage({ id: 'views.Projects.projectsTitle' })}
                            {!this.props.listIsFetching && (
                                <ProjectCount>({this.props.projectsCount})</ProjectCount>
                            )}
                        </Title>
                    </Common.Column>
                    <Common.Column alignItems="flex-end">
                        <SubtleAnchor
                            href={KNOWLEDGE_BASE_LINK}
                            target="_blank"
                            rel="noopener noreferrer"
                            externalLinkIcon
                        >
                            {this.props.intl.formatMessage({ id: 'views.Projects.knowledgeBase' })}
                        </SubtleAnchor>
                    </Common.Column>
                </Common.Row>
                <Common.Row>{content}</Common.Row>
            </React.Fragment>
        );
    };

    renderSidebar = () => (
        <ProjectSidebar
            onResetWithSearch={this.handleResetWithSearch}
            onAddProject={
                this.state.enableAddProject && !this.props.listIsFetching
                    ? this.handleCreateProject
                    : null
            }
            onSearchCriteria={this.handleSearchCriteria}
            withSearch={this.state.withSearch}
            products={this.props.products}
            productsListIsFetching={this.props.productsListIsFetching}
        />
    );

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.Projects.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.Projects.helmetDescription',
                        })}
                    />
                </Helmet>
                <SidebarLayout
                    sidebarWidth="360px"
                    renderSidebar={this.renderSidebar}
                    renderMain={this.renderMain}
                    flush
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    listIsFetching: selectListIsFetching(),
    projectIsFetching: selectSingleProjectIsFetching(),
    projects: selectAllProjects(),
    projectsCount: selectTotalCount(),
    projectsPageCount: selectTotalPageCount(),
    user: selectUser(),
    withSearch: selectWithSearch(),
    products: selectAllProducts(),
    productsListIsFetching: selectProductsListIsFetching(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            createProject,
            deleteProject,
            fetchAllProjects,
            fetchProject,
            updateProject,
            fetchAllProducts,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Projects));
