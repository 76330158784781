// @flow

import { fromJS } from 'immutable';

// Actions
import {
    CREATE_COLLECTOR_SUCCESS,
    CREATE_COLLECTOR_FAILURE,
    CREATE_FROTHER_SUCCESS,
    CREATE_FROTHER_FAILURE,
    FETCH_SUCCESS,
    FETCH_FAILURE,
    SURVEY_IS_FETCHING,
    SURVEY_IS_SUBMITTING,
    REVERT_SUCCESS,
    REVERT_FAILURE,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    INIT_DEFAULT_SURVEY_SUCCESS,
    CLEAR_SURVEY_BODY_SUCCESS,
} from './actions';

// Constants
import {
    COLLECTOR_SURVEY_QUESTIONNAIRE,
    COLLECTOR_SURVEY_WEIGHTING_FACTORS,
    FROTHER_SURVEY_PRIMARY_QUESTIONNAIRE,
    PROJECT_TYPE,
} from 'utils/constants';

// Helper
import { frotherSurveySecondaryQuestions } from 'utils/helpers';

// Types
import type { GenericActionType } from 'types';
import type { CollectorSurveyType } from './types';

const emptySurveyBody = (projectType: string) => {
    const emptySurvey = {};

    switch (projectType) {
        case PROJECT_TYPE.COLLECTOR:
            COLLECTOR_SURVEY_QUESTIONNAIRE.forEach((element: SurveyQuestionnaireType) => {
                emptySurvey[element.key] = element.value;
            });

            COLLECTOR_SURVEY_WEIGHTING_FACTORS.forEach((element: SurveyWeightingFactorType) => {
                emptySurvey[element.key] = element.value;
            });
            break;
        case PROJECT_TYPE.FROTHER:
            FROTHER_SURVEY_PRIMARY_QUESTIONNAIRE.forEach((element: SurveyQuestionnaireType) => {
                emptySurvey[element.key] = element.value;
            });

            frotherSurveySecondaryQuestions([]).forEach((element: SurveyQuestionnaireType) => {
                emptySurvey[element.key] = element.value;
            });

            // Adding the frother custom blend initial values
            emptySurvey.frotherBuildingBlockIdOne = null;
            emptySurvey.frotherBuildingBlockIdOnePercentage = 0;
            emptySurvey.frotherBuildingBlockIdTwo = null;
            emptySurvey.frotherBuildingBlockIdTwoPercentage = 0;
            emptySurvey.frotherBuildingBlockIdThree = null;
            emptySurvey.frotherBuildingBlockIdThreePercentage = 0;
            break;
    }

    return emptySurvey;
};

type State = {
    projectId: number,
    surveyBody: CollectorSurveyType,
    surveyIsFetching: boolean,
    surveyIsSubmitting: boolean,
    errors: {},
};

const initialState = fromJS({
    projectId: null,
    surveyBody: null,
    surveyIsFetching: false,
    surveyIsSubmitting: false,
    listIsFetching: false,
    errors: {},
});

/**
 * Reducer
 *
 * Switch statement to set state based on current action type
 */

function surveyServiceReducer(state: State = initialState, action: GenericActionType) {
    switch (action.type) {
        case CREATE_COLLECTOR_SUCCESS:
        case CREATE_FROTHER_SUCCESS:
        case UPDATE_SUCCESS: {
            const projectId = action.payload.data.project_id;
            const survey = action.payload.data;
            return state
                .set('surveyBody', fromJS(survey))
                .set('projectId', fromJS(projectId))
                .set('surveyIsFetching', false)
                .set('surveyIsSubmitting', false)
                .set('errors', fromJS({}));
        }
        case FETCH_SUCCESS:
        case REVERT_SUCCESS: {
            const projectId = action.payload.data.project_id;
            const survey = action.payload.data;
            return state
                .set('surveyBody', fromJS(survey))
                .set('projectId', fromJS(projectId))
                .set('surveyIsFetching', false)
                .set('errors', fromJS({}));
        }
        case FETCH_FAILURE:
            return state.set('surveyBody', fromJS({})).set('errors', fromJS(action.payload.errors));
        case CREATE_COLLECTOR_FAILURE:
        case CREATE_FROTHER_FAILURE:
        case UPDATE_FAILURE:
        case REVERT_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('surveyIsSubmitting', false)
                .set('surveyIsFetching', false);
        case SURVEY_IS_FETCHING:
            return state
                .set('surveyIsFetching', action.payload.surveyIsFetching)
                .set('surveyBody', initialState.get('surveyBody'));
        case SURVEY_IS_SUBMITTING:
            return state.set('surveyIsSubmitting', action.payload.surveyIsSubmitting);
        case INIT_DEFAULT_SURVEY_SUCCESS:
            return state
                .set('surveyBody', fromJS(emptySurveyBody(action.payload.projectType)))
                .set('projectId', action.payload.projectId)
                .set('surveyIsFetching', false);
        case CLEAR_SURVEY_BODY_SUCCESS:
            return state.set('surveyBody', null);
        default:
            return state;
    }
}

export default surveyServiceReducer;
