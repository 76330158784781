// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const SidebarContainer = styled.div`
    overflow: scroll;
    padding: 24px;
`;

export const SearchContainer = styled.div`
    margin-bottom: 120px;
`;

export const SearchHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const BuildingBlockListContainer = styled.div`
    margin-bottom: 16px;
`;

export const SearchButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    float: right;

    width: 180px;
    margin-top: 16px;
`;

export const Section = styled.div`
    margin-bottom: 24px;
`;

export const InputsTitle = styled.p`
    font-size: 16px;
    letter-spacing: -0.39px;
`;

export const EditToggle = styled.div`
    cursor: pointer;
    color: ${LegacyTheme.defaultSubtleColor7B};
    font-size: 12px;
`;
