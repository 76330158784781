// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const BuildingBlocksMessage = styled.div`
    color: ${LegacyTheme.defaultSubtleColor};
    font-family: inherit;
    font-size: 16px;
`;

export const StyledUnorderedList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const StyledListItem = styled.li`
    display: flex;
    align-items: center;

    margin: 10px 0;

    ${({ bulletColor }) =>
        bulletColor &&
        `
        &::before {
            content: '';
            display: inline-block;
            width: 8px;
            min-width: 8px; // Flex bug
            height: 8px;
            margin-right: 8px;

            border-radius: 50%;
            background-color: ${bulletColor};
        }
    `} :first-child {
        margin-top: 0;
    }
    :last-child {
        margin-bottom: 0;
    }
`;

export const ToolTipContainer = styled.div`
    display: inline-block;
    width: 70px;
    border-radius: 10px;
    border: 1px solid ${LegacyTheme.toolTipTriggerBorderColor};
    background-color: ${LegacyTheme.toolTipTriggerBackgroundColor};
    cursor: pointer;
    margin-top: 10px;

    // Selects the inner <span role="button> to center the pointer of the tooltip.
    > div > span {
        display: block;
    }
`;

export const ToolTipTrigger = styled.div`
    font-size: 12px;
    line-height: 20px;
    font-family: inherit;
    color: ${LegacyTheme.toolTipTriggerColor9C};
    text-align: center;
`;

export const ToolTipContentContainer = styled.div`
    padding: 10px 16px;
    border: 1px solid ${LegacyTheme.toolTipBorder};
    background-color: ${LegacyTheme.toolTip};
`;

export const ToolTipContentItem = styled.div`
    display: flex;
    align-items: center;
    color: ${LegacyTheme.toolTipColor};
    font-family: inherit;
    font-size: 15px;
    height: 30px;
`;

export const ExpandableDetailsButton = styled.div`
    cursor: pointer;
    color: ${LegacyTheme.defaultSubtleColor};
    font-family: Helvetica;
    font-size: 15px;
    text-align: right;
    min-width: 96px;
`;

export const DetailsToggleText = styled.span`
    margin-right: 6px;
`;
