// @flow

import firebase from 'firebase/app';
import type { InputEvent } from 'types';

export const CHANGE_LOCALE = 'app/Language/CHANGE_LOCALE';
export const DEFAULT_LOCALE = 'en';

export function changeLocale({ target: { value: languageLocale } }: InputEvent) {
    firebase.auth().languageCode = languageLocale;

    return {
        type: CHANGE_LOCALE,
        payload: {
            locale: languageLocale,
        },
    };
}
