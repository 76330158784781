// @flow

import firebase from 'firebase/app';

import { report } from 'management/services/Errors/resources';
import {
    receivedAcceptDisclaimerSuccess,
    receivedAcceptDisclaimerFail,
    receivedAuthenticationSuccess,
    setAcceptDisclaimerSaving,
    receivedAuthenticationFail,
    receivedWhoamiSuccess,
    receivedWhoamiFail,
    receivedLogoutSuccess,
    receivedLogoutFail,
    setUserIsFetching,
    setIsUpdatingSettingsStatus,
    receivedUpdateSettingsSuccess,
    receivedUpdateSettingsFailure,
} from './actions';

import { createFeedback } from 'management/services/Feedback/actions';
import {
    acceptDisclaimer,
    authenticate,
    logout,
    getAuthenticatedUser,
    updateSettings,
} from './resources';

import { replace } from 'react-router-redux';

import type { ReduxDispatch, ResponseErrorType } from 'types';
import type { ImmutableUserType } from 'management/services/Authentication/types';

const clearLocalStorage = () => {
    localStorage.removeItem('api_token');
};

/**
 * Authenticates a user
 */
export const authenticateUser = (user: ImmutableUserType) => (dispatch: ReduxDispatch) =>
    authenticate(user)
        .then((response: {}) => {
            dispatch(receivedAuthenticationSuccess(response));
        })
        .catch((response: ResponseErrorType) => {
            dispatch(receivedAuthenticationFail(response.message || response.data.errors));
            dispatch(createFeedback('ERROR', 'feedback.error.authenticateUserFailed'));
        });

/**
 * Checks the user authentication token
 */
export const whoami = () => (dispatch: ReduxDispatch) => {
    dispatch(setUserIsFetching());
    getAuthenticatedUser()
        .then((response: {}) => {
            if (response) {
                dispatch(receivedWhoamiSuccess(response));
            } else {
                throw new Error('response is empty');
            }
        })
        .catch((response: ResponseErrorType) => {
            report(response);
            dispatch(receivedWhoamiFail(response.message || response.data.errors));
            dispatch(replace('/'));
            dispatch(createFeedback('ERROR', 'feedback.error.whoAmIFailed'));
        });
};

/**
 * Invalidates a user token, if clientOnly is true simply clear localStorage and redirect user to login view
 */
export const logoutUser = (clientOnly?: boolean = false) => (dispatch: ReduxDispatch) => {
    if (clientOnly) {
        clearLocalStorage();
        dispatch(replace('/login'));
    } else {
        logout()
            .then((response: boolean) => {
                clearLocalStorage();
                dispatch(receivedLogoutSuccess(response));
                firebase.auth().signOut();
            })
            .catch((response: ResponseErrorType) => {
                report(response);
                dispatch(receivedLogoutFail(response.message || response.data.errors));
                dispatch(createFeedback('ERROR', 'feedback.error.logoutUserFailed'));
            });
    }
};

export const userAcceptDisclaimer = (key: string) => (dispatch: ReduxDispatch) => {
    dispatch(setAcceptDisclaimerSaving());
    acceptDisclaimer(key)
        .then((response: {}) => {
            if (response) {
                dispatch(receivedAcceptDisclaimerSuccess(response));
            } else {
                throw new Error('response is empty');
            }
        })
        .catch((response: ResponseErrorType) => {
            report(response);
            dispatch(receivedAcceptDisclaimerFail(response.message || response.data.errors));
        });
};

/**
 * Update the user settings
 */
export const updateUserSettings = (id: number, settings: UserSettings) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsUpdatingSettingsStatus());
    updateSettings(id, settings)
        .then((response: ImmutableUser) => {
            dispatch(receivedUpdateSettingsSuccess(response));
            dispatch(createFeedback('INFO', 'feedback.info.updateUserSettings'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateSettingsFailure(error));
            dispatch(createFeedback('ERROR', 'feedback.error.updateUserSettingsFailed'));
        });
};
