/* eslint-disable flowtype/no-weak-types */
// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const ContentContainer = styled.div`
    padding: 0 16px;
`;

export const Content = styled.p`
    margin: 0 0 24px;
`;

export const BlankButton = styled.button`
    appearance: none;
    background-color: transparent;
    border: 0 none;

    &:focus {
        outline: none;
    }

    cursor: pointer;
`;

export const SearchLabel = styled.span`
    font-size: 15px;
`;

export const RoleSelector = styled.div`
    width: 100%;
    min-width: 180px;
`;

export const LayoutContainer = styled.div`
    position: relative;

    display: flex;
    flex: 1;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 24px;
`;

export const Title = styled.h1`
    margin: 0;

    font-size: 24px;
    font-weight: normal;

    color: ${LegacyTheme.defaultColor};
`;

export const Count = styled.span`
    margin-left: 10px;
    font-size: 65%;
`;

export const UserTableContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    width: 100%;
`;

export const StatusDot = styled.div`
    display: inline-block;
    width: 10px;
    height: 10px;

    background-color: ${({ active }: Object) =>
        active ? LegacyTheme.statusColorActive : LegacyTheme.statusColorInactive};
    border-radius: 50%;
`;

export const ActivityStatus = styled.div`
    font-size: 12px;

    ${StatusDot} {
        margin-right: 18px;
    }
`;

export const ActivityToolTipBottom = styled.div`
    padding: 10px 16px;
    border-top: 1px solid ${LegacyTheme.defaultBorder};
`;

export const ActivityToolTipTop = styled.div`
    padding: 16px 16px 10px;
`;

export const ActivityToolTipWrapper = styled.div`
    max-width: 210px;

    p {
        text-align: left;

        color: ${LegacyTheme.defaultSubtleColor};

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const ActivityTrigger = styled.div`
    position: relative;
    display: inline-block;
    margin-left: 6px;

    ${({ active }: Object) =>
        active &&
        `
        &:after {
            content: '';
            position: absolute;
            top: -1px;
            right: -2px;

            width: 8px;
            height: 8px;

            border-radius: 50%;
            border: 1px solid ${LegacyTheme.defaultBorder};
            background-color: ${LegacyTheme.statusColorActive}
        }
    `};
`;

export const StatusList = styled.ul`
    display: flex;
    justify-content: space-around;

    margin: 0;
    padding: 0;

    font-size: 11px;

    list-style: none;

    > li {
        display: flex;
        align-items: center;

        ${StatusDot} {
            margin-right: 6px;
        }
    }
`;

export const ToolTipContent = styled.ul`
    padding: 16px;
    margin: 0;

    list-style: none;
    text-align: left;

    > li {
        display: block;
        margin: 12px 0;

        font-size: 12px;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        a {
            color: ${LegacyTheme.defaultColor};
        }
    }
`;
