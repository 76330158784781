// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const Container = styled.div`
    padding: 0px 10px;
    color: ${LegacyTheme.tableSubtleColor};

    > div:not(:last-child) {
        margin-bottom: 32px;
    }
`;

export const TableTitle = styled.div`
    color: ${LegacyTheme.defaultSubtleColor7B};
    font-family: inherit;
    font-size: 16px;
    margin-bottom: 10px;
`;

export const TextLengthLimit = styled.div`
    color: ${LegacyTheme.buttonGridLabelDanger};
    font-family: inherit;
    font-size: 11px;
    text-align: right;
    margin-top: 5px;
`;
