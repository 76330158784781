// @flow

import request from 'services/request';

import type { CollectorSurveyType, FrotherSurveyType } from './types';

/*
* Create a collector survey
*/
export const createCollector = (projectId: number, survey: CollectorSurveyType) =>
    request(`projects/${projectId}/surveyCollector`, {
        method: 'POST',
        data: survey,
    });

/*
* Create a frother survey
*/
export const createFrother = (projectId: number, survey: FrotherSurveyType) =>
    request(`projects/${projectId}/surveyFrother`, {
        method: 'POST',
        // Use form information instead of hard coded values
        data: { ...survey },
    });

/**
 * Fetches a collector survey by project id
 *
 * @param {number} id
 */
export const showCollector = (id: number) =>
    request(`projects/${id}/surveyCollector`, { method: 'GET' });

/**
 * Fetches a frother survey by project id
 *
 * @param {number} id
 */
export const showFrother = (id: number) =>
    request(`projects/${id}/surveyFrother`, { method: 'GET' });

/*
* Update a collector survey by project id
*/
export const updateCollector = (projectId: number, survey: CollectorSurveyType) =>
    request(`projects/${projectId}/surveyCollector`, {
        method: 'PUT',
        data: survey,
    });

/*
* Update a frother survey by project id
*/
export const updateFrother = (projectId: number, survey: FrotherSurveyType) =>
    request(`projects/${projectId}/surveyFrother`, {
        method: 'PUT',
        data: survey,
    });

/*
* Revert a collector survey by project id
*/
export const revertCollector = (projectId: number) =>
    request(`projects/${projectId}/surveyCollector/revert`, {
        method: 'DELETE',
    });

/*
* Revert a frother survey by project id
*/
export const revertFrother = (projectId: number) =>
    request(`projects/${projectId}/surveyFrother/revert`, {
        method: 'DELETE',
    });
