// @flow

import React from 'react';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';

// Components
import { SecondaryButton, InputSelect, PrimaryButton, TertiaryButton } from 'OsedeaReactUI';
import { CHECK_INPUT, CLOSE } from 'components/BuildingBlockItem';
import BuildingBlockList from 'components/BuildingBlockList';

// Constants
import {
    PRIMARY_BUILDING_BLOCK_TYPE,
    SECONDARY_BUILDING_BLOCK_TYPE,
    PROJECT_TYPE,
    FROTHER_BUILDING_BLOCK_TYPE,
} from 'utils/constants';

// Styles
import {
    SidebarContainer,
    Section,
    InputsTitle,
    SearchContainer,
    SearchHeaderContainer,
    SearchButtonsContainer,
    BuildingBlockListContainer,
    EditToggle,
} from './styles';
import { HeaderContainer, Title } from 'styles/common';

// Types
import type { ImmutableList, IntlType } from 'types';
import type { BuildingBlockType } from 'services/Project/types';

type Props = {
    buildingBlocks: ImmutableList<BuildingBlockType>,
    recommendedBuildingBlocks: ImmutableList<BuildingBlockType>,
    selectedBuildingBlockIds: ImmutableList<number>,
    projectType: string,
    onBuildingBlockSelect: (number) => () => void,
    intl: IntlType,
    buildingBlocksIsFetching: boolean,
    isSurveyFilled: boolean,
};

type State = {
    showBuildingBlocksSearch: boolean,
    searchedBuildingBlockIds: ImmutableList<number>,
    searchEditMode: boolean,
};

/**
 *  Product view sidebar component
 *
 *  Features:
 *   1. Users can select and deselect recommended primary and secondary building blocks
 *   2. Users can search for building blocks (via predictive search input) and add them
 *      to the list of selected building blocks.
 */
class ProductSidebar extends React.Component<Props, State> {
    state = {
        searchedBuildingBlockIds: fromJS([]),
        searchEditMode: false,
        showBuildingBlocksSearch: false,
    };

    /**
     * Returns an array of filtered building blocks by enum type ([PRIMARY_BUILDING_BLOCK_TYPE, SECONDARY_BUILDING_BLOCK_TYPE])
     * and for each building block sets a colors property.
     */
    getFilteredRecommendedBuildingBlocks = (filter: string) => {
        // Different filtering for a frother project
        if (filter === FROTHER_BUILDING_BLOCK_TYPE) {
            return this.props.recommendedBuildingBlocks;
        } else {
            return (
                this.props.recommendedBuildingBlocks &&
                this.props.recommendedBuildingBlocks.filter(
                    (buildingBlock: BuildingBlockType) =>
                        buildingBlock.getIn(['pivot', 'primarySecondary']) === filter
                )
            );
        }
    };

    /**
     * Returns an array of building blocks whose ID exists in the list of
     * searched building block ids.
     */
    getSearchedBuildingBlocks = () =>
        this.props.buildingBlocks.filter((buildingBlock: BuildingBlockType) =>
            this.state.searchedBuildingBlockIds.includes(buildingBlock.get('id'))
        );

    /**
     * Returns an array of building block objects compatible with the InputSelect component.
     * Example object in the array may look like: { label: 'AEROPHINE', value: 10 }
     */
    getBuildingBlocksMappedToSelectOptions = () =>
        this.props.buildingBlocks
            // Filter out recommended building blocks
            .filter(
                (buildingBlock: BuildingBlockType) =>
                    !this.props.recommendedBuildingBlocks.find(
                        (recommendedBuildingBlock: BuildingBlockType) =>
                            recommendedBuildingBlock.get('id') === buildingBlock.get('id')
                    )
            )
            // Filter out building blocks that are already selected
            .filter(
                (buildingBlock: BuildingBlockType) =>
                    !this.state.searchedBuildingBlockIds.includes(buildingBlock.get('id'))
            )
            // Map to React-Select compatible object
            .map((buildingBlock: BuildingBlockType) => ({
                label: buildingBlock.get('name'),
                value: buildingBlock.get('id'),
            }));

    /**
     * Adds or removes the user-searched building block to the list of selected building blocks
     * and hides the InputSelect component.
     */
    handleSelectSearchedBuildingBlock = (option: { label: string, value: number }) => {
        const { value } = option;
        const { searchedBuildingBlockIds } = this.state;
        const isBuildingBlockAlreadySelected = searchedBuildingBlockIds.includes(value);

        this.setState((prevState: State) => {
            let updatedIds;

            if (isBuildingBlockAlreadySelected) {
                const idIndex = searchedBuildingBlockIds.indexOf(value);
                updatedIds = prevState.searchedBuildingBlockIds.delete(idIndex);
            } else {
                updatedIds = prevState.searchedBuildingBlockIds.push(value);
            }

            return {
                searchedBuildingBlockIds: updatedIds,
                showBuildingBlocksSearch: false,
            };
        });
    };

    /**
     * Removes the ID of the user-searched building block from list of searched building blocks.
     * If the prevState.searchedBuildingBlockIds size is one then we also want to toggle
     * state.searchEditMode to false.
     *
     * Note: This is a function that returns a function designed for the onCloseCheck callback
     * handler of the BuildingBlock component.
     */
    createRemoveSearchedBuildingBlockHandler = (id: number) => () => {
        const { searchedBuildingBlockIds } = this.state;
        const idIndex = searchedBuildingBlockIds.indexOf(id);

        this.setState(
            (prevState: State) => {
                const searchEditMode = !(prevState.searchedBuildingBlockIds.size === 1);

                return {
                    searchedBuildingBlockIds: prevState.searchedBuildingBlockIds.delete(idIndex),
                    searchEditMode,
                };
            },
            () => this.props.onBuildingBlockSelect(id)()
        );
    };

    /**
     * Toggles the visibility of the building blocks search component.
     */
    handleToggleBuildingBlocksSearch = () => {
        this.setState(
            (prevState: State) => ({
                showBuildingBlocksSearch: !prevState.showBuildingBlocksSearch,
                searchEditMode: false,
            }),
            this.handleScrollIntoView
        );
    };

    /**
     * Toggles the rendering of either a checkmark or close icon of the added searched
     * building blocks.
     */
    handleToggleSearchEditMode = () =>
        this.setState((prevState: State) => ({
            searchEditMode: !prevState.searchEditMode,
        }));

    /**
     * Scrolls window to the building blocks search input when visible or scrolls window to the
     *  top of the sidebar if closed.
     */
    handleScrollIntoView = () => {
        if (this.state.showBuildingBlocksSearch) {
            const searchSection = document.getElementById('section-building-blocks-search');
            searchSection.scrollIntoView();
        } else {
            const headerSection = document.getElementById('section-header');
            headerSection.scrollIntoView();
        }
    };

    renderBuildingBlockList = (blockType: string) => {
        let intlTitle = '';
        switch (blockType) {
            case PRIMARY_BUILDING_BLOCK_TYPE:
                intlTitle = 'primary';
                break;
            case SECONDARY_BUILDING_BLOCK_TYPE:
                intlTitle = 'secondary';
                break;
            case FROTHER_BUILDING_BLOCK_TYPE:
                intlTitle = 'frother';
                break;
        }

        return (
            <Section>
                <InputsTitle>
                    {this.props.intl.formatMessage({
                        id: `components.ProductSidebar.${intlTitle}`,
                    })}
                </InputsTitle>
                <BuildingBlockList
                    buildingBlocks={this.getFilteredRecommendedBuildingBlocks(blockType)}
                    selectedBuildingBlockIds={this.props.selectedBuildingBlockIds}
                    onClickCheckBox={this.props.onBuildingBlockSelect}
                    inputType="check"
                    fontSize="14px"
                    hideType
                />
            </Section>
        );
    };

    renderBuildingBlockLists = () => {
        const { projectType } = this.props;
        if (projectType === PROJECT_TYPE.COLLECTOR) {
            return (
                <div>
                    {/* Primary building block recommendations */}
                    {this.renderBuildingBlockList(PRIMARY_BUILDING_BLOCK_TYPE)}

                    {/* Secondary building block recommendations */}
                    {this.renderBuildingBlockList(SECONDARY_BUILDING_BLOCK_TYPE)}
                </div>
            );
        } else if (projectType === PROJECT_TYPE.FROTHER) {
            return this.renderBuildingBlockList(FROTHER_BUILDING_BLOCK_TYPE);
        }
    };

    renderAddBuildingBlockInput = () => {
        return (
            <React.Fragment>
                {/* Predictive search input */}
                <InputSelect
                    options={this.getBuildingBlocksMappedToSelectOptions()}
                    onSelect={this.handleSelectSearchedBuildingBlock}
                    isLoading={this.props.buildingBlocksIsFetching}
                    placeholder={this.props.intl.formatMessage({
                        id: 'components.ProductSidebar.InputSelect.placeholder',
                    })}
                    noOptionsMessage={this.props.intl.formatMessage({
                        id: 'components.ProductSidebar.InputSelect.noResults',
                    })}
                    hideDropdownIndicator
                />

                {/* Cancel and Add buttons */}
                <SearchButtonsContainer>
                    <TertiaryButton
                        text={this.props.intl.formatMessage({
                            id: 'components.ProductSidebar.searchCancel',
                        })}
                        onClick={this.handleToggleBuildingBlocksSearch}
                    />
                    <PrimaryButton
                        text={this.props.intl.formatMessage({
                            id: 'components.ProductSidebar.searchAdd',
                        })}
                    />
                </SearchButtonsContainer>
            </React.Fragment>
        );
    };

    renderAddBuildingBlockButton = () => {
        return (
            <SecondaryButton
                onClick={this.handleToggleBuildingBlocksSearch}
                text={this.props.intl.formatMessage({
                    id: 'components.ProductSidebar.addBuildingBlock',
                })}
                inline
            />
        );
    };

    /**
     * Main JSX content that will be rendered for this component
     */
    render() {
        const { showBuildingBlocksSearch, searchedBuildingBlockIds, searchEditMode } = this.state;
        const {
            intl,
            selectedBuildingBlockIds,
            onBuildingBlockSelect,
            isSurveyFilled,
            projectType,
        } = this.props;

        return (
            <SidebarContainer>
                {/* Header and add building block button*/}
                <Section id="section-header">
                    <HeaderContainer margin="0">
                        <Title>
                            {intl.formatMessage({
                                id: 'components.ProductSidebar.title',
                            })}
                        </Title>
                        {/* Adding a new building block only for collector project */}
                        {isSurveyFilled &&
                            projectType === PROJECT_TYPE.COLLECTOR &&
                            this.renderAddBuildingBlockButton()}
                    </HeaderContainer>
                </Section>

                {/* If survey is filled render a primary and secondary building block lists,
                 else, render a list of all the building blocks */}
                {isSurveyFilled ? (
                    <React.Fragment>{this.renderBuildingBlockLists()}</React.Fragment>
                ) : (
                    <Section>
                        <BuildingBlockList
                            buildingBlocks={this.props.buildingBlocks}
                            selectedBuildingBlockIds={selectedBuildingBlockIds}
                            onClickCheckBox={onBuildingBlockSelect}
                            inputType="check"
                            fontSize="14px"
                            hideType
                        />
                    </Section>
                )}

                {/* Building block search and list */}
                {(showBuildingBlocksSearch || Boolean(searchedBuildingBlockIds.size)) && (
                    <Section id="section-building-blocks-search">
                        <SearchContainer>
                            {/* Title and Edit toggle button */}
                            <SearchHeaderContainer>
                                <InputsTitle>
                                    {intl.formatMessage({
                                        id: 'components.ProductSidebar.addedBuildingBlocks',
                                    })}
                                </InputsTitle>

                                {Boolean(searchedBuildingBlockIds.size) && (
                                    <EditToggle onClick={this.handleToggleSearchEditMode}>
                                        {searchEditMode
                                            ? intl.formatMessage({
                                                  id: 'components.ProductSidebar.searchDone',
                                              })
                                            : intl.formatMessage({
                                                  id: 'components.ProductSidebar.searchEdit',
                                              })}
                                    </EditToggle>
                                )}
                            </SearchHeaderContainer>

                            {/* List of searched and added building blocks */}
                            {Boolean(searchedBuildingBlockIds.size) && (
                                <BuildingBlockListContainer>
                                    <BuildingBlockList
                                        buildingBlocks={this.getSearchedBuildingBlocks()}
                                        selectedBuildingBlockIds={selectedBuildingBlockIds}
                                        onClickCheckBox={onBuildingBlockSelect}
                                        onClickClose={this.createRemoveSearchedBuildingBlockHandler}
                                        inputType={searchEditMode ? CLOSE : CHECK_INPUT}
                                        hideType
                                        hideDropdown
                                    />
                                </BuildingBlockListContainer>
                            )}
                            {showBuildingBlocksSearch && this.renderAddBuildingBlockInput()}
                        </SearchContainer>
                    </Section>
                )}
            </SidebarContainer>
        );
    }
}

export default injectIntl(ProductSidebar);
