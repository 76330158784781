// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { fromJS } from 'immutable';

// Components
import { Centered, Loader, SidebarLayout, SidebarTab } from 'OsedeaReactUI';
import UserManagement from 'management/components/UserManagement';

// Services
import { selectUser } from 'management/services/Authentication/selectors';
import {
    selectAllRoles,
    selectErrors,
    selectQueriedUsers,
    selectTotalCount,
    selectTotalPageCount,
    selectUserIsDeleting,
    selectUserIsUpdating,
    selectUsers,
    selectUsersAreQuerying,
    selectWithSearch,
} from 'management/services/User/selectors';
import {
    createUser,
    deleteUser,
    fetchRoles,
    fetchUsers,
    updateUser,
    queryUsers,
} from 'management/services/User/thunks';

// Styles
import { UserManagementContainer } from './styles';

// Types
import type {
    ImmutableUserType,
    ImmutableRoleType,
} from 'management/services/Authentication/types';
import type { UserSearchCriteria } from 'management/services/User/types';
import type { ErrorType, ImmutableList, IntlType, ReactNode, ReduxDispatch } from 'types';

// Utils
import { provideFullRoleName } from 'management/utils';

// Constants
export const USER_DEFAULT_PAGE = 1;
export const USER_DEFAULT_SORTBY = 'id';
export const USER_DEFAULT_SORTORDER = 'desc';

const defaultSearchCriteria = {
    search: '',
    sortBy: USER_DEFAULT_SORTBY,
    sortOrder: USER_DEFAULT_SORTORDER,
};

type ItemType = { component: ReactNode, label: string, key: string };

type Props = {
    allUsers: ImmutableList<ImmutableUserType>,
    createUser: () => void,
    deleteUser: (number) => void,
    fetchRoles: () => void,
    fetchUsers: () => void,
    errors: ErrorType;
    intl: IntlType,
    limitedControl?: ?boolean,
    navigableRoles?: Array<string>,
    pageCount?: number,
    queriedUsers: ImmutableList<ImmutableUserType>,
    queryUsers: ({}, ?number) => void,
    reagents?: ImmutableList<string, Object>,
    reagentsAreFetching?: boolean,
    roles?: ImmutableList<ImmutableRoleType>,
    searchable?: boolean,
    updateUser: (number) => void,
    user: ImmutableUserType,
    userIsDeleting: boolean,
    userIsUpdating: boolean,
    usersAreQuerying: boolean,
    usersTotal?: number,
    withSearch?: boolean,
};

type State = {
    activeSidebarTabKey: ?string,
    searchCriteria: UserSearchCriteria,
};

export class Users extends React.PureComponent<Props, State> {
    static defaultProps = {
        limitedControl: false,
        navigableRoles: [],
        pageCount: null,
        roles: null,
        searchable: true,
        usersTotal: null,
        withSearch: null,
    };

    constructor(props: Props) {
        super(props);

        const sidebarItems = this.provideSidebarItems();
        const firstTabKey = sidebarItems && sidebarItems.shift().key;

        this.state = {
            activeSidebarTabKey: firstTabKey,
            searchCriteria: {
                ...defaultSearchCriteria,
                role: firstTabKey,
            },
        };
    }

    /**
     * Check for roles & users, if empty fetch them
     */
    componentDidMount() {
        if (!this.props.limitedControl) {
            // Fetch roles
            if ((this.props.roles && this.props.roles.isEmpty()) || !this.props.roles) {
                this.props.fetchRoles();
            }

            // MinChem: Fetch all users (for Admin to add PMs to SAMs)
            if ((this.props.allUsers && this.props.allUsers.isEmpty()) || !this.props.allUsers) {
                this.props.fetchUsers();
            }
        }
        // All: Query all users
        this.props.queryUsers(this.state.searchCriteria);
    }

    onSidebarTabSelection = (sidebarTabKey: string) => () => {
        this.setState(
            (prevState: State) => ({
                activeSidebarTabKey: sidebarTabKey,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    role: sidebarTabKey,
                },
            }),
            () => this.handleUsersReFetch(this.state.searchCriteria, 1)
        );
    };

    handleUsersReFetch = (searchCriteria: UserSearchCriteria, page: number) =>
        this.props.queryUsers({
            ...this.state.searchCriteria,
            ...searchCriteria
        }, page);

    provideSidebarItems = (): ?Array<ItemType> => {
        if (
            !this.props.navigableRoles ||
            (this.props.navigableRoles && !this.props.navigableRoles.length)
        ) {
            return null;
        }

        return this.props.navigableRoles.map((role: string) => ({
            label: provideFullRoleName(role, this.props.intl, true),
            key: role,
            component: this.renderMain,
        }));
    };

    renderContent = () => {
        if (!this.props.queriedUsers) {
            return (
                <Centered>
                    <Loader loading />
                </Centered>
            );
        }

        // Render sidebar layout if props.navigableRoles contains more than 1 role
        if (this.props.navigableRoles && this.props.navigableRoles.length > 1) {
            return (
                <SidebarLayout
                    mainStyles={{ height: '100%' }}
                    renderMain={this.renderMain}
                    renderSidebar={this.renderSidebar}
                    flush
                />
            );
        } else {
            return <UserManagementContainer>{this.renderMain()}</UserManagementContainer>;
        }
    };

    renderMain = () => (
        <UserManagement
            key={this.state.activeSidebarTabKey}
            activeRole={this.state.activeSidebarTabKey}
            allUsers={this.props.allUsers}
            authUser={this.props.user}
            createUser={this.props.limitedControl ? null : this.props.createUser}
            hideActiveStatus={this.props.limitedControl}
            defaultPage={USER_DEFAULT_PAGE}
            defaultSearchCriteria={defaultSearchCriteria}
            deleteUser={this.props.limitedControl ? null : this.props.deleteUser}
            errors={this.props.errors}
            onUsersReFetch={this.handleUsersReFetch}
            pageCount={this.props.pageCount}
            reagents={this.props.reagents}
            reagentsAreFetching={this.props.reagentsAreFetching}
            roles={
                this.props.navigableRoles && this.props.navigableRoles.length
                    ? null
                    : this.props.roles
            }
            searchable={this.props.searchable}
            title={
                this.state.activeSidebarTabKey
                    ? provideFullRoleName(this.state.activeSidebarTabKey, this.props.intl, true)
                    : this.props.intl.formatMessage({ id: 'management.views.Users.helmetTitle' })
            }
            queriedUsers={this.props.queriedUsers}
            updateUser={this.props.updateUser}
            userIsDeleting={this.props.userIsDeleting}
            userIsUpdating={this.props.userIsUpdating}
            usersAreQuerying={this.props.usersAreQuerying}
            usersTotal={this.props.usersTotal}
            withSearch={this.props.withSearch}
        />
    );

    renderSidebar = (): ?Array<ReactNode> => {
        const items: ?Array<ItemType> = this.provideSidebarItems();

        return (
            items &&
            items.map((item: ItemType) => (
                <SidebarTab
                    key={item.key}
                    active={item.key === this.state.activeSidebarTabKey}
                    handleOnHeaderClick={this.onSidebarTabSelection(item.key)}
                    title={item.label}
                />
            ))
        );
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'management.views.Users.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'management.views.Users.helmetDescription',
                        })}
                    />
                </Helmet>
                {this.renderContent()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    allUsers: selectUsers(),
    errors: selectErrors(),
    pageCount: selectTotalPageCount(),
    queriedUsers: selectQueriedUsers(),
    roles: selectAllRoles(),
    user: selectUser(),
    userIsDeleting: selectUserIsDeleting(),
    userIsUpdating: selectUserIsUpdating(),
    usersAreQuerying: selectUsersAreQuerying(),
    usersTotal: selectTotalCount(),
    withSearch: selectWithSearch(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            createUser,
            deleteUser,
            fetchRoles,
            fetchUsers,
            updateUser,
            queryUsers,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(Users))
);
