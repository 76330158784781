// @flow

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

export const FETCH_PRODUCTS_LIST_SUCCESS = 'app/services/Product/FETCH_PRODUCTS_LIST_SUCCESS';
export const FETCH_PRODUCTS_LIST_FAILURE = 'app/services/Product/FETCH_PRODUCTS_LIST_FAILURE';
export const LIST_IS_FETCHING = 'app/services/Product/LIST_IS_FETCHING';
export const FETCH_PRODUCT_TABLE_LIST_SUCCESS =
    'app/services/Product/FETCH_PRODUCT_TABLE_LIST_SUCCESS';
export const FETCH_PRODUCT_TABLE_LIST_FAILURE =
    'app/services/Product/FETCH_PRODUCT_TABLE_LIST_FAILURE';
export const PRODUCT_TABLE_LIST_IS_FETCHING = 'app/services/Product/PRODUCT_TABLE_LIST_IS_FETCHING';
export const PRODUCT_TABLE_LIST_CLEAR = 'app/services/Product/PRODUCT_TABLE_LIST_CLEAR';

export const receivedFetchListSuccess = (data: {}) => ({
    type: FETCH_PRODUCTS_LIST_SUCCESS,
    payload: { data },
});

export const receivedFetchListFailure = (errors: {}) => ({
    type: FETCH_PRODUCTS_LIST_FAILURE,
    payload: { errors },
});

export const setListIsFetchingStatus = (listIsFetching: boolean = true) => ({
    type: LIST_IS_FETCHING,
    payload: { listIsFetching },
});

export const productTableListClear = () => ({
    type: PRODUCT_TABLE_LIST_CLEAR,
});

// TODO:
export const receivedFetchProductTableListSuccess = (data: {}) => ({
    type: FETCH_PRODUCT_TABLE_LIST_SUCCESS,
    payload: { data },
});

export const receivedFetchProductTableListFailure = (errors: {}) => ({
    type: FETCH_PRODUCT_TABLE_LIST_FAILURE,
    payload: { errors },
});

export const setProductTableListIsFetchingStatus = (listIsFetching: boolean = true) => ({
    type: PRODUCT_TABLE_LIST_IS_FETCHING,
    payload: { listIsFetching },
});
