// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Constraint, Header, Icon, Wrapper, LoaderWrapper, BlendingExceptionsList } from './styles';
import { Checkmark, Close, LoadingDots } from 'OsedeaReactUI';

// Types
import type { ImmutableList, IntlType } from 'types';
import type { ConstraintType } from 'services/BuildingBlock/types';

type Props = {
    constraints?: ImmutableList<ConstraintType>,
    constraintsAreFetching: boolean,
    fontSize?: string,
    hideBlendingNote?: boolean,
    loaderAlign?: string,
    intl: IntlType,
    children?: ({}) => React.Node,
    noConstraintsMessage: string,
    constraintsMessage?: string,
};

/**
 * Render Building Block Blending Constraints for the selected Building Blocks
 */
class BuildingBlockBlendingConstraints extends React.PureComponent<Props> {
    static defaultProps = {
        children: null,
        constraints: null,
        constraintsMessage: '',
        fontSize: null,
        hideBlendingNote: false,
        loaderAlign: 'left',
    };

    render() {
        const {
            constraints,
            constraintsAreFetching,
            fontSize,
            hideBlendingNote,
            loaderAlign,
            intl,
            children,
            noConstraintsMessage,
            constraintsMessage,
        } = this.props;

        // Maps over props.constraints and searches for 'blendable' === 0 which flags the icon & message
        const cannotBeBlended =
            constraints.size &&
            constraints.find((constraint: ConstraintType) => !constraint.get('blendable'));

        const icon = <Icon>{cannotBeBlended ? <Close /> : <Checkmark />}</Icon>;

        let content;
        if (constraintsAreFetching) {
            content = (
                <LoaderWrapper loaderAlign={loaderAlign}>
                    <LoadingDots />
                </LoaderWrapper>
            );
        } else if (constraints.size && children) {
            // You can customize the message for both can and cannot blend cases using render
            // props pattern. The outside renderer function with have access to the cannotBeBlended,
            // icon and rationales variable.
            const rationales = constraints.map((constraint: ConstraintType) =>
                constraint.get('rational')
            );
            content = children({ cannotBeBlended, icon, rationales });
        } else if (constraints.size && !cannotBeBlended) {
            // Case: building blocks can be blended with exceptional constraints
            content = (
                <React.Fragment>
                    <Constraint fontSize={fontSize}>
                        {icon}
                        {intl.formatMessage({
                            id:
                                'components.BuildingBlockBlendingConstraints.customBlend.blendingExceptions',
                        })}
                    </Constraint>
                    <BlendingExceptionsList>
                        {constraints.map((constraint: ConstraintType) => (
                            <li key={constraint.get('id')}>{constraint.get('rational')}</li>
                        ))}
                    </BlendingExceptionsList>
                </React.Fragment>
            );
        } else if (constraints && constraints.size && constraintsMessage && cannotBeBlended) {
            // Case: constraints exist, but children not provided to customize message,
            // so default to the constraintsMessage
            content = (
                <Constraint fontSize={fontSize}>
                    {icon}
                    {constraintsMessage}
                </Constraint>
            );
        } else if (constraints.isEmpty() && noConstraintsMessage) {
            // Case: Building blocks can be blended. Render provided noConstraintsMessage.
            content = (
                <Constraint fontSize={fontSize}>
                    {icon}
                    {noConstraintsMessage}
                </Constraint>
            );
        }

        return (
            <Wrapper>
                {!hideBlendingNote && (
                    <Header>
                        {intl.formatMessage({
                            id: 'components.BuildingBlockBlendingConstraints.blendingNote',
                        })}
                    </Header>
                )}
                {content}
            </Wrapper>
        );
    }
}

export default injectIntl(BuildingBlockBlendingConstraints);
