// @flow

// Resources
import {
    checkBuildingBlockConstraints,
    requestBuildingBlocks,
    checkCustomBlendConstraints,
} from './resources';

// Actions
import {
    fetchBuildingBlockConstraintsSuccess,
    fetchBuildingBlockConstraintsFail,
    fetchBuildingBlocksSuccess,
    fetchBuildingBlocksFail,
    fetchCustomBlendConstraintsSuccess,
    fetchCustomBlendConstraintsFail,
    setBuildingBlockConstraintsIsFetching,
    setListIsFetchingStatus,
    setFrotherBlendsFilterSuccess,
} from './actions';

// Types
import type { ReduxDispatch, ResponseErrorType } from 'types';
import type { BuildingBlockType, FrotherBlendsFilter } from './types';

/**
 * Sends a request to fetch the list of building blocks and dispatches the appropriate action
 * based on the Promise state of the response
 */
export const fetchBuildingBlocksByProjectType = (projectType: string) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setListIsFetchingStatus());

    return requestBuildingBlocks(projectType)
        .then((response: Array<BuildingBlockType>) =>
            dispatch(fetchBuildingBlocksSuccess(response))
        )
        .catch((error: ResponseErrorType) => dispatch(fetchBuildingBlocksFail(error)));
};

/**
 * Sends a request to check if there are constraints between the provided building blocks
 *
 * @param Array ids
 */
export const fetchBuildingBlockConstraints = (ids: Array) => (dispatch: ReduxDipatch) => {
    dispatch(setBuildingBlockConstraintsIsFetching());
    checkBuildingBlockConstraints(ids)
        .then((response: {}) => dispatch(fetchBuildingBlockConstraintsSuccess(response)))
        .catch((error: ResponseErrorType) => dispatch(fetchBuildingBlockConstraintsFail(error)));
};

/**
 * Sends a request to check if there are constraints between the provided building blocks
 *
 * @param Array ids
 */
export const fetchCustomBlendConstraints = (ids: Array) => (dispatch: ReduxDipatch) => {
    dispatch(setBuildingBlockConstraintsIsFetching());
    checkCustomBlendConstraints(ids)
        .then((response: {}) => dispatch(fetchCustomBlendConstraintsSuccess(response)))
        .catch((error: ResponseErrorType) => dispatch(fetchCustomBlendConstraintsFail(error)));
};

/**
 * Sends a action to set the frother blends filters
 *
 * @param Array ids
 */
export const setFrotherBlendsFilter = (frotherBlendsFilter: FrotherBlendsFilter) => (
    dispatch: ReduxDipatch
) => {
    dispatch(setFrotherBlendsFilterSuccess(frotherBlendsFilter));
};
