// @flow

import React from 'react';

// Styles
import { SimpleList } from 'styles/common';
import { ProductListContainer, DeleteButton, ProductLabel } from './styles';

// Types
import type { ProductOptionType } from 'services/Product/types';

type Props = {
    selectedProducts: Array<ProductOptionType>,
    onProductDeselect: (number) => () => void,
};

class ProductList extends React.PureComponent<Props> {
    render() {
        const { selectedProducts, onProductDeselect } = this.props;

        if (selectedProducts.length === 0) {
            return null;
        }

        return (
            <ProductListContainer>
                <SimpleList>
                    {selectedProducts.map((product: ProductOptionType) => (
                        <li key={product.value}>
                            <DeleteButton onClick={onProductDeselect(product.value)} />
                            <ProductLabel>{product.label}</ProductLabel>
                        </li>
                    ))}
                </SimpleList>
            </ProductListContainer>
        );
    }
}

export default ProductList;
