// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { Close, PrimaryButton, LegacyTheme, InputSelect } from 'OsedeaReactUI';

// Styles
import { Flex } from 'styles/common';
import {
    InputsContainer,
    CloseContainer,
    InputLabel,
    SelectFromRecommendations,
    AddNewBuildingBlock,
    StyledOr,
} from './styles';

// Types
import type { GenericOptionType, IntlType } from 'types';

type Props = {
    onClose: () => void,
    onAddClick: (GenericOptionType) => void,
    recommendedPrimaryBuildingBlockOptions: ImmutableList<GenericOptionType>,
    recommendedSecondaryBuildingBlockOptions: ImmutableList<GenericOptionType>,
    allPrimaryBuildingBlockOptions: ImmutableList<GenericOptionType>,
    allSecondaryBuildingBlockOptions: ImmutableList<GenericOptionType>,
    intl: IntlType,
};

type State = {
    selectedOption: GenericOptionType,
    selectInputOption: GenericOptionType,
    textInputOption: GenericOptionType,
};

/**
 * ProductCustomBlendPicker
 *
 * Handles the searchability of a building block to be to a custom blend via:
 * 1. Dropdown select input
 * 2. Predictive text search input
 */
class ProductCustomBlendPicker extends React.PureComponent<Props, State> {
    state = {
        selectedOption: null,
        selectInputOption: null,
        textInputOption: null,
    };

    /**
     * Passes the selectedOption to the onAddClick handler.
     */
    handleOnAddClick = () => {
        this.props.onAddClick(this.state.selectedOption);
    };

    /**
     * Handles the on select event of the dropdown select input.
     * Sets the selected option and the select input option, but clears the text input option.
     */
    handleInputSelectChange = (option: GenericOptionType) => {
        this.setState({
            selectedOption: option,
            selectInputOption: option,
            textInputOption: null,
        });
    };

    /**
     * Handles the select event of the predictive text search input.
     * Sets the selected option and the text input option, but clears the drop down select option.
     */
    handleTextInputChange = (option: GenericOptionType) => {
        this.setState({
            selectedOption: option,
            selectInputOption: null,
            textInputOption: option,
        });
    };

    /**
     * Returns the dropdown select input with grouped options.
     */
    renderSelectRecommendationsInput = () => {
        const groupedOptions = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.ProductCustomBlendPicker.primaryGroupLabel',
                }),
                options: this.props.recommendedPrimaryBuildingBlockOptions.toJS(),
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.ProductCustomBlendPicker.secondaryGroupLabel',
                }),
                options: this.props.recommendedSecondaryBuildingBlockOptions.toJS(),
            },
        ];

        // Custom styles
        const styles = {
            valueContainerFontSize: '16px',
            groupLabelFontSize: '11px',
            groupLabelColor: LegacyTheme.dropdownMenuGroupLabelColor,
            groupLabelTextTransform: 'capitalize',
        };

        return (
            <InputSelect
                options={groupedOptions}
                onSelect={this.handleInputSelectChange}
                placeholder={this.props.intl.formatMessage({
                    id: 'components.ProductCustomBlendPicker.selectInputPlaceholder',
                })}
                formatGroupLabel={this.renderInputSelectGroupLabel()}
                selectedOption={this.state.selectInputOption}
                isSearchable={false}
                value={this.state.selectInputOption}
                styles={styles}
                controlShouldRenderValue
            />
        );
    };

    /**
     * Returns the predictive text search input with grouped options.
     */
    renderAddNewBuildingBlockTextInput = () => {
        const groupedOptions = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.ProductCustomBlendPicker.primaryGroupLabel',
                }),
                options: this.props.allPrimaryBuildingBlockOptions.toJS(),
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.ProductCustomBlendPicker.secondaryGroupLabel',
                }),
                options: this.props.allSecondaryBuildingBlockOptions.toJS(),
            },
        ];

        // Custom styles
        const styles = {
            valueContainerFontSize: '16px',
            groupLabelFontSize: '11px',
            groupLabelColor: LegacyTheme.dropdownMenuGroupLabelColor,
            groupLabelTextTransform: 'capitalize',
        };

        return (
            <InputSelect
                options={groupedOptions}
                onSelect={this.handleTextInputChange}
                placeholder={this.props.intl.formatMessage({
                    id: 'components.ProductCustomBlendPicker.textInputPlaceholder',
                })}
                formatGroupLabel={this.renderInputSelectGroupLabel()}
                selectedOption={this.state.textInputOption}
                value={this.state.textInputOption}
                styles={styles}
                controlShouldRenderValue
                hideDropdownIndicator
            />
        );
    };

    /**
     * Helper render method which renders the inputs' group labels of 'Primary' and 'Secondary'
     */
    renderInputSelectGroupLabel = () => (data: {}) => (
        <div>
            <span>{data.label}</span>
        </div>
    );

    render() {
        const { intl, onClose } = this.props;

        return (
            <Flex>
                <CloseContainer onClick={onClose}>
                    <Close
                        strokeWidth={0}
                        fill={LegacyTheme.modalCloseColor}
                        width="16px"
                        height="16px"
                        margin="none"
                    />
                </CloseContainer>

                <InputsContainer>
                    {/* Select from recommendations */}
                    <SelectFromRecommendations>
                        <InputLabel>
                            {intl.formatMessage({
                                id: 'components.ProductCustomBlendPicker.selectInputLabel',
                            })}
                        </InputLabel>
                        {this.renderSelectRecommendationsInput()}
                    </SelectFromRecommendations>

                    <StyledOr>
                        {intl.formatMessage({
                            id: 'components.ProductCustomBlendPicker.or',
                        })}
                    </StyledOr>

                    {/* Add new building block */}
                    <AddNewBuildingBlock>
                        <InputLabel>
                            {intl.formatMessage({
                                id: 'components.ProductCustomBlendPicker.textInputLabel',
                            })}
                        </InputLabel>
                        {this.renderAddNewBuildingBlockTextInput()}
                    </AddNewBuildingBlock>

                    <PrimaryButton
                        text={intl.formatMessage({
                            id: 'components.ProductCustomBlendPicker.addButton',
                        })}
                        onClick={this.handleOnAddClick}
                    />
                </InputsContainer>
            </Flex>
        );
    }
}

export default injectIntl(ProductCustomBlendPicker);
