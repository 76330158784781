// @flow

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

export const CREATE_SUCCESS = 'app/services/Project/CREATE_SUCCESS';
export const CREATE_SUCCESS_WITH_UPDATE = 'app/services/Project/CREATE_SUCCESS_WITH_UPDATE';
export const CREATE_FAILURE = 'app/services/Project/CREATE_FAILURE';
export const DELETE_FAILURE = 'app/services/Project/DELETE_FAILURE';
export const DELETE_SUCCESS = 'app/services/Project/DELETE_SUCCESS';
export const FETCH_SUCCESS = 'app/services/Project/FETCH_SUCCESS';
export const FETCH_SUCCESS_WITH_UPDATE = 'app/services/Project/FETCH_SUCCESS_WITH_UPDATE';
export const FETCH_FAILURE = 'app/services/Project/FETCH_FAILURE';
export const FETCH_LIST_SUCCESS = 'app/services/Project/FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'app/services/Project/FETCH_LIST_FAILURE';
export const LIST_IS_FETCHING = 'app/services/Project/LIST_IS_FETCHING';
export const REPORT_FAILURE = 'app/services/Prject/REPORT_FAILURE';
export const REPORT_SUCCESS = 'app/services/Prject/REPORT_SUCCESS';
export const SINGLE_PROJECT_IS_FETCHING = 'app/services/Project/SINGLE_PROJECT_IS_FETCHING';
export const UPDATE_SUCCESS = 'app/services/Project/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'app/services/Project/UPDATE_FAILURE';
export const SET_CLEAR_SINGLE_PROJECT_SUCCESS =
    'app/services/Project/SET_CLEAR_SINGLE_PROJECT_SUCCESS';
export const SET_SURVEY_FILLED_SUCCESS = 'app/services/Project/SET_SURVEY_FILLED_SUCCESS';
export const CREATE_CUSTOM_BLEND_SUCCESS = 'app/services/Project/CREATE_CUSTOM_BLEND_SUCCESS';
export const CREATE_CUSTOM_BLEND_FAILURE = 'app/services/Project/CREATE_CUSTOM_BLEND_FAILURE';
export const CREATE_CUSTOM_BLEND_IS_SUBMITTING =
    'app/services/Project/CREATE_CUSTOM_BLEND_IS_SUBMITTING';
export const RESET_CUSTOM_BLEND_STATE = 'app/services/Project/RESET_CUSTOM_BLEND_STATE';
export const ADD_PRODUCTS_TO_DISCOVERY_SUCCESS =
    'app/services/Project/ADD_PRODUCTS_TO_DISCOVERY_SUCCESS';
export const ADD_PRODUCTS_TO_DISCOVERY_FAILURE =
    'app/services/Project/ADD_PRODUCTS_TO_DISCOVERY_FAILURE';
export const FETCH_DISCOVERY_AGREEMENT_SUCCESS =
    'app/services/Project/FETCH_DISCOVERY_AGREEMENT_SUCCESS';
export const FETCH_DISCOVERY_AGREEMENT_FAILURE =
    'app/services/Project/FETCH_DISCOVERY_AGREEMENT_FAILURE';
export const DISCOVERY_AGREEMENT_IS_FETCHING =
    'app/services/Project/DISCOVERY_AGREEMENT_IS_FETCHING';
export const SAVE_DISCOVERY_AGREEMENT_TO_PROJECT_SUCCESS =
    'app/services/Project/SAVE_DISCOVERY_AGREEMENT_TO_PROJECT_SUCCESS';
export const SAVE_DISCOVERY_AGREEMENT_TO_PROJECT_FAILURE =
    'app/services/Project/SAVE_DISCOVERY_AGREEMENT_TO_PROJECT_FAILURE';
export const DISCOVERY_AGREEMENT_IS_SUBMITTING =
    'app/services/Project/DISCOVERY_AGREEMENT_IS_SUBMITTING';
export const RESET_ERRORS_SUCCESS = 'app/services/Project/RESET_ERRORS_SUCCESS';
export const FETCH_COMPANY_ADDRESSES_LIST_SUCCESS =
    'app/services/Project/FETCH_COMPANY_ADDRESSES_LIST_SUCCESS';
export const FETCH_COMPANY_ADDRESSES_LIST_FAILURE =
    'app/services/Project/FETCH_COMPANY_ADDRESSES_LIST_FAILURE';
export const RESET_DISCOVERY_AGREEMENT_SUCCESS =
    'app/services/Project/RESET_DISCOVERY_AGREEMENT_SUCCESS';

export const receivedCreateSuccess = (data: {}, update?: boolean = false) => ({
    type: update ? CREATE_SUCCESS_WITH_UPDATE : CREATE_SUCCESS,
    payload: { data },
});

export const receivedCreateFailure = (errors: {}) => ({
    type: CREATE_FAILURE,
    payload: { errors },
});

export const receivedFetchSuccess = (data: {}, update?: boolean = false) => ({
    type: update ? FETCH_SUCCESS_WITH_UPDATE : FETCH_SUCCESS,
    payload: { data },
});

export const receivedFetchFailure = (errors: {}) => ({
    type: FETCH_FAILURE,
    payload: { errors },
});

export const receivedFetchListSuccess = (data: {}) => ({
    type: FETCH_LIST_SUCCESS,
    payload: { data },
});

export const receivedFetchListFailure = (errors: {}) => ({
    type: FETCH_LIST_FAILURE,
    payload: { errors },
});

export const receivedReportFailure = (errors: {}) => ({
    type: REPORT_FAILURE,
    payload: { errors },
});

export const receivedReportSuccess = (data: {}) => ({
    type: REPORT_SUCCESS,
    payload: { data },
});

export const receivedUpdateSuccess = (data: {}) => ({
    type: UPDATE_SUCCESS,
    payload: { data },
});

export const receivedUpdateFailure = (errors: {}) => ({
    type: UPDATE_FAILURE,
    payload: { errors },
});

export const setListIsFetchingStatus = (listIsFetching: boolean = true) => ({
    type: LIST_IS_FETCHING,
    payload: { listIsFetching },
});

export const setSingleProjectIsFetchingStatus = (singleProjectIsFetching: boolean = true) => ({
    type: SINGLE_PROJECT_IS_FETCHING,
    payload: { singleProjectIsFetching },
});

export const setClearSingleProject = () => ({
    type: SET_CLEAR_SINGLE_PROJECT_SUCCESS,
});

export const setSingleProjectSurveyFilled = () => ({
    type: SET_SURVEY_FILLED_SUCCESS,
});

export const receivedCreateCustomBlendSuccess = (data: {}) => ({
    type: CREATE_CUSTOM_BLEND_SUCCESS,
    payload: { data },
});

export const receivedCreateCustomBlendFailure = (errors: {}) => ({
    type: CREATE_CUSTOM_BLEND_FAILURE,
    payload: { errors },
});

export const setCustomBlendIsSubmitting = (data = true) => ({
    type: CREATE_CUSTOM_BLEND_IS_SUBMITTING,
    payload: { data },
});

export const resetCustomBlendStateSuccess = () => ({
    type: RESET_CUSTOM_BLEND_STATE,
});

export const receivedProductsAddedToDiscoverySuccess = (
    data: {},
    sentProductIds: Array<number>
) => ({
    type: ADD_PRODUCTS_TO_DISCOVERY_SUCCESS,
    payload: {
        data,
        sentProductIds,
    },
});

export const receivedProductsAddedToDiscoveryFailure = (errors: {}) => ({
    type: ADD_PRODUCTS_TO_DISCOVERY_FAILURE,
    payload: { errors },
});

export const receivedDiscoveryAgreementSuccess = (data: {}) => ({
    type: FETCH_DISCOVERY_AGREEMENT_SUCCESS,
    payload: { data },
});

export const receivedDiscoveryAgreementFailure = (errors: {}) => ({
    type: FETCH_DISCOVERY_AGREEMENT_FAILURE,
    payload: { errors },
});

export const setDiscoveryAgreementIsFetching = () => ({
    type: DISCOVERY_AGREEMENT_IS_FETCHING,
});

export const receivedDiscoveryAgreementSaveSuccess = (data: {}) => ({
    type: SAVE_DISCOVERY_AGREEMENT_TO_PROJECT_SUCCESS,
    payload: { data },
});

export const receivedDiscoveryAgreementSaveFailure = (errors: {}) => ({
    type: SAVE_DISCOVERY_AGREEMENT_TO_PROJECT_FAILURE,
    payload: { errors },
});

export const setDiscoveryAgreementIsSubmitting = () => ({
    type: DISCOVERY_AGREEMENT_IS_SUBMITTING,
});

export const resetProjectErrorsSuccess = () => ({
    type: RESET_ERRORS_SUCCESS,
});

export const receivedCompanyAddressesSuccess = (data: {}) => ({
    type: FETCH_COMPANY_ADDRESSES_LIST_SUCCESS,
    payload: { data },
});

export const receivedCompanyAddressesFailure = (errors: {}) => ({
    type: FETCH_COMPANY_ADDRESSES_LIST_FAILURE,
    payload: { errors },
});

export const receivedDeleteFailure = (errors: {}) => ({
    type: DELETE_FAILURE,
    payload: { errors },
});

export const receivedDeleteSuccess = (data: {}) => ({
    type: DELETE_SUCCESS,
    payload: { data },
});

export const resetDiscoveryAgreementSuccess = () => ({
    type: RESET_DISCOVERY_AGREEMENT_SUCCESS,
});
