// @flow

import React from 'react';
import { injectIntl } from 'react-intl';
import debounce from 'lodash/debounce';

// Components
import {
    CheckBox,
    Common,
    InputSearch,
    InputSelect,
    SidebarSection,
    PrimaryButton,
    SecondaryButton,
} from 'OsedeaReactUI';
import ProductList from 'components/ProductList';

// Styles
import { SectionDesc, SimpleList, Subtitle, Title } from 'styles/common';

// Types
import type { ImmutableList, InputEvent, IntlType } from 'types';
import type { ProductType, ProductOptionType } from 'services/Product/types';

const defaultFilters = {
    myProjects: false,
    excludeCollectors: false,
    excludeFrothers: false,
    progressLab: false,
    surveyFilled: false,
    progressTrial: false,
    selectedProducts: [],
    selectedProgress: [],
};

type State = {
    search?: string,
    myProjects?: boolean,
    excludeCollectors?: boolean,
    excludeFrothers?: boolean,
    progressLab?: boolean,
    surveyFilled?: boolean,
    progressTrial?: boolean,
    selectedProducts?: Array<number>,
    selectedProgress?: Array<number>,
};

type Props = {
    intl: IntlType,
    onResetWithSearch: () => void,
    onSearchCriteria?: (state: State) => void,
    onAddProject?: () => void,
    withSearch: boolean,
    products: ImmutableList<ProductType>,
    productsListIsFetching: boolean,
};

/**
 * ProjectSidebar
 *
 * User features:
 * - Add a project button
 * - Project search by owner or plant
 * - Project filter owner, products or progress
 */
class ProjectSidebar extends React.Component<Props, State> {
    static defaultProps = {
        onAddProject: null,
        onSearchCriteria: null,
    };

    state = {
        search: '',
        ...defaultFilters,
    };

    onAddProject = () => this.props.onAddProject();

    /**
     * Clears owner, products, and progress filters and refetches list of projects.
     */
    handleClearFilters = () => {
        this.setState(
            {
                ...defaultFilters,
            },
            () => this.onSubmit()
        );
    };

    /**
     * Clears project search input by owner or plant and refetches list of projects.
     */
    onClearSearch = () => {
        this.setState(
            {
                search: '',
            },
            () => this.onSubmit()
        );
    };

    /**
     * Submits user search and filter options
     */
    onSubmit = () => this.props.onSearchCriteria(this.state);

    /**
     * Handles state updates for input change events for text inputs and select options.
     * If saveOnChange is provided a truthy value, refetch list of projects with current search
     * criteria.
     */
    handleInputChange = (name: string, saveOnChange: boolean = false) => (event: InputEvent) => {
        let value;
        if (event && event.target) {
            value = event.target.isCheckbox ? Boolean(event.target.checked) : event.target.value;
        }

        if (name) {
            this.setState(
                {
                    [name]: value,
                },
                () => {
                    if (saveOnChange) {
                        this.onSubmit();
                    }
                    if (name === 'search') {
                        this.props.onResetWithSearch();
                    }
                }
            );
        }
    };

    /**
     * Submits search criteria on 'Enter' key press.
     */
    handleKeyPress = (event: InputEvent) => event.key === 'Enter' && this.onSubmit();

    /**
     * Adds a product to the list of selected products in state. If product is already found in
     * this list, do not add it, return early from function.
     */
    handleProductSelect = (product: ProductOptionType) => {
        this.setState(
            (prevState: State) => ({
                selectedProducts: prevState.selectedProducts.concat(product),
            }),
            this.onSubmit
        );
    };

    /**
     * Removes the product from the list of selected products whose ID matches the provided ID.
     * This function is debounced 500ms.
     */
    handleProductDeselect = (id: number) => () => {
        this.setState(
            (prevState: State) => ({
                selectedProducts: prevState.selectedProducts.filter(
                    (product: ProductOptionType) => product.value !== id
                ),
            }),
            debounce(this.onSubmit, 500)
        );
    };

    /**
     * Returns an array of mapped products compatible with the InputSelect 'options' prop
     */
    getMappedProductOptions = () =>
        this.props.products
            // Filter out already selected products from select options
            .filter(
                (product: ProductType) =>
                    this.state.selectedProducts.findIndex(
                        (selectedProduct: ProductOptionType) =>
                            selectedProduct.value === product.get('id')
                    ) === -1
            )
            // Map filtered products to React-Select options
            .map((product: ProductType) => ({
                value: product.get('id'),
                label: product.get('name'),
            }));

    render() {
        return (
            <React.Fragment>
                <SidebarSection>
                    <Common.Row alignItems="center">
                        <Common.Column flex={4}>
                            <Title>
                                {this.props.intl.formatMessage({
                                    id: 'components.ProjectSidebar.searchTitle',
                                })}
                            </Title>
                        </Common.Column>
                        <Common.Column flex={2} justifyContent="flex-end">
                            {/* Add a project button */}
                            <PrimaryButton
                                disabled={!this.props.onAddProject}
                                onClick={this.props.onAddProject}
                                text={this.props.intl.formatMessage({
                                    id: 'components.ProjectSidebar.addAProject',
                                })}
                                inline={false}
                            />
                        </Common.Column>
                    </Common.Row>
                    <Common.Row alignItems="center">
                        {/* Project text search input */}
                        <InputSearch
                            handleOnClear={this.onClearSearch}
                            handleOnClick={this.onSubmit}
                            onChange={this.handleInputChange('search')}
                            onKeyDown={this.handleKeyPress}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.ProjectSidebar.searchPlaceholder',
                            })}
                            value={this.state.search}
                            withSearch={this.props.withSearch}
                        />
                    </Common.Row>
                </SidebarSection>
                <SidebarSection>
                    <Common.Row alignItems="center">
                        <Common.Column flex={4}>
                            <Title>
                                {this.props.intl.formatMessage({
                                    id: 'components.ProjectSidebar.filterTitle',
                                })}
                            </Title>
                        </Common.Column>
                        <Common.Column flex={2} justifyContent="flex-end">
                            {/* Clear filters button */}
                            <SecondaryButton
                                onClick={this.handleClearFilters}
                                text={this.props.intl.formatMessage({
                                    id: 'components.ProjectSidebar.filterClear',
                                })}
                                inline={false}
                            />
                        </Common.Column>
                    </Common.Row>
                    <Subtitle>
                        {this.props.intl.formatMessage({
                            id: 'components.ProjectSidebar.ownerTitle',
                        })}
                    </Subtitle>
                    <SimpleList>
                        <li>
                            {/* Filter by owner select option */}
                            <CheckBox
                                disabled={false}
                                checked={this.state.myProjects}
                                label={this.props.intl.formatMessage({
                                    id: 'components.ProjectSidebar.myProjects',
                                })}
                                name="myProjects"
                                onClickHandler={this.handleInputChange('myProjects', true)}
                            />
                        </li>
                    </SimpleList>
                    <Subtitle>
                        {this.props.intl.formatMessage({
                            id: 'components.ProjectSidebar.typeTitle',
                        })}
                    </Subtitle>
                    <SimpleList>
                        <li>
                            {/* Filter out collector project type */}
                            <CheckBox
                                disabled={false}
                                checked={this.state.excludeCollectors}
                                label={this.props.intl.formatMessage({
                                    id: 'components.ProjectSidebar.excludeCollectors',
                                })}
                                name="excludeCollectors"
                                onClickHandler={this.handleInputChange('excludeCollectors', true)}
                            />
                        </li>
                        <li>
                            {/* Filter out frother project type */}
                            <CheckBox
                                disabled={false}
                                checked={this.state.excludeFrothers}
                                label={this.props.intl.formatMessage({
                                    id: 'components.ProjectSidebar.excludeFrothers',
                                })}
                                name="excludeFrothers"
                                onClickHandler={this.handleInputChange('excludeFrothers', true)}
                            />
                        </li>
                    </SimpleList>
                </SidebarSection>
                <SidebarSection>
                    <Subtitle>
                        {this.props.intl.formatMessage({
                            id: 'components.ProjectSidebar.productsTitle',
                        })}
                    </Subtitle>
                    <SectionDesc>
                        {this.props.intl.formatMessage({
                            id: 'components.ProjectSidebar.productsDesc',
                        })}
                    </SectionDesc>
                    {/* Filter by product search input */}
                    <InputSelect
                        options={this.getMappedProductOptions()}
                        onSelect={this.handleProductSelect}
                        isLoading={this.props.productsListIsFetching}
                        placeholder={this.props.intl.formatMessage({
                            id: 'components.ProjectSidebar.productsPlaceholder',
                        })}
                        noOptionsMessage={this.props.intl.formatMessage({
                            id: 'components.ProjectSidebar.productsPlaceholder.productNotFound',
                        })}
                    />
                    {/* Selected products list */}
                    <ProductList
                        selectedProducts={this.state.selectedProducts}
                        onProductDeselect={this.handleProductDeselect}
                    />
                </SidebarSection>
                <SidebarSection>
                    <Subtitle>
                        {this.props.intl.formatMessage({
                            id: 'components.ProjectSidebar.progressTitle',
                        })}
                    </Subtitle>
                    <SimpleList>
                        <li>
                            {/* Filter by progress select option - survey filled */}
                            <CheckBox
                                checked={this.state.surveyFilled}
                                label={this.props.intl.formatMessage({
                                    id: 'components.ProjectSidebar.surveyFilled',
                                })}
                                name="surveyFilled"
                                onClickHandler={this.handleInputChange('surveyFilled', true)}
                            />
                        </li>
                        <li>
                            {/* Filter by progress select option - lab results*/}
                            <CheckBox
                                checked={this.state.progressLab}
                                label={this.props.intl.formatMessage({
                                    id: 'components.ProjectSidebar.progressLab',
                                })}
                                name="progressLab"
                                onClickHandler={this.handleInputChange('progressLab', true)}
                            />
                        </li>
                        <li>
                            {/* Filter by progress select option - plant trial results */}
                            <CheckBox
                                checked={this.state.progressTrial}
                                label={this.props.intl.formatMessage({
                                    id: 'components.ProjectSidebar.progressTrial',
                                })}
                                name="progressTrial"
                                onClickHandler={this.handleInputChange('progressTrial', true)}
                            />
                        </li>
                    </SimpleList>
                </SidebarSection>
            </React.Fragment>
        );
    }
}

export default injectIntl(ProjectSidebar);
