// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const QuestionWrapper = styled.div`
    min-height: 110px;
    margin-bottom: 16px;
    padding: 14px 16px;

    background-color: ${LegacyTheme.white};
    border: 1px solid ${LegacyTheme.defaultBorder};
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    &:last-child {
        margin-bottom: 2px; // Shows box-shadow on last child
    }
`;

export const QuestionTitle = styled.div`
    font-size: 17px;
    letter-spacing: -0.41px;
`;

export const QuestionNote = styled(QuestionTitle)`
    margin-top: 14px;
`;

export const InputWrapper = styled.div`
    margin-top: 14px;
    padding-top: 10px;
`;

export const Input = styled.input`
    height: 45px;
    width: 56px;
    border: 1px solid ${LegacyTheme.defaultBorder};
    border-radius: 4px;
    text-align: center;
    margin-right: 5px;
`;

export const FilterClearIcon = styled.div`
    width: 100%;
    color: #0079ba;
    text-align: right;
`;
