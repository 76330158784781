// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { Loader } from 'OsedeaReactUI';

// Styles
import { LoaderWrapper, Title } from 'styles/common';

// Constants
import { PROJECT_TYPE } from 'utils/constants';

type Props = {
    projectType: string,
};

class SurveySubmitLoader extends React.PureComponent<Props, null> {
    render() {
        const { projectType } = this.props;

        // Get the title depending on the projectType
        let title = '';
        switch (projectType) {
            case PROJECT_TYPE.COLLECTOR:
                title = 'views.Blocks.calculatingCollectorRecommendations';
                break;
            case PROJECT_TYPE.FROTHER:
                title = 'views.Blocks.calculatingFrotherRecommendations';
                break;
        }
        return (
            <LoaderWrapper>
                <div>
                    <Title>
                        {this.props.intl.formatMessage({
                            id: title,
                        })}
                    </Title>
                    <Loader loading />
                </div>
            </LoaderWrapper>
        );
    }
}

export default injectIntl(SurveySubmitLoader);
