// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const Date = styled.p`
    color: ${LegacyTheme.defaultColor};

    font-size: 12px;
`;
