// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';
import { StyleSheet } from '@react-pdf/renderer';

export const PDFControlsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 36px;
    margin-top: 24px;
`;

export const ButtonsContainer = styled.div`
    width: 208px;
    display: flex;
    justify-content: space-between;
`;

// Valid CSS Properties https://react-pdf.org/styling#valid-css-properties
export const pdfStyles = StyleSheet.create({
    header: {
        fontWeight: 700,
        fontFamily: 'Helvetica-Bold',
        marginBottom: 8,
    },
    page: {
        fontSize: 12,
        fontFamily: 'Helvetica',
        color: LegacyTheme.defaultColor,
        padding: '30px 40px 60px',
    },
    pageHeader: {
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
        maxHeight: 60,
    },
    pageHeaderCol: {
        width: '50%',
    },
    pageHeaderLogo: {
        width: 108,
        height: 50,
    },
    pageHeaderDate: {
        textAlign: 'right',
    },
    paragraph: {
        marginBottom: 10,
    },
    section: {
        marginVertical: 10,
    },
    pageNumber: {
        fontFamily: 'Helvetica',
        color: LegacyTheme.defaultSubtleColor,
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    textBlock: {
        marginBottom: 8,
    },
    image: {},
});
