// @flow

import React from 'react';
import { EditorState } from 'draft-js';

// Components
import {
    Column,
    PrimaryButton,
    Row,
    SidebarLayout,
    SidebarTab,
    TextFieldRich,
    TextFieldRichGlobalStyles,
    htmlFromEditorState,
} from 'OsedeaReactUI';

// Styles
import { Date } from './styles';

// Types
import type { ReactNode } from 'types';
import type { DisclaimerType } from 'management/services/Disclaimer/types';

type Props = {
    actionButtonText: string,
    disclaimers: Array<DisclaimerType>,
    onSave: (number, DisclaimerType) => void,
};

type State = {
    activeDisclaimerKey: string,
    disclaimers: Array<DisclaimerType>,
};

export default class DisclaimerManagement extends React.Component<Props, State> {
    state = {
        disclaimers: JSON.parse(JSON.stringify(this.props.disclaimers)), // Avoid mutating props
        activeDisclaimerKey: this.props.disclaimers && this.props.disclaimers[0].key,
    };

    handleOnChangeTextFieldRich = (key: string) => (value: EditorState) => {
        const { disclaimers } = this.state;

        const disclaimerIndex = disclaimers.findIndex(
            (disclaimer: DisclaimerType) => disclaimer.key === key
        );
        disclaimers[disclaimerIndex].content = value;

        this.setState({
            disclaimers,
        });
    };

    handleOnSave = (id: number) => () => {
        const activeDisclaimer = this.state.disclaimers.find(
            (disclaimer: DisclaimerType) => disclaimer.id === id
        );
        if (activeDisclaimer) {
            // Convert EditorState to HTML
            if (typeof activeDisclaimer.content !== 'string') {
                activeDisclaimer.content = htmlFromEditorState(activeDisclaimer.content);
            }
            this.props.onSave(id, activeDisclaimer);
        }
    };

    onDisclaimerSelection = (key: string) => () => {
        this.setState({
            activeDisclaimerKey: key,
        });
    };

    renderMain = () => {
        const activeDisclaimerFromState: DisclaimerType = this.state.disclaimers.find(
            (disclaimer: DisclaimerType) => disclaimer.key === this.state.activeDisclaimerKey
        );

        return (
            <React.Fragment key={this.state.activeDisclaimerKey}>
                <TextFieldRichGlobalStyles />
                <Row>
                    <TextFieldRich
                        onChange={this.handleOnChangeTextFieldRich(activeDisclaimerFromState.key)}
                        initialValue={activeDisclaimerFromState.content}
                    />
                </Row>
                <Row flex="0">
                    <Column>
                        <Date>{activeDisclaimerFromState.updatedAt}</Date>
                    </Column>
                    <Column alignItems="flex-end">
                        <PrimaryButton
                            // TODO: Add comparison validation on button to prevent un-needed saves. Downfall: comparing large objects
                            // disabled={}
                            onClick={this.handleOnSave(activeDisclaimerFromState.id)}
                            text={this.props.actionButtonText}
                        />
                    </Column>
                </Row>
            </React.Fragment>
        );
    };

    renderSidebar = (): ?Array<ReactNode> =>
        this.props.disclaimers &&
        this.props.disclaimers.map((disclaimer: DisclaimerType) => (
            <SidebarTab
                key={disclaimer.key}
                active={disclaimer.key === this.state.activeDisclaimerKey}
                handleOnHeaderClick={this.onDisclaimerSelection(disclaimer.key)}
                title={disclaimer.title}
            />
        ));

    render() {
        if (!this.props.disclaimers) {
            return null;
        }

        return (
            <SidebarLayout
                mainStyles={{ height: '100%' }}
                renderMain={this.renderMain}
                renderSidebar={this.renderSidebar}
                flush
            />
        );
    }
}
