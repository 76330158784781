// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Containers
import MessageBlockTimeoutProvider from 'containers/MessageBlockTimeoutProvider';

// Components
import { Checkmark, Close, LegacyTheme, MessageBlock } from 'OsedeaReactUI';

// Styles
import { MessageBlockContainer } from 'styles/common';

// Types
import type { IntlType, ImmutableMap } from 'types';

type Props = {
    autoHideDuration?: number,
    customBlendName: ?string,
    isLoading: boolean,
    onHide?: Function,
    errors: ImmutableMap,
    productsAddedCount: ?number,
    intl: IntlType,
};

class ProductFeedbackBlock extends React.PureComponent<Props> {
    render() {
        const {
            intl,
            customBlendName,
            isLoading,
            onHide,
            errors,
            productsAddedCount,
            autoHideDuration,
        } = this.props;

        if (isLoading) {
            return null;
        }

        // We will set these variables based on a matching if statement case.
        let icon;
        let color;
        let text;

        // Error: Custom blend not added
        if (errors.get('status') && errors.get('notAdded') === 'customBlend') {
            icon = <Close />;
            color = LegacyTheme.defaultWarningColor;
            text = intl.formatMessage({ id: 'components.Product.createCustomBlendFail' });
        }

        // Error: Existing product(s) not added
        if (errors.get('status') && errors.get('notAdded') === 'existingProducts') {
            icon = <Close />;
            color = LegacyTheme.defaultWarningColor;
            text = intl.formatMessage({ id: 'components.Product.addProductsFail' });
        }

        // Custom blend successfully added.
        if (customBlendName) {
            icon = <Checkmark />;
            color = LegacyTheme.defaultSuccessColor;
            text = intl.formatMessage(
                { id: 'components.Product.createCustomBlendSuccess' },
                { customBlendName }
            );
        }

        // Existing product(s) successfully added.
        if (productsAddedCount !== null) {
            icon = <Checkmark />;
            color = LegacyTheme.defaultSuccessColor;
            text = intl.formatMessage(
                { id: 'components.Product.addProductsSuccess' },
                {
                    productsAddedCount,
                    productPluralization:
                        productsAddedCount > 1 || productsAddedCount === 0 ? 'products' : 'product',
                }
            );
        }

        // If icon, color and text variables not set, return early from function.
        if (!icon || !color || !text) {
            return null;
        }

        return (
            <MessageBlockTimeoutProvider autoHideDuration={autoHideDuration} onHide={onHide}>
                <MessageBlockContainer right={'24px'}>
                    <MessageBlock icon={icon} color={color} text={text} />
                </MessageBlockContainer>
            </MessageBlockTimeoutProvider>
        );
    }
}

export default injectIntl(ProductFeedbackBlock);
