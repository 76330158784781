// @flow

// Types
import type { BuildingBlockType, FrotherBlendsFilter } from './types';

export const CONSTRAINTS_ARE_FETCHING = 'app/services/BuildingBlock/CONSTRAINTS_ARE_FETCHING';
export const LIST_IS_FETCHING = 'app/services/BuildingBlock/LIST_IS_FETCHING';
export const FETCH_BUILDING_BLOCKS_SUCCESS =
    'app/services/BuildingBlock/FETCH_BUILDING_BLOCKS_SUCCESS';
export const FETCH_BUILDING_BLOCKS_FAIL = 'app/services/BuildingBlock/FETCH_BUILDING_BLOCKS_FAIL';
export const FETCH_BUILDING_BLOCK_CONSTRAINTS_SUCCESS =
    'app/services/BuildingBlock/FETCH_BUILDING_BLOCK_CONSTRAINTS_SUCCESS';
export const FETCH_BUILDING_BLOCK_CONSTRAINTS_FAIL =
    'app/services/BuildingBlock/FETCH_BUILDING_BLOCK_CONSTRAINTS_FAIL';
export const FETCH_CUSTOM_BLEND_CONSTRAINTS_SUCCESS =
    'app/services/BuildingBlock/FETCH_CUSTOM_BLEND_CONSTRAINTS_SUCCESS';
export const FETCH_CUSTOM_BLEND_CONSTRAINTS_FAIL =
    'app/services/BuildingBlock/FETCH_CUSTOM_BLEND_CONSTRAINTS_FAIL';
export const SET_FROTHER_BLENDS_FILTER = 'app/services/BuildingBlock/SET_FROTHER_BLENDS_FILTER';

/**
 * Dispatches an action object which updates the constraintsAreLoading state to true
 */
export const setBuildingBlockConstraintsIsFetching = () => ({
    type: CONSTRAINTS_ARE_FETCHING,
});

/**
 * Dispatches an action object which updates the listIsFetching state to true
 */
export const setListIsFetchingStatus = () => ({
    type: LIST_IS_FETCHING,
});

/**
 * Dispatches an action object based on a successful API request to fetch all building blocks
 */
export const fetchBuildingBlocksSuccess = (buildingBlocks: Array<BuildingBlockType> = []) => ({
    type: FETCH_BUILDING_BLOCKS_SUCCESS,
    payload: { data: buildingBlocks },
});

/**
 * Dispatches an action object based on an unsuccessful API request to fetch all building blocks
 */
export const fetchBuildingBlocksFail = (errors: {}) => ({
    type: FETCH_BUILDING_BLOCKS_FAIL,
    payload: { errors },
});

/**
 * Dispatches an action object based on a successful API request to fetch building blocks constraints
 */
export const fetchBuildingBlockConstraintsSuccess = (constraints: {}) => ({
    type: FETCH_BUILDING_BLOCK_CONSTRAINTS_SUCCESS,
    payload: { data: constraints },
});

/**
 * Dispatches an action object based on an unsuccessful API request to fetch building blocks constraints
 */
export const fetchBuildingBlockConstraintsFail = (errors: {}) => ({
    type: FETCH_BUILDING_BLOCK_CONSTRAINTS_FAIL,
    payload: { errors },
});

/**
 * Dispatches an action object based on a successful API request to fetch custom blend constraints
 */
export const fetchCustomBlendConstraintsSuccess = (constraints: {}) => ({
    type: FETCH_CUSTOM_BLEND_CONSTRAINTS_SUCCESS,
    payload: { data: constraints },
});

/**
 * Dispatches an action object based on an unsuccessful API request to fetch custom blend constraints
 */
export const fetchCustomBlendConstraintsFail = (errors: {}) => ({
    type: FETCH_CUSTOM_BLEND_CONSTRAINTS_FAIL,
    payload: { errors },
});

/**
 * Dispatches an action object based on setting the filter for frother blends
 */
export const setFrotherBlendsFilterSuccess = (frotherBlendsFilter: FrotherBlendsFilter) => ({
    type: SET_FROTHER_BLENDS_FILTER,
    payload: { frotherBlendsFilter },
});
