// @flow

import styled, { css } from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const QuestionList = styled.div`
    margin-top: ${({ marginTop }) => marginTop || 'inhreit'};
`;

export const PaddingRightContainer = styled.div`
    padding-right: 12px;
`;

export const FactorsWrapper = styled.div`
    margin-top: 30px;
`;

export const FactorsWrapperContainer = styled.div`
    ${FactorsWrapper} {
        &:first-child {
            margin-top: 0;
        }
    }
`;

export const Intro = styled.p`
    color: ${LegacyTheme.defaultColor};

    font-size: 16px;
    line-height: 1.2;
`;

export const SurveyBody = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;

    margin: 10px 6px;
    padding: 0 18px;

    overflow: auto;
`;

export const SurveyFooter = styled.div`
    display: flex;
    flex: 0;

    padding 10px 24px 20px;
    min-height: 60px;
`;

export const SurveyHeader = styled.div`
    display: flex;
    flex: 0;

    padding 20px 24px 10px;
    min-height: 60px;
`;

export const SurveyContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    height: 100%;
    width: 100%;
    max-width: 720px;
    margin: 0 auto;

    ${({ flex }) =>
        !flex &&
        css`
        display: block;
        height: auto;

        ${SurveyBody} {
            margin: 0;
            padding: 0;
        }

        ${SurveyFooter} {
            margin: 24px 0;
            padding: 0;
        }

        ${SurveyHeader},
        ${SurveyBody},
        ${SurveyFooter} {
            display: block;
        }
    `};
`;
