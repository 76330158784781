// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { Common, CollapsibleBlock, InputSelect } from 'OsedeaReactUI';
import ErrorMessage from 'components/ErrorMessage';

// Constants
import { ERROR_MESSAGE_MARGIN_TOP } from 'utils/constants';

// Styles
import { FormLabel } from 'styles/common';
import { SingleColumn } from 'views/Agreement/styles';

// Types
import type { GenericOptionType, IntlType } from 'types';

type Props = {
    companyAddresses: ImmutableList,
    currentAgreement: ImmutableMap<string, string | number>,
    handleOnChange: (string) => void,
    intl: IntlType,
    errors: ImmutableMap<string, boolean>,
    disabled: boolean,
};

export class AgreementCompanyAddresses extends React.PureComponent<Props> {
    /**
     * Returns a list of active companies transformed into select options.
     */
    getFormattedCompanyAddressOptions = () =>
        this.props.companyAddresses
            // Filter out inactive companies
            .filter((company: {}) => company.get('active'))
            // Transform into compatible select option shape
            .map((activeCompany: {}) => ({
                value: activeCompany.get('id'),
                label: activeCompany.get('name'),
            }));

    /**
     * Takes all the formatted company select options and a company address key ('addressOneId | 'addressTwoId')
     * as arguments and returns the company whose address matches the id in the currentAgreement state.
     */
    getSelectedCompanyAddress = (companySelectOptions: Array, key: string) =>
        companySelectOptions.find(
            (option: GenericOptionType) =>
                /* eslint-disable eqeqeq */
                option.value == this.props.currentAgreement.get(key) && option
        );

    /**
     * On InputSelect selection, fire props.handleOnChange(name)(value) to save to currentAgreement
     */
    handleOnSelect = (name: string) => (option: GenericOptionType) => {
        this.props.handleOnChange(name)(option.value);
    };

    render() {
        const companySelectOptions = this.getFormattedCompanyAddressOptions();
        const companyAddressOneSelectedOption = this.getSelectedCompanyAddress(
            companySelectOptions,
            'addressOneId'
        );
        const companyAddressTwoSelectedOption = this.getSelectedCompanyAddress(
            companySelectOptions,
            'addressTwoId'
        );

        return (
            <CollapsibleBlock
                title={this.props.intl.formatMessage({
                    id: 'components.AgreementCompanyAddresses.blockTitle',
                })}
            >
                <SingleColumn>
                    <Common.Row>
                        <Common.Column>
                            <FormLabel>
                                {this.props.intl.formatMessage({
                                    id: 'components.AgreementCompanyAddresses.companyAddressLabel',
                                })}
                            </FormLabel>
                            <InputSelect
                                onSelect={this.handleOnSelect('addressOneId')}
                                options={companySelectOptions}
                                placeholder={this.props.intl.formatMessage({
                                    id:
                                        'components.AgreementCompanyAddresses.companyAddressPlaceholder',
                                })}
                                selectedOption={companyAddressOneSelectedOption}
                                isDisabled={this.props.disabled}
                                controlShouldRenderValue
                            />
                            {this.props.errors.get('addressOneId') && (
                                <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                    {this.props.intl.formatMessage({
                                        id: 'components.ErrorMessage.required',
                                    })}
                                </ErrorMessage>
                            )}
                        </Common.Column>
                    </Common.Row>
                    <Common.Row>
                        <Common.Column>
                            <FormLabel>
                                {this.props.intl.formatMessage({
                                    id: 'components.AgreementCompanyAddresses.companyAddress2Label',
                                })}
                            </FormLabel>
                            <InputSelect
                                onSelect={this.handleOnSelect('addressTwoId')}
                                options={companySelectOptions}
                                placeholder={this.props.intl.formatMessage({
                                    id:
                                        'components.AgreementCompanyAddresses.companyAddress2Placeholder',
                                })}
                                selectedOption={companyAddressTwoSelectedOption}
                                isDisabled={this.props.disabled}
                                controlShouldRenderValue
                            />
                            {this.props.errors.get('addressTwoId') && (
                                <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                    {this.props.intl.formatMessage({
                                        id: 'components.ErrorMessage.required',
                                    })}
                                </ErrorMessage>
                            )}
                        </Common.Column>
                    </Common.Row>
                </SingleColumn>
            </CollapsibleBlock>
        );
    }
}

export default injectIntl(AgreementCompanyAddresses);
