// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { fromJS } from 'immutable';

// Components
import {
    Close,
    Checkmark,
    Common,
    Loader,
    MessageBlock,
    SidebarLayout,
    SubtleAnchor,
    PrimaryButton,
    LegacyTheme,
    Modal,
} from 'OsedeaReactUI';
import BuildingBlockBlendingConstraints from 'components/BuildingBlockBlendingConstraints';
import BuildingBlockList from 'components/BuildingBlockList';
import CollectorSurveyForm from 'components/CollectorSurveyForm';
import FrotherSurveyForm from 'components/FrotherSurveyForm';
import SurveyIncompleteContainer from 'components/SurveyIncompleteContainer';
import SurveySubmitLoader from 'components/SurveySubmitLoader';
import FormulaCompositionGraph from 'components/FormulaCompositionGraph';

// Constants
import {
    PRIMARY_BUILDING_BLOCK_TYPE,
    SURVEY_TYPE_PRIMARY,
    SECONDARY_BUILDING_BLOCK_TYPE,
    PROJECT_TYPE,
} from 'utils/constants';

// Selectors
import {
    selectBuildingBlocksConstraints,
    selectBuildingBlocksConstraintsAreFetching,
    selectFrotherBuildingBlocks,
    selectFrotherBlendsFilter,
} from 'services/BuildingBlock/selectors';
import { selectSingleProject, selectSingleProjectIsFetching } from 'services/Project/selectors';
import {
    selectSurvey,
    selectSurveyIsFetching,
    selectSurveyIsSubmitting,
} from 'services/Survey/selectors';
import { selectFrotherProducts } from 'services/Product/selectors';

// Styles
import { BlockButtonContainer, ColumnLimiter } from './styles';
import {
    Note,
    NoteContainer,
    LoaderWrapper,
    Title,
    ColumnContainer,
    ColumnBody,
    ColumnSection,
    ContentContainer,
    MessageBlockContainer,
} from 'styles/common';
import {
    Constraint,
    ConstraintsList,
    ConstrainTrigger,
} from 'components/BuildingBlockBlendingConstraints/styles';

// Thunks
import {
    fetchBuildingBlockConstraints,
    setFrotherBlendsFilter,
} from 'services/BuildingBlock/thunks';
import {
    fetchSurveyByIdAndType,
    initDefaultSurvey,
    revertSurveyByProjectIdAndType,
    updateSurveyAndBuildingBlocks,
} from 'services/Survey/thunks';
import {
    fetchProject,
    reportProjectRecommendations,
    fetchProjectWithBlendFilter,
} from 'services/Project/thunks';

// Types
import type { ImmutableList, IntlType } from 'types';
import type { BuildingBlockType, ProjectType } from 'services/Project/types';
import type { CollectorSurveyType } from 'services/Survey/types';
import type {
    ConstraintType,
    FrotherBuildingBlock,
    FrotherBlendsFilter,
} from 'services/BuildingBlock/types';
import type { ProductType } from 'services/Products/types';

// Utils
import { mapIndexToColor, getBuildingBlockVarNameByProjectType } from 'utils/helpers';

type Props = {
    constraints?: ImmutableList<ConstraintType>,
    constraintsAreFetching: boolean,
    fetchBuildingBlockConstraints: (Array<number>) => void,
    fetchProject: (number, Array<string>) => void,
    fetchProjectWithBlendFilter: (number, string[], FrotherBlendsFilter) => void,
    fetchSurveyByIdAndType: (number, string) => void,
    history: {
        push: (string) => void,
    },
    initDefaultSurvey: (number) => void,
    intl: IntlType,
    match?: {
        params: {
            projectId: ?string,
        },
    },
    project?: ProjectType,
    projectIsFetching: boolean,
    setFrotherBlendsFilter: (FrotherBlendsFilter) => void,
    reportProjectRecommendations: (number) => void,
    revertSurveyByProjectIdAndType: (number, string) => void,
    frotherBuildingBlocks: ImmutableList<FrotherBuildingBlock>,
    frotherProducts: ImmutableList<ProductType>,
    frotherBlendsFilter: FrotherBlendsFilter,
    survey: ?CollectorSurveyType,
    surveyIsFetching: boolean,
    surveyIsSubmitting: boolean,
    updateSurveyAndBuildingBlocks: (number, string, CollectorSurveyType) => void,
};

type State = {
    currentPage: string,
    filter: PRIMARY_BUILDING_BLOCK_TYPE | SECONDARY_BUILDING_BLOCK_TYPE,
    reportedFeedback?: boolean,
    selectedBuildingBlocksIds: Array<number>,
};

export class Blocks extends React.PureComponent<Props, State> {
    static defaultProps = {
        constraints: null,
        match: {
            params: {
                projectId: null,
            },
        },
        project: null,
    };

    state = {
        currentPage: SURVEY_TYPE_PRIMARY,
        filter: PRIMARY_BUILDING_BLOCK_TYPE,
        reportedFeedback: false,
        selectedBuildingBlocksIds: [],
    };

    /**
     * On mount, fetch project
     */
    componentDidMount() {
        const projectId = Number(this.props.match.params.projectId);
        if (projectId) {
            this.props
                .fetchProject(projectId, ['buildingBlocks', 'frotherBlends'])
                .then((response) => {
                    // Fetch survey from props.project.id
                    const projectType = this.props.project && this.props.project.get('projectType');
                    const surveyFilled =
                        this.props.project && this.props.project.get('surveyFilled');

                    if (surveyFilled) {
                        this.props.fetchSurveyByIdAndType(projectId, projectType);
                    } else if (projectId) {
                        this.props.initDefaultSurvey(projectId);
                    } else {
                        throw new Error('Cannot fetch survey without project id');
                    }
                });
        }
    }

    /**
     * On component did update,
     * check if project's reported has changed, if so fire this.handleReportProjectRecommendationsFeedback()
     */
    componentDidUpdate(prevProps: Props) {
        const { project } = this.props;

        if (prevProps.project && prevProps.project.get('reported') !== project.get('reported')) {
            this.handleReportProjectRecommendationsFeedback();
        }
    }

    componentWillUnmount() {
        this.props.setFrotherBlendsFilter(fromJS({}));
    }

    isBlockTypeCollector = () => {
        const { project } = this.props;
        if (project.get('projectType')) {
            return project.get('projectType') === PROJECT_TYPE.COLLECTOR;
        }
    };

    /**
     * Handles select/deselect of Building Blocks
     * Fetches constraints (props.fetchBuildingBlockConstraints) on setState callback if state.selectedBuildingBlocksIds has at least one id
     */
    handleOnChangeBuildingBlock = (id: number) => () => {
        const selected = [...this.state.selectedBuildingBlocksIds];

        if (selected.indexOf(id) < 0) {
            selected.push(id);
        } else if (selected.indexOf(id) >= 0) {
            selected.splice(selected.indexOf(id), 1);
        }

        return this.setState(
            {
                selectedBuildingBlocksIds: selected,
            },
            () => {
                if (this.state.selectedBuildingBlocksIds.length) {
                    this.props.fetchBuildingBlockConstraints(this.state.selectedBuildingBlocksIds);
                }
            }
        );
    };

    /**
     * Handles Building Block Type (Primary/Secondary) selection
     * Empties selected Building Blocks
     */
    handleOnClickTypeSelector = (
        filter: PRIMARY_BUILDING_BLOCK_TYPE | SECONDARY_BUILDING_BLOCK_TYPE
    ) => () => {
        if (this.state.filter !== filter) {
            this.setState({
                filter,
                selectedBuildingBlocksIds: [],
            });
        }
    };

    /**
     * Handles report project recommendations by firing props.reportProjectRecommendations(id)
     * Triggers an email notification to admins
     */
    handleReportProjectRecommendations = () =>
        this.props.reportProjectRecommendations(this.props.project.get('id'));

    /**
     * Handles project report feedback by setting state for the provided timeout
     */
    handleReportProjectRecommendationsFeedback = (timeout: number = 6000) => {
        this.setState(
            {
                reportedFeedback: true,
            },
            () =>
                setTimeout(() => {
                    this.setState({ reportedFeedback: false });
                }, timeout)
        );
    };

    /**
     * Handles submission of survey, firing props.updateSurveyAndBuildingBlocks
     */
    handleSubmitSurvey = (survey: CollectorSurveyType) => {
        const projectId = this.props.project && this.props.project.get('id');
        const projectType = this.props.project && this.props.project.get('projectType');

        if (this.props.project && this.props.project.get('surveyFilled')) {
            this.props.updateSurveyAndBuildingBlocks(projectId, projectType, survey.toJS());
        }
    };

    /**
     * Handles reverting of survey, firing props.revertSurveyByProjectIdAndType
     */
    handleRevertSurvey = () => {
        const { project } = this.props;
        const projectId = project && project.get('id');
        const projectType = project && project.get('projectType');

        if (project && project.get('surveyFilled')) {
            this.props.revertSurveyByProjectIdAndType(projectId, projectType);
        }
    };

    /**
     * Handles redirect to survey if not filled
     */
    handleRedirectToSurvey = () => {
        this.props.history.push(`/project/${this.props.project.get('id')}/survey`);
    };

    handleOnFrotherBlendsFilter = (frotherBlendsFilter: ImmutableMap<FrotherBlendsFilter>) => {
        this.props.setFrotherBlendsFilter(frotherBlendsFilter);
        // Fetch again the project with filtered blends
        this.props.fetchProjectWithBlendFilter(
            this.props.project.get('id'),
            ['buildingBlocks', 'frotherBlends'],
            frotherBlendsFilter
        );
    };

    /**
     * Return building blocks with colors base on selected building block index
     */
    colorRecommendedBuildingBlocks = () => {
        const { project } = this.props;
        const { filter } = this.state;
        const projectType = project.get('projectType');
        const blockType = getBuildingBlockVarNameByProjectType(projectType);

        // Filter primary/secondary based on state.filter
        let buildingBlocksByType = [];
        // No filter Primary/Secondary for frothers
        if (projectType === PROJECT_TYPE.FROTHER) {
            buildingBlocksByType = project.get(blockType);
        } else {
            buildingBlocksByType =
                project.get(blockType) &&
                project
                    .get(blockType)
                    .filter(
                        (buildingBlock: BuildingBlockType) =>
                            buildingBlock.getIn(['pivot', 'primarySecondary']) === filter
                    );
        }

        // Map over blocks & assign mapIndexToColor with selectedBuildingBlockIds index
        const buildingBlocksWithColors =
            buildingBlocksByType &&
            buildingBlocksByType.map((buildingBlock: BuildingBlockType) =>
                buildingBlock.set(
                    'colors',
                    fromJS(
                        mapIndexToColor(
                            this.state.selectedBuildingBlocksIds.findIndex(
                                (id: number) => id === buildingBlock.get('id')
                            )
                        )
                    )
                )
            );
        return buildingBlocksWithColors;
    };

    /**
     * Renders user feedback for project reporting
     */
    renderReportProjectRecommendationsFeedback = () => {
        if (!this.state.reportedFeedback) {
            return null;
        }

        let reportedFeedbackBlock;

        if (this.props.project.get('reported')) {
            reportedFeedbackBlock = (
                <MessageBlock
                    icon={<Checkmark />}
                    color={LegacyTheme.defaultSuccessColor}
                    text={this.props.intl.formatMessage({ id: 'views.Blocks.alertAdminSuccess' })}
                />
            );
        } else {
            reportedFeedbackBlock = (
                <MessageBlock
                    icon={<Close />}
                    color={LegacyTheme.defaultWarningColor}
                    text={this.props.intl.formatMessage({ id: 'views.Blocks.alertAdminFailed' })}
                />
            );
        }

        return (
            <MessageBlockContainer right={'24px'}>{reportedFeedbackBlock}</MessageBlockContainer>
        );
    };

    renderCustomConstraintsModal = ({ icon, cannotBeBlended }) => {
        const { intl, constraints } = this.props;

        return (
            <Modal
                modalHeight="270px"
                modalWidth="540px"
                trigger={
                    <Constraint>
                        {icon}
                        <FormattedMessage
                            id={
                                cannotBeBlended
                                    ? 'components.BuildingBlockBlendingConstraints.constraint'
                                    : 'components.BuildingBlockBlendingConstraints.noConstraint'
                            }
                            values={{
                                trigger: (
                                    <ConstrainTrigger>
                                        {intl.formatMessage({
                                            id: cannotBeBlended
                                                ? 'components.BuildingBlockBlendingConstraints.constraintTrigger'
                                                : 'components.BuildingBlockBlendingConstraints.noConstraintTrigger',
                                        })}
                                    </ConstrainTrigger>
                                ),
                            }}
                        />
                    </Constraint>
                }
            >
                <ConstraintsList>
                    {constraints &&
                        constraints.map((constraint: ConstraintType) => (
                            <li key={constraint.get('id')}>{constraint.get('rational')}</li>
                        ))}
                </ConstraintsList>
            </Modal>
        );
    };

    renderFrotherSurveyForm = () => {
        return (
            <FrotherSurveyForm
                onSubmit={this.handleSubmitSurvey}
                frotherBuildingBlocks={this.props.frotherBuildingBlocks}
                frotherProducts={this.props.frotherProducts}
                project={this.props.project}
                onRevert={this.handleRevertSurvey}
                onFrotherBlendsFilter={this.handleOnFrotherBlendsFilter}
                frotherBlendsFilter={this.props.frotherBlendsFilter}
                survey={this.props.survey}
                surveyIsFetching={this.props.surveyIsFetching}
                surveyFilled={Boolean(this.props.project.get('surveyFilled'))}
                isSidebar
            />
        );
    };

    renderCollectorSurveyForm = () => {
        return (
            <CollectorSurveyForm
                currentPage={this.state.currentPage}
                onSubmit={this.handleSubmitSurvey}
                onRevert={this.handleRevertSurvey}
                project={this.props.project}
                survey={this.props.survey}
                surveyIsFetching={this.props.surveyIsFetching}
                surveyFilled={Boolean(this.props.project.get('surveyFilled'))}
                isSidebar
            />
        );
    };

    renderSidebar = () => {
        const { project } = this.props;
        if (!this.props.project.isEmpty() && !this.props.survey.isEmpty()) {
            switch (project.get('projectType')) {
                case PROJECT_TYPE.COLLECTOR:
                    return this.renderCollectorSurveyForm();
                case PROJECT_TYPE.FROTHER:
                    return this.renderFrotherSurveyForm();
            }
        }
    };

    renderFilterButtons = () => {
        const primary = this.state.filter !== PRIMARY_BUILDING_BLOCK_TYPE;
        const secondary = this.state.filter !== SECONDARY_BUILDING_BLOCK_TYPE;
        return (
            <BlockButtonContainer>
                <PrimaryButton
                    text={this.props.intl.formatMessage({ id: 'views.Blocks.Primary' })}
                    borderRadius="15px"
                    backgroundColor={primary && LegacyTheme.buildingBlockButtonSecondary}
                    hoverBackgroundColor={
                        (primary && LegacyTheme.buildingBlockButtonSecondary) ||
                        LegacyTheme.buildingBlockButtonPrimary
                    }
                    hoverTextColor={primary && LegacyTheme.buildingBlockButtonSecondaryColor}
                    textColor={primary && LegacyTheme.buildingBlockButtonSecondaryColor}
                    onClick={this.handleOnClickTypeSelector(PRIMARY_BUILDING_BLOCK_TYPE)}
                />
                <PrimaryButton
                    text={this.props.intl.formatMessage({ id: 'views.Blocks.Secondary' })}
                    borderRadius="15px"
                    backgroundColor={secondary && LegacyTheme.buildingBlockButtonSecondary}
                    hoverBackgroundColor={
                        (secondary && LegacyTheme.buildingBlockButtonSecondary) ||
                        LegacyTheme.buildingBlockButtonPrimary
                    }
                    hoverTextColor={secondary && LegacyTheme.buildingBlockButtonSecondaryColor}
                    textColor={secondary && LegacyTheme.buildingBlockButtonSecondaryColor}
                    onClick={this.handleOnClickTypeSelector(SECONDARY_BUILDING_BLOCK_TYPE)}
                />
            </BlockButtonContainer>
        );
    };

    renderSpiderGraph = (buildingBlocksWithColors: BuildingBlockType[]) => {
        const { project } = this.props;
        const { selectedBuildingBlocksIds } = this.state;
        const projectType = project.get('projectType');
        return (
            <FormulaCompositionGraph
                projectType={projectType}
                formulas={buildingBlocksWithColors}
                selectedFormulasId={selectedBuildingBlocksIds}
            />
        );
    };

    renderReportFeedback = () => {
        return (
            <ColumnSection minHeight={'50px'} padding={'0 24px'}>
                {this.renderReportProjectRecommendationsFeedback()}
                <NoteContainer textAlign={'right'}>
                    <Note>
                        <FormattedMessage
                            id="views.Blocks.alertAdmin"
                            values={{
                                link: (
                                    <SubtleAnchor onClick={this.handleReportProjectRecommendations}>
                                        {this.props.intl.formatMessage({
                                            id: 'views.Blocks.alertAdminLink',
                                        })}
                                    </SubtleAnchor>
                                ),
                            }}
                        />
                    </Note>
                </NoteContainer>
            </ColumnSection>
        );
    };

    renderBuildingBlocksContraints = () => {
        return (
            <BuildingBlockBlendingConstraints
                constraints={this.props.constraints}
                constraintsAreFetching={this.props.constraintsAreFetching}
                noConstraintsMessage={this.props.intl.formatMessage({
                    id: 'components.BuildingBlockBlendingConstraints.blendable',
                })}
            >
                {/* Handle custom JSX/message for cannot be blended case */}
                {(renderProps) => this.renderCustomConstraintsModal(renderProps)}
            </BuildingBlockBlendingConstraints>
        );
    };

    renderTitle = () => {
        const { project } = this.props;
        let title: string = '';

        switch (project.get('projectType')) {
            case PROJECT_TYPE.COLLECTOR:
                title = 'views.Blocks.collectorRecommended';
                break;
            case PROJECT_TYPE.FROTHER:
                title = 'views.Blocks.frotherRecommended';
                break;
        }
        return (
            <Common.Column alignItems="center" flex={6} justifyContent="flex-start">
                <Common.Row alignItems="center" justifyContent="space-between">
                    <Common.Column flex={1}>
                        <Title>
                            {this.props.intl.formatMessage({
                                id: title,
                            })}
                        </Title>
                    </Common.Column>
                    <Common.Column flex={0}>
                        {this.isBlockTypeCollector() && this.renderFilterButtons()}
                    </Common.Column>
                </Common.Row>
            </Common.Column>
        );
    };

    renderMain = () => {
        const buildingBlocksWithColors = this.colorRecommendedBuildingBlocks();

        return (
            <ColumnContainer flex>
                <ColumnBody>
                    <ContentContainer padding={'24px'}>
                        <Common.Row flex="initial" gutter={24}>
                            {this.renderTitle()}
                            <Common.Column alignItems="flex-end" flex={4}>
                                {/* TODO: Add back Country Restriction UI once data is provided. Leaving column for layout */}
                            </Common.Column>
                        </Common.Row>
                        <Common.Row gutter={24}>
                            <Common.Column flex={6} alignItems="flex-start">
                                <BuildingBlockList
                                    buildingBlocks={buildingBlocksWithColors}
                                    selectedBuildingBlockIds={this.state.selectedBuildingBlocksIds}
                                    onClickCheckBox={this.handleOnChangeBuildingBlock}
                                    inputType="check"
                                />
                            </Common.Column>
                            <Common.Column alignItems="flex-end" flex={4}>
                                <ColumnLimiter maxWidth="700px">
                                    {this.renderSpiderGraph(buildingBlocksWithColors)}
                                    {/* Building block constraints */}
                                    {this.state.selectedBuildingBlocksIds &&
                                        this.state.selectedBuildingBlocksIds.length > 0 &&
                                        this.isBlockTypeCollector() &&
                                        this.renderBuildingBlocksContraints()}
                                </ColumnLimiter>
                            </Common.Column>
                        </Common.Row>
                    </ContentContainer>
                </ColumnBody>
                {this.props.project.get('isOwner') && this.renderReportFeedback()}
            </ColumnContainer>
        );
    };

    renderContent = () => {
        const { project, survey, projectIsFetching, surveyIsSubmitting } = this.props;
        let content = (
            <LoaderWrapper>
                <Loader loading />
            </LoaderWrapper>
        );

        if (surveyIsSubmitting) {
            content = <SurveySubmitLoader projectType={project.get('projectType')} />;
        } else if (project && !project.get('surveyFilled')) {
            content = (
                <Common.Column justifyContent="center" alignItems="center">
                    <SurveyIncompleteContainer
                        onClickHandler={project.get('isOwner') ? this.handleRedirectToSurvey : null}
                        blockView
                    />
                </Common.Column>
            );
        } else if (
            survey &&
            project &&
            project.get(getBuildingBlockVarNameByProjectType(project.get('projectType'))) &&
            // project.get(getBuildingBlockVarNameByProjectType(project.get('projectType'))).size &&
            !projectIsFetching
        ) {
            content = (
                <SidebarLayout
                    renderSidebar={this.renderSidebar}
                    renderMain={this.renderMain}
                    flush
                    mainFlush
                />
            );
        }
        return content;
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.Blocks.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.Blocks.helmetDescription',
                        })}
                    />
                </Helmet>
                {this.renderContent()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    constraints: selectBuildingBlocksConstraints(),
    constraintsAreFetching: selectBuildingBlocksConstraintsAreFetching(),
    project: selectSingleProject(),
    projectIsFetching: selectSingleProjectIsFetching(),
    survey: selectSurvey(),
    surveyIsFetching: selectSurveyIsFetching(),
    surveyIsSubmitting: selectSurveyIsSubmitting(),
    frotherBuildingBlocks: selectFrotherBuildingBlocks(),
    frotherProducts: selectFrotherProducts(),
    frotherBlendsFilter: selectFrotherBlendsFilter(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchBuildingBlockConstraints,
            fetchProject,
            fetchSurveyByIdAndType,
            initDefaultSurvey,
            reportProjectRecommendations,
            revertSurveyByProjectIdAndType,
            updateSurveyAndBuildingBlocks,
            setFrotherBlendsFilter,
            fetchProjectWithBlendFilter,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(injectIntl(Blocks)));
