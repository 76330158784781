// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const StyledErrorMessage = styled.div`
    margin-top: ${({ marginTop }) => marginTop || '0px'};
    color: ${LegacyTheme.defaultWarningColor};

    font-family: inherit;
    font-size: 11px;
    letter-spacing: 0.07px;

    text-align: ${({ textAlign }) => textAlign || 'left'};
`;
