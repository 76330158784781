// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Constants
import { SURVEY_TYPE_PRIMARY, SURVEY_TYPE_SECONDARY, VIEW_BLOCK } from 'utils/constants';

// Styles
import { Common, PrimaryButton, SecondaryButton } from 'OsedeaReactUI';

// Types
import type { IntlType, ReactNode } from 'types';
import type { ProjectType } from 'services/Project/types';

type Props = {
    currentPage: string,
    disableSubmit: boolean,
    errorsValueSets?: ReactNode,
    errorsPrimarySurvey?: ReactNode,
    errorsSecondarySurvey?: ReactNode,
    intl: IntlType,
    onGoToMainSurvey?: () => void,
    onGoToSecondarySurvey?: () => void,
    onGoToBuildingBlocks?: () => void,
    onSurveySubmit: () => void,
    onSurveyRevert?: () => void,
    project: ProjectType,
    view?: string,
};

/**
 * Survey action buttons used in the various survey forms of the application.
 */
class SurveyControls extends React.PureComponent<Props, null> {
    static defaultProps = {
        errorsValueSets: null,
        errorsPrimarySurvey: null,
        errorsSecondarySurvey: null,
        onGoToBuildingBlocks: null,
        onGoToMainSurvey: null,
        onGoToSecondarySurvey: null,
        onSurveyRevert: null,
        view: 'Survey',
    };

    handleIsOwner = () => this.props.project && this.props.project.get('isOwner');

    handleSurveyRevert = () => this.handleIsOwner() && this.props.onSurveyRevert();

    handleSurveySubmit = () => {
        if (this.handleIsOwner()) {
            this.props.onSurveySubmit();
        } else {
            this.props.onGoToBuildingBlocks();
        }
    };

    handleCanSubmit = () =>
        !this.props.errorsValueSets &&
        !this.props.errorsPrimarySurvey &&
        !this.props.errorsSecondarySurvey;

    renderBlockView = () => (
        <Common.Row>
            <Common.Column>
                <SecondaryButton
                    disabled={!this.handleIsOwner()}
                    onClick={this.handleSurveyRevert}
                    text={this.props.intl.formatMessage({
                        id: 'views.Blocks.revertToOriginal',
                    })}
                />
            </Common.Column>
            <Common.Column alignItems="flex-end">
                <PrimaryButton
                    disabled={this.props.disableSubmit || !this.handleCanSubmit()}
                    onClick={!this.props.disableSubmit && this.handleSurveySubmit}
                    text={this.props.intl.formatMessage({
                        id: 'views.Blocks.applyChanges',
                    })}
                />
            </Common.Column>
        </Common.Row>
    );

    renderSurveyView = () => {
        let buttons;
        switch (this.props.currentPage) {
            case SURVEY_TYPE_PRIMARY:
                buttons = (
                    <Common.Row>
                        <Common.Column alignItems="flex-end">
                            {/* Next button */}
                            <PrimaryButton
                                disabled={
                                    this.props.errorsPrimarySurvey || this.props.errorsValueSets
                                }
                                onClick={this.props.onGoToSecondarySurvey}
                                text={this.props.intl.formatMessage({
                                    id: 'components.ProjectSurvey.next',
                                })}
                            />
                        </Common.Column>
                    </Common.Row>
                );
                break;
            case SURVEY_TYPE_SECONDARY:
                buttons = (
                    <Common.Row>
                        <Common.Column>
                            {/* Back button */}
                            <PrimaryButton
                                disabled={!this.handleCanSubmit()}
                                onClick={this.props.onGoToMainSurvey}
                                text={this.props.intl.formatMessage({
                                    id: 'components.ProjectSurvey.back',
                                })}
                            />
                        </Common.Column>
                        <Common.Column alignItems="flex-end">
                            {/* Submit button */}
                            <PrimaryButton
                                disabled={!this.handleCanSubmit()}
                                onClick={this.handleSurveySubmit}
                                text={this.props.intl.formatMessage({
                                    id: 'components.ProjectSurvey.next',
                                })}
                            />
                        </Common.Column>
                    </Common.Row>
                );
                break;
            default:
                buttons = null;
        }

        return buttons;
    };

    render() {
        if (this.props.view === VIEW_BLOCK) {
            return this.renderBlockView();
        }

        return (
            <React.Fragment>
                {this.props.errorsSecondarySurvey}
                {this.renderSurveyView()}
                {this.props.errorsPrimarySurvey}
                {this.props.errorsValueSets}
            </React.Fragment>
        );
    }
}

export default injectIntl(SurveyControls);
