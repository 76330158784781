// @flow

import React from 'react';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';

// Components
import { PrimaryButton, SecondaryButton, InputNumber, ButtonWrapper } from 'OsedeaReactUI';
import ProductCustomBlendPicker from 'components/ProductCustomBlendPicker';
import BuildingBlockItem from 'components/BuildingBlockItem';
import BuildingBlockBlendingConstraints from 'components/BuildingBlockBlendingConstraints';
import ErrorMessage from 'components/ErrorMessage';

// Styles
import {
    MainContainer,
    SelectionContainer,
    RecommendationsContainer,
    FooterContainer,
    FooterText,
    CustomBlendHeader,
    BlendingRecommendationsHeader,
    Content,
    MissingRecommendationsMessage,
    BuildingBlockItemContainer,
    ProductExistsMessage,
    ProductExistsInfo,
} from './styles';

// Utils
import { mapIndexToColor } from 'utils/helpers';

// Constants
import {
    PRIMARY_BUILDING_BLOCK_TYPE,
    SECONDARY_BUILDING_BLOCK_TYPE,
    PRIMARY_AND_SECONDARY_BUILDING_BLOCK_TYPE,
} from 'utils/constants';

// Types
import type { IntlType, ImmutableList, GenericOptionType, InputEvent } from 'types';
import type { BuildingBlockType, CustomBlendType } from 'services/Project/types';
import type { ProductCustomBlendType } from 'services/Product/types';

type Props = {
    createModalClosingHandler: () => void,
    intl: IntlType,
    recommendedBuildingBlocks: ImmutableList<BuildingBlockType>,
    allBuildingBlocks: ImmutableList<BuildingBlockType>,
    fetchCustomBlendConstraints: (Array<number>) => void,
    customBlendProductExists: ?boolean,
    constraints: ImmutableList,
    constraintsAreFetching: boolean,
    onAddCustomBlend: (projectId: number, CustomBlendType) => void,
    projectId: number,
};

type State = {
    buildingBlockIds: ImmutableList<number>,
    customBlend: ProductCustomBlendType,
    showAddBuildingBlockButton: boolean,
    showBuildingBlockPicker: boolean,
    recommendedPrimaryBuildingBlockOptions: ImmutableList<GenericOptionType>,
    recommendedSecondaryBuildingBlockOptions: ImmutableList<GenericOptionType>,
    allPrimaryBuildingBlockOptions: ImmutableList<GenericOptionType>,
    allSecondaryBuildingBlockOptions: ImmutableList<GenericOptionType>,
};

/**
 * ProductCustomBlendModalContent
 *
 * Handles the selection and deselection of building blocks to produce a custom product blend.
 */
class ProductCustomBlendModalContent extends React.PureComponent<Props, State> {
    state = {
        // Building block ids used for easy filtering of building blocks.
        buildingBlockIds: fromJS([]),
        // Building block blend profile with user set composition percent.
        customBlend: fromJS({}),
        showAddBuildingBlockButton: true,
        showBuildingBlockPicker: false,
        recommendedPrimaryBuildingBlockOptions: fromJS([]),
        recommendedSecondaryBuildingBlockOptions: fromJS([]),
        allPrimaryBuildingBlockOptions: fromJS([]),
        allSecondaryBuildingBlockOptions: fromJS([]),
    };

    componentDidMount() {
        this.handleMapAllBuildingBlocksToSelectOptions();
        this.handleMapRecommendedBuildingBlocksToSelectOptions();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.allBuildingBlocks.isEmpty() && this.props.allBuildingBlocks.size) {
            this.handleMapAllBuildingBlocksToSelectOptions();
        }
    }

    /**
     * Handles the addition of a building block ID to the list of IDs.
     * Also adds a new property to the customBlend object where the key maps to the added
     * building block id. In addition, hides the building block picker and displays the add
     * building block button in the setState callback..
     */
    handleOnAddBuildingBlock = (buildingBlockOption: GenericOptionType) => {
        if (!buildingBlockOption) {
            return;
        }

        const { buildingBlockIds } = this.state;
        const id = buildingBlockOption.value;
        const buildingBlockAlreadySelected = buildingBlockIds.includes(id);

        if (buildingBlockAlreadySelected) {
            return;
        }

        const buildingBlockComposition = fromJS({
            id,
            name: buildingBlockOption.label,
            percent: 0,
        });

        this.setState(
            (prevState: State) => ({
                buildingBlockIds: prevState.buildingBlockIds.push(id),
                customBlend: prevState.customBlend.set(String(id), buildingBlockComposition),
            }),
            // In the setState callback, fetch building block constraints and hide picker.
            () => {
                this.handleFetchBuildingBlockConstraints();
                this.handleToggleBuildingBlockPicker();
            }
        );
    };

    /**
     * Handles the removal of an adding building block.
     */
    createHandleOnRemoveBuildingBlock = (id: number) => () => {
        this.setState((prevState: State) => {
            const buildingBlockIdIndex = prevState.buildingBlockIds.indexOf(id);
            const buildingBlockIds = prevState.buildingBlockIds.delete(buildingBlockIdIndex);

            return {
                buildingBlockIds,
                customBlend: prevState.customBlend.delete(`${id}`),
            };
        }, this.handleFetchBuildingBlockConstraints);
    };

    /**
     * First checks if there are selected building blocks. Then checks to see if
     * combined building block % is less than or equal to 100. If passes both checks,
     * proceed to fetch constraints.
     */
    handleFetchBuildingBlockConstraints = () => {
        if (this.state.buildingBlockIds.isEmpty()) {
            return;
        }

        const customBlendArray = this.handleSerializeCustomBlend();

        if (this.validateBuildingBlockComposition() > 100) {
            return;
        }

        this.props.fetchCustomBlendConstraints(customBlendArray);
    };

    /**
     * Transforms the customBlend ImmutableMap into an array of objects which have id and
     * percentage keys.
     *
     * Example: [
     *  { id: 1, percent: 50 },
     *  { id: 2, percent: 25 }
     * ]
     */
    handleSerializeCustomBlend = () =>
        this.state.customBlend
            // entrySeq returns an array of arrays. Each subarray contains two elements,
            // [KEY, PROPERTIES] of the ImmutableMap it is called upon.
            .entrySeq()
            // We then want to flatten this 2D array into an array of objects where each object has
            // an id and percentage key.
            .reduce(
                (list: [], [key, value]: [string, ImmutableMap]) =>
                    list.push({
                        id: Number(key),
                        percentage: Number(value.get('percent')),
                    }),
                fromJS([]) // This is our initial array structure we will use to push the objects into.
            )
            .toJS();

    /**
     * Toggles the visibility of the building block picker and the add a building block button.
     * If button is visible, hide picker. If picker is visible, hide button.
     */
    handleToggleBuildingBlockPicker = () =>
        this.setState((prevState: State) => ({
            showAddBuildingBlockButton: !prevState.showAddBuildingBlockButton,
            showBuildingBlockPicker: !prevState.showBuildingBlockPicker,
        }));

    /**
     * Groups all the  building blocks into primary and secondary groups. Then maps
     * each group into an array of React-Select option objects like: { value: ID, label: STRING }.
     * Saves the transformed arrays in component state.
     *
     * Note: some building blocks can be either a primary or secondary type. If so, add to both groups.
     * P = primary; S = secondary; PS = primary and secondary.
     */
    handleMapAllBuildingBlocksToSelectOptions = () => {
        const byTypes = (...filters) => (buildingBlock: BuildingBlockType) =>
            filters.includes(buildingBlock.get('primarySecondary'));

        const toSelectOption = (buildingBlock: BuildingBlockType) => ({
            value: buildingBlock.get('id'),
            label: buildingBlock.get('name'),
        });

        const primaryBuildingBlocks = this.props.allBuildingBlocks
            .filter(byTypes(PRIMARY_BUILDING_BLOCK_TYPE, PRIMARY_AND_SECONDARY_BUILDING_BLOCK_TYPE))
            .map(toSelectOption);

        const secondaryBuildingBlocks = this.props.allBuildingBlocks
            .filter(
                byTypes(SECONDARY_BUILDING_BLOCK_TYPE, PRIMARY_AND_SECONDARY_BUILDING_BLOCK_TYPE)
            )
            .map(toSelectOption);

        this.setState({
            allPrimaryBuildingBlockOptions: primaryBuildingBlocks,
            allSecondaryBuildingBlockOptions: secondaryBuildingBlocks,
        });
    };

    /**
     * Groups the recommended building blocks into primary and secondary groups. Then maps
     * each group into an array of React-Select option objects like: { value: ID, label: STRING }.
     * Saves the transformed arrays in component state.
     */
    handleMapRecommendedBuildingBlocksToSelectOptions = () => {
        const byType = (filter: string) => (buildingBlock: BuildingBlockType) =>
            buildingBlock.getIn(['pivot', 'primarySecondary']) === filter;

        const toSelectOption = (buildingBlock: BuildingBlockType) => ({
            value: buildingBlock.get('id'),
            label: buildingBlock.get('name'),
        });

        const primaryBuildingBlocks = this.props.recommendedBuildingBlocks
            .filter(byType(PRIMARY_BUILDING_BLOCK_TYPE))
            .map(toSelectOption);

        const secondaryBuildingBlocks = this.props.recommendedBuildingBlocks
            .filter(byType(SECONDARY_BUILDING_BLOCK_TYPE))
            .map(toSelectOption);

        this.setState({
            recommendedPrimaryBuildingBlockOptions: primaryBuildingBlocks,
            recommendedSecondaryBuildingBlockOptions: secondaryBuildingBlocks,
        });
    };

    /**
     * Handles the onChange event of the number input used to set the building block percent
     */
    handleAdjustBuildingBlockPercent = (id: number) => (event: InputEvent) => {
        event.persist();

        const { value } = event.target;

        if (value < 0 || value > 100) {
            return;
        }

        this.setState((prevState: State) => ({
            customBlend: prevState.customBlend.setIn([String(id), 'percent'], value),
        }));
    };

    /**
     * First transforms the ImmutableMap of this.state.customBlend into an array of building blocks.
     * Each building block object has its own customized profile including its ID and user set %.
     * In addition, this method passes the customBlendArray to the onAddCustomBlend and closes
     * the modal via createModalClosingHandler.
     */
    handleAddCustomBlend = () => {
        const customBlendArray = this.getReducedCustomBlendArray();
        this.props.onAddCustomBlend(this.props.projectId, { composition: customBlendArray });
        this.props.createModalClosingHandler();
    };

    /**
     * Converts the Immutable Map of custom blends (via reduce) into an array of building blocks
     * Ex. [{ id: 1, name: 'AEROFLOAT', percent: '25}]
     */
    getReducedCustomBlendArray = () =>
        this.state.customBlend.reduce(
            (customBlendArray: [], buildingBlock: ProductCustomBlendType) => {
                customBlendArray.push({
                    id: buildingBlock.get('id'),
                    name: buildingBlock.get('name'),
                    percent: buildingBlock.get('percent'),
                });

                return customBlendArray;
            },
            [] // Starting initial array
        );

    /**
     * Validates that the total composition of added building blocks does not exceed 100%.
     */
    validateBuildingBlockComposition = () => {
        const totalPercent = this.state.customBlend.reduce(
            (accumulator: number, nextValue: ImmutableMap) =>
                accumulator + Number(nextValue.get('percent')),
            0 // Starting value
        );

        return totalPercent;
    };

    /**
     * Renders the building block selection content. Depending on state renders the 'Add' primary
     * button or the ProductCustomBlendPicker component
     */
    renderBuildingBlockSelectionContent = () => {
        const {
            showBuildingBlockPicker,
            showAddBuildingBlockButton,
            recommendedPrimaryBuildingBlockOptions,
            recommendedSecondaryBuildingBlockOptions,
            allPrimaryBuildingBlockOptions,
            allSecondaryBuildingBlockOptions,
        } = this.state;

        let jsxSelectionContent;

        if (showAddBuildingBlockButton) {
            jsxSelectionContent = (
                <PrimaryButton
                    text={this.props.intl.formatMessage({
                        id: 'components.ProductCustomBlendModalContent.main.add',
                    })}
                    onClick={this.handleToggleBuildingBlockPicker}
                />
            );
        } else if (showBuildingBlockPicker) {
            jsxSelectionContent = (
                <ProductCustomBlendPicker
                    onAddClick={this.handleOnAddBuildingBlock}
                    onClose={this.handleToggleBuildingBlockPicker}
                    recommendedPrimaryBuildingBlockOptions={recommendedPrimaryBuildingBlockOptions}
                    recommendedSecondaryBuildingBlockOptions={
                        recommendedSecondaryBuildingBlockOptions
                    }
                    allPrimaryBuildingBlockOptions={allPrimaryBuildingBlockOptions}
                    allSecondaryBuildingBlockOptions={allSecondaryBuildingBlockOptions}
                />
            );
        }

        return jsxSelectionContent;
    };

    /**
     * Filters the selected building blocks from the list of all building blocks, then maps the
     * selected building block into a BuildingBlockItem with a NumberInput.
     */
    renderAddedBuildingBlocks = () =>
        this.props.allBuildingBlocks
            // Sorts alphabetically in desc order.
            // TODO: How do we maintain the order in which the building block was selected?
            .filter((buildingBlock: BuildingBlockType) =>
                this.state.buildingBlockIds.includes(buildingBlock.get('id'))
            )
            .map((buildingBlock: BuildingBlockType) => {
                const id = buildingBlock.get('id');

                return (
                    <BuildingBlockItemContainer key={id}>
                        <BuildingBlockItem
                            name={buildingBlock.get('name')}
                            type={buildingBlock.get('type')}
                            colors={fromJS(mapIndexToColor())}
                            onClickClose={this.createHandleOnRemoveBuildingBlock(id)}
                            inputType="close"
                            closeIconMarginRight="7px"
                            maxWidth="540px"
                            blendingConstraints={buildingBlock.get(
                                'readableConstraintsByBlendingStatus'
                            )}
                        />
                        <InputNumber
                            min={0}
                            max={100}
                            value={this.state.customBlend.getIn([String(id), 'percent'])}
                            onChange={this.handleAdjustBuildingBlockPercent(id)}
                            onBlur={this.handleFetchBuildingBlockConstraints}
                            unit="%"
                            minWidth="102px"
                            height="40px"
                            textAlign="center"
                            disabled={this.props.constraintsAreFetching}
                        />
                    </BuildingBlockItemContainer>
                );
            });

    /**
     * Renders the missing recommendations message if no building block is selected or renders
     * the building block constraints if 1 or more building block is selected.
     */
    renderBlendingRecommendations = () => {
        const { buildingBlockIds } = this.state;

        if (buildingBlockIds.isEmpty()) {
            return (
                <MissingRecommendationsMessage>
                    {this.props.intl.formatMessage({
                        id: 'components.ProductCustomBlendModalContent.sidepanel.noRecommendations',
                    })}
                </MissingRecommendationsMessage>
            );
        } else if (!this.props.constraintsAreFetching && this.props.customBlendProductExists) {
            return (
                <div>
                    <ProductExistsMessage>
                        {this.props.intl.formatMessage({
                            id: 'components.ProductCustomBlendModalContent.sidepanel.productExists',
                        })}
                    </ProductExistsMessage>
                    <ProductExistsInfo>
                        {this.props.intl.formatMessage({
                            id:
                                'components.ProductCustomBlendModalContent.sidepanel.productExistsInfo',
                        })}
                    </ProductExistsInfo>
                </div>
            );
        } else {
            return (
                <BuildingBlockBlendingConstraints
                    constraints={this.props.constraints}
                    constraintsAreFetching={this.props.constraintsAreFetching}
                    constraintsMessage={this.props.intl.formatMessage({
                        id: 'components.BuildingBlockBlendingConstraints.customBlend.constraint',
                    })}
                    noConstraintsMessage={this.props.intl.formatMessage({
                        id: 'components.BuildingBlockBlendingConstraints.customBlend.noConstraint',
                    })}
                    loaderAlign="center"
                    fontSize="16px"
                    hideBlendingNote
                />
            );
        }
    };

    render() {
        const { intl, createModalClosingHandler, constraints, constraintsAreFetching } = this.props;
        const totalPercentOverOneHundred = this.validateBuildingBlockComposition() > 100;
        const disableAddButton =
            this.state.buildingBlockIds.isEmpty() ||
            totalPercentOverOneHundred ||
            constraintsAreFetching ||
            this.props.customBlendProductExists ||
            constraints.find((constraint: ConstraintType) => !constraint.get('blendable'));

        return (
            <React.Fragment>
                <MainContainer>
                    {/* Building block selection (left) */}
                    <SelectionContainer>
                        <CustomBlendHeader>
                            {intl.formatMessage({
                                id: 'components.ProductCustomBlendModalContent.main.header',
                            })}
                        </CustomBlendHeader>
                        <Content>
                            {this.renderAddedBuildingBlocks()}
                            {totalPercentOverOneHundred && (
                                <ErrorMessage textAlign="right">
                                    {intl.formatMessage({
                                        id:
                                            'components.ProductCustomBlendModalContent.main.percentValidation',
                                    })}
                                </ErrorMessage>
                            )}
                            {this.renderBuildingBlockSelectionContent()}
                        </Content>
                    </SelectionContainer>

                    {/* Building block recommendation sidepanel (right) */}
                    <RecommendationsContainer>
                        <BlendingRecommendationsHeader>
                            {intl.formatMessage({
                                id: 'components.ProductCustomBlendModalContent.sidepanel.header',
                            })}
                        </BlendingRecommendationsHeader>
                        <Content>{this.renderBlendingRecommendations()}</Content>
                    </RecommendationsContainer>
                </MainContainer>

                {/* Footer */}
                <FooterContainer>
                    <FooterText>
                        {intl.formatMessage({
                            id: 'components.ProductCustomBlendModalContent.footer.finePrint',
                        })}
                    </FooterText>

                    <ButtonWrapper gutter="12px">
                        <SecondaryButton
                            text={intl.formatMessage({
                                id: 'components.ProductCustomBlendModalContent.footer.cancel',
                            })}
                            onClick={createModalClosingHandler}
                        />
                        <PrimaryButton
                            text={intl.formatMessage({
                                id: 'components.ProductCustomBlendModalContent.footer.add',
                            })}
                            onClick={this.handleAddCustomBlend}
                            disabled={disableAddButton}
                        />
                    </ButtonWrapper>
                </FooterContainer>
            </React.Fragment>
        );
    }
}

export default injectIntl(ProductCustomBlendModalContent);
