// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const ActionButton = styled.button`
    padding: 0;

    background-color: transparent;
    border: 0 none;
    color: ${(props) =>
        props.warning ? LegacyTheme.defaultWarningColor : LegacyTheme.defaultColor};

    font-size: 12px;

    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

export const ActionButtonContainer = styled.div`
    display: block;
    padding: 10px;

    text-align: left;

    ${ActionButton} {
        display: block;
        margin: 20px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const ProjectTableContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const ProductTableTitle = styled.h2`
    color: ${LegacyTheme.tableSubtleTitleColor};

    font-size: 16px;
    font-weight: normal;
`;
export const ProjectTableProductControls = styled.div`
    display: flex;
    justify-content: space-between;
    min-width: 150px;
`;

export const ProductName = styled.div`
    > span {
        margin-right: 6px;
        margin-top: 2px;
    }

    > div,
    > span {
        display: inline-block;
        vertical-align: middle;
    }
`;

export const StaticProductCount = styled.span`
    line-height: 26px;
`;

export const ProjectTypeInputContainer = styled.div`
    min-width: 120px;
`;
