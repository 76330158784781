// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { camelize } from 'humps';

// Selectors
import { selectDisclaimers } from 'management/services/Disclaimer/selectors';

// Thunks
import { fetchDisclaimers } from 'management/services/Disclaimer/thunks';

// Types
import type { ImmutableList, ReactNode, ReduxDispatch } from 'types';
import type { ImmutableUserType } from 'management/services/Authentication/types';
import type { DisclaimerType } from 'management/services/Disclaimer/types';

type ChildrenType = Array<ReactNode> | ReactNode;
type Props = {
    children: ?ChildrenType,
    disclaimers: ImmutableList<DisclaimerType>,
    fetchDisclaimers: () => void,
    user: ?UserType,
};

/**
 * UserIsReady can be used as a "middleware" to fetch disclaimers and ensure a user has accepted all disclaimers
 * Returns it's children with prop.userIsReady true if all disclaimers are accepted
 */
class UserIsReady extends React.PureComponent<Props> {
    componentDidMount() {
        this.props.fetchDisclaimers();
    }

    // Clone children in order to pass prop.userIsReady based on user's acceptance of the disclaimers
    renderChildren = (children: ChildrenType) =>
        React.Children.map(children, (child: ReactNode) => {
            // Return early if provide child is falsey
            if (!child) {
                return null;
            }

            const disclaimerKeys =
                this.props.disclaimers &&
                this.props.disclaimers
                    .map((disclaimer: DisclaimerType) => camelize(disclaimer.get('key')))
                    .toJS();

            let userIsReady = true;

            // Loop over disclaimers and ensure user has accepted each of them.
            for (const disclaimer of disclaimerKeys) {
                if (this.props.user && !this.props.user.get(disclaimer)) {
                    userIsReady = false;
                    break;
                }
            }

            // Clone children to pass userIsReady prop down
            return React.cloneElement(child, {
                userIsReady,
            });
        });

    render() {
        if (!this.props.children) {
            return null;
        }

        return this.renderChildren(this.props.children);
    }
}

const mapStateToProps = createStructuredSelector({
    disclaimers: selectDisclaimers(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchDisclaimers,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UserIsReady)
);
