// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { GraphRadar } from 'OsedeaReactUI';

// Types
import type { BuildingBlockType } from 'services/Project/types';
import type { ImmutableList, IntlType } from 'types';

// Utils
import { calculateCompValueForGraphByProjectType } from 'utils/helpers';

// Constants
import {
    FROTHER_BUILDING_BLOCK_COMPOSITION,
    COLLECTOR_BUILDING_BLOCK_COMPOSITION,
    PROJECT_TYPE,
} from 'utils/constants';

// Styles
import { GraphContainer, FrotherLegendList } from './styles';

type Props = {
    projectType: string,
    formulas: ImmutableMap,
    selectedFormulasId: number[],
    intl: IntlType,
};

type State = {};

/**
 * Display properties with their values from a composition list to a Radar graph.
 * This graph will show the graphical signification of any formula.
 *
 * Formula is currently a Collector/Frother Building Block or a Frother Blend.
 * Collector/Frother products are to be supported soon.
 */
class FormulaCompositionGraph extends React.PureComponent<Props, State> {
    provideTranslatedAxisLabel = (intlId: string) => this.props.intl.formatMessage({ id: intlId });

    /**
     * Get the corresponding project Composition for the GraphRadar depending on the project type
     */
    getProjectCompositionList = () => {
        const { projectType } = this.props;
        if (projectType) {
            switch (projectType) {
                case PROJECT_TYPE.COLLECTOR:
                    return COLLECTOR_BUILDING_BLOCK_COMPOSITION;
                case PROJECT_TYPE.FROTHER:
                    return FROTHER_BUILDING_BLOCK_COMPOSITION;
            }
        } else {
            return [];
        }
    };

    /**
     * Get the scaling of the graph radar depending on the projectType
     * For example, frothers are scaled from -100 to 100.  This and calculateCompValueForGraphByProjectType function converts to 0 to 200.
     */
    getGraphRadarMaxValueByProjectType = (projectType: string) => {
        switch (projectType) {
            case PROJECT_TYPE.COLLECTOR:
                return 100;
            case PROJECT_TYPE.FROTHER:
                return 200;
            default:
                return 100;
        }
    };

    formatFormulaDataForGraph = (index: number, formula: any, compositionList: any[]) => {
        const data = compositionList.reduce((obj: {}, comp: {}) => {
            // Calculate the composition value depending on the project type
            const compValue = calculateCompValueForGraphByProjectType(
                formula.getIn(['pivot', comp.key]),
                this.props.projectType
            );
            /* eslint-disable-next-line no-param-reassign */
            obj[this.provideTranslatedAxisLabel(comp.intlId)] = compValue || 0;
            return obj;
        }, {});
        return (
            formula && {
                id: index,
                name: formula.get('name'),
                color: formula.getIn(['colors', 'accent']),
                data,
            }
        );
    };

    renderFrotherLegend = () => {
        return (
            <FrotherLegendList>
                <li>
                    <b>
                        {this.provideTranslatedAxisLabel(
                            'components.FormulaCompositionGraph.mobilityTitle'
                        )}{' '}
                        :{' '}
                    </b>
                    {this.provideTranslatedAxisLabel(
                        'components.FormulaCompositionGraph.mobilityDescription'
                    )}
                </li>
                <li>
                    <b>
                        {this.provideTranslatedAxisLabel(
                            'components.FormulaCompositionGraph.selectivityTitle'
                        )}{' '}
                        :{' '}
                    </b>
                    {this.provideTranslatedAxisLabel(
                        'components.FormulaCompositionGraph.selectivityDescription'
                    )}
                </li>
                <li>
                    <b>
                        {this.provideTranslatedAxisLabel(
                            'components.FormulaCompositionGraph.stabilityTitle'
                        )}{' '}
                        :{' '}
                    </b>
                    {this.provideTranslatedAxisLabel(
                        'components.FormulaCompositionGraph.stabilityDescription'
                    )}
                </li>
                <li>
                    <b>
                        {this.provideTranslatedAxisLabel(
                            'components.FormulaCompositionGraph.persistenceTitle'
                        )}{' '}
                        :{' '}
                    </b>
                    {this.provideTranslatedAxisLabel(
                        'components.FormulaCompositionGraph.persistenceDescription'
                    )}
                </li>
                <li>
                    <b>
                        {this.provideTranslatedAxisLabel(
                            'components.FormulaCompositionGraph.bubbleSizeTitle'
                        )}{' '}
                        :{' '}
                    </b>
                    {this.provideTranslatedAxisLabel(
                        'components.FormulaCompositionGraph.bubbleSizeDescription'
                    )}
                </li>
                <li>
                    <b>
                        {this.provideTranslatedAxisLabel(
                            'components.FormulaCompositionGraph.dosageEfficiencyTitle'
                        )}{' '}
                        :{' '}
                    </b>
                    {this.provideTranslatedAxisLabel(
                        'components.FormulaCompositionGraph.dosageEfficiencyDescription'
                    )}
                </li>
            </FrotherLegendList>
        );
    };

    renderGraphRadar = () => {
        const { projectType, formulas } = this.props;
        const compositionList = this.getProjectCompositionList();

        // Get the max value (scalling) of the graph
        const maxValue = this.getGraphRadarMaxValueByProjectType(projectType);
        let graphData: ImmutableMap = formulas
            .filter((formula: {}) => {
                return this.props.selectedFormulasId.includes(formula.get('id'));
            })
            .map(
                (formula: {}, i: number) =>
                    this.formatFormulaDataForGraph(i, formula, compositionList),
                []
            );

        if (graphData.size) {
            graphData = graphData.toJS();
        }

        const axisLabels = compositionList.reduce((arr: [], comp: {}) => {
            /* eslint-disable-next-line no-param-reassign */
            arr = [...arr, this.provideTranslatedAxisLabel(comp.intlId)];
            return arr;
        }, []);

        return (
            <GraphContainer>
                <GraphRadar axisLabels={axisLabels} dataSets={graphData} maxValue={maxValue} />
                {this.props.projectType === PROJECT_TYPE.FROTHER && this.renderFrotherLegend()}
            </GraphContainer>
        );
    };

    render() {
        return this.renderGraphRadar();
    }
}

export default injectIntl(FormulaCompositionGraph);
