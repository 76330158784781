// @flow

import styled from 'styled-components';

export const SurveyWrapper = styled.div`
    display: flex;
    flex: 1;

    flex-direction: column;
`;
