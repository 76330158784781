// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Components
import { Centered, Loader } from 'OsedeaReactUI';
import DisclaimerManagement from 'management/components/DisclaimerManagement';

// Services
import {
    selectDisclaimers,
    selectDisclaimersAreFetching,
} from 'management/services/Disclaimer/selectors';
import { fetchDisclaimers, updateDisclaimer } from 'management/services/Disclaimer/thunks';

// Types
import type { ImmutableList, IntlType, ReduxDispatch } from 'types';
import type { DisclaimerType } from 'management/services/Disclaimer/types';

// Utils
import { formatDisclaimers } from 'management/utils';

type Props = {
    disclaimers: ImmutableList<DisclaimerType>,
    listIsFetching: boolean,
    fetchDisclaimers: () => void,
    intl: IntlType,
    updateDisclaimer: (number, DisclaimerType) => void,
};

export class Disclaimers extends React.PureComponent<Props> {
    componentDidMount() {
        if (this.props.disclaimers.isEmpty()) {
            this.props.fetchDisclaimers();
        }
    }

    handleOnSave = (id: number, disclaimer: {}) => this.props.updateDisclaimer(id, disclaimer);

    renderContent = () => {
        if (this.props.listIsFetching) {
            return (
                <Centered>
                    <Loader loading />
                </Centered>
            );
        }

        // TODO: Build out no disclaimer view
        if (this.props.disclaimers.isEmpty()) {
            return <Centered>No disclaimers found</Centered>;
        }

        return (
            <DisclaimerManagement
                actionButtonText={this.props.intl.formatMessage({
                    id: 'management.views.Disclaimers.actionButtonText',
                })}
                disclaimers={formatDisclaimers(this.props.disclaimers.toJS(), this.props.intl)}
                onSave={this.handleOnSave}
            />
        );
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'management.views.Disclaimers.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'management.views.Disclaimers.helmetDescription',
                        })}
                    />
                </Helmet>
                {this.renderContent()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    disclaimers: selectDisclaimers(),
    listIsFetching: selectDisclaimersAreFetching(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchDisclaimers,
            updateDisclaimer,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(Disclaimers))
);
