// @flow

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';

import rootReducer from './reducers';

const configureStore = (initialState = {}, history) => {
    // Add support for Redux dev tools
    let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    if (__PROD__) {
        composeEnhancers = compose;
    }
    // List middleware here
    const middlewares = [routerMiddleware(history), thunk];

    const store = createStore(
        rootReducer,
        fromJS(initialState),
        composeEnhancers(applyMiddleware(...middlewares))
    );

    return store;
};

export default configureStore;
