// @flow

import { createSelector } from 'reselect';
import type { ImmutableUserState } from 'management/services/User/types';

/**
 * Direct selector to the auth state domain
 *
 * TODO: Replace Object type for proper Redux Store Type
 */
export const selectUserState = () => (state: Object) => state.get('users');

/**
 * Select list of users
 */
export const selectUsers = () =>
    createSelector(
        selectUserState(),
        (substate: ImmutableUserState) => substate.get('allUsers')
    );

/**
 * Select users are fetching state
 */
export const selectUsersAreFetching = () =>
    createSelector(
        selectUserState(),
        (substate: ImmutableUserState) => substate.get('usersAreFetching')
    );

/**
 * Select list of users
 */
export const selectQueriedUsers = () =>
    createSelector(
        selectUserState(),
        (substate: ImmutableUserState) => substate.get('queriedUsers')
    );

/**
 * Select users are querying state
 */
export const selectUsersAreQuerying = () =>
    createSelector(
        selectUserState(),
        (substate: ImmutableUserState) => substate.get('usersAreQuerying')
    );

/**
 * Select list of users
 */
export const selectAllRoles = () =>
    createSelector(
        selectUserState(),
        (substate: ImmutableUserState) => substate.get('roles')
    );

/**
 * Select list of users by role
 */
export const selectAllUsersByRole = () =>
    createSelector(
        selectUserState(),
        (substate: ImmutableUserState) => substate.get('usersByRole')
    );

/**
 * Select user's total count
 */
export const selectTotalCount = () =>
    createSelector(
        selectUserState(),
        (substate: ImmutableUserState) => substate.get('totalCount')
    );

/**
 * Select user's total page count
 */
export const selectTotalPageCount = () =>
    createSelector(
        selectUserState(),
        (substate: ImmutableUserState) => substate.get('lastPage')
    );

/**
 * Select user's search status
 */
export const selectWithSearch = () =>
    createSelector(
        selectUserState(),
        (substate: ImmutableUserState) => substate.get('withSearch')
    );

/*
 * Gets isDeleting status while user is being deleted: to hide list or show loading animation
 */
export const selectUserIsDeleting = () =>
    createSelector(
        selectUserState(),
        (substate: ImmutableUserState) => substate.get('userIsDeleting')
    );

/*
 * Gets isUpdating status while user is being updated: to hide list or show loading animation
 */
export const selectUserIsUpdating = () =>
    createSelector(
        selectUserState(),
        (substate: ImmutableUserState) => substate.get('userIsUpdating')
    );

export const selectErrors = () =>
    createSelector(
        selectUserState(),
        (substate: ImmutableUserState) => substate.get('errors')
    );
