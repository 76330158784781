// @flow

import { createSelector } from 'reselect';

import type { ImmutableAuthenticationState } from './types';

/**
 * Direct selector to the auth state domain
 *
 * TODO: Replace Object type for proper Redux Store Type
 */
export const selectAuthState = () => (state: Object) => state.get('auth');

/**
 * Select the auth state
 */

export const selectUser = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('user')
    );

export const selectUserEmail = () =>
    createSelector(
        selectUser(),
        (substate: ImmutableAuthenticationState) => substate && substate.get('email')
    );

export const selectUserIsFetching = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('userIsFetching')
    );

export const selectPasswordResetMessage = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('passwordResetMessage')
    );

export const selectAcceptDisclaimerSaving = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('acceptDisclaimerSaving')
    );

export const selectUserSettingsIsUpdating = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('isUpdatingSettings')
    );

export const selectErrors = () =>
    createSelector(
        selectAuthState(),
        (substate: ImmutableAuthenticationState) => substate.get('errors')
    );
