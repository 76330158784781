// @flow

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    html,
    body {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        h1, h2, h3, h4, h5, h6 {
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    textarea,
    input,
    button,
    select,
    body.fontLoaded {
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    #app {
        min-height: 100%;
        min-width: 100%;
    }

    // Firebase Style Overwrite
    #firebaseui_container {
        width: 100%;
    }
`;

export default GlobalStyle;
