// @flow

import React from 'react';

// Component
import BuildingBlockItem from 'components/BuildingBlockItem';

// Styles
import { Container } from './styles';

// Types
import type { ImmutableList } from 'types';
import type { BuildingBlockType } from 'services/Project/types';

type Props = {
    buildingBlocks?: ImmutableList<BuildingBlockType>,
    fontSize: ?string,
    hideDropdown?: boolean,
    hideType: ?boolean,
    inputType: string,
    onClickCheckBox: ?(number) => () => void,
    onClickClose?: ?(number) => () => void,
    selectedBuildingBlockIds: ImmutableList<number>,
};

class BuildingBlockList extends React.PureComponent<Props> {
    static defaultProps = {
        buildingBlocks: null,
        hideDropdown: false,
        onClickClose: null,
    };

    render() {
        const {
            buildingBlocks,
            selectedBuildingBlockIds,
            onClickCheckBox,
            onClickClose,
            inputType,
            hideType,
            hideDropdown,
            fontSize,
        } = this.props;

        return (
            buildingBlocks && (
                <Container>
                    {buildingBlocks.map((block: BuildingBlockType) => {
                        const selected = selectedBuildingBlockIds.indexOf(block.get('id')) >= 0;
                        return (
                            <BuildingBlockItem
                                key={`${block.get('id')}-${block.getIn([
                                    'pivot',
                                    'primarySecondary',
                                ])}`}
                                blendingConstraints={block.get(
                                    'readableConstraintsByBlendingStatus'
                                )}
                                colors={block.get('colors')}
                                disabled={selectedBuildingBlockIds.size === 3 && !selected}
                                fontSize={fontSize}
                                hideDropdown={hideDropdown}
                                hideType={hideType}
                                inputType={inputType}
                                name={block.get('name')}
                                onClickCheckBox={
                                    onClickCheckBox ? onClickCheckBox(block.get('id')) : null
                                }
                                onClickClose={onClickClose ? onClickClose(block.get('id')) : null}
                                selected={selected}
                                type={block.get('type')}
                            />
                        );
                    })}
                </Container>
            )
        );
    }
}

export default BuildingBlockList;
