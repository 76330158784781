// @flow

/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { addLocaleData } from 'react-intl';
import flatten from 'flat';

import { DEFAULT_LOCALE } from 'services/Language/actions';

import enLocaleData from 'react-intl/locale-data/en';

import enApplicationTranslationMessages from './translations/en.json';
import enManagementTranslationMessages from 'management/translations/en.json';
// Merge both application & management translations together
const enTranslationMessages = {
    ...flatten(enManagementTranslationMessages),
    ...flatten(enApplicationTranslationMessages),
};

export const appLocales = ['en'];

addLocaleData(enLocaleData);

// eslint-disable-next-line flowtype/no-weak-types
export const formatTranslationMessages = (locale: string, messages: Object) => {
    const defaultFormattedMessages =
        locale !== DEFAULT_LOCALE
            ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
            : {};
    const formattedMessages = {};
    const messageKeys = Object.keys(messages);

    for (const messageKey of messageKeys) {
        if (locale === DEFAULT_LOCALE) {
            formattedMessages[messageKey] = messages[messageKey];
        } else {
            formattedMessages[messageKey] =
                messages[messageKey] || defaultFormattedMessages[messageKey];
        }
    }

    return formattedMessages;
};

export const translationMessages = {
    en: formatTranslationMessages('en', enTranslationMessages),
};
