// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the result state domain
 */
export const selectResultState = () => (state) => state.get('results');

/**
 * Select result
 */
export const selectResult = () =>
    createSelector(selectResultState(), (substate: any) => substate.get('list'));

/**
 * Select single Result is fetching state
 */
export const selectResultIsFetching = () =>
    createSelector(selectResultState(), (substate: any) => substate.get('resultIsFetching'));

/**
 * Select updated Result
 */
export const selectUpdatedResult = () =>
    createSelector(selectResultState(), (substate: any) => substate.get('updatedResult'));

/**
 * Select single Result is submitting state
 */
export const selectResultIsSubmitting = () =>
    createSelector(selectResultState(), (substate: any) => substate.get('resultIsSubmitting'));

/**
 * Select lab result option list
 */
export const selectLabResultOptions = () =>
    createSelector(selectResultState(), (substate: any) => substate.get('labResultOptionList'));

/**
 * Select plant trial result option list
 */
export const selectPlantTrialResultOptions = () =>
    createSelector(selectResultState(), (substate: any) =>
        substate.get('plantTrialResultOptionList')
    );
