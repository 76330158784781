// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import { Loader, SidebarLayout, Common } from 'OsedeaReactUI';
import ProductSidebar from 'components/ProductSidebar';
import ProductTable from 'components/ProductTable';
import FormulaCompositionGraph from 'components/FormulaCompositionGraph';

// Selectors
import {
    selectSingleProject,
    selectCustomBlendIsSubmitting,
    selectLatestCreatedCustomBlendName,
    selectProjectErrors,
    selectProductsAddedCount,
} from 'services/Project/selectors';
import {
    selectBuildingBlockByProjectType,
    selectBuildingBlocksListIsFetching,
    selectBuildingBlocksConstraints,
    selectBuildingBlocksConstraintsAreFetching,
    selectBuildingBlocksProductExists,
} from 'services/BuildingBlock/selectors';
import {
    selectProductsContainingBuildingBlocks,
    selectProductTableListIsFetching,
} from 'services/Product/selectors';

// Styles
import { LoaderWrapper, ColumnContainer, ColumnBody, ContentContainer } from 'styles/common';
import { ScrollableTable, ProductFrotherGraphContainer } from './styles';

// Thunks
import {
    fetchBuildingBlocksByProjectType,
    fetchCustomBlendConstraints,
} from 'services/BuildingBlock/thunks';
import {
    fetchProductsContainingBuildingBlocks,
    clearProductTableList,
} from 'services/Product/thunks';
import {
    createCustomBlend,
    fetchProject,
    resetCustomBlendState,
    submitProductsToDiscovery,
} from 'services/Project/thunks';

// Constants
import { PROJECT_TYPE } from 'utils/constants';

// Types
import type { IntlType, ImmutableList } from 'types';
import type { ProductType } from 'services/Product/types';
import type { ProjectType, CustomBlendType } from 'services/Project/types';
import type { BuildingBlockType, ConstraintType } from 'services/BuildingBlock/types';

// Utils
import { mapIndexToColor } from 'utils/helpers';

type Props = {
    buildingBlocks?: ImmutableList<BuildingBlockType>,
    buildingBlocksIsFetching: boolean,
    products: ImmutableList<ProductType>,
    isFetchingProducts: boolean,
    fetchProductsContainingBuildingBlocks: (Array<number>, string) => void,
    fetchCustomBlendConstraints: (Array<number>) => void,
    constraints?: ImmutableList<ConstraintType>,
    constraintsAreFetching: boolean,
    createCustomBlend: (number, CustomBlendType) => void,
    customBlendIsSubmitting: boolean,
    customBlendName: ?string,
    customBlendProductExists: ?boolean,
    fetchBuildingBlocksByProjectType: (projectType: string) => void,
    clearProductTableList: () => void,
    fetchProject: (number, Array<string>) => void,
    intl: IntlType,
    match?: {
        params: {
            projectId: ?string,
        },
    },
    productsAddedCount: ?number,
    project?: ProjectType,
    projectErrors: {
        message?: string,
        status?: number,
    },
    resetCustomBlendState: () => void,
    submitProductsToDiscovery: (number, Array<number>) => void,
};

type State = {
    selectedBuildingBlockIds: ImmutableList<number>,
    selectedProductIds: ImmutableList<number>,
};

/*
*   Products view
*
*   Overview:
*   Users can create custom blend products by selecting recommended building blocks or searching
*   for building blocks and adding them manually into the table.
*/
export class Products extends React.Component<Props, State> {
    static defaultProps = {
        buildingBlocks: fromJS([]),
        constraints: fromJS([]),
        products: fromJS([]),
        match: {
            params: {
                projectId: null,
            },
        },
        project: null,
    };

    state = {
        // Building blocks selected from ProductsSidebar used to find products whose composition
        // contains the building block.
        selectedBuildingBlockIds: fromJS([]),
        // Products which have been selected to be added to the discovery agreement.
        selectedProductIds: fromJS([]),
    };

    componentDidMount() {
        const projectId = Number(this.props.match.params.projectId);
        if (projectId) {
            this.props
                .fetchProject(projectId, ['buildingBlocks', 'frotherBlends', 'products'])
                .then((projectData) => {
                    const projectType = this.props.project.get('projectType');
                    /* eslint-disable-next-line promise/no-nesting */
                    this.props
                        .fetchBuildingBlocksByProjectType(projectType)
                        .then((buildingBlockData) => {
                            // With a frother project, load products for every building blocks at the beginning.
                            if (projectType === PROJECT_TYPE.FROTHER) {
                                const allIds = Array.from(
                                    this.props.buildingBlocks.map((block) => block.get('id'))
                                );
                                this.props.fetchProductsContainingBuildingBlocks(
                                    allIds,
                                    projectType
                                );
                                // Set the selected blocks Ids
                                this.setState({
                                    selectedBuildingBlockIds: allIds,
                                });
                            }
                        });
                });
        }
    }

    componentWillUnmount() {
        // Clear the products
        this.props.clearProductTableList();
    }

    /**
     * Returns array of building blocks whose ID is found in the list of selected building block ids in state
     */
    getSelectedBuildingBlocks = (idsOnly: boolean = false) => {
        const selectedBuildingBlocks = this.props.buildingBlocks.filter(
            (buildingBlock: BuildingBlockType) =>
                this.state.selectedBuildingBlockIds.includes(buildingBlock.get('id'))
        );

        if (idsOnly) {
            return selectedBuildingBlocks.reduce(
                (ids: [], buildingBlock: BuildingBlockType) => {
                    ids.push(buildingBlock.get('id'));
                    return ids;
                },
                [] // Starting initial array
            );
        }

        return selectedBuildingBlocks;
    };

    /**
     * Handles the AJAX request to fetch products whose composition includes the selected building block ids.
     */
    handleFetchProductsContainingBuildingBlocks = () => {
        const { selectedBuildingBlockIds } = this.state;

        // To prevent unnecessary AJAX requests, only fetch products if the selected building
        // block ids count is greater than 0.
        if (selectedBuildingBlockIds.size) {
            this.props.fetchProductsContainingBuildingBlocks(
                selectedBuildingBlockIds.toJS(),
                this.props.project.get('projectType')
            );
        }
    };

    /**
     * Handles the selection of a building block to be added to the list of selected building
     * blocks to be shown in the products table.
     */
    handleOnBuildingBlockSelect = (id: number) => () => {
        this.setState(
            this.handleIdSelectionUpdater('selectedBuildingBlockIds', id),
            this.handleFetchProductsContainingBuildingBlocks
        );
    };

    /**
     * Handles the selection of a building block ID to be added or removed from the list used for
     * the discovery agreement phase.
     */
    createOnProductSelectHandler = (id: number) => () => {
        this.setState(this.handleIdSelectionUpdater('selectedProductIds', id));
    };

    /**
     * Helper setState updater function for adding and removing ids from a list.
     * @param stateProp is the list property we are trying to manipulate in state
     * @param id is the id we are trying to add or remove from the state property above.
     */
    handleIdSelectionUpdater = (stateProp: string, id: number) => (prevState: State) => {
        const currentList = this.state[stateProp];
        const previousList = prevState[stateProp];
        const isItemAlreadySelected = currentList.includes(id);

        let updatedList;

        if (isItemAlreadySelected) {
            const idIndex = currentList.indexOf(id);
            updatedList = previousList.delete(idIndex);
        } else {
            updatedList = previousList.push(id);
        }

        return {
            [stateProp]: updatedList,
        };
    };

    /**
     * Submits the list of selected product ids to the project (via ID) to be added to the
     * projects list of products for discovery.
     */
    handleSubmitProductsToDiscovery = () => {
        if (this.state.selectedProductIds.isEmpty()) {
            return;
        }

        this.props.submitProductsToDiscovery(
            this.props.project.get('id'),
            this.state.selectedProductIds.toJS()
        );
    };

    /**
     * Return recommended building blocks with colors base on selected building block index
     * Building blocks differs from a projectType to another.
     *
     */
    providedRecommendedBuildingBlocks = (): BuildingBlockType[] => {
        const { project } = this.props;
        let buildingBlocks: BuildingBlockType[] = [];

        switch (project.get('projectType')) {
            case PROJECT_TYPE.COLLECTOR:
                // For the Collectors, we pass recommended building blocks
                buildingBlocks = project.get('buildingBlocks') || [];
                break;

            case PROJECT_TYPE.FROTHER:
                // For the Frothers, we pass every available building Blocks .
                buildingBlocks = this.props.buildingBlocks || [];
                break;
        }
        return buildingBlocks.map((buildingBlock: BuildingBlockType) => {
            const selectedBuildingBlockIndex = this.state.selectedBuildingBlockIds.findIndex(
                (id: number) => id === buildingBlock.get('id')
            );
            return buildingBlock.set('colors', fromJS(mapIndexToColor(selectedBuildingBlockIndex)));
        });
    };

    /**
     * Return products with colors base on selected products index
     *
     */
    colorSelectedProducts = () => {
        const { products } = this.props;

        const colorProducts = products;
        return colorProducts.map((product: ImmutableMap<ProductType>) => {
            const selectedProductIndex = this.state.selectedProductIds.findIndex(
                (id: number) => id === product.get('id')
            );
            return product.set('colors', fromJS(mapIndexToColor(selectedProductIndex)));
        });
    };

    renderProductRadarGraph = () => {
        const { project } = this.props;
        return (
            <FormulaCompositionGraph
                projectType={project.get('projectType')}
                formulas={this.colorSelectedProducts()}
                selectedFormulasId={this.state.selectedProductIds.toJS()}
            />
        );
    };

    /**
     *  Helper render props function for renderSidebar prop of the SidebarLayout component
     */
    renderSidebar = () => {
        const { project } = this.props;
        return (
            <ProductSidebar
                buildingBlocksIsFetching={this.props.buildingBlocksIsFetching}
                buildingBlocks={this.props.buildingBlocks}
                recommendedBuildingBlocks={this.providedRecommendedBuildingBlocks()}
                selectedBuildingBlockIds={this.state.selectedBuildingBlockIds}
                projectType={project.get('projectType')}
                onBuildingBlockSelect={this.handleOnBuildingBlockSelect}
                isSurveyFilled={Boolean(this.props.project.get('surveyFilled'))}
            />
        );
    };

    renderProductTable = () => {
        return (
            <ProductTable
                noSelectedBuildingBlocks={this.state.selectedBuildingBlockIds.size === 0}
                selectedBuildingBlocks={this.getSelectedBuildingBlocks()}
                selectedBuildingBlockIds={this.getSelectedBuildingBlocks(true)}
                products={this.props.products}
                isLoading={this.props.isFetchingProducts || this.props.buildingBlocksIsFetching}
                onProductSelect={this.createOnProductSelectHandler}
                onAddCustomBlend={this.props.createCustomBlend}
                recommendedBuildingBlocks={this.providedRecommendedBuildingBlocks()}
                allBuildingBlocks={this.props.buildingBlocks}
                fetchCustomBlendConstraints={this.props.fetchCustomBlendConstraints}
                constraints={this.props.constraints}
                constraintsAreFetching={this.props.constraintsAreFetching}
                onSubmitProductsToDiscovery={this.handleSubmitProductsToDiscovery}
                projectId={this.props.project.get('id')}
                customBlendIsSubmitting={this.props.customBlendIsSubmitting}
                customBlendName={this.props.customBlendName}
                customBlendProductExists={this.props.customBlendProductExists}
                projectErrors={this.props.projectErrors}
                resetCustomBlendState={this.props.resetCustomBlendState}
                selectedProductCount={this.state.selectedProductIds.size}
                productsAddedCount={this.props.productsAddedCount}
                projectProducts={this.props.project.get('products')}
                isOwner={this.props.project.get('isOwner')}
                projectType={this.props.project.get('projectType')}
            />
        );
    };

    renderFrotherMain = () => {
        // Rendering this function without sidebar.  Taking all the space with flex
        return (
            <ColumnContainer flex>
                <ColumnBody>
                    <ContentContainer padding={'24px'}>
                        <Common.Row flex="initial" gutter={24}>
                            <Common.Column alignItems="flex-start" flex={9}>
                                <ScrollableTable>{this.renderProductTable()}</ScrollableTable>
                            </Common.Column>
                            <Common.Column alignItems="flex-end" flex={4}>
                                <ProductFrotherGraphContainer>
                                    {this.renderProductRadarGraph()}
                                </ProductFrotherGraphContainer>
                            </Common.Column>
                        </Common.Row>
                    </ContentContainer>
                </ColumnBody>
            </ColumnContainer>
        );
    };

    renderCollectorMain = () => {
        return this.renderProductTable();
    };

    renderCollectorSidebarLayout = () => {
        return (
            <SidebarLayout
                mainStyles={{
                    height: '100%',
                }}
                renderMain={this.renderCollectorMain}
                renderSidebar={this.renderSidebar}
                sidebarWidth={'360px'}
                sidebarStyles={{
                    overflow: 'auto',
                }}
                flush
                mainFlush
            />
        );
    };

    /**
     *  Main JSX content that will be rendered for this component
     */
    render() {
        const { project } = this.props;
        let content = (
            <LoaderWrapper>
                <Loader loading />
            </LoaderWrapper>
        );

        // For a collector project, wait for building blocks
        if (
            project &&
            project.get('projectType') === PROJECT_TYPE.COLLECTOR &&
            this.props.buildingBlocks.size
        ) {
            content = this.renderCollectorSidebarLayout();
        }

        // For a frother project, wait for the products
        else if (
            project &&
            project.get('projectType') === PROJECT_TYPE.FROTHER &&
            this.props.products.size
        ) {
            content = this.renderFrotherMain();
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.Products.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.Products.helmetDescription',
                        })}
                    />
                </Helmet>
                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    project: selectSingleProject(),
    buildingBlocks: selectBuildingBlockByProjectType(),
    buildingBlocksIsFetching: selectBuildingBlocksListIsFetching(),
    products: selectProductsContainingBuildingBlocks(),
    isFetchingProducts: selectProductTableListIsFetching(),
    constraints: selectBuildingBlocksConstraints(),
    constraintsAreFetching: selectBuildingBlocksConstraintsAreFetching(),
    customBlendIsSubmitting: selectCustomBlendIsSubmitting(),
    customBlendName: selectLatestCreatedCustomBlendName(),
    customBlendProductExists: selectBuildingBlocksProductExists(),
    projectErrors: selectProjectErrors(),
    productsAddedCount: selectProductsAddedCount(),
});

const mapDispatchToProps = {
    createCustomBlend,
    fetchCustomBlendConstraints,
    fetchBuildingBlocksByProjectType,
    fetchProductsContainingBuildingBlocks,
    fetchProject,
    clearProductTableList,
    resetCustomBlendState,
    submitProductsToDiscovery,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(injectIntl(Products)));
