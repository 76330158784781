// @flow

import request from 'services/request';

import type { ResultType } from './types';

/**
 * Fetches results by project id
 *
 * @param {number} id
 */
export const show = (id: number) => request(`projects/${id}/results`, { method: 'GET' });

/*
* Update survey by project id and result id
*/
export const update = (projectId: number, resultId: number, result: ResultType) =>
    request(`projects/${projectId}/results/${resultId}`, {
        method: 'PUT',
        data: result.toJS(),
    });

/*
* get Lab Results options
*/
export const getLabResultOptions = () =>
    request(`lab-results`, {
        method: 'GET',
    });

/*
* get Plant Trial Results options
*/
export const getPlantTrialResultOptions = () =>
    request(`plant-trial-results`, {
        method: 'GET',
    });
