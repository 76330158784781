// @flow

// Constants
import { ROLES, PROJECT_TYPE, BUILDING_BLOCK_COMPOSITION_NONE_OPTION } from './constants';

// Components
import { LegacyTheme } from 'OsedeaReactUI';

// Styles
import { blockColors } from 'styles/blockColors';

// Types
import type { IntlType, ImmutableMap, SelectOptionType } from 'types';
import type { ProductOptionType, ProductType } from 'services/Product/types';
import type { SurveyQuestionnaireType, CollectorSurveyType } from 'services/Survey/types';
import type { UserType } from 'management/services/Authentication/types';
import type { FrotherBuildingBlock } from 'services/BuildingBlocks/types';
import { userHasRoleBySlug } from 'management/utils';

export function userIsAdmin(user: UserType) {
    return userHasRoleBySlug(user, ROLES.ADMIN);
}

export function parseJwt(token: string) {
    if (!token) {
        return null;
    }

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64));
}

export function getSurveyValidationErrors({
    survey,
    fields,
    arrayReduceFn,
    arrayReduceInitialValue,
    intl,
}: {}) {
    const errors = fields.reduce(arrayReduceFn(survey, intl), arrayReduceInitialValue);
    return errors;
}

export function mapSurveyQuestionnaireErrors(
    survey: ImmutableMap<SurveyQuestionnaireType>,
    intl: IntlType
) {
    return (errorObj: {}, field: SurveyQuestionnaireType) => {
        const questionKey = field.key;
        const currentValue = survey.get(questionKey);

        // If the answer to the question is not required, skip the verification
        if (field.required !== false) {
            const notWithinRange =
                currentValue && (currentValue < field.min || currentValue > field.max);
            const missingRequiredField = currentValue === null || currentValue === '';

            if (missingRequiredField) {
                /* eslint-disable-next-line no-param-reassign */
                errorObj[questionKey] = intl.formatMessage({
                    id: 'components.ProjectSurvey.questionnaire.requiredField',
                });
            }

            if (notWithinRange) {
                /* eslint-disable-next-line no-param-reassign */
                errorObj[questionKey] = intl.formatMessage(
                    {
                        id: 'components.ProjectSurvey.questionnaire.notInRange',
                    },
                    {
                        min: field.min,
                        max: field.max,
                    }
                );
            }

            if (Object.keys(errorObj).length > 0) {
                /* eslint-disable-next-line no-param-reassign */
                errorObj.questionnaireErrors = true;
            }
        }

        return errorObj;
    };
}

export function mapSurveyWeightingFactorsErrors(survey: ImmutableMap<SurveyQuestionnaireType>) {
    return (
        runningTotals: {
            primaryBuildingBlocksTotalPercent: number,
            secondaryBuildingBlocksTotalPercent: number,
        },
        weightingFactor: {
            group: string,
            key: string,
            max?: number,
            min?: number,
            text: string,
            value: string | number,
        }
    ) => {
        const isPrimaryBuildingBlock = weightingFactor.key.toLowerCase().includes('primary');
        const isSecondaryBuildingBlock = weightingFactor.key.toLowerCase().includes('secondary');

        if (isPrimaryBuildingBlock) {
            runningTotals.primaryBuildingBlocksTotalPercent =
                runningTotals.primaryBuildingBlocksTotalPercent +
                Number(survey.get(weightingFactor.key));
        } else if (isSecondaryBuildingBlock) {
            runningTotals.secondaryBuildingBlocksTotalPercent =
                runningTotals.secondaryBuildingBlocksTotalPercent +
                Number(survey.get(weightingFactor.key));
        }

        return runningTotals;
    };
}

export function mapProductOptionsToIds(productOptions: ImmutableMap<ProductOptionType>) {
    return productOptions.map((product: ProductOptionType) => product.value);
}

export function mapIndexToColor(index?: number) {
    const color = {};
    switch (index) {
        case 0:
            color.primary = blockColors.yellowFca;
            color.accent = blockColors.yellowFC;
            break;
        case 1:
            color.primary = blockColors.violetB1a;
            color.accent = blockColors.violetB1;
            break;
        case 2:
            color.primary = blockColors.blue84a;
            color.accent = blockColors.blue84;
            break;
        case 3:
            color.primary = blockColors.redE6a;
            color.accent = blockColors.redE6;
            break;
        case 4:
            color.primary = blockColors.green97a;
            color.accent = blockColors.green97;
            break;
        case 5:
            color.primary = blockColors.orangeFFa;
            color.accent = blockColors.orangeFF;
            break;
        default:
            color.primary = LegacyTheme.defaultBuildingBlockBackground;
            color.accent = LegacyTheme.defaultBuildingBlockBorder;
            color.divider = LegacyTheme.defaultBuildingBlockDivider;
    }
    return color;
}

export function getPrimaryBuildingBlocksTotals(survey: CollectorSurveyType) {
    if (!survey) {
        return null;
    }

    const primaryCuGrade = survey.get('primaryCuGrade');
    const primaryCuRecovery = survey.get('primaryCuRecovery');
    const primaryAuAgRecovery = survey.get('primaryAuAgRecovery');
    const primaryMolyRecovery = survey.get('primaryMolyRecovery');
    const primaryPenaltyElements = survey.get('primaryPenaltyElements');

    const primaryBuildingBlockTotal =
        Number(primaryCuGrade) +
        Number(primaryCuRecovery) +
        Number(primaryAuAgRecovery) +
        Number(primaryMolyRecovery) +
        Number(primaryPenaltyElements);

    return primaryBuildingBlockTotal;
}

export function getSecondaryBuildingBlocksTotals(survey: CollectorSurveyType) {
    if (!survey) {
        return null;
    }

    const secondaryCuGrade = survey.get('secondaryCuGrade');
    const secondaryCuRecovery = survey.get('secondaryCuRecovery');
    const secondaryAuAgRecovery = survey.get('secondaryAuAgRecovery');
    const secondaryMolyRecovery = survey.get('secondaryMolyRecovery');
    const secondaryPenaltyElements = survey.get('secondaryPenaltyElements');

    const secondaryBuildingBlockTotal =
        Number(secondaryCuGrade) +
        Number(secondaryCuRecovery) +
        Number(secondaryAuAgRecovery) +
        Number(secondaryMolyRecovery) +
        Number(secondaryPenaltyElements);

    return secondaryBuildingBlockTotal;
}

export function castSurveyNumberFieldToNumber(surveyFieldValue: string) {
    const value = Number(surveyFieldValue);
    return !isNaN(value) ? value : 0;
}

export function capitalizeWord(word: string) {
    if (word) {
        word = word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word;
}

// Function used for getting the the selected project type (SelectOptionType typing) in order to set this in a Select input
export function getSelectOptionForSelectList(
    optionValue: string,
    optionList: Array<SelectOptionType>
) {
    return optionList.find((option: SelectOptionType) => {
        return option.value === optionValue;
    });
}

/**
 * Transform a list of Frother building block to a select option list to fit in a Select field
 * @param {*} frotherBuildingBlock
 */
export function frotherBuildingBlockToSelectList(
    frotherBuildingBlock: ImmutableMap<FrotherBuildingBlock>
): SelectOptionType[] {
    const selectList = [];
    // Add a empty option with the BUILDING_BLOCK_COMPOSITION_NONE_OPTION value
    selectList.push({ value: BUILDING_BLOCK_COMPOSITION_NONE_OPTION, label: 'None' });

    frotherBuildingBlock.forEach((block: FrotherBuildingBlock) => {
        selectList.push({ value: block.get('id'), label: block.get('name') });
    });
    return selectList;
}

/**
 * Transform a list of Frother building block to a select option list to fit in a Select field
 * @param {*} frotherBuildingBlock
 */
export function frotherProductsToSelectList(frotherProducts: ProductType[]): SelectOptionType[] {
    return frotherProducts.map((block: ProductType) => {
        return { value: block.get('id'), label: block.get('name') };
    });
}

/**
 * Determine the name of the building block variable to use
 *
 * @param {*} projectType
 */
export function getBuildingBlockVarNameByProjectType(projectType: string) {
    switch (projectType) {
        case PROJECT_TYPE.COLLECTOR:
            return 'buildingBlocks';
        case PROJECT_TYPE.FROTHER:
            return 'filteredFrotherBlends';
        default:
            return '';
    }
}

export function frotherSurveySecondaryQuestions(frotherProducts: SelectOptionType[]) {
    return [
        {
            text: 'Do you know what is the frother?',
            type: 'boolean',
            key: 'knowTheFrother',
            value: 0,
            page: 'prior',
        },
        {
            text: 'Is the Frother Syensqo?',
            type: 'boolean',
            key: 'isFrotherSolvay',
            value: 0,
            page: 'prior',
        },
        {
            text: 'Select a Syensqo frother from drowndown:',
            type: 'select',
            key: 'frotherProductId',
            value: null,
            selectOptions: frotherProducts,
            page: 'prior',
        },
        {
            text: `Do you know the Frother's composition?`,
            type: 'boolean',
            key: 'knowFrotherComposition',
            value: 0,
            page: 'prior',
        },
    ];
}

/**
 * Calculate the composition value of a spider graph item depending on the projectType
 * For example, frother need a +100 value adjustment because values are between -100 and 100
 *
 * @param {*} compValue
 * @param {*} projectType
 */
export function calculateCompValueForGraphByProjectType(
    compValue: number | string,
    projectType: string
) {
    switch (projectType) {
        case PROJECT_TYPE.COLLECTOR:
            return Number(compValue);
        case PROJECT_TYPE.FROTHER:
            return Number(compValue) + 100;
        default:
            return Number(compValue);
    }
}
