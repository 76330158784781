// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const StyledUnorderedList = styled.ul`
    list-style-type: none;
    margin: 5px;
    padding-left: 0px;

    li:not(:first-child) {
        margin-top: 10px;
    }
`;

export const ProductInactive = styled.span`
    display: block;
    color: ${LegacyTheme.buttonGridLabelDanger};
    font-size: 11px;
    letter-spacing: 0.07px;
    margin-top: 5px;
`;

export const StyledListItem = styled.li``;

export const ExpandableDetailsButton = styled.div`
    cursor: pointer;
    color: ${LegacyTheme.defaultSubtleColor};
    font-family: Helvetica;
    font-size: 15px;
    text-align: right;
    min-width: 96px;
`;

export const DetailsToggleText = styled.span`
    margin-right: 6px;
`;

export const PlantTrialResultOptionsWrapper = styled.div`
    min-width: 175px;
`;
