// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

// Components
import { Common, SidebarLayout, Loader } from 'OsedeaReactUI';
import ResultTable from 'components/ResultTable';
import ResultFeedbackBlock from 'components/ResultFeedbackBlock';
import SurveyIncompleteContainer from 'components/SurveyIncompleteContainer';

// Selectors
import { selectSingleProject } from 'services/Project/selectors';
import {
    selectResult,
    selectResultIsFetching,
    selectResultIsSubmitting,
    selectLabResultOptions,
    selectPlantTrialResultOptions,
    selectUpdatedResult,
} from 'services/Result/selectors';

// Styles
import { LoaderWrapper, Title } from 'styles/common';
import { FeedbackWrapper } from './styles';

// Thunks
import {
    fetchResult,
    fetchLabResultOptions,
    fetchPlantTrialResultOptions,
    updateResult,
} from 'services/Result/thunks';
import { fetchProject } from 'services/Project/thunks';

// Types
import type { GenericOptionType, ImmutableList, IntlType, ReduxDispatch } from 'types';
import type { ProjectType } from 'services/Project/types';
import type { ResultType } from 'services/Result/types';

type Props = {
    fetchLabResultOptions: () => void,
    fetchResult: (number) => void,
    fetchPlantTrialResultOptions: () => void,
    fetchProject: (number, Array<string>, boolean) => void,
    intl: IntlType,
    isResultIsFetching: boolean,
    isResultIsSubmitting: boolean,
    labResultOptions: ?ImmutableList<GenericOptionType>,
    match?: {
        params: {
            projectId: ?string,
        },
    },
    results: ImmutableList<ResultType>,
    plantTrialResultOptions: ?ImmutableList<GenericOptionType>,
    project?: ProjectType,
    updateResult: (number, number, ResultType) => void,
};

export class Results extends React.PureComponent<Props> {
    static defaultProps = {
        match: {
            params: {
                projectId: null,
            },
        },
        project: null,
    };

    componentDidMount() {
        const projectId = Number(this.props.match.params.projectId);
        if (projectId) {
            this.props.fetchProject(projectId, ['results'], true);
            this.props.fetchResult(projectId);
            this.props.fetchLabResultOptions();
            this.props.fetchPlantTrialResultOptions();
        }
    }

    handleUpdateResult = (result: ResultType) => {
        this.props.updateResult(result.get('projectId'), result.get('id'), result);
    };

    renderMain = () => (
        <div>
            <Title>
                {this.props.intl.formatMessage({
                    id: 'views.Results.pageTitle',
                })}
            </Title>
            <br />
            <ResultTable
                results={this.props.results}
                isLoading={this.props.isResultIsFetching || this.props.isResultIsSubmitting}
                isOwner={this.props.project.get('isOwner')}
                resultIsUpdating={this.props.isResultIsSubmitting}
                onHandleUpdateResult={this.handleUpdateResult}
                labResultOptions={this.props.labResultOptions}
                plantTrialResultOptions={this.props.plantTrialResultOptions}
            />
        </div>
    );

    render() {
        let feedback;
        let content = (
            <LoaderWrapper>
                <Loader loading />
            </LoaderWrapper>
        );

        if (this.props.results.isEmpty() && !this.props.isResultIsFetching) {
            content = (
                <Common.Column justifyContent="center" alignItems="center">
                    <p>
                        {this.props.intl.formatMessage({
                            id: 'views.Results.noResultsMessage',
                        })}
                    </p>
                </Common.Column>
            );
        } else if (
            this.props.project &&
            this.props.project.get('surveyFilled') &&
            this.props.results &&
            this.props.labResultOptions &&
            this.props.plantTrialResultOptions &&
            !this.props.isResultIsFetching
        ) {
            content = <SidebarLayout renderMain={this.renderMain} mainMaxWidth={'1280px'} />;
            feedback = (
                <ResultFeedbackBlock autoHideDuration={2500} isLoading={this.props.updatedResult} />
            );
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.Results.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.Results.helmetDescription',
                        })}
                    />
                </Helmet>
                {content}
                {/* Render the feedback message to the bottom right of the table */}
                <FeedbackWrapper>{feedback}</FeedbackWrapper>
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    project: selectSingleProject(),
    results: selectResult(),
    labResultOptions: selectLabResultOptions(),
    plantTrialResultOptions: selectPlantTrialResultOptions(),
    isResultIsFetching: selectResultIsFetching(),
    isResultIsSubmitting: selectResultIsSubmitting(),
    updatedResult: selectUpdatedResult(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchLabResultOptions,
            fetchResult,
            fetchPlantTrialResultOptions,
            fetchProject,
            updateResult,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(injectIntl(Results)));
