// @flow

import React from 'react';

// Components
import { Common, Loader, Modal } from 'OsedeaReactUI';
import ModalContent from './ModalContent';

// Styles
import { BackgroundImage, ContainerCentered, LayoutContainer, MainBlock, Title } from './styles';

// Types
import type { ReactNode } from 'types';
import type { ImmutableUserType } from 'management/services/Authentication/types';
import type { DisclaimerType } from 'management/services/Disclaimer/types';

type titleLogoType = string | ReactNode;

type Props = {
    appBlockingMessage: ?string,
    backgroundImage?: string,
    disclaimers?: ?Array<DisclaimerType>,
    notAuthorizedMessage: string,
    onDisclaimerAcceptance: (string) => void,
    renderLoginUI: () => ReactNode,
    titleLogo?: titleLogoType,
    user?: ?ImmutableUserType,
    isLoading: boolean,
    uiIsLoading: boolean,
};

export default class LoginWithDisclaimers extends React.Component<Props> {
    static defaultProps = {
        backgroundImage: '',
        disclaimers: [],
        titleLogo: null,
        user: null,
    };

    onAgreement = (key: string) => () => this.props.onDisclaimerAcceptance(key);

    renderContent = () => {
        if (this.props.appBlockingMessage) {
            return <p>{this.props.appBlockingMessage}</p>;
        }

        if (this.props.isLoading) {
            return <Loader loading />;
        }

        // If no user and user is not being fetched, display login UI
        if (!this.props.user && !this.props.isLoading) {
            return this.props.renderLoginUI();
        }

        // Filter out policies if user has matching field as disclaimer.key
        const unacceptedDisclaimers = this.props.disclaimers.filter(
            (disclaimer: DisclaimerType) => !(this.props.user && this.props.user[disclaimer.key])
        );

        // If user and user has no roles, display notAuthorizedMessage
        // TODO: Build in consistency between user.roles & user.role
        if (
            (this.props.user && this.props.user.hasOwnProperty('role') && !this.props.user.role) ||
            (this.props.user &&
                this.props.user.hasOwnProperty('roles') &&
                !this.props.user.roles.length)
        ) {
            return this.props.notAuthorizedMessage;
        }

        // Set first disclaimer as active
        const disclaimer = unacceptedDisclaimers && unacceptedDisclaimers[0];

        if (disclaimer && this.props.user && !this.props.isLoading) {
            return (
                <Modal
                    key={disclaimer.key}
                    onHandleClose={null}
                    modalHeight="600px"
                    modalWidth="1088px"
                    overlay={false}
                    disableClose
                    hideCloseIcon
                    inlineTrigger
                >
                    <ModalContent
                        actionButtonText={disclaimer.actionButtonText}
                        content={disclaimer.content}
                        handleAgreement={this.onAgreement(disclaimer.key)}
                        title={disclaimer.title}
                        uiIsLoading={this.props.uiIsLoading}
                        warningNote={disclaimer.warningNote}
                    />
                </Modal>
            );
        }

        return <Loader loading />;
    };

    render() {
        return (
            <ContainerCentered>
                <LayoutContainer>
                    <Common.Row alignItems="center" justifyContent="center">
                        <MainBlock>
                            {this.props.titleLogo && <Title>{this.props.titleLogo}</Title>}
                            {this.renderContent()}
                        </MainBlock>
                    </Common.Row>
                </LayoutContainer>
                {this.props.backgroundImage && (
                    <BackgroundImage backgroundImage={this.props.backgroundImage} />
                )}
            </ContainerCentered>
        );
    }
}
