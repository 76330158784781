// @flow

import React from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import 'firebase/auth';
import firebase from 'firebase/app';

// Config
import { firebaseConfig, gaTrackingId } from 'env';

// Components
import Header from 'components/Header';
// Rename with UserIsReady when SMS is updated
import UserIsReady from 'management/components/UserIsReady';

// Routes
import Routes from 'routes';

// Services
import { receivedFirebaseAuthSuccess } from 'management/services/Authentication/actions';
import { selectUser, selectUserIsFetching } from 'management/services/Authentication/selectors';
import { logoutUser, authenticateUser, whoami } from 'management/services/Authentication/thunks';
import { changeLocale } from 'services/Language/actions';
import { selectLocale } from 'services/Language/selectors';
import { selectSingleProject, selectSingleProjectIsFetching } from 'services/Project/selectors';
import { clearSingleProject } from 'services/Project/thunks';

// Styles
import GlobalStyle from 'styles/global';
import { AppWrapper } from 'styles/common';
import { ToolTipGlobalStyles } from 'OsedeaReactUI';

import 'sanitize.css/sanitize.css';

// Types
import type { ReduxDispatch } from 'types';
import type {
    LogoutUserType,
    UserType,
    WhoamiType,
} from 'management/services/Authentication/types';
import type { ProjectType } from 'services/Project/types';

// Initialize Google Analytics
if (gaTrackingId) {
    ReactGA.initialize(gaTrackingId);
}

type Props = {
    authenticateUser: (UserType) => void,
    logoutUser: LogoutUserType,
    project?: ProjectType,
    projectIsFetching: boolean,
    handleClearSingleProject: () => void,
    receivedFirebaseAuthSuccess: (UserType) => void,
    user: ?UserType,
    userIsFetching: boolean,
    whoami: WhoamiType,
};

type State = {
    initialized: boolean,
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

class App extends React.PureComponent<Props, State> {
    static defaultProps = {
        project: null,
    };

    state = { initialized: false };

    componentDidMount() {
        // If a user api token exists we'll retrieve the user by dispatching
        // the whoami action and store the user in the global state
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user: UserType) => {
            // To clean the data structure
            const userParsed = JSON.parse(JSON.stringify(user));
            let userData = userParsed;

            if (userParsed) {
                userData = {
                    ...userParsed.providerData[0],
                    firebaseUid: userParsed.uid,
                    photoUrl: userParsed.photoURL,
                    accessToken:
                        userParsed.stsTokenManager && userParsed.stsTokenManager.accessToken,
                };

                delete userData.photoURL;
            }

            this.props.receivedFirebaseAuthSuccess(userData);

            if (userData && this.state.initialized) {
                // In this case, it means we have a successful login
                this.props.authenticateUser(userData);
            } else if (userData) {
                this.props.whoami();
            }

            this.setState({ initialized: true });
        });
    }

    // Make sure we un-register Firebase observers when the component unmounts.
    componentWillUnmount() {
        if (this.unregisterAuthObserver) {
            this.unregisterAuthObserver();
        }
    }

    handleLogout = () => {
        this.props.logoutUser();
    };

    render() {
        return (
            <AppWrapper>
                <Helmet titleTemplate="%s - Asking more from chemistry®" defaultTitle="Syensqo">
                    <meta name="description" content="Asking more from chemistry®" />
                </Helmet>
                <GlobalStyle />
                <ToolTipGlobalStyles />
                <Header
                    onLogout={this.handleLogout}
                    onClearSingleProject={this.props.handleClearSingleProject}
                    project={this.props.project}
                    user={this.props.user}
                    userIsFetching={this.props.userIsFetching}
                />
                {this.state.initialized ? (
                    <UserIsReady user={this.props.user}>
                        <Routes
                            isOwner={this.props.project && this.props.project.get('isOwner')}
                            projectIsFetching={this.props.projectIsFetching}
                            user={this.props.user}
                            userIsFetching={this.props.userIsFetching}
                        />
                    </UserIsReady>
                ) : null}
            </AppWrapper>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    projectIsFetching: selectSingleProjectIsFetching(),
    locale: selectLocale(),
    project: selectSingleProject(),
    user: selectUser(),
    userIsFetching: selectUserIsFetching(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            authenticateUser,
            changeLocale,
            logoutUser,
            receivedFirebaseAuthSuccess,
            whoami,
            handleClearSingleProject: clearSingleProject,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
