// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const InputsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 16px 24px;
    margin-left: 7px;
    min-height: 88px;
    min-width: 642px;

    border: 1px solid ${LegacyTheme.defaultBorder};
    border-radius: 4px;
    background-color: ${LegacyTheme.defaultSubtleColorFA};
`;

export const CloseContainer = styled.div`
    padding-top: 12px;
    height: fit-content;
    cursor: pointer;
`;

export const InputLabel = styled.label`
    font-size: 12px;
    margin-bottom: 10px;
`;

export const SelectFromRecommendations = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 216px;
`;

export const AddNewBuildingBlock = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 240px;
`;

export const StyledOr = styled.div`
    min-height: 30px;
    display: flex;
    align-items: center;
`;
