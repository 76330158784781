// @flow

import React from 'react';

// Components
import {
    Common,
    Slider,
    // ToolTip,
    // ToolTipTrigger
} from 'OsedeaReactUI';

// Styles
// import { ToolTipPadding } from 'styles/common';
import {
    Factors,
    FactorSlider,
    FactorSliderTitle,
    FactorsSliderWrapper,
    FactorTitle,
    FactorsTitleWrapper,
    FactorWrapper,
    TotalNumber,
    TotalText,
} from './styles';

// Types
import type { IntlType } from 'types';
import type { SurveyWeightingFactorType } from 'services/Survey/types';

type Props = {
    title: string,
    toolTip: string,
    weightingFactors: Array<SurveyWeightingFactorType>,
    onSliderChange: (number, string) => void,
    disabled?: boolean,
    buildingBlockFilter: string,
    getSurveyField: (string) => void,
    buildingBlockTotalPercent: number,
    buildingBlockInvalid: boolean,
    intl: IntlType,
    renderString?: boolean,
};

export default class SurveyWeightingFactors extends React.PureComponent<Props> {
    static defaultProps = {
        disabled: true,
        renderString: false,
    };

    handleOnSlideChange = (key: string) => (value: string | number) => {
        this.props.onSliderChange(value, key);
    };

    renderSliders = () => {
        const { weightingFactors, buildingBlockFilter, getSurveyField, disabled } = this.props;

        return weightingFactors
            .filter(
                (weightingFactor: SurveyWeightingFactorType) =>
                    weightingFactor.group === buildingBlockFilter
            )
            .map(({ key, text, max, min }: SurveyWeightingFactorType) => (
                <FactorWrapper key={key}>
                    <FactorSliderTitle>{text}</FactorSliderTitle>
                    <FactorSlider fullRange={max - min === 100}>
                        <Slider
                            disabled={disabled}
                            value={getSurveyField(key)}
                            min={min}
                            max={max}
                            onSlideEndHandler={this.handleOnSlideChange(key)}
                            renderString={this.props.renderString}
                        />
                    </FactorSlider>
                </FactorWrapper>
            ));
    };

    render() {
        const {
            title,
            // toolTip,
            buildingBlockTotalPercent,
            buildingBlockInvalid,
            intl,
        } = this.props;

        return (
            <Factors>
                <FactorsTitleWrapper>
                    {/* Title */}
                    <FactorTitle>{title}</FactorTitle>
                    <br />
                    {/* Tool tip */}
                    {/* <ToolTip
                        content={<ToolTipPadding>{toolTip}</ToolTipPadding>}
                        position="top"
                        trigger={
                            <React.Fragment>
                                <ToolTipTrigger size={16} />
                            </React.Fragment>
                        }
                        triggerType="click"
                        interactive
                    /> */}
                </FactorsTitleWrapper>

                {/* Sliders */}
                <FactorsSliderWrapper>{this.renderSliders()}</FactorsSliderWrapper>

                {/* Primary building block total percents */}
                <Common.Row justifyContent="flex-end" alignItems="flex-end">
                    <TotalText>
                        {intl.formatMessage({
                            id: 'components.ProjectSurvey.weightingFactors.total',
                        })}
                    </TotalText>
                    <TotalNumber error={buildingBlockInvalid}>
                        {buildingBlockTotalPercent}% / 100%
                    </TotalNumber>
                </Common.Row>
            </Factors>
        );
    }
}
