// @flow

import styled from 'styled-components';

import { Wrapper } from 'components/BuildingBlockItem/styles';

export const Container = styled.div`
    width: 100%;

    ${Wrapper}:not(:first-child) {
        margin-top: 10px;
    }
`;
