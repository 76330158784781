// @flow

import { createSelector } from 'reselect';
import { fromJS } from 'immutable';

// Constants
import { PROJECT_TYPE } from 'utils/constants';

// Selector
import { selectSingleProject } from 'services/Project/selectors';

// Types
import type { ImmutableMap } from 'types';
import type { BuildingBlockType } from './types';
import type { ProjectType } from 'services/Project/types';

/**
 * Direct selector to the building blocks state domain
 */
export const selectBuildingBlocksState = () => (state: ImmutableMap<string, any>) =>
    state.get('buildingBlocks');

/**
 * Select the loading/fetching state of the building blocks
 */
export const selectBuildingBlocksListIsFetching = () =>
    createSelector(selectBuildingBlocksState(), (substate: ImmutableMap<string, any>) =>
        substate.get('listIsFetching')
    );

/**
 * Select the building block of the current project type.
 * Returns empty map if no current project is selected
 */
export const selectBuildingBlockByProjectType = () =>
    createSelector(
        selectBuildingBlocksState(),
        selectSingleProject(),
        (substateBuildingBlocks: ImmutableMap<string, any>, singleProject: ProjectType) => {
            const projectType = singleProject.get('projectType');
            return projectType
                ? substateBuildingBlocks
                      .get('list')
                      .filter(
                          (block: BuildingBlockType) => block.get('projectType') === projectType
                      )
                : fromJS([]);
        }
    );

/**
 * Select the list of collector building blocks
 */
export const selectCollectorBuildingBlocks = () =>
    createSelector(selectBuildingBlocksState(), (substate: ImmutableMap<string, any>) =>
        substate
            .get('list')
            .filter(
                (block: BuildingBlockType) => block.get('projectType') === PROJECT_TYPE.COLLECTOR
            )
    );

/**
 * Select the list of all building blocks
 */
export const selectFrotherBuildingBlocks = () =>
    createSelector(selectBuildingBlocksState(), (substate: ImmutableMap<string, any>) =>
        substate
            .get('list')
            .filter((block: BuildingBlockType) => block.get('projectType') === PROJECT_TYPE.FROTHER)
    );

/**
 * Select the building blocks constraints
 */
export const selectBuildingBlocksConstraints = () =>
    createSelector(selectBuildingBlocksState(), (substate: ImmutableMap<string, any>) =>
        substate.get('constraints')
    );

/**
 * Select the loading/fetching state of the building block constraints
 */
export const selectBuildingBlocksConstraintsAreFetching = () =>
    createSelector(selectBuildingBlocksState(), (substate: ImmutableMap<string, any>) =>
        substate.get('constraintsAreFetching')
    );

/**
 * Selects the boolean value for if a product already exists for a custom blend.
 */
export const selectBuildingBlocksProductExists = () =>
    createSelector(selectBuildingBlocksState(), (substate: ImmutableMap<string, any>) =>
        substate.get('customBlendProductExists')
    );

/**
 * Selects frother blends filter
 */
export const selectFrotherBlendsFilter = () =>
    createSelector(selectBuildingBlocksState(), (substate: ImmutableMap<string, any>) =>
        substate.get('frotherBlendsFilter')
    );
