// @flow

// Resources
import { index, requestProductsContainingBuildingBlockIds } from './resources';
import { report } from 'management/services/Errors/resources';

// Types
import type { ReduxDispatch, ResponseErrorType } from 'types';
import type { ProductType } from './types';

// Actions
import {
    receivedFetchListSuccess,
    receivedFetchListFailure,
    setListIsFetchingStatus,
    receivedFetchProductTableListSuccess,
    receivedFetchProductTableListFailure,
    setProductTableListIsFetchingStatus,
    productTableListClear,
} from './actions';

// Constants
import { PROJECT_TYPE } from 'utils/constants';

/**
 * Fetches an array of all products then dispatches an action event depending on the response.
 */
export const fetchAllProducts = () => (dispatch: ReduxDispatch) => {
    dispatch(setListIsFetchingStatus());

    index()
        .then((response: Array<ProductType>) => {
            if (response.error) {
                dispatch(receivedFetchListFailure(response.error));
            } else if (response) {
                dispatch(receivedFetchListSuccess(response));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchListFailure(error));
        });
};

/**
 * Fetches an array of products whose composition consist of the building block ids provided. After
 * a response is received, dispatches an action event depending on the response.
 */
export const fetchProductsContainingBuildingBlocks = (ids: Array<number>, projectType: string) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setProductTableListIsFetchingStatus());
    // For a frother project, we need the ffp_blend_score properties of a each fetched products
    const relations = projectType === PROJECT_TYPE.FROTHER ? ['ffpBlendScore'] : [];

    requestProductsContainingBuildingBlockIds(ids, relations)
        .then((response: Array<ProductType>) =>
            dispatch(receivedFetchProductTableListSuccess(response))
        )
        .catch((error: ResponseErrorType) => dispatch(receivedFetchProductTableListFailure(error)));
};

export const clearProductTableList = () => (dispatch: ReduxDispatch) => {
    dispatch(productTableListClear());
};
