// @flow

import { fromJS } from 'immutable';

// Actions
import {
    FETCH_SUCCESS,
    FETCH_FAILURE,
    RESULT_IS_FETCHING,
    RESULT_IS_SUBMITTING,
    FETCH_LAB_RESULT_OPTION_SUCCESS,
    FETCH_LAB_RESULT_OPTION_FAILURE,
    FETCH_PLANT_TRIAL_RESULT_OPTION_SUCCESS,
    FETCH_PLANT_TRIAL_RESULT_OPTION_FAILURE,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
} from './actions';

// Types
import type { GenericActionType } from 'types';
import type { ResultType } from './types';

type State = {
    list: Array<ResultType>,
    resultIsFetching: boolean,
    resultIsSubmitting: boolean,
    errors: {},
};

const initialState = fromJS({
    list: [],
    resultIsFetching: false,
    resultIsSubmitting: false,
    errors: {},
});

/**
 * Reducer
 *
 * Switch statement to set state based on current action type
 */

function resultServiceReducer(state: State = initialState, action: GenericActionType) {
    switch (action.type) {
        case FETCH_SUCCESS: {
            const results = action.payload.data;
            return state
                .set('list', fromJS(results))
                .set('resultIsFetching', false)
                .set('updatedResult', false)
                .set('resultIsSubmitting', false)
                .set('errors', fromJS({}));
        }
        case FETCH_LAB_RESULT_OPTION_SUCCESS: {
            const results = action.payload.data;
            return state
                .set('labResultOptionList', results)
                .set('updatedResult', false)
                .set('errors', fromJS({}));
        }
        case FETCH_PLANT_TRIAL_RESULT_OPTION_SUCCESS: {
            const results = action.payload.data;
            return state.set('plantTrialResultOptionList', results).set('errors', fromJS({}));
        }
        case FETCH_LAB_RESULT_OPTION_FAILURE: {
            return state
                .set('labResultOptionList', fromJS({}))
                .set('errors', fromJS(action.payload.errors));
        }
        case FETCH_PLANT_TRIAL_RESULT_OPTION_FAILURE: {
            return state
                .set('plantTrialResultOptionList', fromJS({}))
                .set('errors', fromJS(action.payload.errors));
        }
        case UPDATE_SUCCESS: {
            const results = action.payload.data;
            return state
                .set('resultIsFetching', false)
                .set('updatedResult', true)
                .set('resultIsSubmitting', false)
                .set('errors', fromJS({}));
        }
        case UPDATE_FAILURE:
        case FETCH_FAILURE:
            return state
                .set('list', fromJS({}))
                .set('errors', fromJS(action.payload.errors))
                .set('updatedResult', false)
                .set('resultIsFetching', false)
                .set('resultIsSubmitting', false);
        case RESULT_IS_FETCHING:
            return state
                .set('resultIsFetching', action.payload.resultIsFetching)
                .set('updatedResult', false)
                .set('results', []);
        case RESULT_IS_SUBMITTING:
            return state
                .set('resultIsSubmitting', action.payload.resultIsSubmitting)
                .set('updatedResult', false)
                .set('results', []);
        default:
            return state;
    }
}

export default resultServiceReducer;
