// @flow

import * as React from 'react';

// Style
import { StyledErrorMessage } from './styles';

// PropTypes
type Props = {
    children: React.Node,
    marginTop?: ?string,
    textAlign: ?string,
};

const defaultProps = {
    marginTop: null,
};

const ErrorMessage = (props: Props) => (
    <StyledErrorMessage marginTop={props.marginTop} textAlign={props.textAlign}>
        {props.children}
    </StyledErrorMessage>
);

ErrorMessage.defaultProps = defaultProps;
export default ErrorMessage;
