// @flow

import { push } from 'react-router-redux';
import { fromJS } from 'immutable';

import type { ReduxDispatch, ResponseErrorType } from 'types';
import type { CollectorSurveyType, FrotherSurverType } from './types';

import {
    createCollector,
    createFrother,
    showCollector,
    showFrother,
    updateFrother,
    updateCollector,
    revertCollector,
    revertFrother,
} from './resources';

import {
    receivedCreateCollectorFailure,
    receivedCreateCollectorSuccess,
    receivedCreateFrotherFailure,
    receivedCreateFrotherSuccess,
    receivedFetchFailure,
    receivedFetchSuccess,
    receivedUpdateSuccess,
    receivedUpdateFailure,
    receivedRevertSuccess,
    receivedRevertFailure,
    setSurveyIsFetchingStatus,
    setSurveyIsSubmittingStatus,
    setDefaultSurvey,
    setClearedSurveyBody,
} from './actions';

// Constants
import { PROJECT_TYPE } from 'utils/constants';

import { setSingleProjectSurveyFilled } from 'services/Project/actions';
import { fetchProject } from 'services/Project/thunks';
import { setFrotherBlendsFilterSuccess } from 'services/BuildingBlock/actions';
import { report } from 'management/services/Errors/resources';

/**
 * Thunks
 *
 * Basic function: Validate response and dispatch action to save data/error to redux store
 */

/**
 * Create a collector survey
 *
 * @param {CollectorSurveyType} surveyObject
 */
export const createCollectorSurvey = (projectId: number, survey: CollectorSurveyType) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setSurveyIsSubmittingStatus());
    createCollector(projectId, survey)
        .then((response: {}) => {
            dispatch(receivedCreateCollectorSuccess(response));
            dispatch(setSingleProjectSurveyFilled());
        })
        .then(() => {
            dispatch(push(`/project/${projectId}/building-blocks`));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCreateCollectorFailure(error));
        });
};

/**
 * Create a frother survey
 *
 * @param {CollectorSurveyType} surveyObject
 */
export const createFrotherSurvey = (projectId: number, survey: FrotherSurveyType) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setSurveyIsSubmittingStatus());
    createFrother(projectId, survey)
        .then((response: {}) => {
            dispatch(receivedCreateFrotherSuccess(response));
            dispatch(setSingleProjectSurveyFilled());
        })
        .then(() => {
            dispatch(push(`/project/${projectId}/building-blocks`));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedCreateFrotherFailure(error));
        });
};

/**
 * Fetch collector survey by Project ID
 *
 * @param {number} projectId
 */
const fetchCollectorSurvey = (projectId: number) => (dispatch: ReduxDispatch) => {
    dispatch(setSurveyIsFetchingStatus());
    showCollector(projectId)
        .then((response: {}) => {
            if (response.error) {
                dispatch(receivedFetchFailure(response.error));
            } else if (response) {
                dispatch(receivedFetchSuccess(response));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchFailure(error));
        });
};

/**
 * Fetch frother survey by Project ID
 *
 * @param {number} projectId
 */
const fetchFrotherSurvey = (projectId: number) => (dispatch: ReduxDispatch) => {
    dispatch(setSurveyIsFetchingStatus());
    showFrother(projectId)
        .then((response: {}) => {
            if (response.error) {
                dispatch(receivedFetchFailure(response.error));
            } else if (response) {
                dispatch(receivedFetchSuccess(response));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedFetchFailure(error));
        });
};

/**
 * Fetch a survey by Project ID and projectType
 *
 * @param {number} projectId
 */
export const fetchSurveyByIdAndType = (projectId: number, projectType: string) => (
    dispatch: ReduxDispatch
) => {
    switch (projectType) {
        case PROJECT_TYPE.COLLECTOR:
            dispatch(fetchCollectorSurvey(projectId));
            break;
        case PROJECT_TYPE.FROTHER:
            dispatch(fetchFrotherSurvey(projectId));
            break;
    }
};

/**
 * Update collector survey by projectId
 */
export const updateCollectorSurvey = (
    projectId: number,
    survey: CollectorSurveyType,
    redirect: boolean = false
) => (dispatch: ReduxDispatch) => {
    dispatch(setSurveyIsSubmittingStatus());
    return updateCollector(projectId, survey)
        .then((response: {}) => {
            dispatch(receivedUpdateSuccess(response));
            dispatch(setFrotherBlendsFilterSuccess(fromJS({})));
        })
        .then(() => {
            if (redirect) {
                dispatch(push(`/project/${projectId}/building-blocks`));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateFailure(error));
        });
};

/**
 * Update frother survey by projectId
 */
export const updateFrotherSurvey = (
    projectId: number,
    survey: FrotherSurveyType,
    redirect: boolean = false
) => (dispatch: ReduxDispatch) => {
    dispatch(setSurveyIsSubmittingStatus());
    return updateFrother(projectId, survey)
        .then((response: {}) => {
            dispatch(receivedUpdateSuccess(response));
            dispatch(setFrotherBlendsFilterSuccess(fromJS({})));
        })
        .then(() => {
            if (redirect) {
                dispatch(push(`/project/${projectId}/building-blocks`));
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateFailure(error));
        });
};

/**
 * Update a survey by Project ID and projectType
 *
 * @param {number} projectId
 */
export const updateSurveyByIdAndType = (
    projectId: number,
    projectType: string,
    survey: CollectorSurveyType | FrotherSurveyType
) => (dispatch: ReduxDispatch) => {
    switch (projectType) {
        case PROJECT_TYPE.COLLECTOR:
            return dispatch(updateCollectorSurvey(projectId, survey));
        case PROJECT_TYPE.FROTHER:
            return dispatch(updateFrotherSurvey(projectId, survey));
    }
};

/**
 * Revert a collector survey by projectId
 */
export const revertCollectorSurvey = (projectId: number) => (dispatch: ReduxDispatch) => {
    dispatch(setSurveyIsFetchingStatus());
    revertCollector(projectId)
        .then((response: {}) => {
            dispatch(receivedRevertSuccess(response));
            dispatch(setFrotherBlendsFilterSuccess(fromJS({})));
            dispatch(fetchProject(projectId, ['buildingBlocks', 'frotherBlends']));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedRevertFailure(error));
        });
};

/**
 * Revert a frother survey by projectId
 */
export const revertFrotherSurvey = (projectId: number) => (dispatch: ReduxDispatch) => {
    dispatch(setSurveyIsFetchingStatus());
    revertFrother(projectId)
        .then((response: {}) => {
            dispatch(receivedRevertSuccess(response));
            dispatch(setFrotherBlendsFilterSuccess(fromJS({})));
            dispatch(fetchProject(projectId, ['buildingBlocks', 'frotherBlends']));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedRevertFailure(error));
        });
};

/**
 * Revert a survey by project id and project type
 *
 * @param {*} projectId
 * @param {*} projectType
 */
export const revertSurveyByProjectIdAndType = (projectId: number, projectType: string) => (
    dispatch: ReduxDispatch
) => {
    switch (projectType) {
        case PROJECT_TYPE.COLLECTOR:
            dispatch(revertCollectorSurvey(projectId));
            break;
        case PROJECT_TYPE.FROTHER:
            dispatch(revertFrotherSurvey(projectId));
            break;
    }
};

/**
 * Initialize a new default survey (for unfilled surveys)
 */
export const initDefaultSurvey = (projectId: number, projectType: string) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setSurveyIsFetchingStatus());
    dispatch(setDefaultSurvey(projectId, projectType));
};

/**
 * Clear survey to default state null.
 */
export const clearSurvey = () => (dispatch: ReduxDispatch) => {
    dispatch(setClearedSurveyBody());
};

/**
 * Update survey by projectId & re-fetch project
 */
export const updateSurveyAndBuildingBlocks = (
    projectId: number,
    projectType: string,
    survey: CollectorSurveyType
) => (dispatch: ReduxDispatch) => {
    dispatch(setSurveyIsSubmittingStatus());
    dispatch(updateSurveyByIdAndType(projectId, projectType, survey)).then((response: {}) => {
        dispatch(fetchProject(projectId, ['buildingBlocks', 'frotherBlends']));
    });
};
