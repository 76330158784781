// @flow

import * as React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { PrimaryButton } from 'OsedeaReactUI';

// Styles
import { Container } from './styles';

// Types
import type { IntlType } from 'types';

type Props = {
    blockView?: boolean,
    intl: IntlType,
    onClickHandler?: () => void,
};

const defaultProps = {
    blockView: false,
    onClickHandler: null,
};

const SurveyIncompleteContainer = (props: Props) => {
    let content = (
        <p>
            {props.intl.formatMessage({
                id: 'components.SurveyNotCompletedMessage.default',
            })}
        </p>
    );

    if (props.blockView) {
        content = (
            <React.Fragment>
                <p>
                    {props.intl.formatMessage({
                        id: 'components.SurveyIncompleteContainer.fullSurveyMessage',
                    })}
                </p>
                {props.onClickHandler && (
                    <PrimaryButton
                        text={props.intl.formatMessage({
                            id: 'components.SurveyIncompleteContainer.fillSurvey',
                        })}
                        onClick={props.onClickHandler}
                    />
                )}
            </React.Fragment>
        );
    }

    return <Container>{content}</Container>;
};

SurveyIncompleteContainer.defaultProps = defaultProps;

export default injectIntl(SurveyIncompleteContainer);
