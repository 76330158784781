// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const GraphContainer = styled.div`
    width: 100%;
    border: 1px solid ${LegacyTheme.defaultBorder};
    border-radius: 4px;
`;

export const FrotherLegendList = styled.ul`
    font-size: 12px;
    font-family: Helvetica, Arial, sans-serif;
    color: rgb(166, 168, 169);
    padding-right: 40px;
    text-align: justify;
`;
