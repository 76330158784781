// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const Constraint = styled.div`
    display: flex;
    align-items: baseline;

    margin-bottom: 10px;

    color: ${LegacyTheme.black};
    font-size: ${({ fontSize }) => fontSize || '17px'}

    word-break: break-word;
`;

export const Header = styled.h3`
    color: ${LegacyTheme.defaultSubtleColor};
    font-size: 11px;
    font-weight: normal;
`;

export const Wrapper = styled.div`
    width: 100%;
    margin-top: 24px;

    text-align: left;
`;

export const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 40px;
`;

export const ConstraintsList = styled.ul`
    padding: 0;
    margin: 8px 0;

    list-style: none;

    > li {
        display: block;
        padding: 16px 40px;

        border-bottom: 1px solid ${LegacyTheme.defaultBorder};

        line-height: 1.2;

        &:first-child {
            margin-top: 0;
            padding-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;

            border-bottom: 0;
        }
    }
`;

export const ConstrainTrigger = styled.span`
    text-decoration: underline;
`;

export const LoaderWrapper = styled.div`
    text-align: ${({ loaderAlign }) => loaderAlign || 'inherit'};
`;

export const BlendingExceptionsList = styled.ul`
    padding-left: 70px;
    overflow-y: scroll;
    height: 294px;

    > li {
        font-size: 14px;
        font-weight: normal;
        margin-top: 8px;
    }
`;
