import styled from 'styled-components';

export const ScrollableTable = styled.div`
    height: 100vh;
    width: 100%;
    overflow: scroll;

    // hide scrollbar
    &::-webkit-scrollbar {
        display: none;
    }
`;

// Container used to align the spider graph to the product table.  Only used in Frother products
export const ProductFrotherGraphContainer = styled.div`
    padding-top: 78px;
    width: 100%;
`;
