// @flow

import styled from 'styled-components';

export const ProductName = styled.div`
    display: flex;
    align-items: center;

    > div {
        margin-left: 12px;
    }
`;
