// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import SurveyQuestionnaire from 'components/SurveyQuestionnaire';
import {
    ButtonGrid,
    InputSelect,
    InputNumber,
    PrimaryButton,
    LegacyTheme,
    Common,
} from 'OsedeaReactUI';
import SurveyControls from 'components/SurveyControls';

// Constants
import {
    FROTHER_SURVEY_PRIMARY_QUESTIONNAIRE,
    FROTHER_BUILDING_BLOCK_FILTERS_QUESTIONS,
    SURVEY_TYPE_PRIMARY,
    SURVEY_TYPE_SECONDARY,
    SURVEY_TYPE_TERTIARY,
    VIEW_BLOCK,
    VIEW_SURVEY,
    BUILDING_BLOCK_COMPOSITION_NONE_OPTION,
} from 'utils/constants';

// Styles
import {
    QuestionList,
    SurveyContainer,
    SurveyBody,
    SurveyFooter,
    SurveyHeader,
} from 'styles/survey';
import { Title } from 'styles/common';
import {
    FrotherSurveyCustomBlendBlock,
    FrotherSurveyCustomBlendBlockPercentage,
    FrotherSurveyCustomBlendBlockId,
} from './styles';

// Helpers
import {
    getSurveyValidationErrors,
    mapSurveyQuestionnaireErrors,
    getSelectOptionForSelectList,
    frotherBuildingBlockToSelectList,
    frotherProductsToSelectList,
    frotherSurveySecondaryQuestions,
} from 'utils/helpers';
import {
    getIndividualCustomBlendDisableRule,
    getIndividualPrimaryQuestionDisableRule,
    changeFrotherSurveyAnswers,
} from 'utils/frotherValidations';

// Types
import type { InputEvent, IntlType, ImmutableMap, SelectOptionType } from 'types';
import type { ProjectType } from 'services/Project/types';
import type { FrotherSurveyType, SurveyQuestionnaireType } from 'services/Survey/types';
import type { FrotherBuildingBlock, FrotherBlendsFilter } from 'services/BuildingBlock/types';
import type { ProductType } from 'services/Product/types';

type Props = {
    history: {
        push: () => void,
    },
    intl: IntlType,
    isSidebar?: boolean,
    onRevert?: () => void,
    onSubmit: (FrotherSurveyType) => void,
    goToBuildingBlocks?: () => void,
    project: ?ProjectType,
    survey: FrotherSurveyType,
    frotherBuildingBlocks: ImmutableList<FrotherBuildingBlock>,
    frotherProducts: ImmutableList<ProductType>,
    // Since the component unmount in Blocks, we need the filters here
    frotherBlendsFilter?: FrotherBlendsFilter,
    onFrotherBlendsFilter?: (ImmutableMap) => void,
};

type State = {
    currentPage: string,
    survey: ?FrotherSurveyType,
    frotherBlendsFilter: ImmutableMap<FrotherBlendsFilter>,
    questionnaireErrors: ImmutableMap,
};

class FrotherSurveyForm extends React.Component<Props, State> {
    static defaultProps = {
        isSidebar: false,
        onRevert: null,
        title: null,
    };

    state = {
        currentPage: SURVEY_TYPE_PRIMARY,
        survey: this.props.survey,
        frotherBlendsFilter: this.props.frotherBlendsFilter,
        questionnaireErrors: fromJS({}),
    };

    /**
     * Handles setting of state on survey input, select option, slider change. Runs live validations
     */
    handleOnChange = (value: string | number, key: string) => {
        this.setState(
            (prevState: State) => ({
                survey: prevState.survey.set(key, value),
            }),
            () => {
                if (this.state.questionnaireErrors.size) {
                    this.validateQuestionnaireFields();
                }
            }
        );
    };

    /**
     * Handles survey revert by disabling inputs & calling props.onRevert to fetch original survey
     * Check if Local (state) Survey is different from one provided by Global Store (props):
     * if true simply "undo" local state, else fire props.onRevert
     */
    handleSurveyRevert = () => {
        if (this.compareStateAndPropsSurvey()) {
            this.props.onRevert();
        } else {
            this.setState({
                survey: this.props.survey,
            });
        }
    };

    /**
     * Helper function to check if Local (state) Survey is different from one provided by Global Store (props)
     */
    compareStateAndPropsSurvey = () => this.props.survey.equals(this.state.survey);

    /**
     * Handles setting state.currentPage based on event.target.value
     */
    handleSetCurrentPage = (event: InputEvent) => this.onSetCurrentPage(event.target.value)();

    onSetCurrentPage = (newCurrentPage: string) => () => {
        // Validate survey when trying to go to the prior questions
        if (newCurrentPage === SURVEY_TYPE_SECONDARY && !this.validateQuestionnaireFields()) {
            return;
        }
        this.setState({
            currentPage: newCurrentPage,
        });
    };

    /**
     * Validations for survey questionnaire values.
     * Returns a boolean value indicating whether the values are valid or not
     */
    validateQuestionnaireFields = () => {
        const questionnaireErrors = getSurveyValidationErrors({
            survey: this.state.survey,
            fields: FROTHER_SURVEY_PRIMARY_QUESTIONNAIRE,
            arrayReduceFn: mapSurveyQuestionnaireErrors,
            arrayReduceInitialValue: {},
            intl: this.props.intl,
        });

        this.setState({ questionnaireErrors: fromJS(questionnaireErrors) });

        return Object.keys(questionnaireErrors).length === 0;
    };

    /**
     * Validation for the second set of questions (Prior questions)
     * Returns a boolean value indicating whether the values are valid or not
     *
     * A lot of combinations are possible in this page:
     *  - The user doesn't know the frother
     *  - The user knows it's a Solvay Frother (needs a Solvay frother)
     *  - The user knows the frother composition (custom blend with 100%)
     *
     * Not yet implemented
     */
    validateSecondaryQuestionsFields = () => {
        if (!getIndividualCustomBlendDisableRule(this.state.survey, 'frotherBuildingBlockIdOne')) {
            return this.getCustomBlendTotalPercentage() === 100;
        }

        return true;
    };

    handleSurveySubmit = () => {
        if (this.validateQuestionnaireFields() && this.validateSecondaryQuestionsFields()) {
            if (this.compareStateAndPropsSurvey()) {
                this.props.goToBuildingBlocks();
            } else {
                this.props.onSubmit(changeFrotherSurveyAnswers(this.state.survey));
            }
        }
    };

    // Handler when a select option change (custom building block blend)
    handleOnSelectChange = (fieldName: string) => (value: SelectOptionType) => {
        let valueId = value.value;
        // If a user select the "None" option, we will know if the value equals the BUILDING_BLOCK_COMPOSITION_NONE_OPTION value
        if (valueId === BUILDING_BLOCK_COMPOSITION_NONE_OPTION) {
            valueId = null;
        }
        this.handleOnChange(valueId, fieldName);
    };

    handleOnNumberChange = (fieldName: string) => (event: InputEvent) => {
        let inputValue: number = event.target.value;
        // If inputValue is 0, "reset" the value back to null
        if (Number(inputValue) === 0) {
            inputValue = null;
        }
        this.handleOnChange(inputValue, fieldName);
    };

    // Handler to change a frother blend filter state
    handleOnFrotherBlendFilterChange = (value: string, key: string) => {
        this.setState((prevState: State) => ({
            frotherBlendsFilter: prevState.frotherBlendsFilter.set(key, value),
        }));
    };

    // Handler to apply the frother blends filters
    handleOnFrotherBlendFilterClick = () => {
        this.props.onFrotherBlendsFilter(this.state.frotherBlendsFilter.toJS());
    };

    // Handler to clear the frother blends filters
    handleOnFrotherBlendFilterClear = () => {
        this.props.onFrotherBlendsFilter({});
        this.setState({
            frotherBlendsFilter: fromJS({}),
        });
    };

    // Handler for the onClick clear Filter Icon event
    handleOnFilterClearIconClick = (filterKey: string) => {
        this.setState((prevState: State) => ({
            frotherBlendsFilter: prevState.frotherBlendsFilter.remove(filterKey),
        }));
    };

    // Handler for key press event on input number
    handleOnInputNumberKeyPress = (event: Event) => {
        event.preventDefault();
    };

    // Handler for key down event on input number
    handleOnInputNumberKeyDown = (event: Event) => {
        // Only arrows are allowed
        if (event.keyCode !== 38 && event.keyCode !== 40) {
            event.preventDefault();
        }
    };

    // Calculate the allowed max for each input
    getCustomBlendTotalPercentage = (): number => {
        const totalPercentage =
            Number(this.state.survey.get('frotherBuildingBlockIdOnePercentage')) +
            Number(this.state.survey.get('frotherBuildingBlockIdTwoPercentage')) +
            Number(this.state.survey.get('frotherBuildingBlockIdThreePercentage'));

        return totalPercentage;
    };

    /**
     * Get the available frother building blocks for a input.
     * A custom building block can't have the same building block as another one
     */
    getFilteredBuildingBlocks = () => {
        const selectedBuildingBlocks: number[] = [];
        if (Number.isInteger(this.state.survey.get('frotherBuildingBlockIdOne'))) {
            selectedBuildingBlocks.push(this.state.survey.get('frotherBuildingBlockIdOne'));
        }
        if (Number.isInteger(this.state.survey.get('frotherBuildingBlockIdTwo'))) {
            selectedBuildingBlocks.push(this.state.survey.get('frotherBuildingBlockIdTwo'));
        }
        if (Number.isInteger(this.state.survey.get('frotherBuildingBlockIdThree'))) {
            selectedBuildingBlocks.push(this.state.survey.get('frotherBuildingBlockIdThree'));
        }

        return this.props.frotherBuildingBlocks.filter((buildingBlock: FrotherBuildingBlock) => {
            return !selectedBuildingBlocks.includes(buildingBlock.get('id'));
        });
    };

    /**
     * Calculate the maximum value a input can have.  Limit is 100.
     * To achieve the correct value, we substract every % input from 100, but we keep the calculated input value.
     */
    calculateNumberInputMaximum = (inputSelfNumber: number): number => {
        return 100 - this.getCustomBlendTotalPercentage() + inputSelfNumber;
    };

    /**
     * Helper function which controls the flow of which stage of the Survey to render
     */
    renderSurveyContent = (current: string) => {
        switch (current) {
            case SURVEY_TYPE_PRIMARY:
                return this.renderPrimaryQuestionnaire();
            case SURVEY_TYPE_SECONDARY:
                return this.renderSecondaryQuestionnaire();
            case SURVEY_TYPE_TERTIARY:
                return this.renderTertiaryQuestionnaire();
        }
    };

    /**
     * Helper function for rendering the title
     */
    renderTitle = () => {
        if (this.props.isSidebar) {
            return null;
        }

        const intlText =
            this.state.currentPage === SURVEY_TYPE_PRIMARY
                ? 'components.FrotherSurveyForm.primaryFormTitle'
                : 'components.FrotherSurveyForm.secondaryFormTitle';

        return <Title>{this.props.intl.formatMessage({ id: intlText })}</Title>;
    };

    /**
     * Helper function for rendering the survey header
     */
    renderSurveyHeader = () =>
        this.props.isSidebar && (
            <SurveyHeader>
                <ButtonGrid
                    onClick={this.handleSetCurrentPage}
                    options={[
                        {
                            label: this.props.intl.formatMessage({
                                id: 'views.Blocks.frotherSurveyAnswers',
                            }),
                            value: SURVEY_TYPE_PRIMARY,
                        },
                        {
                            label: this.props.intl.formatMessage({
                                id: 'views.Blocks.frotherPriorAnswers',
                            }),
                            value: SURVEY_TYPE_SECONDARY,
                        },
                        {
                            label: this.props.intl.formatMessage({
                                id: 'views.Blocks.frotherFilters',
                            }),
                            value: SURVEY_TYPE_TERTIARY,
                        },
                    ]}
                    value={this.state.currentPage}
                    multilines
                />
            </SurveyHeader>
        );

    /**
     * Rendering the frother custom blend building blocks.  Simple selectinon of a product with a percentage.  No recommendations or anything else.
     * Number input can only be used by their spinner and steps by 10
     */
    renderFrotherCustomBlend = () => {
        const { frotherBuildingBlocks } = this.props;
        const filteredBuildingBlocks = this.getFilteredBuildingBlocks();
        return (
            <SurveyQuestionnaire
                type={'custom'}
                key={'customBlendPercentage'}
                questionKey={'customBlendPercentage'}
                text={'Enter the custom Building Block composition:'}
                onValueChangeHandler={this.handleOnChange}
            >
                <FrotherSurveyCustomBlendBlock>
                    <FrotherSurveyCustomBlendBlockId>
                        <InputSelect
                            onSelect={this.handleOnSelectChange('frotherBuildingBlockIdOne')}
                            isDisabled={getIndividualCustomBlendDisableRule(
                                this.state.survey,
                                'frotherBuildingBlockIdOne'
                            )}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.FrotherSurveyForm.frotherBuildingBlockId',
                            })}
                            controlShouldRenderValue
                            options={frotherBuildingBlockToSelectList(filteredBuildingBlocks)}
                            width={'50%'}
                            selectedOption={getSelectOptionForSelectList(
                                this.state.survey.get('frotherBuildingBlockIdOne'),
                                frotherBuildingBlockToSelectList(frotherBuildingBlocks)
                            )}
                        />
                    </FrotherSurveyCustomBlendBlockId>
                    <FrotherSurveyCustomBlendBlockPercentage>
                        <InputNumber
                            min={0}
                            max={this.calculateNumberInputMaximum(
                                this.state.survey.get('frotherBuildingBlockIdOnePercentage')
                            )}
                            onKeyPress={this.handleOnInputNumberKeyPress}
                            onKeyDown={this.handleOnInputNumberKeyDown}
                            step={10}
                            disabled={getIndividualCustomBlendDisableRule(
                                this.state.survey,
                                'frotherBuildingBlockIdOne'
                            )}
                            value={this.state.survey.get('frotherBuildingBlockIdOnePercentage')}
                            onChange={this.handleOnNumberChange(
                                'frotherBuildingBlockIdOnePercentage'
                            )}
                            unit={'%'}
                        />
                    </FrotherSurveyCustomBlendBlockPercentage>
                </FrotherSurveyCustomBlendBlock>
                <FrotherSurveyCustomBlendBlock>
                    <FrotherSurveyCustomBlendBlockId>
                        <InputSelect
                            onSelect={this.handleOnSelectChange('frotherBuildingBlockIdTwo')}
                            isDisabled={getIndividualCustomBlendDisableRule(
                                this.state.survey,
                                'frotherBuildingBlockIdTwo'
                            )}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.FrotherSurveyForm.frotherBuildingBlockId',
                            })}
                            controlShouldRenderValue
                            options={frotherBuildingBlockToSelectList(filteredBuildingBlocks)}
                            selectedOption={getSelectOptionForSelectList(
                                this.state.survey.get('frotherBuildingBlockIdTwo'),
                                frotherBuildingBlockToSelectList(frotherBuildingBlocks)
                            )}
                        />
                    </FrotherSurveyCustomBlendBlockId>
                    <FrotherSurveyCustomBlendBlockPercentage>
                        <InputNumber
                            min={0}
                            max={this.calculateNumberInputMaximum(
                                this.state.survey.get('frotherBuildingBlockIdTwoPercentage')
                            )}
                            onKeyPress={this.handleOnInputNumberKeyPress}
                            onKeyDown={this.handleOnInputNumberKeyDown}
                            step={10}
                            disabled={getIndividualCustomBlendDisableRule(
                                this.state.survey,
                                'frotherBuildingBlockIdTwo'
                            )}
                            value={this.state.survey.get('frotherBuildingBlockIdTwoPercentage')}
                            onChange={this.handleOnNumberChange(
                                'frotherBuildingBlockIdTwoPercentage'
                            )}
                            unit={'%'}
                        />
                    </FrotherSurveyCustomBlendBlockPercentage>
                </FrotherSurveyCustomBlendBlock>
                <FrotherSurveyCustomBlendBlock>
                    <FrotherSurveyCustomBlendBlockId>
                        <InputSelect
                            onSelect={this.handleOnSelectChange('frotherBuildingBlockIdThree')}
                            isDisabled={getIndividualCustomBlendDisableRule(
                                this.state.survey,
                                'frotherBuildingBlockIdThree'
                            )}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.FrotherSurveyForm.frotherBuildingBlockId',
                            })}
                            controlShouldRenderValue
                            options={frotherBuildingBlockToSelectList(filteredBuildingBlocks)}
                            selectedOption={getSelectOptionForSelectList(
                                this.state.survey.get('frotherBuildingBlockIdThree'),
                                frotherBuildingBlockToSelectList(frotherBuildingBlocks)
                            )}
                        />
                    </FrotherSurveyCustomBlendBlockId>
                    <FrotherSurveyCustomBlendBlockPercentage>
                        <InputNumber
                            min={0}
                            max={this.calculateNumberInputMaximum(
                                this.state.survey.get('frotherBuildingBlockIdThreePercentage')
                            )}
                            onKeyPress={this.handleOnInputNumberKeyPress}
                            onKeyDown={this.handleOnInputNumberKeyDown}
                            step={10}
                            disabled={getIndividualCustomBlendDisableRule(
                                this.state.survey,
                                'frotherBuildingBlockIdThree'
                            )}
                            value={this.state.survey.get('frotherBuildingBlockIdThreePercentage')}
                            onChange={this.handleOnNumberChange(
                                'frotherBuildingBlockIdThreePercentage'
                            )}
                            unit={'%'}
                        />
                    </FrotherSurveyCustomBlendBlockPercentage>
                </FrotherSurveyCustomBlendBlock>
            </SurveyQuestionnaire>
        );
    };

    renderPrimaryQuestionnaire = () => (
        <QuestionList marginTop={!this.props.isSidebar && '20px'}>
            {FROTHER_SURVEY_PRIMARY_QUESTIONNAIRE.map((question: SurveyQuestionnaireType) => {
                // Radiobutton component compares provided value with value of options
                const rawValue = this.state.survey.get(question.key);
                const value = question.type === 'boolean' ? Number(rawValue) : rawValue;
                const isDisabled = getIndividualPrimaryQuestionDisableRule(
                    this.state.survey,
                    question.key
                );
                return (
                    <SurveyQuestionnaire
                        key={question.key}
                        disabled={isDisabled}
                        max={question.max}
                        min={question.min}
                        note={question.note}
                        onValueChangeHandler={this.handleOnChange}
                        options={question.options}
                        questionKey={question.key}
                        text={question.text}
                        type={question.type}
                        value={value}
                        error={this.state.questionnaireErrors.get(question.key)}
                        renderString={this.props.project && !this.props.project.get('isOwner')}
                    />
                );
            })}
        </QuestionList>
    );

    renderSecondaryQuestionnaire = () => (
        <QuestionList marginTop={!this.props.isSidebar && '20px'}>
            {frotherSurveySecondaryQuestions(
                frotherProductsToSelectList(this.props.frotherProducts)
            ).map((question: SurveyQuestionnaireType) => {
                // Radiobutton component compares provided value with value of options
                const rawValue = this.state.survey.get(question.key);
                const value = question.type === 'boolean' ? Number(rawValue) : rawValue;
                const isDisabled = getIndividualCustomBlendDisableRule(
                    this.state.survey,
                    question.key
                );

                return (
                    <SurveyQuestionnaire
                        key={question.key}
                        disabled={
                            !this.props.project ||
                            (this.props.project && !this.props.project.get('isOwner')) ||
                            isDisabled
                        }
                        max={question.max}
                        min={question.min}
                        note={question.note}
                        onValueChangeHandler={this.handleOnChange}
                        selectOptions={question.selectOptions}
                        options={question.options}
                        questionKey={question.key}
                        text={question.text}
                        type={question.type}
                        value={value}
                        error={this.state.questionnaireErrors.get(question.key)}
                        renderString={this.props.project && !this.props.project.get('isOwner')}
                    />
                );
            })}
            {/* Render the custom frother blend block composition with percentage */}
            {this.renderFrotherCustomBlend()}
        </QuestionList>
    );

    renderTertiaryQuestionnaire = () => (
        <QuestionList marginTop={!this.props.isSidebar && '20px'}>
            {FROTHER_BUILDING_BLOCK_FILTERS_QUESTIONS.map((question: SurveyQuestionnaireType) => {
                const rawValue = this.state.frotherBlendsFilter.get(question.key);
                const value = question.type === 'boolean' ? Number(rawValue) : rawValue;
                return (
                    <SurveyQuestionnaire
                        key={question.key}
                        max={question.max}
                        min={question.min}
                        note={question.note}
                        isFilter={question.isFilter}
                        onFilterClearIconClick={this.handleOnFilterClearIconClick}
                        disabled={false}
                        onValueChangeHandler={this.handleOnFrotherBlendFilterChange}
                        selectOptions={question.selectOptions}
                        options={question.options}
                        questionKey={question.key}
                        text={question.text}
                        type={question.type}
                        value={value}
                        error={this.state.questionnaireErrors.get(question.key)}
                        renderString={this.props.project && !this.props.project.get('isOwner')}
                    />
                );
            })}
        </QuestionList>
    );

    renderSurveyControls = () => {
        return (
            <SurveyControls
                currentPage={this.state.currentPage}
                disableSubmit={false}
                errorValueSets={false}
                onGoToSecondarySurvey={this.onSetCurrentPage(SURVEY_TYPE_SECONDARY)}
                onGoToBuildingBlocks={this.props.goToBuildingBlocks}
                onGoToMainSurvey={this.onSetCurrentPage(SURVEY_TYPE_PRIMARY)}
                onSurveySubmit={this.handleSurveySubmit}
                onSurveyRevert={this.handleSurveyRevert}
                project={this.props.project}
                view={this.props.isSidebar ? VIEW_BLOCK : VIEW_SURVEY}
            />
        );
    };

    renderFilterButtons = () => {
        return (
            <Common.Row>
                <Common.Column>
                    <PrimaryButton
                        text={this.props.intl.formatMessage({ id: 'views.Blocks.applyFilters' })}
                        borderRadius="15px"
                        backgroundColor={LegacyTheme.buildingBlockButtonPrimary}
                        onClick={this.handleOnFrotherBlendFilterClick}
                    />
                </Common.Column>
                <Common.Column alignItems="flex-end">
                    <PrimaryButton
                        text={this.props.intl.formatMessage({ id: 'views.Blocks.clearFilters' })}
                        borderRadius="15px"
                        backgroundColor={LegacyTheme.buildingBlockButtonPrimary}
                        onClick={this.handleOnFrotherBlendFilterClear}
                    />
                </Common.Column>
            </Common.Row>
        );
    };

    render() {
        if (!this.props.project) {
            return null;
        }

        return (
            <SurveyContainer flex={this.props.isSidebar}>
                {this.renderTitle()}
                {this.renderSurveyHeader()}
                <SurveyBody id="surveyBody">
                    {this.renderSurveyContent(this.state.currentPage)}
                </SurveyBody>
                <SurveyFooter>
                    {this.state.currentPage === SURVEY_TYPE_TERTIARY
                        ? this.renderFilterButtons()
                        : this.renderSurveyControls()}
                </SurveyFooter>
            </SurveyContainer>
        );
    }
}

export default withRouter(injectIntl(FrotherSurveyForm));
