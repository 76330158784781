// @flow

/*
 *
 * Authentication reducer
 *
 */

import { LOCATION_CHANGE } from 'react-router-redux';
import { fromJS } from 'immutable';

// Actions
import {
    ACCEPT_DISCLAIMER_FAIL,
    ACCEPT_DISCLAIMER_SUCCESS,
    ACCEPT_DISCLAIMER_SAVING,
    AUTHENTICATION_FAIL,
    AUTHENTICATION_SUCCESS,
    FIREBASE_AUTH_SUCCESS,
    LOGOUT_FAIL,
    LOGOUT_SUCCESS,
    USER_IS_FETCHING,
    WHOAMI_FAIL,
    WHOAMI_SUCCESS,
    IS_UPDATING_SETTINGS,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAILURE,
} from './actions';

// Types
import type { GenericActionType } from 'types';

const initialState: ImmutableAuthenticationState = fromJS({
    acceptDisclaimerSaving: false,
    user: null,
    userIsFetching: false,
    isUpdatingSettings: false,
    errors: {},
});

function authenticationServiceReducer(
    state: ImmutableAuthenticationState = initialState,
    action: GenericActionType
) {
    switch (action.type) {
        case FIREBASE_AUTH_SUCCESS: {
            const {
                payload: { user },
            } = action;
            // If user, set api_token in local storage
            if (user) {
                localStorage.setItem('api_token', user.accessToken);
            }

            return state;
        }
        case ACCEPT_DISCLAIMER_SUCCESS:
        case AUTHENTICATION_SUCCESS:
        case WHOAMI_SUCCESS: {
            const {
                payload: { user },
            } = action;

            if (user) {
                // Delete accessToken before saving user to state
                delete user.accessToken;
                user.backendUser = true;
            }

            return state
                .set('user', fromJS(user))
                .set('userIsFetching', false)
                .set('acceptDisclaimerSaving', false)
                .set('errors', fromJS({}));
        }
        case ACCEPT_DISCLAIMER_FAIL:
        case AUTHENTICATION_FAIL:
        case WHOAMI_FAIL:
            return state
                .set('user', fromJS({}))
                .set('userIsFetching', false)
                .set('acceptDisclaimerSaving', false)
                .set('errors', fromJS(action.payload.errors));
        case LOGOUT_SUCCESS:
            localStorage.removeItem('api_token');
            // On logout, clear start to avoid stale data
            return initialState;
        case LOGOUT_FAIL:
            localStorage.removeItem('api_token');
            return state.set('user', null).set('errors', fromJS(action.payload.errors));
        case LOCATION_CHANGE:
            // TODO: Look into why this was added; It's causing an issue where we loose our errors once we redirect
            // if (state.get('errors').size !== 0) {
            //     return state.set('errors', fromJS({}));
            // }
            return state;
        case USER_IS_FETCHING:
            return state.set('userIsFetching', action.payload.userIsFetching);
        case ACCEPT_DISCLAIMER_SAVING:
            return state.set('acceptDisclaimerSaving', action.payload.acceptDisclaimerSaving);
        case IS_UPDATING_SETTINGS:
            return state.set('isUpdatingSettings', action.payload.isUpdatingSettings);
        case UPDATE_SETTINGS_SUCCESS: {
            const user = action.payload.user;

            if (user) {
                user.backendUser = true;
            }

            return state
                .set('user', fromJS(user))
                .set('errors', fromJS({}))
                .set('isUpdatingSettings', false);
        }
        case UPDATE_SETTINGS_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isUpdatingSettings', false);
        default:
            return state;
    }
}

export default authenticationServiceReducer;
