// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const BlockContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;

    width: 100%;
    height: 40px;

    padding-left: 20px;
    padding-right: 10px;

    background-color: ${({ backgroundColor }) => backgroundColor};
    border: 1px solid ${({ borderColor }) => borderColor};
    ${({ open }) =>
        open
            ? `
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    `
            : `
        border-radius: 4px;
    `}

    color: ${LegacyTheme.black};
`;

export const Split = styled.div`
    width: 2px;
    height: 50%;
    background-color: ${({ color }) => color};
    margin-right: 11px;
`;

export const Name = styled.div`
    flex: 2;

    padding: 6px;
    padding-left: 0;

    font-size: ${({ fontSize }) => fontSize || '16px'};
    color: ${({ disabled }) =>
        disabled ? LegacyTheme.defaultSubtleColor : LegacyTheme.defaultColor};
`;

export const Description = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;

    font-size: 12px;
`;

export const Circle = styled.div`
    border-radius: 50%;
    background-color: ${(props) => props.color || LegacyTheme.buildingDefaultIcon};
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: ${(props) => props.maxWidth || 'none'};
`;

export const CheckboxWrapper = styled.div`
    align-self: flex-start;

    margin-top: 10px;
    margin-right: 10px;
`;

export const CloseWrapper = styled.div`
    align-self: flex-start;

    margin-top: 10px;
    margin-right: ${(props) => props.closeIconMarginRight || '20px'};
`;

export const BlockWrapper = styled.div`
    width: 100%;
    position: relative;
`;

export const DropDownContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    max-height: 300px;
    overflow: auto;

    padding: 20px 24px;

    border: 1px solid ${LegacyTheme.defaultBorder};
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    font-size: 14px;
    line-height: 20px;
`;

export const DropDownHeader = styled.h1`
    font-size: 17px;
    font-weight: normal;

    color: ${LegacyTheme.black};
`;

export const DropDownContent = styled.div`
    font-size: 14px;
    font-weight: normal;

    color: ${LegacyTheme.defaultColor};

    > p {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const Icon = styled.img`
    width: 10px;
    height: 6px;
`;
