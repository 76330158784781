// @flow

import request from 'services/request';
import { decamelize, decamelizeKeys } from 'humps';

// Types
import type { ProjectType, ProjectSearchCriteriaType, CustomBlendType } from './types';
import type { FrotherBlendsFilter } from 'services/BuildingBlock/types';

import { mapProductOptionsToIds } from 'utils/helpers';

/*
* Create project
*/
export const create = (project: ProjectType) =>
    request('projects', {
        method: 'POST',
        data: project,
    });

/*
* Fetch paginated entries of the resource with search criteria for filtering
*
* searchCriteriaUpdated: decamlizing sortBy to make backend happy
*/
export const index = (
    searchCriteria: ProjectSearchCriteriaType = {},
    page: number = 1,
    perPage: number = 10
) => {
    const options = decamelizeKeys({
        page,
        perPage,
        ...searchCriteria,
        selectedProducts: searchCriteria.selectedProducts
            ? mapProductOptionsToIds(searchCriteria.selectedProducts)
            : [],
        sortBy: searchCriteria.sortBy ? decamelize(searchCriteria.sortBy) : '',
    });
    return request('projects', { method: 'GET' }, { ...options });
};

/**
 * Fetches project by id
 *
 * @param {number} id
 */
export const show = (
    id: number,
    relations: Array<string> = [],
    blendFilters: FrotherBlendsFilter = {}
) => {
    const options = decamelizeKeys({
        ...blendFilters,
    });

    return request(
        `projects/${id}`,
        {
            method: 'GET',
        },
        { relations, ...options }
    );
};

/*
* Update project by id
*/
export const update = (id: number, project: ProjectType) =>
    request(`projects/${id}`, {
        method: 'PUT',
        data: project,
    });

export const reportProject = (id: number) =>
    request(`projects/${id}/report`, {
        method: 'PUT',
    });

/**
 * Create custom product blend
 */
export const createCustomProductBlend = (projectId: number, customBlend: CustomBlendType) =>
    request(`projects/${projectId}/customize-product`, {
        method: 'POST',
        data: customBlend,
    });

/**
 * Submits list of product ids to be attached to a project's discovery agreement
 */
export const addProductsToDiscovery = (projectId: number, productIds: Array<number>) =>
    request(`projects/${projectId}/products`, {
        method: 'POST',
        data: {
            products: productIds,
        },
    });

/**
 * Requests to get the discovery agreement attached to the project if it exists.
 */
export const getDiscoveryAgreement = (projectId: number) =>
    request(`projects/${projectId}/agreement`);

/**
 * Submits the form data collected in the Discovery Agreement to the backend endpoint to be saved in the DB.
 */
export const saveDiscoveryAgreementToProject = (projectId: number, formData: {}) =>
    request(
        `projects/${projectId}/agreement`,
        {
            method: 'POST',
            data: formData,
            headers: {
                contentType: 'multipart/form-data',
            },
        },
        // eslint-disable-next-line no-undefined
        undefined,
        // eslint-disable-next-line no-undefined
        undefined,
        true, // humps
        true // is form data?
    );

/**
 * Requests the list of company addresses used in Discovery for company address select options.
 */
export const getCompanyAddressesList = () => request(`addresses`, { method: 'GET' });

/*
* Delete project by id
*/
export const del = (id: number) =>
    request(`projects/${id}`, {
        method: 'DELETE',
    });
