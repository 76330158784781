// @flow

import { fromJS } from 'immutable';

// Actions
import {
    FETCH_PRODUCTS_LIST_SUCCESS,
    FETCH_PRODUCTS_LIST_FAILURE,
    LIST_IS_FETCHING,
    FETCH_PRODUCT_TABLE_LIST_SUCCESS,
    FETCH_PRODUCT_TABLE_LIST_FAILURE,
    PRODUCT_TABLE_LIST_IS_FETCHING,
} from './actions';

// Types
import type { GenericActionType } from 'types';

type State = {
    list: [],
    listIsFetching: boolean,
    productTableList: [],
    productTableListIsFetching: false,
    errors: {},
};

const initialState = fromJS({
    list: [],
    listIsFetching: false,
    productTableList: [],
    productTableListIsFetching: false,
    errors: {},
});

/**
 * Reducer
 *
 * Switch statement to set state based on current action type
 */

function productServiceReducer(state: State = initialState, action: GenericActionType) {
    switch (action.type) {
        case LIST_IS_FETCHING: {
            return state.set('listIsFetching', action.payload.listIsFetching);
        }

        case PRODUCT_TABLE_LIST_IS_FETCHING: {
            return state.set('productTableListIsFetching', action.payload.listIsFetching);
        }

        case FETCH_PRODUCTS_LIST_SUCCESS: {
            return state
                .set('list', fromJS(action.payload.data))
                .set('listIsFetching', false)
                .set('errors', fromJS({}));
        }

        case FETCH_PRODUCT_TABLE_LIST_SUCCESS: {
            let productList = action.payload.data;
            productList = productList.map((product) => {
                // Get the ffp blend score relationship.  This relationship return as a array, even if it only ever has 1 score
                if (product.ffpBlendScore) {
                    const ffpBlendScore = product.ffpBlendScore[0];
                    // Format the ffp blend score as a pivot attribute
                    product.pivot = {
                        stability: ffpBlendScore.stability,
                        mobility: ffpBlendScore.mobility,
                        bubbleSize: ffpBlendScore.bubbleSize,
                        selectivity: ffpBlendScore.selectivity,
                        dosageEfficiency: ffpBlendScore.ccc,
                        persistence: ffpBlendScore.persistence,
                    };
                }
                return product;
            });
            return state
                .set('productTableList', fromJS(productList))
                .set('productTableListIsFetching', false)
                .set('errors', fromJS({}));
        }

        case FETCH_PRODUCTS_LIST_FAILURE:
            return state.set('listIsFetching', false).set('errors', fromJS(action.payload.errors));

        case FETCH_PRODUCT_TABLE_LIST_FAILURE: {
            return state
                .set('productTableListIsFetching', fromJS(action.payload.errors))
                .set('productTableListIsFetching', false);
        }

        default:
            return state;
    }
}

export default productServiceReducer;
