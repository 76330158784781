// @flow

import { camelize } from 'humps';
import moment from 'moment';

// Types
import type {
    ImmutableRoleType,
    ImmutableUserType,
} from 'management/services/Authentication/types';
import type { DisclaimerType } from 'management/services/Disclaimer/types';
import type { IntlType } from 'types';

export const formatDisclaimers = (disclaimers: Array<DisclaimerType>, intl: IntlType): ?Array<{}> =>
    disclaimers.map((disclaimer: DisclaimerType) => ({
        id: disclaimer.id,
        actionButtonText: intl.formatMessage({
            id: `management.utils.formatDisclaimers.disclaimerActionButtonKey.${camelize(
                disclaimer.key
            )}`,
        }),
        key: camelize(disclaimer.key),
        title: disclaimer.title,
        content: disclaimer.content,
        updatedAt: intl.formatMessage(
            { id: 'management.utils.formatDisclaimers.editedOn' },
            { date: moment(disclaimer.updatedAt).format('MMM. DD, YYYY') }
        ),
        warningNote: intl.formatMessage({
            id: 'management.utils.formatDisclaimers.disclaimerWarningNote',
        }),
    }));

export function userHasRoleBySlug(user: ImmutableUserType, roleSlug: string) {
    if (!user || (user && !user.has('roles')) || !roleSlug) {
        return false;
    }
    return user
        .get('roles')
        .find(
            (role: ImmutableRoleType) => role.get('slug').toUpperCase() === roleSlug.toUpperCase()
        );
}

/**
 * Provides the "translated" of "full" name of the role based on the slug provided by backend.
 * Should be represented in project level translations as follows:
 *
 * "management": {
 *     "utils": {
 *         "provideFullRoleName": {
 *             "singular": {
 *                 "ADMIN": "Admin",
 *                 "SAM": "Solvay Account Manager",
 *                 "PM": "Plant Metallurgist"
 *             },
 *             "pluralized" : {
 *                 "ADMIN": "Admins",
 *                 "SAM": "Solvay Account Managers",
 *                 "PM": "Plant Metallurgists"
 *             }
 *         }
 *     }
 * },
 */
export const provideFullRoleName = (
    roleSlug: string,
    intl: IntlType,
    pluralized?: boolean = false
) =>
    roleSlug &&
    intl.formatMessage({
        id: `management.utils.provideFullRoleName.${
            pluralized ? 'pluralized' : 'singular'
        }.${roleSlug.toUpperCase()}`,
    });
