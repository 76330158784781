// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

// Components
import { Common, Loader, SidebarLayout } from 'OsedeaReactUI';
import CollectorSurveyForm from 'components/CollectorSurveyForm';
import FrotherSurveyForm from 'components/FrotherSurveyForm';
import SurveyIncompleteContainer from 'components/SurveyIncompleteContainer';
import SurveySubmitLoader from 'components/SurveySubmitLoader';

// Selectors
import { selectSingleProject, selectSingleProjectIsFetching } from 'services/Project/selectors';
import {
    selectSurvey,
    selectSurveyIsFetching,
    selectSurveyIsSubmitting,
} from 'services/Survey/selectors';
import { selectFrotherBuildingBlocks } from 'services/BuildingBlock/selectors';
import { selectFrotherProducts } from 'services/Product/selectors';

// Styles
import { LoaderWrapper } from 'styles/common';
import { SurveyWrapper } from './styles';

// Thunks
import { fetchProject } from 'services/Project/thunks';
import {
    createCollectorSurvey,
    createFrotherSurvey,
    fetchSurveyByIdAndType,
    initDefaultSurvey,
    updateCollectorSurvey,
    updateFrotherSurvey,
    clearSurvey,
} from 'services/Survey/thunks';
import { fetchBuildingBlocksByProjectType } from 'services/BuildingBlock/thunks';

// Constant
import { PROJECT_TYPE } from 'utils/constants';

// Types
import type { ReduxDispatch, IntlType, ImmutableMap } from 'types';
import type { ProjectType } from 'services/Project/types';
import type { CollectorSurveyType, FrotherSurveyType } from 'services/Survey/types';
import type { FrotherBuildingBlock } from 'services/BuildingBlock/types';
import type { ProductType } from 'services/Product/types';

type Props = {
    createCollectorSurvey: (number, CollectorSurveyType) => void,
    createFrotherSurvey: (number, FrotherSurveyType) => void,
    fetchProject: (number) => void,
    fetchSurveyByIdAndType: (number, string) => void,
    initDefaultSurvey: (number, string) => void,
    intl: IntlType,
    match?: {
        params: {
            projectId: ?string,
        },
    },
    survey: ?CollectorSurveyType,
    surveyIsFetching: boolean,
    surveyIsSubmitting?: boolean,
    projectIsFetching: boolean,
    clearSurvey: () => void,
    project?: ProjectType,
    frotherBuildingBlocks: ImmutableList<FrotherBuildingBlock>,
    frotherProducts: ImmutableList<ProductType>,
    updateCollectorSurvey: (number, CollectorSurveyType, boolean) => void,
    updateFrotherSurvey: (number, FrotherSurveyType, boolean) => void,
    fetchBuildingBlocksByProjectType: (projectType: string) => void,
};

export class Survey extends React.Component<Props> {
    static defaultProps = {
        match: {
            params: {
                projectId: null,
            },
        },
        project: null,
        surveyIsSubmitting: false,
    };

    componentDidMount() {
        const projectId = Number(this.props.match.params.projectId);
        if (projectId) {
            this.props.fetchProject(projectId).then((response) => {
                const surveyFilled = this.props.project && this.props.project.get('surveyFilled');
                const projectType = this.props.project && this.props.project.get('projectType');

                // get building blocks for a frother project
                if (projectType === PROJECT_TYPE.FROTHER) {
                    this.props.fetchBuildingBlocksByProjectType(PROJECT_TYPE.FROTHER);
                }
                if (surveyFilled) {
                    this.props.fetchSurveyByIdAndType(projectId, projectType);
                } else if (projectId) {
                    this.props.initDefaultSurvey(projectId, projectType);
                } else {
                    throw new Error('Cannot fetch survey without project id');
                }
            });
        }
    }

    componentWillUnmount() {
        this.props.clearSurvey();
    }

    handleSubmitCollectorSurvey = (survey: CollectorSurveyType) => {
        const { project } = this.props;
        const projectId = project && project.get('id');

        if (project && project.get('surveyFilled')) {
            this.props.updateCollectorSurvey(projectId, survey.toJS(), true);
        } else {
            this.props.createCollectorSurvey(projectId, survey.toJS());
        }
    };

    handleSubmitFrotherSurvey = (survey: FrotherSurveyType) => {
        const { project } = this.props;
        const projectId = project && project.get('id');
        if (project && project.get('surveyFilled')) {
            this.props.updateFrotherSurvey(projectId, survey.toJS(), true);
        } else {
            this.props.createFrotherSurvey(projectId, survey.toJS());
        }
    };

    goToBuildingBlocks = () => {
        this.props.history.push(`/project/${this.props.project.get('id')}/building-blocks`);
    };

    renderCollectorSurvey = () => (
        <SurveyWrapper>
            <CollectorSurveyForm
                onSubmit={this.handleSubmitCollectorSurvey}
                project={this.props.project}
                goToBuildingBlocks={this.goToBuildingBlocks}
                survey={this.props.survey}
                surveyFilled={Boolean(this.props.project.get('surveyFilled'))}
            />
        </SurveyWrapper>
    );

    renderFrotherSurvey = () => (
        <SurveyWrapper>
            <FrotherSurveyForm
                onSubmit={this.handleSubmitFrotherSurvey}
                project={this.props.project}
                frotherBuildingBlocks={this.props.frotherBuildingBlocks}
                frotherProducts={this.props.frotherProducts}
                goToBuildingBlocks={this.goToBuildingBlocks}
                survey={this.props.survey}
                surveyFilled={Boolean(this.props.project.get('surveyFilled'))}
            />
        </SurveyWrapper>
    );

    renderMain = () => {
        const { project } = this.props;
        switch (project.get('projectType')) {
            case PROJECT_TYPE.COLLECTOR:
                return this.renderCollectorSurvey();
            case PROJECT_TYPE.FROTHER:
                return this.renderFrotherSurvey();
        }
    };

    render() {
        let content = (
            <LoaderWrapper>
                <Loader loading />
            </LoaderWrapper>
        );

        // Validate if local survey belongs to project
        const newSurvey = this.props.survey && !this.props.survey.has('projectId');
        const isProperSurvey =
            newSurvey ||
            !(
                newSurvey &&
                Number(this.props.match.params.projectId) ===
                    Number(this.props.survey && this.props.survey.get('projectId'))
            );

        if (this.props.surveyIsSubmitting) {
            content = <SurveySubmitLoader projectType={this.props.project.get('projectType')} />;
        } else if (
            this.props.project &&
            !this.props.project.get('surveyFilled') &&
            !this.props.project.get('isOwner')
        ) {
            content = (
                <Common.Column justifyContent="center" alignItems="center">
                    <SurveyIncompleteContainer />
                </Common.Column>
            );
        } else if (
            this.props.project &&
            this.props.survey &&
            isProperSurvey &&
            !this.props.surveyIsFetching &&
            !this.props.projectIsFetching
        ) {
            content = <SidebarLayout renderMain={this.renderMain} mainMaxWidth={'720px'} />;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'views.Survey.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.Survey.helmetDescription',
                        })}
                    />
                </Helmet>
                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    project: selectSingleProject(),
    survey: selectSurvey(),
    surveyIsFetching: selectSurveyIsFetching(),
    frotherProducts: selectFrotherProducts(),
    projectIsFetching: selectSingleProjectIsFetching(),
    surveyIsSubmitting: selectSurveyIsSubmitting(),
    frotherBuildingBlocks: selectFrotherBuildingBlocks(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            createCollectorSurvey,
            createFrotherSurvey,
            fetchProject,
            clearSurvey,
            fetchSurveyByIdAndType,
            initDefaultSurvey,
            updateCollectorSurvey,
            updateFrotherSurvey,
            fetchBuildingBlocksByProjectType,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(injectIntl(Survey)));
