// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';
import { Flex, FooterContainer as CommonFooterContainer } from 'styles/common';

export const MainContainer = styled.div`
    display: flex;
    height: 464px;
`;

export const SelectionContainer = styled.div`
    width: 733px;
    padding: 26px;
    overflow-y: scroll;
`;

export const RecommendationsContainer = styled.div`
    width: 311px;
    padding: 26px 30px;
    background-color: ${LegacyTheme.sidebarLayoutSidebar};
`;

export const FooterContainer = styled(CommonFooterContainer)`
    justify-content: space-between;

    height: 62px;

    margin: 0;
    padding: 16px;

    border-top: 1px solid ${LegacyTheme.tableSubtleBorder};
`;

const Header = styled.div`
    font-family: Helvetica;
    font-size: 20px;
`;

export const CustomBlendHeader = styled(Header)`
    margin-left: 22px;
`;

export const BlendingRecommendationsHeader = styled(Header)``;

export const FooterText = styled.div`
    height: 32px;
    width: 560px;
    font-size: 12px;
    color: ${LegacyTheme.defaultSubtleColor7B};
`;

export const Content = styled.div`
    margin-top: 26px;

    > div {
        margin-bottom: 16px;
    }

    > button:last-child {
        margin-left: 22px;
    }
`;

export const MissingRecommendationsMessage = styled.div`
    color: ${LegacyTheme.defaultSubtleColor7B};
    font-size: 12px;
    text-align: center;
`;

export const BuildingBlockItemContainer = styled(Flex)`
    justify-content: space-between;
    align-items: flex-start;
`;

export const ProductExistsMessage = styled.div`
    color: ${LegacyTheme.defaultWarningColor};
    font-size: 16px;
    margin-bottom: 8px;
`;

export const ProductExistsInfo = styled.div`
    color: ${LegacyTheme.defaultWarningColor};
    font-size: 12px;
`;
