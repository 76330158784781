// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Constants
import { TEXTAREA_LIMIT } from 'utils/constants';

// Components
import { TextField } from 'OsedeaReactUI';

// Styles
import { Container, TableTitle, TextLengthLimit } from './styles';

// Flow Types
import type { IntlType } from 'types';
import type { ResultType } from 'services/Result/types';

type Props = {
    intl: IntlType,
    index: number,
    result: ResultType,
    isOwner: boolean,
    onHandleTextChange: () => void,
    onTextBlur: () => void,
};

/**
 * ResultTableDetails
 *
 * Toggleable result comments which can be expanded and collapsed.
 */

class ResultTableDetails extends React.PureComponent<Props, State> {
    commentOnChangeHandler = (name: string) => (event: EventType) => {
        const value = event.target.value;
        this.props.onHandleTextChange(
            this.props.index,
            name,
            value && value.length > TEXTAREA_LIMIT ? value.slice(0, TEXTAREA_LIMIT) : value
        );
    };

    onBlur = (index: number) => (event: EventType) => {
        this.props.onTextBlur(index);
    };

    testInputReachedLimit = (key: string) => {
        if (this.props.result.get(key)) {
            return this.props.result.get(key).length === TEXTAREA_LIMIT;
        }
        return false;
    };

    render() {
        const { intl } = this.props;

        return (
            <Container>
                <div>
                    <TableTitle>
                        {intl.formatMessage({
                            id: 'components.ResultTable.comment.labTesting.title',
                        })}
                    </TableTitle>
                    <TextField
                        onBlur={this.onBlur(this.props.index)}
                        placeholder={this.props.intl.formatMessage({
                            id: 'components.ResultTable.comment.placeholder',
                        })}
                        onChange={this.commentOnChangeHandler('labResultComment')}
                        value={this.props.result.get('labResultComment') || ''}
                        disabled={!this.props.isOwner}
                    />
                    {this.testInputReachedLimit('labResultComment') && (
                        <TextLengthLimit>
                            {intl.formatMessage(
                                {
                                    id: 'components.ResultTable.comment.limits',
                                },
                                { count: TEXTAREA_LIMIT }
                            )}
                        </TextLengthLimit>
                    )}
                </div>

                <div>
                    <TableTitle>
                        {intl.formatMessage({
                            id: 'components.ResultTable.comment.plantTrial.title',
                        })}
                    </TableTitle>
                    <TextField
                        onBlur={this.onBlur(this.props.index)}
                        placeholder={this.props.intl.formatMessage({
                            id: 'components.ResultTable.comment.placeholder',
                        })}
                        onChange={this.commentOnChangeHandler('plantTrialResultComment')}
                        value={this.props.result.get('plantTrialResultComment') || ''}
                        disabled={!this.props.isOwner}
                    />
                    {this.testInputReachedLimit('plantTrialResultComment') && (
                        <TextLengthLimit>
                            {intl.formatMessage(
                                {
                                    id: 'components.ResultTable.comment.limits',
                                },
                                { count: TEXTAREA_LIMIT }
                            )}
                        </TextLengthLimit>
                    )}
                </div>
            </Container>
        );
    }
}

export default injectIntl(ResultTableDetails);
