// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const SurveyBody = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;

    margin: 10px;
    padding: 0 14px;

    overflow: auto;
`;

export const SurveyFooter = styled.div`
    display: flex;
    flex: 0;

    padding 10px 24px 20px;
`;

export const SurveyHeader = styled.div`
    display: flex;
    flex: 0;

    padding 20px 24px 10px;
`;

export const SurveyContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    height: calc(100vh - 61px);
`;

export const SurveyIncompleteContainer = styled.div`
    display: block;
    width: 377px;
    text-align: center;
`;

export const BlockHeader = styled.div`
    font-size: 20px;
    line-height: 24px;
    display: flex;
    height: 30px;
`;

export const BlockButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 55px;

    button:first-child {
        margin-right: 16px;
    }
`;

export const ColumnLimiter = styled.div`
    width: 100%;
    max-width: ${({ maxWidth }) => maxWidth || 'none'};
`;
