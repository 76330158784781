// @flow

import type { ErrorsType, GenericActionType } from 'types';
import type { ImmutableUserType } from './types';

export const ACCEPT_DISCLAIMER_FAIL =
    'app/management/services/Authentication/ACCEPT_DISCLAIMER_FAIL';
export const ACCEPT_DISCLAIMER_SUCCESS =
    'app/management/services/Authentication/ACCEPT_DISCLAIMER_SUCCESS';
export const ACCEPT_DISCLAIMER_SAVING =
    'app/management/services/Authentication/ACCEPT_DISCLAIMER_SAVING';
export const AUTHENTICATION_FAIL = 'app/management/services/Authentication/AUTHENTICATION_FAIL';
export const AUTHENTICATION_SUCCESS =
    'app/management/services/Authentication/AUTHENTICATION_SUCCESS';
export const FIREBASE_AUTH_SUCCESS = 'app/management/services/Authentication/FIREBASE_AUTH_SUCCESS';
export const LOGOUT_SUCCESS = 'app/management/services/Authentication/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'app/management/services/Authentication/LOGOUT_FAIL';
export const USER_IS_FETCHING = 'app/management/services/Authentication/USER_IS_FETCHING';
export const WHOAMI_SUCCESS = 'app/management/services/Authentication/WHOAMI_SUCCESS';
export const WHOAMI_FAIL = 'app/management/services/Authentication/WHOAMI_FAIL';

export const IS_UPDATING_SETTINGS = 'app/managament/services/Authentication/IS_UPDATING_SETTINGS';
export const UPDATE_SETTINGS_SUCCESS =
    'app/managament/services/Authentication/UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILURE =
    'app/managament/services/Authentication/UPDATE_SETTINGS_FAILURE';

export const receivedAcceptDisclaimerFail = (errors: ErrorsType): GenericActionType => ({
    type: ACCEPT_DISCLAIMER_FAIL,
    payload: { errors },
});

export const receivedAcceptDisclaimerSuccess = (user: ImmutableUserType): GenericActionType => ({
    type: ACCEPT_DISCLAIMER_SUCCESS,
    payload: { user },
});

export const setAcceptDisclaimerSaving = (acceptDisclaimerSaving: boolean = true) => ({
    type: ACCEPT_DISCLAIMER_SAVING,
    payload: { acceptDisclaimerSaving },
});

export const receivedAuthenticationFail = (errors: ErrorsType): GenericActionType => ({
    type: AUTHENTICATION_FAIL,
    payload: { errors },
});

export const receivedAuthenticationSuccess = (user: ImmutableUserType): GenericActionType => ({
    type: AUTHENTICATION_SUCCESS,
    payload: { user },
});

export const receivedFirebaseAuthSuccess = (user: ImmutableUserType): GenericActionType => ({
    type: FIREBASE_AUTH_SUCCESS,
    payload: { user },
});

export const receivedLogoutSuccess = (response: boolean): GenericActionType => ({
    type: LOGOUT_SUCCESS,
    payload: { response },
});

export const receivedLogoutFail = (errors: ErrorsType): GenericActionType => ({
    type: LOGOUT_FAIL,
    payload: { errors },
});

export const receivedWhoamiSuccess = (user: ImmutableUserType): GenericActionType => ({
    type: WHOAMI_SUCCESS,
    payload: { user },
});

export const receivedWhoamiFail = (errors: ErrorsType): GenericActionType => ({
    type: WHOAMI_FAIL,
    payload: { errors },
});

export const setUserIsFetching = (userIsFetching: boolean = true) => ({
    type: USER_IS_FETCHING,
    payload: { userIsFetching },
});

export const setIsUpdatingSettingsStatus = (isUpdatingSettings: boolean = true) => ({
    type: IS_UPDATING_SETTINGS,
    payload: { isUpdatingSettings },
});

export const receivedUpdateSettingsSuccess = (user: boolean): GenericActionType => ({
    type: UPDATE_SETTINGS_SUCCESS,
    payload: { user },
});

export const receivedUpdateSettingsFailure = (errors: ErrorType): GenericActionType => ({
    type: UPDATE_SETTINGS_FAILURE,
    payload: { errors },
});
