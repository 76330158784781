// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid ${LegacyTheme.defaultBorder};
`;

export const SecondaryWrapper = styled.div`
    display: flex;
    flex: 1;
`;

export const PrimaryWrapper = styled.div`
    display: flex;
    flex: 5;
    justify-content: space-between;
    align-items: center;

    > div {
        margin: 0 auto;
    }
`;

export const Logo = styled.div`
    display: block;

    cursor: ${({ isHomePage }) => (isHomePage ? 'cursor' : 'pointer')};
    text-decoration: none;

    > img {
        width: 100%;
        max-width: 108px;
        height: auto;

        margin: 5px 0;
    }
`;

export const LogoContainer = styled.div`
    ${Logo} {
        margin-left: 20px;
    }
`;

export const SecondaryNav = styled.div`
    display: flex;
    flex: 1;

    justify-content: flex-end;
    align-items: center;

    > div {
        padding: 0 10px;

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 25px;
        }
    }
`;

export const ProjectIdentifier = styled.div`
    display: flex;

    color: ${LegacyTheme.defaultSubtleColor};

    font-size: 12px;
    font-weight: bold;

    white-space: nowrap;

    cursor: pointer;
`;

export const ProjectToolTipContent = styled.ul`
    min-width: 276px;
    margin: 0;
    padding: 24px 16px 16px;

    list-style: none;
    text-align: left;

    font-size: 16px;
    line-height: normal;

    > li {
        display: block;
        margin: 20px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
            text-align: right;
        }

        a {
            color: ${LegacyTheme.defaultColor};
        }
    }
`;

export const SubtleHeader = styled.div`
    margin-bottom: 5px;
    color: ${LegacyTheme.defaultSubtleColor};

    font-size: 12px;
`;

export const HelpCTA = styled.p`
    color: ${LegacyTheme.black} !important;
    a {
        color: ${LegacyTheme.toolTipLink};
    }
`;

export const HelpToolTip = styled.div`
    display: flex;

    align-items: center;

    color: ${LegacyTheme.defaultSubtleColor};

    font-size: 11px;

    cursor: pointer;
    text-decoration: none;

    white-space: nowrap;

    > span {
        margin-right: 5px;
    }
`;

export const HelpToolTipContent = styled.div`
    padding: 16px;

    p {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const AccountToolTipContent = styled.ul`
    padding: 16px;
    margin: 0;

    list-style: none;
    text-align: left;

    > li {
        display: block;
        margin: 10px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        a {
            color: ${LegacyTheme.defaultColor};
        }
    }
`;
