// @flow

import styled, { css } from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const AppWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    width: 100%;
    min-width: 1280px;
    height: 100vh;

    margin: 0 auto;

    box-sizing: border-box;
`;

export const Container = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    box-sizing: border-box;

    overflow: auto;
`;

export const ContainerCentered = styled.div`
    position: relative;

    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;

    background-color: ${LegacyTheme.defaultBackground};
`;

export const Title = styled.h1`
    color: ${LegacyTheme.defaultBoldColor};

    font-size: 20px;
    line-height: 1.2;
    font-weight: normal;
`;

export const Subtitle = styled.h2`
    color: ${LegacyTheme.defaultColor};

    font-size: 15px;
    font-weight: normal;
    line-height: 1.2;

    letter-spacing: -0.36px;
`;

export const NoteContainer = styled.div`
    width: 100%;
    padding: 16px 0;

    background-color: ${LegacyTheme.defaultBackground};
    text-align: ${({ textAlign }) => textAlign || 'inherit'};
`;

export const Note = styled.p`
    margin: 16px 0;

    color: ${({ color }) => color || LegacyTheme.defaultSubtleColor} !important;

    font-size: 12px;
    line-height: 1.2;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export const SectionDesc = styled.p`
    color: ${LegacyTheme.defaultColor};

    font-size: 12px;
    line-height: 1.2;
`;

export const SimpleList = styled.ul`
    padding: 0;
    margin: 20px 15px;

    list-style: none;

    > li {
        display: block;
        margin: 10px 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export const ToolTipPadding = styled.div`
    padding: 10px 16px;
`;

export const LoaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    > div {
        text-align: center;
    }

    ${Title} {
        margin-bottom: 24px;
    }

    ${({ style }) => style};
`;

export const Banner = styled.div`
    display: flex;
    flex: 0;
    justify-content: center;
    align-items: center;

    padding: 15px 0;

    background-color: ${LegacyTheme.buttonPrimary};
    color: ${LegacyTheme.white};

    font-size: 16px;
`;

export const InlineBlock = styled.div`
    display: inline-block;
`;

export const Flex = styled.div`
    display: flex;
`;

export const FormLabel = styled.label`
    display: block;
    margin-bottom: 6px;

    font-size: 13px;

    color: ${LegacyTheme.defaultSubtleColor};
`;

export const BlankButton = styled.button`
    padding: 0;

    background-color: transparent;
    border: 0 none;

    appearance: none;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
    align-items: center;

    min-height: ${({ minHeight }) => minHeight || '30px'};

    margin: ${({ margin }) => margin || '24px 0'};

    ${Title} {
        margin: 0;
        margin-right: 12px;
    }
`;

export const FooterContainer = styled.div`
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
    min-height: ${({ minHeight }) => minHeight || '30px'};

    margin: 24px 0;
`;

export const ColumnBody = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;

    overflow: auto;
`;

export const ColumnSection = styled.div`
    position: relative;
    display: flex;
    flex: 0;
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
    align-items: ${({ alignItems }) => alignItems || 'center'};
    align-content: ${({ alignContent }) => alignContent || 'center'};

    min-height: ${({ minHeight }) => minHeight || '30px'};

    margin: ${({ margin }) => margin || '0'};
    padding: ${({ padding }) => padding || '0'};
`;

export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    ${({ flex }) =>
        !flex &&
        css`
            display: block;
            height: auto;

            ${ColumnBody}, ${ColumnSection} {
                display: block;
            }
        `};
`;

export const ContentContainer = styled.div`
    margin: ${({ margin }) => margin || '0'};
    padding: ${({ padding }) => padding || '0'};
`;

export const MessageBlockContainer = styled.div`
    position: absolute;
    bottom: 100%;
    right: ${({ right }) => right || 0};
`;

export const ProductToolTip = styled.div`
    max-width: 210px;

    padding: 16px;

    text-align: left;

    ${SimpleList} {
        margin-left: 0;
    }
`;
