// @flow

import styled from 'styled-components';

export const FrotherSurveyCustomBlendBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const FrotherSurveyCustomBlendBlockPercentage = styled.div`
    width: 20%;
`;

export const FrotherSurveyCustomBlendBlockId = styled.div`
    width: 75%;
`;
