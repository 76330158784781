// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

export const Container = styled.div`
    padding: 0px 10px;
    color: ${LegacyTheme.tableSubtleColor};

    > div:not(:last-child) {
        margin-bottom: 32px;
    }
`;

export const CountryRestrictions = styled.div`
    color: ${LegacyTheme.defaultSubtleColor7B};
    font-family: inherit;
    font-size: 14px;
`;

export const ModalTrigger = styled.span`
    cursor: pointer;
    text-decoration: underline;
    margin-left: 5px;
`;

export const TableTitle = styled.div`
    color: ${LegacyTheme.defaultSubtleColor7B};
    font-family: inherit;
    font-size: 16px;
    margin-bottom: 10px;
`;

export const ProductOfferContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${LegacyTheme.defaultSubtleColor};
    font-size: 12px;

    > :first-child {
        font-size: 12px;
    }

    > :nth-child(2) {
        margin-left: 5px;
    }
`;
