// @flow

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

import { CheckBox, Close, LegacyTheme, Triangle } from 'OsedeaReactUI';
import {
    BLENDING_STATUS_SHOULDNOT,
    BLENDING_STATUS_NEEDSOTHER,
    BLENDING_STATUS_CANNOT,
    BLENDING_STATUS_CAN,
} from 'utils/constants';

// Constants
export const CHECK_INPUT = 'check';
export const CLOSE = 'close';

// Styles
import {
    BlockContainer,
    BlockWrapper,
    CheckboxWrapper,
    CloseWrapper,
    Circle,
    Description,
    DropDownContainer,
    DropDownContent,
    DropDownHeader,
    Name,
    Split,
    Wrapper,
} from './styles';

// Types
import type { ImmutableList, ImmutableMap, InputEvent, IntlType } from 'types';
import type { ConstraintType } from 'services/BuildingBlock/types';

// Utils
import { mapIndexToColor } from 'utils/helpers';

type Props = {
    blendingConstraints?: ImmutableList<ConstraintType>,
    closeIconMarginRight?: string,
    colors?: ImmutableMap<string, string>,
    disabled?: boolean,
    fontSize: ?string,
    hideType: ?boolean,
    hideDropdown: ?boolean,
    inputType: ?CHECK_INPUT | CLOSE,
    intl: IntlType,
    name: string,
    maxWidth?: string,
    onClickCheckBox: ?(id: number) => void,
    onClickClose: ?(id: number) => void,
    selected?: boolean,
    type: ?string,
};

type State = {
    dropdown?: boolean,
    selected: boolean,
};

class BuildingBlockItem extends React.PureComponent<Props, State> {
    static defaultProps = {
        blendingConstraints: null,
        colors: fromJS(mapIndexToColor()),
        closeIconMarginRight: null,
        disabled: false,
        maxWidth: null,
        selected: false,
    };

    state = {
        dropdown: false,
    };

    handleOnClickDropdown = () => {
        this.setState((prevState: State) => ({
            dropdown: !prevState.dropdown,
        }));
    };

    handleOnClickCheckBox = (event: InputEvent) => {
        this.props.onClickCheckBox(event.target.checked);
    };

    handleOnClickClose = (event: InputEvent) => {
        this.props.onClickClose(event.target.checked);
    };

    renderDropDownContent = () => {
        let constraints = [];
        const defaultConstraintMessage = (
            <p>
                {this.props.intl.formatMessage({
                    id: 'components.BuildingBlockItem.noConstraints',
                })}
            </p>
        );

        if (this.props.blendingConstraints && this.props.blendingConstraints.size) {
            const keys = this.props.blendingConstraints.keySeq().toArray();
            constraints = keys
                .map((status: string) => {
                    const conflictingBuildingBlocks = this.props.blendingConstraints.get(status);
                    if (conflictingBuildingBlocks.isEmpty()) {
                        return null;
                    }

                    // Edge case for Building Blocks that either cannot be blended with others or needs to be blended with others
                    if (
                        status === BLENDING_STATUS_SHOULDNOT ||
                        status === BLENDING_STATUS_NEEDSOTHER
                    ) {
                        return (
                            <p key={status}>
                                {this.props.intl.formatMessage({
                                    id: `components.BuildingBlockItem.${status}`,
                                })}
                            </p>
                        );
                    }

                    let listHeader;
                    let listNote;
                    switch (status) {
                        case BLENDING_STATUS_CANNOT:
                        default:
                            listHeader = this.props.intl.formatMessage({
                                id: 'components.BuildingBlockItem.cannotBeBlended',
                            });
                            listNote = (
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: 'components.BuildingBlockItem.cannotBeBlendedNote',
                                    })}
                                </p>
                            );
                            break;
                        case BLENDING_STATUS_CAN:
                            listHeader = this.props.intl.formatMessage({
                                id: 'components.BuildingBlockItem.canBeBlended',
                            });
                            break;
                    }

                    return (
                        <React.Fragment key={status}>
                            {listHeader}
                            <ul>
                                {conflictingBuildingBlocks.map((blockName: string) => (
                                    <li key={blockName}>{blockName}</li>
                                ))}
                            </ul>
                            {listNote}
                        </React.Fragment>
                    );
                })
                .filter((value: string) => value);
        }

        // Build up dropdown content with header, warning & conflicting building block content
        const dropDownContainer = !this.props.hideDropdown &&
            this.state.dropdown && (
                <DropDownContainer>
                    <DropDownHeader>
                        {this.props.intl.formatMessage({
                            id: 'components.BuildingBlockItem.recommendations',
                        })}
                    </DropDownHeader>
                    <DropDownContent>
                        {constraints.length ? constraints : defaultConstraintMessage}
                    </DropDownContent>
                </DropDownContainer>
            );

        return dropDownContainer;
    };

    render() {
        // If inputType is CHECK_INPUT, show checkbox, else show 'x'
        let buildingBlockSelector;
        if (this.props.inputType === CHECK_INPUT && this.props.onClickCheckBox) {
            buildingBlockSelector = (
                <CheckboxWrapper>
                    <CheckBox
                        name={this.props.name}
                        checked={Boolean(this.props.selected)}
                        disabled={this.props.disabled}
                        onClickHandler={this.handleOnClickCheckBox}
                        stateless
                    />
                </CheckboxWrapper>
            );
        }

        if (this.props.inputType === CLOSE && this.props.onClickClose) {
            buildingBlockSelector = (
                <CloseWrapper
                    onClick={this.handleOnClickClose}
                    closeIconMarginRight={this.props.closeIconMarginRight}
                >
                    <Close fill={LegacyTheme.defaultSubtleColor7B} strokeWidth={0.1} clickable />
                </CloseWrapper>
            );
        }

        // Build up dropdown toggler markup
        const dropDownToggler = !this.props.hideDropdown && (
            <Circle onClick={this.handleOnClickDropdown} color={this.props.colors.get('accent')}>
                <Triangle
                    color={LegacyTheme.white}
                    degrees={!this.state.dropdown && 180}
                    height={5}
                    margin={this.state.dropdown ? '-1px auto 0' : 'auto'}
                />
            </Circle>
        );

        // If type is provided, build up markup
        const type = !this.props.hideType &&
            this.props.type && (
                <Description>
                    <Split
                        color={this.props.colors.get('divider') || this.props.colors.get('accent')}
                    />
                    {this.props.type}
                </Description>
            );

        return (
            <Wrapper maxWidth={this.props.maxWidth}>
                {buildingBlockSelector}
                <BlockWrapper>
                    <BlockContainer
                        backgroundColor={this.props.colors.get('primary')}
                        borderColor={this.props.colors.get('accent')}
                        open={this.state.dropdown}
                        selected={this.props.selected}
                    >
                        <Name disabled={this.props.disabled} fontSize={this.props.fontSize}>
                            {this.props.name}
                        </Name>
                        {type}
                        {this.props.blendingConstraints &&
                            this.props.blendingConstraints.size &&
                            dropDownToggler}
                    </BlockContainer>
                    {this.renderDropDownContent()}
                </BlockWrapper>
            </Wrapper>
        );
    }
}

export default injectIntl(BuildingBlockItem);
