// @flow

import { combineReducers } from 'redux-immutable';

// Management
import authenticationServiceReducer from 'management/services/Authentication/reducer';
import disclaimerServiceReducer from 'management/services/Disclaimer/reducer';
import userServiceReducer from 'management/services/User/reducer';
// Application
import buildingBlockServiceReducer from 'services/BuildingBlock/reducer';
import languageProviderReducer from 'services/Language/reducer';
import productServiceReducer from 'services/Product/reducer';
import projectServiceReducer from 'services/Project/reducer';
import resultServiceReducer from 'services/Result/reducer';
import routeReducer from 'services/Route/reducer';
import surveyServiceReducer from 'services/Survey/reducer';

/**
 * Returns a single reducer object whose keys point to sub-reducers.
 * This reducer is used to help create the store in createStore().
 */
const rootReducer = combineReducers({
    // Management
    auth: authenticationServiceReducer,
    disclaimers: disclaimerServiceReducer,
    users: userServiceReducer,
    // Application
    language: languageProviderReducer,
    route: routeReducer,
    projects: projectServiceReducer,
    products: productServiceReducer,
    buildingBlocks: buildingBlockServiceReducer,
    survey: surveyServiceReducer,
    results: resultServiceReducer,
});

export default rootReducer;
