/* eslint-disable flowtype/no-weak-types */
// @flow

import React from 'react';

// Styles
import { Wrapper, Header, Body, Footer, Field, FieldHeader } from './styles';

// Components
import { Title } from 'styles/common';
import { PrimaryButton, SecondaryButton, InputSelect, Modal } from 'OsedeaReactUI';

// Constants
import { MODAL_WIDTH } from 'utils/constants';

// Types
import type { ReactSelectObject } from 'types';

type Props = {
    list: Array<ReactSelectObject>,
    loading: boolean,
    onConfirm: (selectedItem: ReactSelectObject) => void,
    onCancel: () => void,
    strings: {
        title: string,
        fieldTitle: string,
        cancelButton: string,
        saveButton: string,
    },
};

type State = {
    selectedItem: ?ReactSelectObject,
};

class AddItemToListModal extends React.PureComponent<Props, State> {
    state = {
        selectedItem: null,
    };

    /**
     * Handle the save button click
     */
    handleSaveClicked = () => {
        // Fire the save update to the container
        if (this.state.selectedItem) {
            this.props.onConfirm(this.state.selectedItem);
        }
    };

    // Handle item selection on `react-ui_input-select` (`onSelect` prop)
    handleItemSelected = (selectedItem: ReactSelectObject) => this.setState({ selectedItem });

    render() {
        return (
            <Modal
                modalWidth={MODAL_WIDTH.SMALL}
                onHandleClose={this.props.onCancel}
                style={{ content: { overflowY: 'visible' } }}
                disableClose
            >
                <Wrapper>
                    <Header>
                        <Title>{this.props.strings.title}</Title>
                    </Header>
                    <Body>
                        <Field>
                            <FieldHeader>{this.props.strings.fieldTitle}</FieldHeader>
                            <InputSelect
                                options={this.props.list}
                                onSelect={this.handleItemSelected}
                                selectedOption={this.state.selectedItem}
                                controlShouldRenderValue
                                isSearchable
                            />
                        </Field>
                    </Body>
                    <Footer>
                        <SecondaryButton
                            onClick={this.props.onCancel}
                            text={this.props.strings.cancelButton}
                        />
                        <PrimaryButton
                            disabled={!this.state.selectedItem}
                            loading={this.props.loading}
                            onClick={this.handleSaveClicked}
                            text={this.props.strings.saveButton}
                        />
                    </Footer>
                </Wrapper>
            </Modal>
        );
    }
}

export default AddItemToListModal;
