// @flow

import React from 'react';
import firebase from 'firebase/app';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

// Assets
import loginBackground from 'assets/login-background.jpg';

// Components
import LoginWithDisclaimers from 'management/components/LoginWithDisclaimers';

// Config
import { appBlockingMessage } from 'env';

// Constants
import { MANAGEMENT_CONFIG } from 'utils/constants';

// Selectors
import {
    selectAcceptDisclaimerSaving,
    selectErrors,
    selectUser,
    selectUserIsFetching,
} from 'management/services/Authentication/selectors';

import {
    selectDisclaimers,
    selectDisclaimersAreFetching,
} from 'management/services/Disclaimer/selectors';

// Styles
import { ContainerCentered } from 'styles/common';

// Thunks
import { userAcceptDisclaimer } from 'management/services/Authentication/thunks';
import { fetchDisclaimers } from 'management/services/Disclaimer/thunks';

// Types
import type { AuthenticateUserType } from 'management/services/Authentication/types';
import type { DisclaimerType } from 'management/services/Disclaimer/types';
import type { ImmutableList, IntlType, ReduxDispatch, ErrorType } from 'types';

// Utils
import { formatDisclaimers } from 'management/utils';

type Props = {
    acceptDisclaimerSaving: boolean,
    disclaimers: ImmutableList<DisclaimerType>,
    listIsFetching: boolean,
    errors?: ErrorType,
    fetchDisclaimers: () => void,
    intl: IntlType,
    userAcceptDisclaimer: (key: string) => void,
    user?: AuthenticateUserType,
    userIsFetching: boolean,
};

export class Login extends React.PureComponent<Props> {
    static defaultProps = {
        errors: null,
        user: null,
    };

    componentDidMount() {
        this.props.fetchDisclaimers();
    }

    uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
    };

    handleDisclaimerAcceptance = (key: string) => {
        this.props.userAcceptDisclaimer(key);
    };

    renderLoginUI = () => (
        <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
    );

    render() {
        return (
            <ContainerCentered>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'management.views.Login.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'management.views.Login.helmetDescription',
                        })}
                    />
                </Helmet>
                <LoginWithDisclaimers
                    appBlockingMessage={appBlockingMessage}
                    backgroundImage={loginBackground}
                    disclaimers={formatDisclaimers(this.props.disclaimers.toJS(), this.props.intl)}
                    errors={this.props.errors}
                    isLoading={this.props.userIsFetching || this.props.listIsFetching}
                    notAuthorizedMessage={this.props.intl.formatMessage({ id: 'management.views.Login.notAuthorized' })}
                    onDisclaimerAcceptance={this.handleDisclaimerAcceptance}
                    renderLoginUI={this.renderLoginUI}
                    titleLogo={MANAGEMENT_CONFIG.titleLogo}
                    uiIsLoading={this.props.acceptDisclaimerSaving}
                    user={this.props.user ? this.props.user.toJS() : null}
                />
            </ContainerCentered>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    disclaimers: selectDisclaimers(),
    listIsFetching: selectDisclaimersAreFetching(),
    errors: selectErrors(),
    user: selectUser(),
    userIsFetching: selectUserIsFetching(),
    acceptDisclaimerSaving: selectAcceptDisclaimerSaving(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchDisclaimers,
            userAcceptDisclaimer,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(Login))
);
