// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Constants
import { ERROR_MESSAGE_MARGIN_TOP } from 'utils/constants';

// Components
import { Common, CollapsibleBlock, InputField } from 'OsedeaReactUI';
import ErrorMessage from 'components/ErrorMessage';

// Styles
import { FormLabel } from 'styles/common';

// Types
import type { EventType, IntlType } from 'types';

type Props = {
    currentAgreement: ImmutableMap<string, string | number>,
    handleOnChange: (string) => void,
    intl: IntlType,
    errors: ImmutableMap<string, boolean>,
    disabled: boolean,
};

export class AgreementCustomer extends React.PureComponent<Props> {
    /**
     * On InputField change, fire props.handleOnChange(name)(value) to save to currentAgreement
     */
    handleOnChange = (name: string) => (event: EventType) => {
        this.props.handleOnChange(name)(event.target.value);
    };

    render() {
        return (
            <CollapsibleBlock
                title={this.props.intl.formatMessage({
                    id: 'components.AgreementCustomer.blockTitle',
                })}
            >
                <Common.Row gutter={16}>
                    <Common.Column>
                        <FormLabel>
                            {this.props.intl.formatMessage({
                                id: 'components.AgreementCustomer.customerNameLabel',
                            })}
                        </FormLabel>
                        <InputField
                            onChange={this.handleOnChange('customerName')}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.AgreementCustomer.customerNamePlaceholder',
                            })}
                            value={this.props.currentAgreement.get('customerName')}
                            disabled={this.props.disabled}
                        />
                        {this.props.errors.get('customerName') && (
                            <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                {this.props.intl.formatMessage({
                                    id: 'components.ErrorMessage.required',
                                })}
                            </ErrorMessage>
                        )}
                    </Common.Column>
                    <Common.Column>
                        <FormLabel>
                            {this.props.intl.formatMessage({
                                id: 'components.AgreementCustomer.customerTitleLabel',
                            })}
                        </FormLabel>
                        <InputField
                            onChange={this.handleOnChange('customerTitle')}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.AgreementCustomer.customerTitlePlaceholder',
                            })}
                            value={this.props.currentAgreement.get('customerTitle')}
                            disabled={this.props.disabled}
                        />
                        {this.props.errors.get('customerTitle') && (
                            <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                {this.props.intl.formatMessage({
                                    id: 'components.ErrorMessage.required',
                                })}
                            </ErrorMessage>
                        )}
                    </Common.Column>
                    <Common.Column>
                        <FormLabel>
                            {this.props.intl.formatMessage({
                                id: 'components.AgreementCustomer.customerSiteLabel',
                            })}
                        </FormLabel>
                        <InputField
                            onChange={this.handleOnChange('customerMineSite')}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.AgreementCustomer.customerSitePlaceholder',
                            })}
                            value={this.props.currentAgreement.get('customerMineSite')}
                            disabled={this.props.disabled}
                        />
                        {this.props.errors.get('customerMineSite') && (
                            <ErrorMessage marginTop={ERROR_MESSAGE_MARGIN_TOP}>
                                {this.props.intl.formatMessage({
                                    id: 'components.ErrorMessage.required',
                                })}
                            </ErrorMessage>
                        )}
                    </Common.Column>
                </Common.Row>
            </CollapsibleBlock>
        );
    }
}

export default injectIntl(AgreementCustomer);
