// @flow

import type { FeedbackType } from './types';

/**
 * Actions
 *
 * Define constant types for each action
 * For each type, define action to pass data/errors as payload
 */

export const CREATE_FEEDBACK = 'app/management/services/Feedback/CREATE_FEEDBACK';
export const DESTROY_FEEDBACK = 'app/management/services/Feedback/DESTROY_FEEDBACK';
export const DESTROY_ALL_FEEDBACKS = 'app/management/services/Feedback/DESTROY_ALL_FEEDBACKS';

export const createFeedback = (feedbackType: FeedbackType, messageId: string, data: ?any) => ({
    type: CREATE_FEEDBACK,
    payload: {
        feedbackType,
        messageId,
        data,
    },
});

export const destroyFeedback = (feedbackId: number) => ({
    type: DESTROY_FEEDBACK,
    payload: {
        feedbackId,
    },
});

export const destroyAllFeedbacks = () => ({
    type: DESTROY_ALL_FEEDBACKS,
    payload: null,
});
