// @flow

import { fromJS } from 'immutable';

// Actions
import {
    CREATE_SUCCESS,
    CREATE_SUCCESS_WITH_UPDATE,
    CREATE_FAILURE,
    DELETE_SUCCESS,
    DELETE_FAILURE,
    FETCH_SUCCESS,
    FETCH_SUCCESS_WITH_UPDATE,
    FETCH_FAILURE,
    FETCH_LIST_SUCCESS,
    FETCH_LIST_FAILURE,
    LIST_IS_FETCHING,
    REPORT_FAILURE,
    REPORT_SUCCESS,
    SINGLE_PROJECT_IS_FETCHING,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    SET_CLEAR_SINGLE_PROJECT_SUCCESS,
    SET_SURVEY_FILLED_SUCCESS,
    CREATE_CUSTOM_BLEND_SUCCESS,
    CREATE_CUSTOM_BLEND_FAILURE,
    CREATE_CUSTOM_BLEND_IS_SUBMITTING,
    RESET_CUSTOM_BLEND_STATE,
    ADD_PRODUCTS_TO_DISCOVERY_SUCCESS,
    ADD_PRODUCTS_TO_DISCOVERY_FAILURE,
    FETCH_DISCOVERY_AGREEMENT_SUCCESS,
    FETCH_DISCOVERY_AGREEMENT_FAILURE,
    DISCOVERY_AGREEMENT_IS_FETCHING,
    SAVE_DISCOVERY_AGREEMENT_TO_PROJECT_SUCCESS,
    SAVE_DISCOVERY_AGREEMENT_TO_PROJECT_FAILURE,
    DISCOVERY_AGREEMENT_IS_SUBMITTING,
    RESET_ERRORS_SUCCESS,
    FETCH_COMPANY_ADDRESSES_LIST_SUCCESS,
    FETCH_COMPANY_ADDRESSES_LIST_FAILURE,
    RESET_DISCOVERY_AGREEMENT_SUCCESS,
} from './actions';

// Helper
import { capitalizeWord } from 'utils/helpers';

// Types
import type { GenericActionType, ImmutableList } from 'types';
import type { ProjectType } from './types.js';

type State = {
    customBlendIsSubmitting: boolean,
    discoveryAgreement: ?{},
    discoveryAgreementIsFetching: boolean,
    discoveryAgreementIsSubmitting: boolean,
    companyAddresses: [],
    latestCreatedCustomBlendName: string,
    list: [],
    listIsFetching: boolean,
    productCountAdded: ?number,
    singleProject?: ProjectType,
    singleProjectIsFetching: boolean,
    totalCount: number,
    lastPage: number,
    withSearch: boolean,
    errors: {},
};

const initialState = fromJS({
    customBlendIsSubmitting: false,
    discoveryAgreementIsFetching: false,
    discoveryAgreement: {},
    discoveryAgreementIsSubmitting: false,
    companyAddresses: [],
    latestCreatedCustomBlendName: null,
    list: [],
    listIsFetching: false,
    productCountAdded: null,
    singleProject: null,
    singleProjectIsFetching: false,
    totalCount: 0,
    lastPage: 0,
    withSearch: false,
    errors: {},
});

/**
 * Reducer
 *
 * Switch statement to set state based on current action type
 */

function projectServiceReducer(state: State = initialState, action: GenericActionType) {
    switch (action.type) {
        case CREATE_SUCCESS:
        case FETCH_SUCCESS: {
            const single = action.payload.data;
            return state
                .set('singleProject', fromJS(single))
                .set('discoveryAgreement', fromJS({}))
                .set('singleProjectIsFetching', false)
                .set('errors', fromJS({}));
        }
        case CREATE_SUCCESS_WITH_UPDATE:
        case FETCH_SUCCESS_WITH_UPDATE: {
            const single = action.payload.data;
            // Capitalize the projectType, as it's returned as lowercase from the backend
            single.projectType = capitalizeWord(single.projectType);
            // For frotherBlends, format them to a common type
            if (single.filteredFrotherBlends) {
                single.filteredFrotherBlends = single.filteredFrotherBlends.map((blend: any) => {
                    return {
                        ...blend,
                        name: blend.buildingBlocks,
                        // Modify hasAssociatedProduct to 0 or 1, matching the other props
                        hasAssociatedProduct: blend.hasAssociatedProduct ? 1 : 0,
                        pivot: {
                            stability: blend.stability,
                            mobility: blend.mobility,
                            bubbleSize: blend.bubbleSize,
                            selectivity: blend.selectivity,
                            dosageEfficiency: blend.ccc,
                            persistence: blend.persistence,
                        },
                    };
                });
            }
            return state
                .set('singleProject', fromJS(single))
                .set('discoveryAgreement', fromJS({}))
                .updateIn(['list'], (list: ImmutableList<ProjectType>) => {
                    // Check if list contains project by id, if so replace it, else unshift (append) to list
                    if (list) {
                        const idx = list.findIndex(
                            (item: ProjectType) => item.get('id') === single.id
                        );
                        return idx !== -1
                            ? list.setIn([idx], fromJS(single))
                            : list.unshift(fromJS(single));
                    }
                })
                .set('singleProjectIsFetching', false)
                .set('errors', fromJS({}));
        }
        case DELETE_SUCCESS:
            return state
                .updateIn(['list'], (list: ImmutableList<ProjectType>) => {
                    // Delete project within list via payload.data (project id)
                    if (list && !list.isEmpty()) {
                        const idx = list.findIndex(
                            (item: ProjectType) => item.get('id') === action.payload.data
                        );
                        return list.delete(idx);
                    }
                    return list;
                })
                .set('listIsFetching', false)
                .set('errors', fromJS({}));
        case DELETE_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('singleProjectIsFetching', false);
        case UPDATE_SUCCESS: {
            const single = action.payload.data;
            return state
                .set('singleProject', fromJS(single))
                .updateIn(['list'], (list: ImmutableList) => {
                    const idx = list.findIndex((item: ProjectType) => item.get('id') === single.id);
                    return list.setIn([idx], fromJS(single));
                })
                .set('singleProjectIsFetching', false)
                .set('errors', fromJS({}));
        }
        case CREATE_FAILURE:
        case FETCH_FAILURE:
        case UPDATE_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('singleProjectIsFetching', false);
        case FETCH_LIST_SUCCESS: {
            const data = action.payload.data;
            return state
                .set('totalCount', data.total)
                .set('lastPage', data.lastPage)
                .set('list', fromJS(data.data))
                .set('listIsFetching', false)
                .set('withSearch', data.withSearch)
                .set('errors', fromJS({}));
        }
        case FETCH_LIST_FAILURE:
            return state.set('errors', fromJS(action.payload.errors)).set('listIsFetching', false);
        case LIST_IS_FETCHING:
            return state.set('listIsFetching', action.payload.listIsFetching);
        case REPORT_FAILURE:
            return state
                .setIn(['singleProject', 'reported'], false)
                .set('errors', fromJS(action.payload.errors));
        case REPORT_SUCCESS:
            return state.setIn(['singleProject', 'reported'], true);
        case SINGLE_PROJECT_IS_FETCHING:
            return state.set('singleProjectIsFetching', action.payload.singleProjectIsFetching);
        case SET_CLEAR_SINGLE_PROJECT_SUCCESS:
            return state.set('singleProject', initialState.get('singleProject'));
        case SET_SURVEY_FILLED_SUCCESS:
            return state.setIn(['singleProject', 'surveyFilled'], true);
        case CREATE_CUSTOM_BLEND_SUCCESS:
            return state
                .set('latestCreatedCustomBlendName', action.payload.data.name)
                .set('customBlendIsSubmitting', false);
        case CREATE_CUSTOM_BLEND_FAILURE:
            return state
                .set('latestCreatedCustomBlendName', null)
                .set('customBlendIsSubmitting', false)
                .set('errors', fromJS(action.payload.errors))
                .setIn(['errors', 'notAdded'], 'customBlend');
        case CREATE_CUSTOM_BLEND_IS_SUBMITTING:
            return state.set('customBlendIsSubmitting', action.payload.data);
        case RESET_CUSTOM_BLEND_STATE:
            return state
                .set('customBlendIsSubmitting', false)
                .set('latestCreatedCustomBlendName', null)
                .set('productCountAdded', null)
                .set('errors', fromJS({}));
        case ADD_PRODUCTS_TO_DISCOVERY_SUCCESS: {
            // Creating an array of product ids stored in state.
            const stateMappedIds = state
                .getIn(['singleProject', 'products'])
                .map((product: {}) => product.get('id'));

            // From the sent product ids array, we filter out ids which do not exist in current
            // state. Then we grab the length of that array to find the count of products that
            // have been added.
            const productCountAdded = action.payload.sentProductIds.filter(
                (id: number) => !stateMappedIds.includes(id)
            ).length;

            return state
                .mergeIn(['singleProject'], fromJS(action.payload.data))
                .set('customBlendIsSubmitting', false)
                .set('productCountAdded', productCountAdded);
        }

        case ADD_PRODUCTS_TO_DISCOVERY_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('customBlendIsSubmitting', false)
                .set('latestCreatedCustomBlendName', null)
                .set('productCountAdded', null)
                .setIn(['errors', 'notAdded'], 'existingProducts');

        case FETCH_COMPANY_ADDRESSES_LIST_SUCCESS: {
            return state.set('companyAddresses', fromJS(action.payload.data));
        }

        case FETCH_COMPANY_ADDRESSES_LIST_FAILURE: {
            return state.set('errors', fromJS(action.payload.errors));
        }

        case FETCH_DISCOVERY_AGREEMENT_SUCCESS: {
            const jsonParsedMeasurement = JSON.parse(action.payload.data.measurement);
            const projectProducts = action.payload.data.products;

            // Delete products from payload because we will be using state.singleProject.products
            // as the source of truth for the current project's list of products.
            delete action.payload.data.products;

            return state
                .set('discoveryAgreement', fromJS(action.payload.data))
                .setIn(['discoveryAgreement', 'measurement'], fromJS(jsonParsedMeasurement))
                .setIn(['singleProject', 'products'], fromJS(projectProducts))
                .set('discoveryAgreementIsFetching', false);
        }

        case FETCH_DISCOVERY_AGREEMENT_FAILURE: {
            return state
                .set('errors', action.payload.errors)
                .set('discoveryAgreementIsFetching', false);
        }

        case DISCOVERY_AGREEMENT_IS_FETCHING: {
            return state.set('discoveryAgreementIsFetching', true);
        }

        case SAVE_DISCOVERY_AGREEMENT_TO_PROJECT_SUCCESS: {
            const jsonParsedMeasurement = JSON.parse(action.payload.data.measurement);
            const projectProducts = action.payload.data.products;

            // Delete products from payload because we will be using state.singleProject.products
            // as the source of truth for the current project's list of products.
            delete action.payload.data.products;

            return state
                .set('discoveryAgreement', fromJS(action.payload.data))
                .setIn(['discoveryAgreement', 'measurement'], fromJS(jsonParsedMeasurement))
                .setIn(['singleProject', 'products'], fromJS(projectProducts))
                .set('discoveryAgreementIsSubmitting', false);
        }

        case SAVE_DISCOVERY_AGREEMENT_TO_PROJECT_FAILURE: {
            return state
                .set('errors', fromJS(action.payload.errors))
                .setIn(['errors', 'notAdded'], 'discoveryAgreement')
                .set('discoveryAgreementIsSubmitting', false);
        }

        case DISCOVERY_AGREEMENT_IS_SUBMITTING: {
            return state.set('discoveryAgreementIsSubmitting', true);
        }

        case RESET_ERRORS_SUCCESS: {
            return state.set('errors', fromJS({}));
        }

        case RESET_DISCOVERY_AGREEMENT_SUCCESS: {
            return state.set('discoveryAgreement', null);
        }

        default:
            return state;
    }
}

export default projectServiceReducer;
