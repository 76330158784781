// @flow

import request from 'services/request';

import type { DisclaimerType } from './types';

/**
 * GET Request to return disclaimers
 */
export const getDisclaimers = () =>
    request('disclaimers', {
        method: 'GET',
    });

/**
 * POST Request to update a disclaimer
 *
 * @param {number} id
 * @param {DisclaimerType} disclaimer
 */
export const postDisclaimer = (id: number, disclaimer: DisclaimerType) =>
    request(`disclaimer/${id}`, {
        method: 'POST',
        data: { ...disclaimer },
    });
