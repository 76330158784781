// @flow

import styled from 'styled-components';
import { LegacyTheme } from 'OsedeaReactUI';

// Assets
import deleteIcon from 'assets/delete_1x.png';

export const ProductListContainer = styled.div`
    max-height: 123px;
    overflow: scroll;
    margin-top: 15px;

    ul {
        margin-top: 0px;

        > li:first-child {
            margin-top: 0px;
        }
    }
`;

export const DeleteButton = styled.button`
    background-image: url(${deleteIcon});
    background-size: contain;
    background-color: transparent;
    width: 16px;
    height: 16px;
    border: none;
    margin-right: 10px;
    cursor: pointer;
`;

export const ProductLabel = styled.span`
    color: ${LegacyTheme.checkboxLabelColor};
    font-family: inherit;
    font-size: 12px;
`;
