// @flow

import styled from 'styled-components';

export const FeedbackWrapper = styled.div`
    position: absolute;
    right: 0;
    padding-bottom: 20px;
    padding-right: 20px;
    height: 100%;
    display: flex;
    align-items: flex-end;
`;
