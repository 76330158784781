// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import { ButtonGrid, Common } from 'OsedeaReactUI';
import SurveyControls from 'components/SurveyControls';
import SurveyQuestionnaire from 'components/SurveyQuestionnaire';
import SurveyWeightingFactors from 'components/SurveyWeightingFactors';
import ErrorMessage from 'components/ErrorMessage';

// Constants
import {
    COLLECTOR_SURVEY_QUESTIONNAIRE,
    SURVEY_TYPE_PRIMARY,
    SURVEY_TYPE_SECONDARY,
    VIEW_BLOCK,
    VIEW_SURVEY,
    COLLECTOR_SURVEY_WEIGHTING_FACTORS,
} from 'utils/constants';

// Styles
import {
    FactorsWrapper,
    FactorsWrapperContainer,
    Intro,
    PaddingRightContainer,
    QuestionList,
    SurveyContainer,
    SurveyBody,
    SurveyFooter,
    SurveyHeader,
} from 'styles/survey';
import { Title } from 'styles/common';

// Helpers
import {
    getPrimaryBuildingBlocksTotals,
    getSecondaryBuildingBlocksTotals,
    getSurveyValidationErrors,
    mapSurveyQuestionnaireErrors,
    mapSurveyWeightingFactorsErrors,
    castSurveyNumberFieldToNumber,
} from 'utils/helpers';

// Types
import type { InputEvent, IntlType, ImmutableMap } from 'types';
import type { ProjectType } from 'services/Project/types';
import type { CollectorSurveyType, SurveyQuestionnaireType } from 'services/Survey/types';

type Props = {
    history: {
        push: () => void,
    },
    intl: IntlType,
    isSidebar?: boolean,
    onRevert?: () => void,
    onSubmit: (CollectorSurveyType) => void,
    goToBuildingBlocks?: () => void,
    project: ?ProjectType,
    survey: CollectorSurveyType,
};

type State = {
    currentPage: string,
    disableInputs: boolean,
    survey: ?CollectorSurveyType,
    invalidValueSets: Array<string>,
    questionnaireErrors: ImmutableMap,
    weightingFactorsErrors: ImmutableMap,
};

/**
 * CollectorSurveyForm
 *
 * Survey UI that handles and renders the questionnaire and weighting factor sliders used to
 * recommended building blocks for the Collectors.
 */
class CollectorSurveyForm extends React.Component<Props, State> {
    static defaultProps = {
        isSidebar: false,
        onRevert: null,
        title: null,
    };

    state = {
        currentPage: SURVEY_TYPE_PRIMARY,
        disableInputs: false,
        survey: this.props.survey,
        invalidValueSets: [],
        questionnaireErrors: fromJS({}),
        weightingFactorsErrors: fromJS({}),
    };

    /**
     * Conditionals used to determine what a component should do based on previous props
     */
    componentDidUpdate(prevProps: Props) {
        // Case: reverting to original survey
        if (this.props.survey.get('id') !== prevProps.survey.get('id')) {
            /* eslint-disable react/no-did-update-set-state */
            this.setState({ survey: this.props.survey });
        }
    }

    /**
     * Handles setting of state on survey input, select option, slider change. Runs live validations
     */
    handleOnChange = (isQuestionnaire?: boolean = true) => (
        value: string | number,
        key: string
    ) => {
        this.setState(
            (prevState: State) => ({
                survey: prevState.survey.set(key, value),
            }),
            () => {
                this.validateSurveyValueSet();

                if (this.state.questionnaireErrors.size) {
                    this.validateQuestionnaireFields();
                }

                if (!isQuestionnaire) {
                    this.validateWeightingFactorsFields();
                }
            }
        );
    };

    /**
     * Handles survey revert by disabling inputs & calling props.onRevert to fetch original survey
     * Check if Local (state) Survey is different from one provided by Global Store (props):
     * if true simply "undo" local state, else fire props.onRevert
     */
    handleSurveyRevert = () => {
        if (this.compareStateAndPropsSurvey()) {
            this.setState(
                {
                    disableInputs: true,
                },
                () => this.props.onRevert()
            );
        } else {
            this.setState({
                survey: this.props.survey,
            });
        }
    };

    /**
     * Validates both survey questionnaire and survey weighting factor values
     * If validation passes, function redirects to building-block page if no local change in survey has occurred (props/state)
     * Else it submits local survey to endpoint
     */
    handleSurveySubmit = () => {
        if (this.validateWeightingFactorsFields() && this.validateQuestionnaireFields()) {
            if (this.compareStateAndPropsSurvey()) {
                this.props.goToBuildingBlocks();
            } else {
                this.setState(
                    {
                        disableInputs: true,
                    },
                    () => this.props.onSubmit(this.state.survey)
                );
            }
        }
    };

    /**
     * Handles setting state.currentPage based on event.target.value
     */
    handleSetCurrentPage = (event: InputEvent) => this.onSetCurrentPage(event.target.value)();

    /**
     * Handles the setting of state.currentPage. If user tries to move to weighting factors,
     * first validate the questionnaire values. setState callback scrolls the user into the selected
     * CSS id.
     */
    onSetCurrentPage = (currentPage: string) => () => {
        // Validate survey when trying to go to weighting factors
        if (currentPage === SURVEY_TYPE_SECONDARY && !this.validateQuestionnaireFields()) {
            return;
        }

        this.setState(
            {
                currentPage,
            },
            () => this.scrollToTopOfElement(this.props.isSidebar ? 'surveyBody' : 'main')
        );
    };

    /**
     * Scrolls user view to selected element whose id matches the argument id
     */
    scrollToTopOfElement = (id: string) => {
        const element = id && document.getElementById(id);
        if (element) {
            element.scrollTo(0, 0);
        }
    };

    /**
     * Helper function which plucks a state.survey value based on provided string key
     */
    getSurveyField = (key: string) => this.state.survey && this.state.survey.get(key);

    /**
     * Helper function to check if Local (state) Survey is different from one provided by Global Store (props)
     */
    compareStateAndPropsSurvey = () => this.props.survey.equals(this.state.survey);

    validateSurveyValueSet = () => {
        const invalidValueSets = [];
        if (!this.validateNonMassiveSulfideOre()) {
            invalidValueSets.push({
                id: 'massiveSulfideOre',
                message: this.props.intl.formatMessage({
                    id: 'components.CollectorSurveyForm.validateNonMassiveSulfideOre',
                }),
            });
        }
        if (!this.validateTotalCopper()) {
            invalidValueSets.push({
                message: this.props.intl.formatMessage({
                    id: 'components.CollectorSurveyForm.validateTotalCopper',
                }),
            });
        }
        if (!this.validateMiddlingsPossibility()) {
            invalidValueSets.push({
                message: this.props.intl.formatMessage({
                    id: 'components.CollectorSurveyForm.validateMiddlingsPossibility',
                }),
            });
        }
        if (!this.validateNoRimmingOrVeinsPossible()) {
            invalidValueSets.push({
                message: this.props.intl.formatMessage({
                    id: 'components.CollectorSurveyForm.validateNoRimmingOrVeinsPossible',
                }),
            });
        }
        if (!this.validateSlagPercentageInfluence()) {
            invalidValueSets.push({
                message: this.props.intl.formatMessage({
                    id: 'components.CollectorSurveyForm.validateSlagPercentageInfluence',
                }),
            });
        }

        this.setState({
            invalidValueSets,
        });
    };

    /**
     * Validation: This Select-a-Guide is preferable for non-massive sulfide ores
     */
    validateNonMassiveSulfideOre = () => !this.state.survey.get('massiveSulfideOre');

    /**
     * Validation: No rimming or veins are possible if chalcopyrite percentage = 0 or 100 (Q17)
     */
    validateNoRimmingOrVeinsPossible = () => {
        if (this.state.survey.get('chalCoveDigeBornRimmingOnChalcopyrite')) {
            const percentageOfChalcopyrite = castSurveyNumberFieldToNumber(
                this.state.survey.get('percentageOfChalcopyrite')
            );
            return percentageOfChalcopyrite > 0 && percentageOfChalcopyrite < 100;
        }
        return true;
    };

    /**
     * Validation: Pyrite percentage = 0: it's not possible to have middlings (Q20)
     */
    validateMiddlingsPossibility = () => {
        if (this.state.survey.get('pyriteCopperSulfidesCompositesImportant')) {
            const percentageOfPyrite = castSurveyNumberFieldToNumber(
                this.state.survey.get('pyritePercentage')
            );
            return percentageOfPyrite > 0;
        }
        return true;
    };

    /**
     * Validation: Slag percentage = 100: all the other percentages among Py, Cp, Cc, Cv, Bo, Ncu and ASCu should equal 0.
     */
    validateSlagPercentageInfluence = () => {
        const percentOfSlagContent = castSurveyNumberFieldToNumber(
            this.state.survey.get('percentOfSlagContent')
        );
        if (percentOfSlagContent === 100) {
            const percentageOfPyrite = castSurveyNumberFieldToNumber(
                this.state.survey.get('pyritePercentage')
            );
            const percentageOfChalcocite = castSurveyNumberFieldToNumber(
                this.state.survey.get('percentageOfChalcocite')
            );
            const percentageOfChalcopyrite = castSurveyNumberFieldToNumber(
                this.state.survey.get('percentageOfChalcopyrite')
            );
            const percentageOfBornite = castSurveyNumberFieldToNumber(
                this.state.survey.get('percentageOfBornite')
            );
            const percentageOfCovellite = castSurveyNumberFieldToNumber(
                this.state.survey.get('percentageOfCovellite')
            );
            const nativeCopperContentGreaterThanTen = this.state.survey.get(
                'nativeCopperContentGreaterThanTen'
            );
            const oxideCopperMinerals = this.state.survey.get('oxideCopperMinerals');

            return (
                percentageOfPyrite +
                    percentageOfChalcocite +
                    percentageOfChalcopyrite +
                    percentageOfBornite +
                    percentageOfCovellite ===
                    0 &&
                !nativeCopperContentGreaterThanTen &&
                !oxideCopperMinerals
            );
        }
        return true;
    };

    /**
     * Validation: Total copper in the feed should be less than 100
     */
    validateTotalCopper = () => {
        const percentageOfChalcopyrite = castSurveyNumberFieldToNumber(
            this.state.survey.get('percentageOfChalcopyrite')
        );
        const percentageOfChalcocite = castSurveyNumberFieldToNumber(
            this.state.survey.get('percentageOfChalcocite')
        );
        const percentageOfCovellite = castSurveyNumberFieldToNumber(
            this.state.survey.get('percentageOfCovellite')
        );
        const percentageOfBornite = castSurveyNumberFieldToNumber(
            this.state.survey.get('percentageOfBornite')
        );

        const copperBase =
            percentageOfChalcopyrite +
            percentageOfChalcocite +
            percentageOfCovellite +
            percentageOfBornite;
        let copperAdditive = 0;
        if (this.state.survey.get('nativeCopperContentGreaterThanTen')) {
            copperAdditive += 10;
        }
        if (this.state.survey.get('oxideCopperMinerals')) {
            copperAdditive += 5;
        }

        return copperBase + copperAdditive <= 100;
    };

    /**
     * Validations for survey questionnaire values.
     * Returns a boolean value indicating whether the values are valid or not
     */
    validateQuestionnaireFields = () => {
        const questionnaireErrors = getSurveyValidationErrors({
            survey: this.state.survey,
            fields: COLLECTOR_SURVEY_QUESTIONNAIRE,
            arrayReduceFn: mapSurveyQuestionnaireErrors,
            arrayReduceInitialValue: {},
            intl: this.props.intl,
        });

        this.setState({ questionnaireErrors: fromJS(questionnaireErrors) });

        return Object.keys(questionnaireErrors).length === 0;
    };

    /**
     * Validations for survey weighting factor values.
     * Returns a boolean value indicating whether the values are valid or not
     */
    validateWeightingFactorsFields = () => {
        const errors = getSurveyValidationErrors({
            survey: this.state.survey,
            fields: COLLECTOR_SURVEY_WEIGHTING_FACTORS,
            arrayReduceFn: mapSurveyWeightingFactorsErrors,
            arrayReduceInitialValue: {
                primaryBuildingBlocksTotalPercent: 0,
                secondaryBuildingBlocksTotalPercent: 0,
            },
            intl: this.props.intl,
        });

        const areWeightingFactorsValid =
            errors.primaryBuildingBlocksTotalPercent === 100 &&
            errors.secondaryBuildingBlocksTotalPercent === 100;

        if (errors.primaryBuildingBlocksTotalPercent !== 100) {
            errors.primaryBuildingBlocks = true;
        }

        if (errors.secondaryBuildingBlocksTotalPercent !== 100) {
            errors.secondaryBuildingBlocks = true;
        }

        this.setState({ weightingFactorsErrors: fromJS(errors) });

        return areWeightingFactorsValid;
    };

    /**
     * Helper render function for value set errors
     */
    renderValueSetsErrors = () => {
        if (!this.state.invalidValueSets.length) {
            return null;
        }

        return (
            <Common.Row>
                <Common.Column alignItems="flex-end">
                    {this.state.invalidValueSets.map(
                        (error: { id?: 'string', message: string }) => (
                            <ErrorMessage key={error.id || error.message}>
                                {error.message}
                            </ErrorMessage>
                        )
                    )}
                </Common.Column>
            </Common.Row>
        );
    };

    /**
     * Helper render function for questionnaire errors
     */
    renderQuestionnaireErrors = () => {
        const errors = this.state.questionnaireErrors.size;

        if (this.state.currentPage !== SURVEY_TYPE_PRIMARY || !errors) {
            return null;
        }

        return (
            <Common.Row>
                <Common.Column alignItems="flex-end">
                    <ErrorMessage>
                        {this.props.intl.formatMessage({
                            id: 'components.ProjectSurvey.questionnaire.error',
                        })}
                    </ErrorMessage>
                </Common.Column>
            </Common.Row>
        );
    };

    /**
     * Helper render function for weighting factor errors
     */
    renderWeightingFactorErrors = () => {
        const { weightingFactorsErrors } = this.state;
        const errors =
            weightingFactorsErrors.get('primaryBuildingBlocks') ||
            weightingFactorsErrors.get('secondaryBuildingBlocks');

        if (this.state.currentPage !== SURVEY_TYPE_SECONDARY || !errors) {
            return null;
        }

        return (
            <Common.Row>
                <Common.Column alignItems="flex-end">
                    {weightingFactorsErrors.get('primaryBuildingBlocks') && (
                        <PaddingRightContainer>
                            <ErrorMessage>
                                {this.props.intl.formatMessage({
                                    id: 'components.ProjectSurvey.weightingFactors.error',
                                })}
                            </ErrorMessage>
                        </PaddingRightContainer>
                    )}
                </Common.Column>
                <Common.Column alignItems="flex-end">
                    {weightingFactorsErrors.get('secondaryBuildingBlocks') && (
                        <ErrorMessage>
                            {this.props.intl.formatMessage({
                                id: 'components.ProjectSurvey.weightingFactors.error',
                            })}
                        </ErrorMessage>
                    )}
                </Common.Column>
            </Common.Row>
        );
    };

    /**
     * Helper function for rendering the survey questionnaire
     */
    renderQuestionnaire = () => (
        <QuestionList marginTop={!this.props.isSidebar && '20px'}>
            {COLLECTOR_SURVEY_QUESTIONNAIRE.map((question: SurveyQuestionnaireType) => {
                const massiveSulfideOreInvalid = this.state.invalidValueSets.find(
                    (valueSet: { id?: ?string, message: string }) =>
                        valueSet.id === 'massiveSulfideOre'
                );
                const massiveSulfideOreInvalidMessage =
                    question.key === 'massiveSulfideOre' && massiveSulfideOreInvalid
                        ? massiveSulfideOreInvalid.message
                        : null;
                return (
                    <SurveyQuestionnaire
                        key={question.key}
                        disabled={
                            !this.props.project ||
                            (this.props.project && !this.props.project.get('isOwner')) ||
                            this.state.disableInputs ||
                            Boolean(
                                massiveSulfideOreInvalid && question.key !== 'massiveSulfideOre'
                            )
                        }
                        max={question.max}
                        min={question.min}
                        note={question.note}
                        onValueChangeHandler={this.handleOnChange(true)}
                        options={question.options}
                        questionKey={question.key}
                        text={question.text}
                        type={question.type}
                        value={this.getSurveyField(question.key)}
                        error={
                            this.state.questionnaireErrors.get(question.key) ||
                            massiveSulfideOreInvalidMessage
                        }
                        renderString={this.props.project && !this.props.project.get('isOwner')}
                    />
                );
            })}
        </QuestionList>
    );

    /**
     * Helper function for rendering the survey weighting factors
     */
    renderWeightingFactors = () => {
        const { intl, project, isSidebar } = this.props;
        const { weightingFactorsErrors, survey } = this.state;
        const disabled =
            !project || (project && !project.get('isOwner')) || this.state.disableInputs;

        const primary = (
            <SurveyWeightingFactors
                title={intl.formatMessage({
                    id: 'components.ProjectSurvey.weightingFactors.primary',
                })}
                toolTip={'Tooltip content'}
                weightingFactors={COLLECTOR_SURVEY_WEIGHTING_FACTORS}
                onSliderChange={this.handleOnChange(false)}
                disabled={disabled}
                buildingBlockFilter="primary"
                getSurveyField={this.getSurveyField}
                buildingBlockTotalPercent={getPrimaryBuildingBlocksTotals(survey)}
                buildingBlockInvalid={weightingFactorsErrors.get('primaryBuildingBlocks') || false}
                intl={intl}
                survey={survey}
            />
        );

        const secondary = (
            <SurveyWeightingFactors
                title={intl.formatMessage({
                    id: 'components.ProjectSurvey.weightingFactors.secondary',
                })}
                toolTip={'Tooltip content'}
                weightingFactors={COLLECTOR_SURVEY_WEIGHTING_FACTORS}
                onSliderChange={this.handleOnChange(false)}
                disabled={disabled}
                buildingBlockFilter="secondary"
                getSurveyField={this.getSurveyField}
                buildingBlockTotalPercent={getSecondaryBuildingBlocksTotals(survey)}
                buildingBlockInvalid={
                    weightingFactorsErrors.get('secondaryBuildingBlocks') || false
                }
                intl={intl}
                survey={survey}
            />
        );

        let content;
        if (isSidebar) {
            content = (
                <FactorsWrapperContainer>
                    <FactorsWrapper>{primary}</FactorsWrapper>
                    <FactorsWrapper>{secondary}</FactorsWrapper>
                </FactorsWrapperContainer>
            );
        } else {
            content = (
                <React.Fragment>
                    <Intro>
                        {intl.formatMessage({
                            id: 'components.ProjectSurvey.weightingFactors.description',
                        })}
                    </Intro>
                    <FactorsWrapper>
                        <Common.Row gutter={40}>
                            {/* Primary building block sliders*/}
                            <Common.Column>{primary}</Common.Column>
                            {/* Secondary building block sliders*/}
                            <Common.Column flex={1} justifyContent="flex-end">
                                {secondary}
                            </Common.Column>
                        </Common.Row>
                    </FactorsWrapper>
                </React.Fragment>
            );
        }

        return content;
    };

    /**
     * Helper function for rendering the title
     */
    renderTitle = () => {
        if (this.props.isSidebar) {
            return null;
        }

        const intlText =
            this.state.currentPage === SURVEY_TYPE_PRIMARY
                ? 'components.CollectorSurveyForm.primaryFormTitle'
                : 'components.CollectorSurveyForm.secondaryFormTitle';

        return <Title>{this.props.intl.formatMessage({ id: intlText })}</Title>;
    };

    /**
     * Helper function which controls the flow of which stage of the Survey to render
     */
    renderSurveyContent = (current: string) =>
        current === SURVEY_TYPE_PRIMARY
            ? this.renderQuestionnaire()
            : this.renderWeightingFactors();

    /**
     * Helper function for rendering the survey header
     */
    renderSurveyHeader = () =>
        this.props.isSidebar && (
            <SurveyHeader>
                <ButtonGrid
                    onClick={this.handleSetCurrentPage}
                    options={[
                        {
                            label: this.props.intl.formatMessage({
                                id: 'views.Blocks.collectorSurveyAnswers',
                            }),
                            value: SURVEY_TYPE_PRIMARY,
                        },
                        {
                            label: this.props.intl.formatMessage({
                                id: 'views.Blocks.weightingFactors',
                            }),
                            value: SURVEY_TYPE_SECONDARY,
                        },
                    ]}
                    value={this.state.currentPage}
                />
            </SurveyHeader>
        );

    /**
     * Main render function and output of component
     */
    render() {
        if (!this.props.project) {
            return null;
        }

        return (
            <SurveyContainer flex={this.props.isSidebar}>
                {this.renderTitle()}
                {this.renderSurveyHeader()}
                <SurveyBody id="surveyBody">
                    {this.renderSurveyContent(this.state.currentPage)}
                </SurveyBody>
                <SurveyFooter>
                    <SurveyControls
                        currentPage={this.state.currentPage}
                        disableSubmit={this.compareStateAndPropsSurvey()}
                        errorsValueSets={this.renderValueSetsErrors()}
                        errorsPrimarySurvey={this.renderQuestionnaireErrors()}
                        errorsSecondarySurvey={this.renderWeightingFactorErrors()}
                        onGoToSecondarySurvey={this.onSetCurrentPage(SURVEY_TYPE_SECONDARY)}
                        onGoToBuildingBlocks={this.props.goToBuildingBlocks}
                        onGoToMainSurvey={this.onSetCurrentPage(SURVEY_TYPE_PRIMARY)}
                        onSurveySubmit={this.handleSurveySubmit}
                        onSurveyRevert={this.handleSurveyRevert}
                        project={this.props.project}
                        view={this.props.isSidebar ? VIEW_BLOCK : VIEW_SURVEY}
                    />
                </SurveyFooter>
            </SurveyContainer>
        );
    }
}

export default withRouter(injectIntl(CollectorSurveyForm));
