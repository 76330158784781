// @flow

import { fromJS } from 'immutable';

// Actions
import {
    CONSTRAINTS_ARE_FETCHING,
    FETCH_BUILDING_BLOCK_CONSTRAINTS_SUCCESS,
    FETCH_BUILDING_BLOCK_CONSTRAINTS_FAIL,
    FETCH_CUSTOM_BLEND_CONSTRAINTS_SUCCESS,
    FETCH_CUSTOM_BLEND_CONSTRAINTS_FAIL,
    FETCH_BUILDING_BLOCKS_SUCCESS,
    FETCH_BUILDING_BLOCKS_FAIL,
    SET_FROTHER_BLENDS_FILTER,
    LIST_IS_FETCHING,
} from './actions';

// Types
import type { FrotherBlendsFilter } from './types';
import type { GenericActionType, ImmutableMap } from 'types';

type State = ImmutableMap<
    string,
    {
        constraints: [],
        constraintsAreFetching: boolean,
        customBlendProductExists: ?boolean,
        listIsFetching: boolean,
        frotherBlendsFilter: FrotherBlendsFilter,
        list: [],
        errors: {},
    }
>;

/**
 * Initial Immutable state of reducer passed as a default parameter
 */
const initialState = fromJS({
    constraints: [],
    constraintsAreFetching: false,
    customBlendProductExists: null,
    listIsFetching: false,
    frotherBlendsFilter: {},
    list: [],
    errors: {},
});

/**
 * Building blocks reducer
 *
 * Switch statement to set state based on current action type
 */

function buildingBlocksServiceReducer(state: State = initialState, action: GenericActionType) {
    switch (action.type) {
        case CONSTRAINTS_ARE_FETCHING:
            return state.set('constraintsAreFetching', true);
        case LIST_IS_FETCHING:
            return state.set('listIsFetching', true);
        case FETCH_BUILDING_BLOCK_CONSTRAINTS_SUCCESS:
            return state
                .set('constraints', fromJS(action.payload.data))
                .set('constraintsAreFetching', false);
        case FETCH_BUILDING_BLOCK_CONSTRAINTS_FAIL:
        case FETCH_CUSTOM_BLEND_CONSTRAINTS_FAIL:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('constraintsAreFetching', false);
        case FETCH_BUILDING_BLOCKS_SUCCESS:
            return state.set('list', fromJS(action.payload.data)).set('listIsFetching', false);
        case FETCH_BUILDING_BLOCKS_FAIL:
            return state.set('errors', fromJS(action.payload.errors)).set('listIsFetching', false);
        case FETCH_CUSTOM_BLEND_CONSTRAINTS_SUCCESS: {
            // Case where if a product already exists, payload will not have a constraints property.
            // If so, we will need to default to an empty array.
            const constraints = action.payload.data.constraints
                ? action.payload.data.constraints
                : [];

            return state
                .set('constraints', fromJS(constraints))
                .set('customBlendProductExists', fromJS(action.payload.data.productExist))
                .set('constraintsAreFetching', false);
        }
        case SET_FROTHER_BLENDS_FILTER:
            return state.set('frotherBlendsFilter', fromJS(action.payload.frotherBlendsFilter));
        default:
            return state;
    }
}

export default buildingBlocksServiceReducer;
